/**
 * @generated SignedSource<<225141221f5b3495c7eb78dee4deffa4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RunningTimeTrackWidget_employee$data = {
  readonly id: string;
  readonly " $fragmentType": "RunningTimeTrackWidget_employee";
};
export type RunningTimeTrackWidget_employee$key = {
  readonly " $data"?: RunningTimeTrackWidget_employee$data;
  readonly " $fragmentSpreads": FragmentRefs<"RunningTimeTrackWidget_employee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RunningTimeTrackWidget_employee",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Employee",
  "abstractKey": null
};

(node as any).hash = "cb6dd296d44a7169456c7b9670182ead";

export default node;
