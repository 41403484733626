import { FC } from "react";
import { NavigationItem } from "./NavigationItem";
import {
  useExpectPermission,
  usePreference,
  useViewerUsername,
} from "../../app-controller/ViewerContext";
import { WorkspaceUserIcon } from "./icons/WorkspaceUserIcon";
import { WorkspaceUsersIcon } from "./icons/WorkspaceUsersIcon";
import styles from "./NavigationItemWorkspace.module.scss";

export type Props = {
  onClick?: ((event: React.MouseEvent) => void) | undefined;
};

export const NavigationItemWorkspace: FC<Props> = function ({ onClick }) {
  const username = useViewerUsername();
  const navigationItemWorkspaceDestination = usePreference(
    "navigationItemWorkspaceDestination",
  );

  const normalLink = username
    ? `/user/${username}/workspace`
    : `/user/me/workspace`;
  const overviewLink = `/user/all/workspace`;

  const canViewFullTimeTrackList = useExpectPermission(
    "canViewFullTimeTrackList",
  );
  const canViewLastTwoWeeksTimeTrackList = useExpectPermission(
    "canViewLastTwoWeeksTimeTrackList",
  );
  const canView = canViewFullTimeTrackList || canViewLastTwoWeeksTimeTrackList;

  if (navigationItemWorkspaceDestination === "ALL_USERS" && canView) {
    return (
      <NavigationItem
        icon={<WorkspaceUsersIcon className={styles.icon} />}
        label="Workspace"
        link={overviewLink}
        onClick={onClick}
      />
    );
  } else {
    return (
      <NavigationItem
        icon={<WorkspaceUserIcon className={styles.icon} />}
        label="Workspace"
        link={normalLink}
        onClick={onClick}
      />
    );
  }
};
