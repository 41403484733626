import { HeaderLoader } from "../../components/HeaderLoading";
import { CustomerOverview } from "./CustomerOverview";
import { STORE_THEN_NETWORK, useQuery } from "relay-hooks";
import { CustomersRouteQuery } from "./__generated__/CustomersRouteQuery.graphql";
import { Header, Tab } from "../../components/Header";
import { useMemo } from "react";
import { useURLParams } from "../../util/useURLParams";
import { Icon } from "@mormahr/babel-transform-icon";

const queryDocument = graphql`
  query CustomersRouteQuery($onlyArchived: Boolean!) {
    ...CustomerOverview_query @arguments(onlyArchived: $onlyArchived)
  }
`;

function isArchivedParam(urlSearchParams: URLSearchParams): boolean {
  const param = urlSearchParams.get("archived");
  return param !== null && param !== undefined && param !== "false";
}

export function CustomersRoute() {
  const urlSearchParams = useURLParams();

  const variables = useMemo(() => {
    return {
      onlyArchived: isArchivedParam(urlSearchParams),
    };
  }, [urlSearchParams]);

  const {
    data: props,
    error,
    isLoading: cached,
  } = useQuery<CustomersRouteQuery>(queryDocument, variables, {
    fetchPolicy: STORE_THEN_NETWORK,
  });

  if (error) {
    throw error;
  }

  return (
    <>
      <Header
        title={variables.onlyArchived ? "Archiviert" : "Übersicht"}
        subTitle="Kunden"
        isLoading={cached}
      >
        <Tab
          to="/customer"
          icon={<Icon icon="user-doctor" type="solid" />}
          isActive={(_, location) =>
            !isArchivedParam(new URLSearchParams(location.search))
          }
        >
          Übersicht
        </Tab>
        <Tab
          to="/customer?archived"
          icon={<Icon icon="box-archive" type="solid" />}
          isActive={(_, location) =>
            isArchivedParam(new URLSearchParams(location.search))
          }
        >
          Archiviert
        </Tab>
      </Header>
      {props ? (
        <CustomerOverview query={props} archived={variables.onlyArchived} />
      ) : (
        <HeaderLoader />
      )}
    </>
  );
}
