import { FC } from "react";
import { Toolbar } from "../../components/Toolbar";
import { Option, options } from "./DailyQuarterHourlyHistogramOptions";
import { HTMLSelect } from "@blueprintjs/core";
import { useHistory } from "react-router";

export const DailyQuarterHourlyHistogramToolbar: FC<{ option: Option }> =
  function ({ option }) {
    const history = useHistory();

    return (
      <Toolbar>
        <HTMLSelect
          value={option}
          onChange={(e) => {
            const params = new URLSearchParams(history.location.search);
            params.set("filter", e.currentTarget.value);

            history.push({
              pathname: history.location.pathname,
              search: params.toString(),
            });
          }}
        >
          {options.map((_option) => (
            <option key={_option.key} value={_option.key}>
              {_option.label}
            </option>
          ))}
        </HTMLSelect>
      </Toolbar>
    );
  };
