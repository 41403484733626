import {
  addYears,
  startOfYear,
  subYears,
  formatISO,
  startOfMonth,
  subMonths,
  addMonths,
  startOfWeek,
  subWeeks,
} from "date-fns";
import { DailyQuarterHourlyHistogramQuery$variables } from "./__generated__/DailyQuarterHourlyHistogramQuery.graphql";

export type Option =
  | "ALL_TIME"
  | "LAST_YEAR"
  | "RUNNING_YEAR"
  | "CURRENT_YEAR"
  | "LAST_MONTH"
  | "CURRENT_MONTH"
  | "LAST_WEEK";

export const info: { [key in Option]: { label: string } } = {
  ALL_TIME: {
    label: "Alles",
  },
  RUNNING_YEAR: {
    label: "Laufendes Jahr",
  },
  LAST_YEAR: {
    label: "Letztes Jahr",
  },
  CURRENT_YEAR: {
    label: "Dieses Jahr",
  },
  LAST_MONTH: {
    label: "Letzter Monat",
  },
  CURRENT_MONTH: {
    label: "Aktueller Monat",
  },
  LAST_WEEK: {
    label: "Letzte Woche",
  },
};

export const options = (
  [
    "ALL_TIME",
    "LAST_YEAR",
    "RUNNING_YEAR",
    "CURRENT_YEAR",
    "LAST_MONTH",
    "CURRENT_MONTH",
    "LAST_WEEK",
  ] as const
).map((key) => ({ ...info[key], key }));

export function optionToVariables(
  option: Option,
  reference: Date,
): DailyQuarterHourlyHistogramQuery$variables {
  switch (option) {
    case "ALL_TIME":
      return {};
    case "LAST_YEAR":
      return {
        start: formatISO(subYears(startOfYear(reference), 1)),
        end: formatISO(startOfYear(reference)),
      };
    case "RUNNING_YEAR":
      return {
        start: formatISO(subYears(reference, 1)),
        end: formatISO(reference),
      };
    case "CURRENT_YEAR":
      return {
        start: formatISO(startOfYear(reference)),
        end: formatISO(addYears(startOfYear(reference), 1)),
      };
    case "LAST_MONTH":
      return {
        start: formatISO(subMonths(startOfMonth(reference), 1)),
        end: formatISO(startOfMonth(reference)),
      };
    case "CURRENT_MONTH":
      return {
        start: formatISO(startOfMonth(reference)),
        end: formatISO(addMonths(startOfMonth(reference), 1)),
      };
    case "LAST_WEEK":
      return {
        start: formatISO(
          subWeeks(startOfWeek(reference, { weekStartsOn: 1 }), 1),
        ),
        end: formatISO(startOfWeek(reference, { weekStartsOn: 1 })),
      };
  }
}
