import { Children, FC, ReactNode, useEffect, useState } from "react";
import styles from "./Header.module.scss";
import classNamesBind from "classnames/bind";
import { NavLink } from "react-router-dom";
import type { Location, LocationState } from "history";
import { useHeaderLoading } from "./HeaderLoading";
import { Icon } from "@mormahr/babel-transform-icon";

const cn = classNamesBind.bind(styles);

export interface Props {
  title: ReactNode;
  titleMenuButton?: ReactNode | null | undefined;
  subTitle?: ReactNode | null | undefined;

  /**
   * Indicate that we're displaying cached content or loading and should
   * indicate this to the user.
   */
  isLoading?: boolean | null | undefined;
  bigContainer?: boolean | null | undefined;

  back?: ReactNode | null | undefined;
}

export interface TabProps {
  to: string;
  exact?: boolean;
  icon?: ReactNode;
  isActive?: (match: never, location: Location<LocationState>) => boolean;
}

export const Tab: FC<TabProps> = function Tab({
  to,
  exact,
  children,
  icon,
  isActive,
}) {
  return (
    <NavLink
      to={to}
      exact={exact}
      isActive={isActive}
      activeClassName={cn("active")}
      className={cn("tab")}
    >
      <span className={cn("icon")}>{icon}</span>
      <span className={cn("text")}>{children}</span>
    </NavLink>
  );
};

export const Header: FC<Props> = function Header({
  title,
  titleMenuButton,
  subTitle,
  isLoading: _isLoading,
  bigContainer,
  back,
  children,
}) {
  const [debouncedCached, setDebouncedCached] = useState(false);
  const isLoadingContext = useHeaderLoading();
  const isLoading = _isLoading || isLoadingContext;

  useEffect(() => {
    const _cached = isLoading ?? false;

    if (!_cached) {
      setDebouncedCached(false);
      return;
    }

    const interval = setInterval(() => setDebouncedCached(true), 500);
    return () => clearInterval(interval);
  }, [isLoading]);

  return (
    <div className={cn("wrapper", { cached: debouncedCached })}>
      <header
        className={cn(bigContainer ? "big-header" : "header", {
          "no-nav": Children.count(children) === 0,
        })}
      >
        <div className={cn("back")}>
          {back ? (
            <div>
              <Icon
                icon="chevron-left"
                type="regular"
                className={cn("back-chevron")}
              />{" "}
              {back}
            </div>
          ) : null}
        </div>
        <div className={cn("primary")}>
          <h1 className={cn("title")}>
            {title}
            <span className={cn("menu-button")}>{titleMenuButton}</span>
          </h1>

          {Children.count(children) > 0 && (
            <nav className={cn("nav")}>{children}</nav>
          )}
        </div>

        <div className={cn("secondary")}>
          {subTitle && <h2 className={cn("sub-title")}>{subTitle}</h2>}
        </div>
      </header>
    </div>
  );
};
