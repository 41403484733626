/**
 * @generated SignedSource<<9663b9e6f066b69fc1e65dbe339680ed>>
 * @relayHash a1accc4a1f8e0dbc8a5f5713ecccc36a
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID a8f5e0cc4d7d9a91278ab7718207404423db89e7

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TodosQuery$variables = {
  order?: ReadonlyArray<string> | null;
  searchTerm?: string | null;
  onlyDone?: boolean | null;
  onlyNotDone?: boolean | null;
  onlyCreatedBy?: ReadonlyArray<string> | null;
  onlyEmployee?: ReadonlyArray<string> | null;
  includeUnassignedEmployee?: boolean | null;
  onlyCustomer?: ReadonlyArray<string> | null;
  onlyPriority?: ReadonlyArray<string> | null;
  onlyDueBefore?: string | null;
  onlyDueAfter?: string | null;
};
export type TodosQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"TodoConnectionProvider_query">;
};
export type TodosQuery = {
  variables: TodosQuery$variables;
  response: TodosQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeUnassignedEmployee"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "onlyCreatedBy"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "onlyCustomer"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "onlyDone"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "onlyDueAfter"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "onlyDueBefore"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "onlyEmployee"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "onlyNotDone"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "onlyPriority"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchTerm"
},
v11 = {
  "kind": "Variable",
  "name": "includeUnassignedEmployee",
  "variableName": "includeUnassignedEmployee"
},
v12 = {
  "kind": "Variable",
  "name": "onlyCreatedBy",
  "variableName": "onlyCreatedBy"
},
v13 = {
  "kind": "Variable",
  "name": "onlyCustomer",
  "variableName": "onlyCustomer"
},
v14 = {
  "kind": "Variable",
  "name": "onlyDone",
  "variableName": "onlyDone"
},
v15 = {
  "kind": "Variable",
  "name": "onlyDueAfter",
  "variableName": "onlyDueAfter"
},
v16 = {
  "kind": "Variable",
  "name": "onlyDueBefore",
  "variableName": "onlyDueBefore"
},
v17 = {
  "kind": "Variable",
  "name": "onlyEmployee",
  "variableName": "onlyEmployee"
},
v18 = {
  "kind": "Variable",
  "name": "onlyNotDone",
  "variableName": "onlyNotDone"
},
v19 = {
  "kind": "Variable",
  "name": "onlyPriority",
  "variableName": "onlyPriority"
},
v20 = {
  "kind": "Variable",
  "name": "order",
  "variableName": "order"
},
v21 = {
  "kind": "Variable",
  "name": "searchTerm",
  "variableName": "searchTerm"
},
v22 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 30
  },
  (v11/*: any*/),
  (v12/*: any*/),
  (v13/*: any*/),
  (v14/*: any*/),
  (v15/*: any*/),
  (v16/*: any*/),
  (v17/*: any*/),
  (v18/*: any*/),
  (v19/*: any*/),
  (v20/*: any*/),
  (v21/*: any*/)
],
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "note",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "link",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "printName",
  "storageKey": null
},
v29 = [
  (v23/*: any*/),
  (v27/*: any*/),
  (v28/*: any*/)
],
v30 = [
  (v23/*: any*/),
  (v27/*: any*/),
  (v28/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "reference",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TodosQuery",
    "selections": [
      {
        "args": [
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "TodoConnectionProvider_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v9/*: any*/),
      (v10/*: any*/),
      (v3/*: any*/),
      (v7/*: any*/),
      (v1/*: any*/),
      (v6/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v8/*: any*/),
      (v5/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "TodosQuery",
    "selections": [
      {
        "alias": null,
        "args": (v22/*: any*/),
        "concreteType": "TodoConnection",
        "kind": "LinkedField",
        "name": "todos",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TodoEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Todo",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v23/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v24/*: any*/),
                  (v25/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "done",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "due",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TodoPriority",
                    "kind": "LinkedField",
                    "name": "priority",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "key",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TimeTrack",
                    "kind": "LinkedField",
                    "name": "timeTrack",
                    "plural": false,
                    "selections": [
                      (v23/*: any*/),
                      (v26/*: any*/),
                      (v24/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "description",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "descriptionHTML",
                        "storageKey": null
                      },
                      (v25/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "recordedStart",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "recordedEnd",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "journey",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "unbilled",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hiddenForCustomer",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "canEdit",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "approved",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "canApprove",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "canRevokeApproval",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "canRemove",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Employee",
                        "kind": "LinkedField",
                        "name": "associatedEmployee",
                        "plural": false,
                        "selections": (v29/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Customer",
                        "kind": "LinkedField",
                        "name": "associatedCustomer",
                        "plural": false,
                        "selections": (v30/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Call",
                        "kind": "LinkedField",
                        "name": "call",
                        "plural": false,
                        "selections": [
                          (v23/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "connected",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "ended",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isOutbound",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Customer",
                            "kind": "LinkedField",
                            "name": "customer",
                            "plural": false,
                            "selections": [
                              (v23/*: any*/),
                              (v27/*: any*/),
                              (v26/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Customer",
                    "kind": "LinkedField",
                    "name": "customer",
                    "plural": false,
                    "selections": (v30/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Employee",
                    "kind": "LinkedField",
                    "name": "employee",
                    "plural": false,
                    "selections": (v29/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v22/*: any*/),
        "filters": [
          "order",
          "searchTerm",
          "onlyDone",
          "onlyNotDone",
          "onlyCreatedBy",
          "onlyEmployee",
          "includeUnassignedEmployee",
          "onlyCustomer",
          "onlyPriority",
          "onlyDueBefore",
          "onlyDueAfter"
        ],
        "handle": "connection",
        "key": "TodoConnection_todos",
        "kind": "LinkedHandle",
        "name": "todos"
      }
    ]
  },
  "params": {
    "id": "a8f5e0cc4d7d9a91278ab7718207404423db89e7",
    "metadata": {},
    "name": "TodosQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "1a631740443097310ec087b18f439ca1";

export default node;
