import { FormGroup } from "@blueprintjs/core";
import {
  Item,
  ItemSelect,
  ItemUsableCategory,
} from "../../../components/ItemSelect";
import { FC } from "react";
import { useFormikContext } from "formik";

export interface CustomerEditJourneyItemModel {
  journeyItem: Item | null;
}

export const CustomerEditJourneyItem: FC = function () {
  const formikContext = useFormikContext<CustomerEditJourneyItemModel>();

  return (
    <div data-cy="customer-journey-item">
      <FormGroup label="Artikel für Anfahrt">
        <div className="flex w-full justify-between">
          <ItemSelect
            item={formikContext.values.journeyItem}
            usableCategory={ItemUsableCategory.journey}
            onChange={(item) =>
              formikContext.setFieldValue("journeyItem", item)
            }
          />
        </div>
      </FormGroup>
    </div>
  );
};
