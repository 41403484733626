import { FC, useEffect, useState } from "react";
import { differenceInSeconds } from "date-fns";
import { Stopwatch } from "./Stopwatch";

interface Props {
  since?: Date | null | undefined;
}

export const LiveStopwatch: FC<Props> = function ({ since }) {
  const [now, setNow] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(new Date());
    }, 20000);

    return () => clearInterval(interval);
  });

  return <Stopwatch seconds={since ? differenceInSeconds(now, since) : 0} />;
};
