import { useReducer } from "react";
import { assertModelLength, emptyValue } from "./mask";
import { extractFromStartDate } from "./extractFromStartDate";
import { extractFromEndDate } from "./extractFromEndDate";

export type ReceiveStartDateAction = {
  type: "RECEIVE_START_DATE";
  startDate: Optional<Date>;
};

export type ReceiveEndDateAction = {
  type: "RECEIVE_END_DATE";
  endDate: Optional<Date>;
};

export type SetDateAction = {
  type: "SET_VALUE";
  value: string;
};

export type SetValueQuietAction = {
  type: "SET_VALUE_QUIET";
  value: string;
};

export type Action =
  | ReceiveStartDateAction
  | ReceiveEndDateAction
  | SetDateAction
  | SetValueQuietAction;

export type State = {
  value: string;
  lastStartDate: Optional<Date>;
  lastEndDate: Optional<Date>;
  isDirty: boolean;
};

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "SET_VALUE":
      assertModelLength(action.value);
      return {
        ...state,
        value: action.value,
        isDirty: true,
      };
    case "SET_VALUE_QUIET":
      // Leaves isDirty as is
      assertModelLength(action.value);
      return {
        ...state,
        value: action.value,
      };
    case "RECEIVE_START_DATE":
      if (state.lastStartDate !== action.startDate) {
        return {
          ...state,
          lastStartDate: action.startDate,
          value: extractFromStartDate(state.value, action.startDate),
          isDirty: false,
        };
      } else {
        return state;
      }
    case "RECEIVE_END_DATE":
      if (state.lastEndDate !== action.endDate) {
        return {
          ...state,
          lastEndDate: action.endDate,
          value: extractFromEndDate(state.value, action.endDate),
          isDirty: false,
        };
      } else {
        return state;
      }
  }
}

export function useDateRangeReducer({
  initialStartDate,
  initialEndDate,
}: {
  initialStartDate: Date | null | undefined;
  initialEndDate: Date | null | undefined;
}): [State, (action: Action) => void] {
  return useReducer(reducer, {
    value: extractFromStartDate(
      extractFromEndDate(emptyValue, initialEndDate),
      initialStartDate,
      true,
    ),
    lastStartDate: initialStartDate,
    lastEndDate: initialEndDate,
    isDirty: false,
  });
}
