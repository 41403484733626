import { createFragmentContainer } from "react-relay";
import { DashboardRevenue_statistics$data } from "./__generated__/DashboardRevenue_statistics.graphql";
import styles from "./DashboardRevenue.module.scss";
import { Heading } from "../../components/Heading";
import ClipLoader from "react-spinners/ClipLoader";
import { HTMLSelect } from "@blueprintjs/core";
import loadable from "@loadable/component";
import { usePreferences } from "../../app-controller/ViewerContext";
import { DashboardStatisticsGraphAdditionalInfoPreference } from "../../app-controller/__generated__/ViewerContext_viewer.graphql";
import { doMutation } from "../../mutation/doMutation";
import { IEnvironment } from "relay-runtime";
import { useRelayEnvironment } from "relay-hooks";
import { DashboardRevenueSetStatisticsMutation } from "./__generated__/DashboardRevenueSetStatisticsMutation.graphql";

const DashboardRevenueGraph = loadable(
  () =>
    import(
      /* webpackChunkName: "DashboardRevenueGraph" */ "./DashboardRevenueGraph"
    ),
);

export interface Props {
  statistics: DashboardRevenue_statistics$data | null;
}

const mutation = graphql`
  mutation DashboardRevenueSetStatisticsMutation(
    $input: setUserDashboardStatisticsGraphAdditionalInfoPreferenceInput!
  ) {
    setUserDashboardStatisticsGraphAdditionalInfoPreference(input: $input) {
      user {
        dashboardStatisticsGraphAdditionalInfoPreference
      }
    }
  }
`;

function setDashboardStatisticsGraphAdditionalInfoPreference(
  environment: IEnvironment,
  additionalInfoPreference: DashboardStatisticsGraphAdditionalInfoPreference,
) {
  doMutation<DashboardRevenueSetStatisticsMutation>({
    environment,
    mutation,
    variables: {
      input: {
        dashboardStatisticsGraphAdditionalInfoPreference:
          additionalInfoPreference,
      },
    },
  });
}

function DashboardRevenueAdditionalInfoSelect({
  additionalInfo,
}: {
  additionalInfo: DashboardStatisticsGraphAdditionalInfoPreference;
}) {
  const environment = useRelayEnvironment();

  return (
    <HTMLSelect
      onChange={(event) => {
        const value = event.currentTarget.value;
        setDashboardStatisticsGraphAdditionalInfoPreference(
          environment,
          value as DashboardStatisticsGraphAdditionalInfoPreference,
        );
      }}
      value={additionalInfo}
      title="Zusätzliche Informationen"
    >
      <option value="NONE">Keine</option>
      <option value="REGRESSION">Trendlinie</option>
      <option value="LAST_YEAR">Letztes Jahr</option>
      <option value="MOVING_AVERAGE_EIGHT_WEEKS">
        Gleitender Durchschnitt (8 Wochen)
      </option>
      <option value="MOVING_AVERAGE_TWELVE_WEEKS">
        Gleitender Durchschnitt (12 Wochen)
      </option>
    </HTMLSelect>
  );
}

export function DashboardRevenueComponent({ statistics }: Props) {
  const { dashboardStatisticsGraphAdditionalInfo } = usePreferences();

  return (
    <>
      <Heading level={2}>
        <span className={styles.header}>
          <span>Wöchentlicher Umsatz</span>
          <span className={styles.select}>
            <DashboardRevenueAdditionalInfoSelect
              additionalInfo={dashboardStatisticsGraphAdditionalInfo}
            />
          </span>
        </span>
      </Heading>
      <div className={styles.graph}>
        {statistics ? (
          <DashboardRevenueGraph
            statistics={statistics}
            additionalInfo={dashboardStatisticsGraphAdditionalInfo}
            fallback={
              <div className={styles.loader}>
                <ClipLoader />
              </div>
            }
          />
        ) : (
          <div className={styles.loader}>
            <ClipLoader />
          </div>
        )}
      </div>
    </>
  );
}

export const DashboardRevenue = createFragmentContainer(
  DashboardRevenueComponent,
  {
    statistics: graphql`
      fragment DashboardRevenue_statistics on Statistics {
        ...DashboardRevenueGraph_statistics
      }
    `,
  },
);
