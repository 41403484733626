/**
 * @generated SignedSource<<87710054f3410a3090105faf1644dd21>>
 * @relayHash c8bbeb53dac4a23ad355fd427b450e9f
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 0adf48ca90015c9a5555d119e1c88abdf9dd9689

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type SubscriptionEventType = "INSERT" | "UPDATE" | "DELETE" | "%future added value";
export type CommonDataContextCustomerSubscription$variables = {};
export type CommonDataContextCustomerSubscription$data = {
  readonly customers: {
    readonly node: {
      readonly id?: string;
      readonly displayName?: string;
      readonly printName?: string;
      readonly reference?: string | null;
    } | null;
    readonly cursor: string | null;
    readonly type: SubscriptionEventType | null;
    readonly nodeId: string;
  } | null;
};
export type CommonDataContextCustomerSubscription = {
  variables: CommonDataContextCustomerSubscription$variables;
  response: CommonDataContextCustomerSubscription$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "skipFastForward",
    "value": true
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "printName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reference",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CommonDataContextCustomerSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "NodeSubscriptionValue",
        "kind": "LinkedField",
        "name": "customers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "type": "Customer",
                "abstractKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": "customers(skipFastForward:true)"
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CommonDataContextCustomerSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "NodeSubscriptionValue",
        "kind": "LinkedField",
        "name": "customers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v1/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "type": "Customer",
                "abstractKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": "customers(skipFastForward:true)"
      }
    ]
  },
  "params": {
    "id": "0adf48ca90015c9a5555d119e1c88abdf9dd9689",
    "metadata": {},
    "name": "CommonDataContextCustomerSubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();

(node as any).hash = "81acc19940c66747c62d164799d8f318";

export default node;
