import { FC } from "react";
import { CustomerNavbar } from "./CustomerNavbar";
import { CustomerNavbarLoaderQuery } from "./__generated__/CustomerNavbarLoaderQuery.graphql";
import { STORE_THEN_NETWORK, useQuery } from "relay-hooks";

export interface Props {
  id: string;
  subTitle: string;
  /**
   * Related to content, not to the loading of the Navbar
   */
  isLoading?: boolean;
}

const query = graphql`
  query CustomerNavbarLoaderQuery($id: ID!) {
    node(id: $id) {
      ... on Customer {
        __typename

        ...CustomerNavbar_customer
      }
    }
  }
`;

export const CustomerNavbarLoader: FC<Props> = function CustomerNavbarLoader({
  id,
  subTitle,
  isLoading,
}) {
  const { data: props } = useQuery<CustomerNavbarLoaderQuery>(
    query,
    { id },
    { fetchPolicy: STORE_THEN_NETWORK },
  );

  const customer = props?.node;
  if (customer && customer.__typename !== "Customer") {
    throw new Error("Node is not a customer.");
  }

  return (
    <CustomerNavbar
      customerId={id}
      customer={customer ?? null}
      subTitle={subTitle}
      isLoading={isLoading}
    />
  );
};
