import { Permissions } from "./ViewerUtils";
import { EmployeeInfo, ViewerInfo } from "./AppMachineStorage";
import { Flags, Preferences } from "@mormahr/portal-utils";

export interface AppContext {
  expiry: Date | undefined;
  viewer: ViewerInfo | undefined;
  employee: EmployeeInfo | undefined;
  permissions: Permissions | undefined;
  flags: Flags | undefined;
  preferences: Preferences | undefined;
}

export interface UserAppContext extends AppContext {
  expiry: Date;
  viewer: ViewerInfo;
  employee: EmployeeInfo;
  permissions: Permissions;
  flags: Flags;
  preferences: Preferences;
}

export type AppState =
  | { value: "pending"; context: AppContext }
  | { value: "error"; context: AppContext }
  | { value: "probablyUnauthenticated"; context: AppContext }
  | { value: "unauthenticated"; context: AppContext }
  | { value: "probablyAuthenticated"; context: UserAppContext }
  | { value: "authenticated"; context: UserAppContext };

export enum EventTypes {
  readCachedExpiryAndData = "READ_CACHED_EXPIRY_AND_DATA",
  readEmptyExpiryAndData = "READ_EMPTY_EXPIRY_AND_DATA",
  readExpired = "READ_EXPIRED",
  receiveExpiryAndDataFromNetwork = "RECEIVE_EXPIRY_AND_DATA_FROM_NETWORK",
  receiveAuthenticationErrorFromNetwork = "RECEIVE_AUTHENTICATION_ERROR_FROM_NETWORK",
  receiveNonEmployee = "RECEIVE_NON_EMPLOYEE",
  receiveNetworkError = "RECEIVE_NETWORK_ERROR",
}

export interface ReadCachedExpiryAndDataEvent {
  type: EventTypes.readCachedExpiryAndData;
  expiry: Date;
  viewer: ViewerInfo;
  employee: EmployeeInfo;
  permissions: Permissions;
  flags: Flags;
  preferences: Preferences;
}

export interface ReadEmptyExpiryAndDataEvent {
  type: EventTypes.readEmptyExpiryAndData;
}

export interface ReadExpiredEvent {
  type: EventTypes.readExpired;
}

export interface ReceiveExpiryAndDataFromNetworkEvent {
  type: EventTypes.receiveExpiryAndDataFromNetwork;
  expiry: Date;
  viewer: ViewerInfo;
  employee: EmployeeInfo;
  permissions: Permissions;
  flags: Flags;
  preferences: Preferences;
}

export interface ReceiveAuthenticationErrorFromNetworkEvent {
  type: EventTypes.receiveAuthenticationErrorFromNetwork;
}

export interface ReceiveNonEmployeeEvent {
  type: EventTypes.receiveNonEmployee;
}

export interface ReceiveErrorEvent {
  type: EventTypes.receiveNetworkError;
  error: any;
}

export type AppEvent =
  | ReadCachedExpiryAndDataEvent
  | ReadEmptyExpiryAndDataEvent
  | ReadExpiredEvent
  | ReceiveExpiryAndDataFromNetworkEvent
  | ReceiveAuthenticationErrorFromNetworkEvent
  | ReceiveNonEmployeeEvent
  | ReceiveErrorEvent;

export const initialContext: AppContext = {
  expiry: undefined,
  viewer: undefined,
  employee: undefined,
  permissions: undefined,
  flags: undefined,
  preferences: undefined,
};
