/**
 * @generated SignedSource<<4f1449a98f5cdcd9f835f24a21c5ac64>>
 * @relayHash 17dcc863583233228bbbb9a869ff66e6
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID c7942568308cbbbf93ced657c280133fa9cb6b3e

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CallWidgetHostSubscription$variables = {};
export type CallWidgetHostSubscription$data = {
  readonly currentCall: {
    readonly " $fragmentSpreads": FragmentRefs<"CallWidget_call">;
  } | null;
};
export type CallWidgetHostSubscription = {
  variables: CallWidgetHostSubscription$variables;
  response: CallWidgetHostSubscription$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CallWidgetHostSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Call",
        "kind": "LinkedField",
        "name": "currentCall",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CallWidget_call"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CallWidgetHostSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Call",
        "kind": "LinkedField",
        "name": "currentCall",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Customer",
            "kind": "LinkedField",
            "name": "customer",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "noticeText",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "link",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PhoneNumber",
            "kind": "LinkedField",
            "name": "externalNumber",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "phoneNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "private",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "created",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "connected",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ended",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isOutbound",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 10
              }
            ],
            "concreteType": "TimeTrackConnection",
            "kind": "LinkedField",
            "name": "timeTracks",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TimeTrackEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TimeTrack",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "recordedEnd",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "timeTracks(first:10)"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "c7942568308cbbbf93ced657c280133fa9cb6b3e",
    "metadata": {},
    "name": "CallWidgetHostSubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();

(node as any).hash = "29b12fc91511caf59e0fed36285003fb";

export default node;
