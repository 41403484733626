/**
 * @generated SignedSource<<a187b9ccddc5fe45cdb03ae64ae031fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TodoDoneButton_todo$data = {
  readonly id: string;
  readonly done: boolean;
  readonly " $fragmentType": "TodoDoneButton_todo";
};
export type TodoDoneButton_todo$key = {
  readonly " $data"?: TodoDoneButton_todo$data;
  readonly " $fragmentSpreads": FragmentRefs<"TodoDoneButton_todo">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TodoDoneButton_todo",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "done",
      "storageKey": null
    }
  ],
  "type": "Todo",
  "abstractKey": null
};

(node as any).hash = "4f507bace4407c67b2a79ef70597c64d";

export default node;
