/**
 * @generated SignedSource<<1fa9d18747466e2e47ce5c6f19d73004>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TimeTrackEventKind = "created" | "edited" | "removed" | "restored" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TimeTrackHistoryRow_event$data = {
  readonly kind: TimeTrackEventKind;
  readonly date: string;
  readonly user: {
    readonly displayName: string;
  } | null;
  readonly changedFields: any;
  readonly " $fragmentSpreads": FragmentRefs<"TimeTrackHistoryRowCustomerChange_event" | "TimeTrackHistoryRowEmployeeChange_event" | "TimeTrackHistoryRowTitleChange_event" | "TimeTrackHistoryRowTimeChange_event" | "TimeTrackHistoryRowCustomerBillingChange_event">;
  readonly " $fragmentType": "TimeTrackHistoryRow_event";
};
export type TimeTrackHistoryRow_event$key = {
  readonly " $data"?: TimeTrackHistoryRow_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"TimeTrackHistoryRow_event">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TimeTrackHistoryRow_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "changedFields",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TimeTrackHistoryRowCustomerChange_event"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TimeTrackHistoryRowEmployeeChange_event"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TimeTrackHistoryRowTitleChange_event"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TimeTrackHistoryRowTimeChange_event"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TimeTrackHistoryRowCustomerBillingChange_event"
    }
  ],
  "type": "TimeTrackEvent",
  "abstractKey": null
};

(node as any).hash = "1e51dc5ec919e6ff82ad3775f6200e35";

export default node;
