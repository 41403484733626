import { FC } from "react";
import { TimeTrackHistoryRowTitleChange_event$key } from "./__generated__/TimeTrackHistoryRowTitleChange_event.graphql";
import { useFragment } from "relay-hooks";

const fragment = graphql`
  fragment TimeTrackHistoryRowTitleChange_event on TimeTrackEvent {
    changedFields
  }
`;

export const TimeTrackHistoryRowTitleChange: FC<{
  event: TimeTrackHistoryRowTitleChange_event$key;
}> = function TimeTrackHistoryRowTitleChange(props) {
  const event = useFragment(fragment, props.event);
  const changedFields = event.changedFields as {
    [key: string]: [unknown, unknown];
  };

  if (!changedFields.title) {
    return null;
  }

  const from = (changedFields.title[0] ?? "") as string;
  const to = (changedFields.title[1] ?? "") as string;

  if (from && to) {
    return (
      <span>
        den Titel von <q className="line-through">{from}</q> zu{" "}
        <q className="font-bold">{to}</q> geändert
      </span>
    );
  } else if (from) {
    return (
      <span>
        den Titel <q className="line-through">{from}</q> entfernt
      </span>
    );
  } else if (to) {
    return (
      <span>
        den Titel <q className="font-bold">{to}</q> eingetragen
      </span>
    );
  } else {
    throw new Error("Invalid difference");
  }
};
