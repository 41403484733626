import { FC, useCallback } from "react";
import { FlatCheckbox } from "../../../components/checkbox/FlatCheckbox";
import { TodoDoneButton_todo$key } from "./__generated__/TodoDoneButton_todo.graphql";
import { setTodoDone, setTodoNotDone } from "../mutations/TodoMutations";
import { useRelayEnvironment } from "relay-hooks";
import { useFragment } from "relay-hooks";

export type Props = {
  todo: TodoDoneButton_todo$key;
};

const fragment = graphql`
  fragment TodoDoneButton_todo on Todo {
    id
    done
  }
`;

export const TodoDoneButton: FC<Props> = function (props: Props) {
  const environment = useRelayEnvironment();
  const { id, done } = useFragment(fragment, props.todo);

  const changeHandler = useCallback(
    (done: boolean) => {
      if (done) {
        setTodoDone(environment, id);
      } else {
        setTodoNotDone(environment, id);
      }
    },
    [environment, id],
  );

  return <FlatCheckbox checked={done} onChange={changeHandler} />;
};
