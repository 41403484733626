export enum CustomerEditField {
  displayName = "displayName",
  printName = "printName",
  reference = "reference",
  billingAddress = "billingAddress",
  email = "email",
  notice = "notice",
  category = "category",
  phoneNumbers = "phoneNumbers",
  workItem = "workItem",
  journeyItem = "journeyItem",
  hourlyRate = "hourlyRate",
  extraBillingItems = "extraBillingItems",
}
