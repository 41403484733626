import { FC, useEffect, useRef, useState } from "react";
import { useFormikContext } from "formik";
import { CustomerEditPhoneNumbersModel } from "./CustomerEditPhoneNumbers";
import { InputGroup, FormGroup, Button } from "@blueprintjs/core";
import * as Sentry from "@sentry/react";

export const CustomerEditPhoneNumberCreator: FC = function () {
  const formikContext = useFormikContext<CustomerEditPhoneNumbersModel>();
  const [number, setNumber] = useState<string>("");
  const [displayName, setDisplayName] = useState<string>("");

  const [libphonenumber, setLibphonenumber] = useState<
    | Pick<typeof import("libphonenumber-js/min"), "parsePhoneNumberFromString">
    | Error
    | null
  >(null);
  useEffect(() => {
    let stopped = false;

    import(
      /* webpackExports: ["parsePhoneNumberFromString"] */ "libphonenumber-js/min"
    )
      .then((result) => {
        if (stopped) {
          return;
        }
        setLibphonenumber(result);
      })
      .catch((error) => {
        if (stopped) {
          return;
        }
        Sentry.captureException(error);
        setLibphonenumber(error);
      });

    return () => {
      stopped = true;
    };
  }, []);

  if (libphonenumber instanceof Error) {
    return null;
  }

  function add() {
    let phoneNumbers = [...formikContext.values.phoneNumbers];
    if (phoneNumbers.map((obj) => obj.phoneNumber).includes(number)) {
      phoneNumbers = phoneNumbers.map((obj) => {
        if (obj.phoneNumber === number) {
          return {
            ...obj,
            displayName,
            remove: false,
          };
        } else {
          return obj;
        }
      });
    } else {
      phoneNumbers.push({
        phoneNumberId: null,
        phoneNumber: number,
        displayName,
        remove: false,
      });
    }

    formikContext.setFieldValue("phoneNumbers", phoneNumbers);
    formikContext.setFieldTouched("phoneNumbers");
    setNumber("");
    setDisplayName("");
  }

  return (
    <div className="flex space-x-4 mt-4">
      <FormGroup label="Telefonnummer" className="flex-grow">
        <InputGroup
          fill
          type="tel"
          value={number}
          onChange={(event) => {
            setNumber(event.currentTarget.value);
          }}
          onBlur={(event) => {
            setNumber((number) => {
              if (!libphonenumber) {
                event.preventDefault();
                return number;
              }

              return (
                libphonenumber
                  .parsePhoneNumberFromString(number, "DE")
                  ?.formatInternational() ?? ""
              );
            });
          }}
        />
      </FormGroup>
      <FormGroup label="Bezeichnung" className="flex-grow">
        <InputGroup
          fill
          value={displayName}
          onChange={(event) => {
            setDisplayName(event.currentTarget.value);
          }}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              add();
              event.preventDefault();
              event.stopPropagation();
            }
          }}
        />
      </FormGroup>

      <div className="mb-4 flex flex-col justify-end">
        <Button
          disabled={
            !libphonenumber ||
            !libphonenumber.parsePhoneNumberFromString(number, "DE")
          }
          loading={!libphonenumber}
          onClick={add}
        >
          Hinzufügen
        </Button>
      </div>
    </div>
  );
};
