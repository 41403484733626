/**
 * @generated SignedSource<<998946ea604b2d2c760d656e50e13a8a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TimeTrackHistoryRowTimeChange_event$data = {
  readonly changedFields: any;
  readonly " $fragmentType": "TimeTrackHistoryRowTimeChange_event";
};
export type TimeTrackHistoryRowTimeChange_event$key = {
  readonly " $data"?: TimeTrackHistoryRowTimeChange_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"TimeTrackHistoryRowTimeChange_event">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TimeTrackHistoryRowTimeChange_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "changedFields",
      "storageKey": null
    }
  ],
  "type": "TimeTrackEvent",
  "abstractKey": null
};

(node as any).hash = "354251d6f6a1a7fd5489002775b9c110";

export default node;
