/**
 * @generated SignedSource<<447a4c9b8a3eeacba7282909155b954d>>
 * @relayHash d543fadd9e846a8c6ae994ce40d5c5ce
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID d9be628a6862f72b393117d4927d940f7b973df5

import { ConcreteRequest, Query } from 'relay-runtime';
export type CustomerCategorySelectCustomerCategoriesQuery$variables = {};
export type CustomerCategorySelectCustomerCategoriesQuery$data = {
  readonly customerCategories: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly title: string;
        readonly description: string;
        readonly color: string;
      } | null;
    } | null> | null;
  } | null;
};
export type CustomerCategorySelectCustomerCategoriesQuery = {
  variables: CustomerCategorySelectCustomerCategoriesQuery$variables;
  response: CustomerCategorySelectCustomerCategoriesQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "first",
        "value": 1000
      }
    ],
    "concreteType": "CustomerCategoryConnection",
    "kind": "LinkedField",
    "name": "customerCategories",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CustomerCategoryEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomerCategory",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "color",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": "customerCategories(first:1000)"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomerCategorySelectCustomerCategoriesQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CustomerCategorySelectCustomerCategoriesQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "d9be628a6862f72b393117d4927d940f7b973df5",
    "metadata": {},
    "name": "CustomerCategorySelectCustomerCategoriesQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "33dacbf686c8d68a6fd5bac573d5434d";

export default node;
