import { FormikProps } from "formik";
import { FormGroup, InputGroup } from "@blueprintjs/core";
import {
  IPeopleEditorPartialValues,
  PeopleEditorPartial,
} from "./PeopleEditorPartial";

export interface Props {
  formik: FormikProps<TodoCreationData>;
  disabled: boolean;
}

export interface TodoCreationData extends IPeopleEditorPartialValues {
  title: string;
  note: string;
}

export const initialValue = (): TodoCreationData => ({
  title: "",
  note: "",
  employee: null,
  customer: null,
});

export function TodoCreateForm({ formik, disabled }: Props) {
  const { handleChange, values } = formik;

  return (
    <>
      <FormGroup label="Titel" disabled={disabled}>
        <InputGroup
          name="title"
          value={values.title}
          onChange={handleChange}
          disabled={disabled}
          data-cy={"todo-title"}
          autoFocus
        />
      </FormGroup>
      <FormGroup label="Notiz" disabled={disabled}>
        <InputGroup
          name="note"
          value={values.note}
          onChange={handleChange}
          disabled={disabled}
          data-cy={"todo-note"}
        />
      </FormGroup>
      <PeopleEditorPartial
        disabled={disabled}
        values={values}
        formik={formik}
      />
    </>
  );
}
