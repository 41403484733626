import { Icon } from "@mormahr/babel-transform-icon";
import { FC, useCallback, useState } from "react";
import { TimeTrackWidget } from "../widget/TimeTrackWidget";
import { Sidebar_employee$key } from "./__generated__/Sidebar_employee.graphql";
import { Sidebar_viewer$key } from "./__generated__/Sidebar_viewer.graphql";
import { AuthenticationWidget } from "./AuthenticationWidget";
import { Link } from "react-router-dom";
import { Navigation } from "./Navigation";
import { UpdateInfo } from "./UpdateInfo";
import styles from "./Sidebar.module.scss";
import Logo from "../../assets/LogoHorizontal.svg";
import classnames from "classnames";
import { Classes } from "@blueprintjs/core";
import { useFragment } from "relay-hooks";
import {
  SpecialBackgroundWrapper,
  SpecialIllustrationWrapper,
} from "./specials/SpecialIllustration";
import { CallWidgetHost } from "./call/CallWidgetHost";

const IS_DARK = true;

export interface Props {
  employee: Sidebar_employee$key | null;
  viewer: Sidebar_viewer$key | null;
  isLoading: boolean;
}

const viewerFragment = graphql`
  fragment Sidebar_viewer on ViewerUser {
    ...AuthenticationWidget_viewer
  }
`;

const employeeFragment = graphql`
  fragment Sidebar_employee on Employee {
    ...TimeTrackWidget_employee
    ...CallWidgetHost_employee
  }
`;

export const Sidebar: FC<Props> = function (props) {
  const viewer = useFragment(viewerFragment, props.viewer);
  const employee = useFragment(employeeFragment, props.employee);
  const [expanded, setExpanded] = useState(false);
  const { isLoading } = props;

  const handleClose = useCallback(() => {
    setExpanded(false);
  }, []);

  const handleToggle = useCallback(() => {
    setExpanded((v) => !v);
  }, []);

  return (
    <>
      <div className={styles.spacer} />
      <div
        className={classnames(styles.sidebar, {
          [Classes.DARK]: IS_DARK,
          [styles.collapsed]: !expanded,
        })}
        data-cy="sidebar"
      >
        <SpecialBackgroundWrapper />
        <div className={styles.top}>
          <Link to={"/"} onClick={handleClose} title="Startseite">
            <Logo />
          </Link>
          <div
            className={classnames(styles.handle, {
              [styles.rotated]: expanded,
            })}
          >
            <button
              onClick={handleToggle}
              title={
                expanded ? "Navigation einklappen" : "Navigation ausklappen"
              }
            >
              <Icon icon="chevron-down" type="regular" />
            </button>
          </div>
        </div>
        <div className={styles.main}>
          <TimeTrackWidget employee={employee} isLoading={isLoading} />

          <Navigation onClick={handleClose} />
        </div>
        <div className={styles.footer}>
          <SpecialIllustrationWrapper />
          <br />
          <UpdateInfo />

          <CallWidgetHost employee={employee ?? null} />
          <AuthenticationWidget viewer={viewer ?? null} />
        </div>
      </div>
    </>
  );
};
