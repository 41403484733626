import {
  forwardRef,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
} from "react";
import * as React from "react";
import { MaskedInput } from "../MaskedInput";
import { assertModelLength, mask, maskLength, momentDateFormat } from "./mask";
import { convert, ConvertedDate } from "./convert";
import { Action, useDateRangeReducer } from "./useDateRangeReducer";
import {
  extractEndTime,
  extractStartTime,
  isEmpty,
  withDate,
} from "./manipulate";
import { format } from "date-fns";

export interface Props {
  initialStartDate: Optional<Date>;
  initialEndDate: Optional<Date>;
  onChange?: Optional<(date: ConvertedDate) => void>;
  onBlur?: Optional<() => void>;

  onKeyCommand?: Optional<(data: { key: string }) => void>;

  // Set to prefill date
  currentDate?: Optional<() => Date>;
  prefillDate: boolean;

  disabled?: boolean;

  className?: string;
  id?: string;
  name?: string;

  autoFocus?: boolean;
}

export const DateRangeInput = forwardRef<(action: Action) => void, Props>(
  (props, outsideRef) => {
    const {
      initialStartDate,
      initialEndDate,
      onChange,
      onKeyCommand,
      currentDate,
      prefillDate,
      autoFocus,
      ...rest
    } = props;
    const [state, dispatch] = useDateRangeReducer({
      initialStartDate,
      initialEndDate,
    });
    if (typeof outsideRef === "object" && outsideRef !== null) {
      outsideRef.current = dispatch;
    }
    const { value, isDirty } = state;
    const ref = useRef<HTMLInputElement>();

    useLayoutEffect(() => {
      if (autoFocus && ref.current) {
        const value = ref.current.value;
        const index = isEmpty(extractStartTime(value))
          ? 11
          : isEmpty(extractEndTime(value))
          ? 19
          : 24;
        ref.current.setSelectionRange(index, index);
      }
    }, [autoFocus]);

    useEffect(() => {
      if (
        !isDirty &&
        prefillDate &&
        initialEndDate === null &&
        initialStartDate === null &&
        isEmpty(value) &&
        currentDate
      ) {
        dispatch({
          type: "SET_VALUE_QUIET",
          value: withDate(value, format(currentDate(), momentDateFormat)),
        });
      }
    }, [
      initialStartDate,
      initialEndDate,
      value,
      isDirty,
      currentDate,
      prefillDate,
      dispatch,
    ]);

    useEffect(() => {
      if (onChange) {
        onChange(convert(value));
      }
    }, [value, onChange]);

    const handleKeyPress = useCallback(
      (event: React.KeyboardEvent<HTMLInputElement>) => {
        const { key } = event;

        if (onKeyCommand) {
          onKeyCommand({ key });
        }
      },
      [onKeyCommand],
    );

    assertModelLength(value);
    return (
      // @ts-ignore
      <MaskedInput
        {...rest}
        // @ts-ignore
        type={"text"}
        size={maskLength + 1}
        mask={mask}
        keepCharPositions
        value={value}
        showMask={true}
        onKeyPress={handleKeyPress}
        inputMode="numeric"
        autoFocus={autoFocus}
        ref={ref}
        // @ts-ignore
        onChange={(event) =>
          dispatch({
            type: "SET_VALUE",
            value: event.currentTarget.value,
          })
        }
      />
    );
  },
);

DateRangeInput.displayName = "DateRangeInput";

DateRangeInput.defaultProps = {
  prefillDate: false,
};
