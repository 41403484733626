import { Tab } from "../../components/Header";
import { Icon } from "@mormahr/babel-transform-icon";

export function OrganizationTabs() {
  return (
    <>
      <Tab
        to="/organization/customer-categories"
        icon={<Icon icon="circle" type="solid" />}
      >
        Kundenkategorien
      </Tab>
      <Tab to="/item" icon={<Icon icon="boxes-stacked" type="solid" />}>
        Artikel
      </Tab>
    </>
  );
}
