import { Component, FC, ReactNode } from "react";
import loadable from "@loadable/component";

const ErrorIllustration = loadable(
  () =>
    import(/* webpackChunkName: "ErrorIllustration" */ "./ErrorIllustration"),
);

interface State {
  hasError: boolean;
}

export const Fallback: FC = function Fallback() {
  return (
    <svg
      width="1155"
      height="700"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: "100%", height: "auto" }}
    />
  );
};

export default class ErrorIllustrationLazy extends Component<unknown, State> {
  constructor(props: never) {
    super(props);
    this.state = { hasError: false };
  }

  // This is an error boundary because if the network is unavailable and we haven't downloaded all
  // assets it would crash the whole app because the error boundary that shows this illustration
  // would itself throw..
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render(): ReactNode {
    if (this.state.hasError) {
      return <Fallback />;
    }

    return <ErrorIllustration fallback={<Fallback />} />;
  }
}
