import { useFragment } from "relay-hooks";
import { TextButton } from "../button/TextButton";
import { TimeTrackLink_timeTrack$key } from "./__generated__/TimeTrackLink_timeTrack.graphql";
import { FC, useState } from "react";
import { LazyTimeTrackEditorDialog } from "../../app/user/workspace/LazyTimeTrackEditorDialog";

export type Props = {
  timeTrack: TimeTrackLink_timeTrack$key;
};

const fragment = graphql`
  fragment TimeTrackLink_timeTrack on TimeTrack {
    link

    ...TimeTrackEditorDialog_timeTrack
  }
`;

export const TimeTrackLink: FC<Props> = function ({
  timeTrack: _timeTrack,
  ...rest
}) {
  const timeTrack = useFragment(fragment, _timeTrack);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <TextButton
        title="Zeiterfassungseintrag öffnen"
        href={timeTrack.link}
        onClick={(e) => {
          if (e.metaKey) {
            return;
          }

          e.preventDefault();
          setIsOpen(true);
        }}
        {...rest}
      />
      {isOpen && (
        <LazyTimeTrackEditorDialog
          timeTrack={timeTrack}
          onClose={() => setIsOpen(false)}
          isOpen={isOpen}
        />
      )}
    </>
  );
};
