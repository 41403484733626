import { ContextType, PureComponent, useCallback, useContext } from "react";
import {
  Hotkey,
  Hotkeys,
  HotkeysTarget,
  IHotkeysTargetComponent,
} from "@blueprintjs/core";
import {
  CommandManagerDispatchContext,
  CommandManagerStateContext,
  isTodoCreateModalOpen,
} from "./CommandManager";
import { TodoCreateQuickDialogCombo } from "./TodoCreateQuickDialogConfig";
import { TodoCreateDialog } from "./TodoCreateDialog";
import { transformFilters } from "../app/todo/model/TodoConnectionFilter";
import { defaultFilters } from "../app/todo/components/TodoListDefaultFilters";

export function TodoCreateQuickDialogRenderer() {
  const state = useContext(CommandManagerStateContext);
  const dispatch = useContext(CommandManagerDispatchContext);

  const close = useCallback(() => {
    dispatch({
      type: "CLOSE_TODO_CREATE",
    });
  }, [dispatch]);

  const { open } = state;

  return (
    <TodoCreateDialog
      filters={
        open?.type === "TODO_CREATE"
          ? open.filters
          : transformFilters(defaultFilters)
      }
      isOpen={isTodoCreateModalOpen(state)}
      onClose={close}
    />
  );
}

@HotkeysTarget
export class TodoCreateQuickDialogHost
  extends PureComponent
  implements IHotkeysTargetComponent {
  context!: ContextType<typeof CommandManagerDispatchContext>;

  open = () => {
    this.context({
      type: "OPEN_TODO_CREATE",
      filters: transformFilters(defaultFilters),
    });
  };

  renderHotkeys() {
    return (
      <Hotkeys>
        <Hotkey
          global={true}
          combo={TodoCreateQuickDialogCombo}
          label="Todo erstellen"
          onKeyDown={this.open}
          // prevent typing "O" in omnibar input
          preventDefault={true}
        />
      </Hotkeys>
    );
  }

  render() {
    return <TodoCreateQuickDialogRenderer />;
  }
}

TodoCreateQuickDialogHost.contextType = CommandManagerDispatchContext;
