/**
 * @generated SignedSource<<02cbac6ff2d7bfbde75072b4cc2657b8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DashboardRevenue_statistics$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DashboardRevenueGraph_statistics">;
  readonly " $fragmentType": "DashboardRevenue_statistics";
};
export type DashboardRevenue_statistics$key = {
  readonly " $data"?: DashboardRevenue_statistics$data;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardRevenue_statistics">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DashboardRevenue_statistics",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardRevenueGraph_statistics"
    }
  ],
  "type": "Statistics",
  "abstractKey": null
};

(node as any).hash = "432d718a3295e7df617a448a77cece15";

export default node;
