import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { v4 as uuid } from "uuid";
import { groupBy, omit } from "lodash";
import { GraphQLSubscriptionConfig, requestSubscription } from "relay-runtime";
import { DeclarativeMutationConfig } from "relay-runtime/lib/mutations/RelayDeclarativeMutationConfig";
import { GraphQLTaggedNode } from "relay-runtime/lib/query/RelayModernGraphQLTag";
import { OperationType } from "relay-runtime/lib/util/RelayRuntimeTypes";
import { SelectorStoreUpdater } from "relay-runtime/lib/store/RelayStoreTypes";
import { useRelayEnvironment } from "relay-hooks";

export type SubscriptionId = string;
export type SubscriptionGroup = string;
export type VariablesType = Record<string, any>;

export interface SubscriptionKind {
  /**
   * Essentially the module or file in "machine" form (should be viewed as an enum).
   * Used to group instances of the same subscription together.
   */
  readonly group: SubscriptionGroup;

  /**
   * Label to be displayed in the UI. Must be the same for all descriptors with the same
   * {@link .group}.
   */
  readonly label: string;
}

export interface SubscriptionDescriptor {
  readonly kind: SubscriptionKind;

  /**
   * Variables used in the subscription. Not currently used.
   */
  readonly variables: VariablesType;

  /**
   * Last time the subscription instance yielded a value.
   */
  readonly lastReceived: Date | null;

  /**
   * Has an error happened for this subscription instance.
   */
  readonly error: Error | null;
}

export interface SubscriptionManagerState {
  readonly active: {
    readonly [key: string /* SubscriptionId */]: SubscriptionDescriptor;
  };
}

const initialState: SubscriptionManagerState = {
  active: {},
};

/**
 * Dispatch after a subscription was requested. For {@link .group}, {@link .label} and
 * {@link .variables} see {@link SubscriptionDescriptor}
 */
export interface SubscribeAction {
  readonly type: "SUBSCRIBE";
  readonly id: SubscriptionId;
  readonly kind: SubscriptionKind;
  readonly variables: VariablesType;
}

export interface ErrorAction {
  readonly type: "ERROR";
  readonly id: SubscriptionId;
  readonly error: Error;
}

export interface UnsubscribeAction {
  readonly type: "UNSUBSCRIBE";
  readonly id: SubscriptionId;
}

export interface ReceivedAction {
  readonly type: "RECEIVED";
  readonly id: SubscriptionId;
  readonly date: Date;
}

export type SubscriptionAction =
  | SubscribeAction
  | ErrorAction
  | UnsubscribeAction
  | ReceivedAction;

/**
 * Create a unique subscription id. Used as a handle to update info about this subscription.
 */
export function createSubscriptionId(): SubscriptionId {
  return uuid();
}

export function reducer(
  state: SubscriptionManagerState,
  action: SubscriptionAction,
): SubscriptionManagerState {
  const current = state.active[action.id];
  switch (action.type) {
    case "SUBSCRIBE":
      return {
        ...state,
        active: {
          ...state.active,
          [action.id]: {
            kind: action.kind,
            variables: action.variables,
            lastReceived: null,
            error: null,
          },
        },
      };
    case "ERROR":
      if (!current) {
        throw new Error("Didn't find subscription");
      }

      return {
        ...state,
        active: {
          ...state.active,
          [action.id]: {
            ...current,
            error: action.error,
          },
        },
      };
    case "RECEIVED":
      if (!current) {
        throw new Error("Didn't find subscription");
      }

      return {
        ...state,
        active: {
          ...state.active,
          [action.id]: {
            ...current,
            lastReceived: action.date,
          },
        },
      };
    case "UNSUBSCRIBE":
      return {
        ...state,
        active: omit(state.active, action.id),
      };
  }
}

export const SubscriptionManagerDispatchContext = createContext<
  (action: SubscriptionAction) => void
>(() => undefined);
SubscriptionManagerDispatchContext.displayName =
  "SubscriptionManagerDispatchContext";

export const SubscriptionManagerStateContext = createContext<SubscriptionManagerState>(
  { active: {} },
);
SubscriptionManagerStateContext.displayName = "SubscriptionManagerStateContext";

export const SubscriptionManager: FC = function SubscriptionManager({
  children,
}) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <SubscriptionManagerDispatchContext.Provider value={dispatch}>
      <SubscriptionManagerStateContext.Provider value={state}>
        {children}
      </SubscriptionManagerStateContext.Provider>
    </SubscriptionManagerDispatchContext.Provider>
  );
};

export function useSubscriptionManagerActions(kind: SubscriptionKind) {
  const dispatch = useContext(SubscriptionManagerDispatchContext);
  const [id] = useState(createSubscriptionId());

  return {
    subscribe: useCallback(
      (variables: VariablesType) => {
        dispatch({
          type: "SUBSCRIBE",
          id,
          kind,
          variables,
        });
      },
      [dispatch, id, kind],
    ),
    received: useCallback(
      (date: Date = new Date()) => {
        dispatch({
          type: "RECEIVED",
          id,
          date,
        });
      },
      [dispatch, id],
    ),
    error: useCallback(
      (error: Error) => {
        dispatch({
          type: "ERROR",
          id,
          error,
        });
      },
      [dispatch, id],
    ),
    unsubscribe: useCallback(() => {
      dispatch({
        type: "UNSUBSCRIBE",
        id,
      });
    }, [dispatch, id]),
  };
}

export interface SubscriptionOptions<TSubscription extends OperationType>
  extends GraphQLSubscriptionConfig<TSubscription> {
  readonly kind: SubscriptionKind;

  /**
   * Should we actually subscribe (set to false to disable the subscription).
   *
   * @default true
   */
  readonly enable?: boolean | null | undefined;

  readonly configs?: ReadonlyArray<DeclarativeMutationConfig>;
  readonly subscription: GraphQLTaggedNode;
  readonly variables: TSubscription["variables"];
  readonly onCompleted?: () => void;
  readonly onError?: (error: Error) => void;
  readonly onNext?: (
    response: TSubscription["response"] | null | undefined,
  ) => void;
  readonly updater?: SelectorStoreUpdater<TSubscription["response"]>;
}

/**
 * Wrapper around relays requestSubscription inside an useEffect. This
 * automatically applies all interactions with the SubscriptionManager.
 *
 * The options argument must be memoized, as changes to it will cause the
 * subscription to be cancelled and reopened.
 *
 * @example ```ts
 * const subscriptionConfig = useMemo<
 *   SubscriptionOptions<TimeTrackRowSubscription>
 * >(
 *   () => ({
 *     group: "TimeTrackRow",
 *     label: "Zeiterfassungseintrag: Zeile",
 *     subscription: TimeTrackSubscription,
 *     variables: {
 *       id,
 *     },
 *   }),
 *   [id],
 * );
 * useSubscription<TimeTrackRowSubscription>(subscriptionConfig);
 * ```
 *
 * @param options combination of requestSubscription's options with
 *  SubscriptionManager options. Must be memoized.
 */
export function useSubscription<TSubscription extends OperationType>(
  options: SubscriptionOptions<TSubscription>,
) {
  const environment = useRelayEnvironment();
  const dispatch = useContext(SubscriptionManagerDispatchContext);
  const [id] = useState(createSubscriptionId());
  const {
    kind,
    enable,
    configs,
    subscription,
    variables,
    onCompleted,
    onError,
    onNext,
    updater,
  } = options;

  useEffect(() => {
    /**
     * if explicitly disabled we don't subscribe
     *
     * @see SubscriptionOptions.enable
     */
    if (enable === false) {
      return undefined;
    }

    dispatch({
      type: "SUBSCRIBE",
      id,
      kind,
      variables,
    });

    const disposable = requestSubscription<TSubscription>(environment, {
      configs,
      subscription,
      variables,
      onCompleted() {
        // TODO: Completed Status? (currently not used by server)

        if (onCompleted) onCompleted();
      },
      onError(error) {
        dispatch({
          type: "ERROR",
          id,
          error,
        });

        if (onError) onError(error);
      },
      onNext(response) {
        dispatch({
          type: "RECEIVED",
          id,
          date: new Date(),
        });

        if (onNext) onNext(response);
      },
      updater,
    });

    return () => {
      dispatch({
        type: "UNSUBSCRIBE",
        id,
      });
      disposable.dispose();
    };
  }, [
    id,
    kind,
    enable,
    configs,
    subscription,
    variables,
    onCompleted,
    onError,
    onNext,
    updater,
    dispatch,
    environment,
  ]);
}

export type SubscriptionManagerData = SubscriptionManagerState & {
  readonly grouped: { readonly [key: string]: SubscriptionDescriptor[] };
};

/**
 * Use computed values from {@link SubscriptionManagerStateContext}.
 *
 * @todo Could be optimized to cache the result globally in the future (lazy). But is currently only
 *   used in one place.
 */
export function useSubscriptionManagerState(): SubscriptionManagerData {
  const state = useContext(SubscriptionManagerStateContext);

  return useMemo<SubscriptionManagerData>(() => {
    const grouped = groupBy(Object.values(state.active), "kind.group");

    return {
      ...state,
      grouped,
    };
  }, [state]);
}
