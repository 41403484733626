import { Card, H3, KeyCombo } from "@blueprintjs/core";
import { PortalBarKeyCombo } from "../../components/PortalBarConfig";
import { Col, Row } from "../../components/Grid";
import { useFeatureFlag } from "../../app-controller/ViewerContext";
import { Heading } from "../../components/Heading";
import { TodoCreateQuickDialogCombo } from "../../components/TodoCreateQuickDialogConfig";
import { expectYes } from "../../app-controller/ViewerUtils";

export function DashboardTips() {
  const tips = useFeatureFlag("tips");

  if (!expectYes(tips)) {
    return null;
  }

  return (
    <>
      <Heading level={2}>Tipps</Heading>
      <Row>
        <Col md={6}>
          <DashboardShortcutsTip />
        </Col>
        <Col md={6}>
          <DashboardTodoPriorityTip />
        </Col>
      </Row>
      <br />
    </>
  );
}

export function DashboardShortcutsTip() {
  return (
    <Card elevation={1}>
      <H3 size={5}>Kurzbefehle</H3>
      <p>
        Das Portal bietet Tastatur-Kurzbefehle um bestimmte Aufgaben schneller
        zu erledigen.
      </p>
      <p>
        Die globale Suche kann beispielsweise mit{" "}
        <KeyCombo combo={PortalBarKeyCombo} className={"inline-flex"} />{" "}
        geöffnet werden.
      </p>
      <p>
        Über den Kurzbefehl{" "}
        <KeyCombo
          combo={TodoCreateQuickDialogCombo}
          className={"inline-flex"}
        />{" "}
        kann von überall ein neues Todo erstellt werden!
      </p>
      <p>
        Über den Kurzbefehl{" "}
        <KeyCombo combo={"shift + ?"} className={"inline-flex"} /> kann eine
        Übersicht aller Kurzbefehle angezeigt werden.
      </p>
    </Card>
  );
}

export function DashboardTodoPriorityTip() {
  return (
    <Card elevation={1}>
      <H3 size={5}>Todo Priorität</H3>
      <p>
        Mit einem Rechtsklick auf ein Todo kann dessen Priorität eingestellt
        werden.
      </p>
    </Card>
  );
}
