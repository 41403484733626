import { FC } from "react";
import { useFeatureFlag } from "../../../app-controller/ViewerContext";
import { MaybeAllowed } from "../../../app-controller/ViewerUtils";
import { Snowflakes } from "./Snowflakes";
import { ChristmasTreeIllustration } from "../../../components/ChristmasTreeIllustration";
import { HalloweenIllustration } from "./HalloweenIllustration";

export const SpecialBackgroundWrapper: FC = function () {
  const christmas = useFeatureFlag("christmas");

  if (
    process.env.REACT_APP_EMBED_CHRISTMAS === "true" &&
    christmas === MaybeAllowed.YES
  ) {
    return <Snowflakes />;
  } else {
    return null;
  }
};

export const SpecialIllustrationWrapper: FC = function () {
  const christmas = useFeatureFlag("christmas");
  const halloween = useFeatureFlag("halloween");

  if (
    process.env.REACT_APP_EMBED_CHRISTMAS === "true" &&
    christmas === MaybeAllowed.YES
  ) {
    return <ChristmasTreeIllustration />;
  } else if (
    process.env.REACT_APP_EMBED_HALLOWEEN === "true" &&
    halloween === MaybeAllowed.YES
  ) {
    return <HalloweenIllustration />;
  } else {
    return null;
  }
};
