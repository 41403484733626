import { format } from "date-fns";
import { momentDateFormat, momentTimeFormat } from "./mask";
import { isDateEmpty, withDate, withStartTime } from "./manipulate";

/**
 *
 * @param date
 * @param initialStartDate
 * @param keepDateIfNull if initialStartDate is null/undefined don't overwrite the date part
 */
export function extractFromStartDate(
  date: string,
  initialStartDate: Optional<Date>,
  keepDateIfNull = false,
): string {
  const startDate = initialStartDate ? initialStartDate : null;
  const newDate = startDate ? format(startDate, momentDateFormat) : "";
  const startTime = startDate ? format(startDate, momentTimeFormat) : "";

  if (keepDateIfNull && isDateEmpty(newDate)) {
    return withStartTime(date, startTime);
  }

  return withDate(withStartTime(date, startTime), newDate);
}
