import { hot } from "react-hot-loader/root";
import { RelayEnvironmentProvider } from "relay-hooks";
import { StateHost } from "./app-controller/StateHost";
import {
  AppServiceContext,
  AppServiceType,
} from "./app-controller/AppServiceContext";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import { DeviceInfo, DeviceInfoContext } from "./app/DeviceInfo";
import { HotkeysProvider } from "@blueprintjs/core";

export interface Props {
  environment: RelayModernEnvironment;
  appService: AppServiceType;
  deviceInfo: DeviceInfo;
}

function Root({ environment, appService, deviceInfo }: Props) {
  return (
    <DeviceInfoContext.Provider value={deviceInfo}>
      <HotkeysProvider>
        <RelayEnvironmentProvider environment={environment}>
          <AppServiceContext.Provider value={appService}>
            <StateHost />
          </AppServiceContext.Provider>
        </RelayEnvironmentProvider>
      </HotkeysProvider>
    </DeviceInfoContext.Provider>
  );
}

export default hot(Root);
