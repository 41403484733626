import { AppNonIdealState } from "../components/AppNonIdealState";
import * as Sentry from "@sentry/react";
import { routes } from "./routes";
import { renderRoutes } from "react-router-config";

export function Content() {
  return (
    <Sentry.ErrorBoundary fallback={<AppNonIdealState />}>
      {renderRoutes(routes)}
    </Sentry.ErrorBoundary>
  );
}
