import {
  assertModelLength,
  dateMask,
  endTimeOffset,
  startTimeOffset,
  timeMask,
} from "./mask";

/**
 *
 * @param value whole model (date + times)
 * @return {string} 'date' part of model
 */
export function extractDate(value: string): string {
  assertModelLength(value);
  return value.slice(0, dateMask.length);
}

/**
 *
 * @param value whole model (date + times)
 * @param date replacing date
 * @return {string} new model
 */
export function withDate(value: string, date: string): string {
  assertModelLength(value);
  return formatDate(date) + value.slice(dateMask.length);
}

export function extractStartTime(value: string): string {
  assertModelLength(value);
  return value.slice(startTimeOffset, startTimeOffset + timeMask.length);
}

export function withStartTime(value: string, startTime: string): string {
  assertModelLength(value);
  return (
    value.slice(0, startTimeOffset) +
    formatTime(startTime) +
    value.slice(startTimeOffset + timeMask.length)
  );
}

export function extractEndTime(value: string): string {
  assertModelLength(value);
  return value.slice(endTimeOffset, endTimeOffset + timeMask.length);
}

export function withEndTime(value: string, endTime: string): string {
  assertModelLength(value);
  return (
    value.slice(0, endTimeOffset) +
    formatTime(endTime) +
    value.slice(endTimeOffset + timeMask.length)
  );
}

export function formatDate(date: string): string {
  return dateMask
    .map((_, index) => {
      const char = date[index];
      if (!char || char > "9" || char < "0") {
        if (index === 2 || index === 5) {
          return ".";
        }
        return "_";
      }
      return char;
    })
    .join("");
}

export function formatTime(time: string): string {
  return timeMask
    .map((_, index) => {
      const char = time[index];
      if (!char || char > "9" || char < "0") {
        if (index === 2) {
          return ":";
        }
        return "_";
      }
      return char;
    })
    .join("");
}

export function isDateEmpty(date: string): boolean {
  return date.replace(/[_ .]/g, "") === "";
}

export function isTimeEmpty(time: string): boolean {
  return time.replace(/[_ :]/g, "") === "";
}

export function isEmpty(value: string): boolean {
  return value.replace(/[_ .:-]/g, "") === "";
}
