function joinList(items: string[]): string {
  if (items.length === 0) {
    return "";
  } else if (items.length === 1) {
    return items[0];
  } else {
    const prefix =
      items.length > 2
        ? items.slice(0, items.length - 2).join(", ") + ", "
        : "";

    return prefix + items[items.length - 2] + " und " + items[items.length - 1];
  }
}

/**
 * Formats the items as a natural sentence including pluralization and
 * truncation. Returns an empty string if no items are given.
 */
export function checksListFormat(items: string[]): string {
  const _items = items.length > 3 ? [items[0], items[1], "Weitere"] : items;

  const joinedList = joinList(_items);
  const suffix =
    items.length > 1 ? " fehlen" : items.length === 1 ? " fehlt" : "";

  return joinedList + suffix;
}
