import { Button } from "./button/Button";
import { Intent } from "../util/Intent";
import ErrorIllustration from "./ErrorIllustrationLazy";

export function AppNonIdealState() {
  return (
    <div className="flex flex-col m-8 md:mx-auto md:w-144 text-center">
      <div aria-hidden>
        <ErrorIllustration />
      </div>
      <h2 className="font-bold">Es ist ein Fehler aufgetreten.</h2>
      <p className="w-3/4 mx-auto">
        Sollte dieses Problem weiter bestehen, kontaktieren Sie bitte Ihren
        Administrator.
      </p>
      <div>
        <Button
          intent={Intent.PRIMARY}
          onClick={() => {
            window.location.reload();
          }}
        >
          Neu Laden
        </Button>
      </div>
    </div>
  );
}
