import {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { v4 } from "uuid";

const HeaderLoadingValueContext = createContext<{ isLoading: boolean }>({
  isLoading: false,
});

const HeaderLoadingCommandContext = createContext<{
  setLoading: (id: string, loading: boolean) => void;
}>({
  setLoading: () => {},
});

export const useHeaderLoading = function (): boolean {
  const value = useContext(HeaderLoadingValueContext);
  return value.isLoading;
};

export const useSetHeaderLoading = function (): (
  id: string,
  loading: boolean,
) => void {
  const commands = useContext(HeaderLoadingCommandContext);
  return commands.setLoading;
};

export const HeaderLoader: FC = function () {
  const setLoading = useSetHeaderLoading();
  const [id] = useState(v4());
  useEffect(() => {
    setLoading(id, true);
    return () => setLoading(id, false);
  }, [id, setLoading]);

  return null;
};

export const HeaderLoadingProvider: FC = function ({ children }) {
  const [loadingTokens, setLoadingTokens] = useState<{ [key: string]: true }>(
    {},
  );

  const commands = useMemo<{
    setLoading: (id: string, loading: boolean) => void;
  }>(
    () => ({
      setLoading: (id, loading) => {
        setLoadingTokens((tokens) => {
          if (!loading) {
            const val = { ...tokens };
            delete val[id];
            return val;
          } else {
            return { ...tokens, [id]: true };
          }
        });
      },
    }),
    [setLoadingTokens],
  );

  const value = useMemo(
    () => ({ isLoading: Object.keys(loadingTokens).length > 0 }),
    [loadingTokens],
  );

  return (
    <HeaderLoadingCommandContext.Provider value={commands}>
      <HeaderLoadingValueContext.Provider value={value}>
        {children}
      </HeaderLoadingValueContext.Provider>
    </HeaderLoadingCommandContext.Provider>
  );
};
