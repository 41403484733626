/**
 * @generated SignedSource<<8feb0aba7fa1a5d72054189c9679c518>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TodoListEntry_todo$data = {
  readonly id: string;
  readonly title: string | null;
  readonly note: string | null;
  readonly due: string | null;
  readonly done: boolean;
  readonly priority: {
    readonly key: string;
  };
  readonly timeTrack: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"TimeTrackLink_timeTrack">;
  } | null;
  readonly customer: {
    readonly id: string;
    readonly displayName: string;
    readonly printName: string;
    readonly reference: string | null;
  } | null;
  readonly employee: {
    readonly id: string;
    readonly displayName: string;
    readonly printName: string;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"TodoDoneButton_todo">;
  readonly " $fragmentType": "TodoListEntry_todo";
};
export type TodoListEntry_todo$key = {
  readonly " $data"?: TodoListEntry_todo$data;
  readonly " $fragmentSpreads": FragmentRefs<"TodoListEntry_todo">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "printName",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TodoListEntry_todo",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "note",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TodoDoneButton_todo"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "due",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "done",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TodoPriority",
      "kind": "LinkedField",
      "name": "priority",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TimeTrack",
      "kind": "LinkedField",
      "name": "timeTrack",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TimeTrackLink_timeTrack"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Customer",
      "kind": "LinkedField",
      "name": "customer",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reference",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Employee",
      "kind": "LinkedField",
      "name": "employee",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Todo",
  "abstractKey": null
};
})();

(node as any).hash = "934d8db973e6c69056f1afe61c0e8a91";

export default node;
