import { useMemo } from "react";
import { TimeTrackWidget_employee$key } from "./__generated__/TimeTrackWidget_employee.graphql";
import { RunningTimeTrackWidget } from "./RunningTimeTrackWidget";
import { EmptyTimeTrackWidget } from "./EmptyTimeTrackWidget";
import { Container } from "../../components/Grid";
import "./TimeTrackWidget.module.scss";
import ClipLoader from "react-spinners/ClipLoader";
import styles from "./TimeTrackWidget.module.scss";
import {
  SubscriptionOptions,
  useSubscription,
} from "../../components/SubscriptionManager";
import { TimeTrackWidgetViewerSubscription } from "./__generated__/TimeTrackWidgetViewerSubscription.graphql";
import classNames from "classnames";
import { useFragment } from "relay-hooks";

type Props = {
  employee: TimeTrackWidget_employee$key | null;
  isLoading: boolean;
  /**
   * Smaller negative margin
   */
  isOnDashboard?: boolean;
};

const fragment = graphql`
  fragment TimeTrackWidget_employee on Employee {
    id
    currentTimeTrack {
      id
      ...RunningTimeTrackWidget_timeTrack
    }

    ...EmptyTimeTrackWidget_employee
    ...RunningTimeTrackWidget_employee
  }
`;

const ViewerSubscription = graphql`
  subscription TimeTrackWidgetViewerSubscription($id: ID!) {
    node(id: $id) {
      node {
        ... on Employee {
          ...TimeTrackWidget_employee
        }
      }
    }
  }
`;

const WidgetLoader = () => (
  <div className={styles.wrapper}>
    <div className={styles.widget}>
      <Container>
        <div className="text-center">
          <ClipLoader color="#ffffff" />
        </div>
      </Container>
    </div>
  </div>
);

export const ViewerSubscriptionKind = {
  group: "TimeTrackWidget",
  label: "Aktueller Zeiterfassungseintrag",
};

export function TimeTrackWidget(props: Props) {
  const employee = useFragment(fragment, props.employee);
  const id = employee ? employee.id : null;

  const subscriptionConfig = useMemo<
    SubscriptionOptions<TimeTrackWidgetViewerSubscription>
  >(
    () => ({
      kind: ViewerSubscriptionKind,
      enable: id !== null,
      subscription: ViewerSubscription,
      variables: {
        // @ts-expect-error: subscription is disabled if id is null
        id,
      },
    }),
    [id],
  );
  useSubscription<TimeTrackWidgetViewerSubscription>(subscriptionConfig);

  if (props.isLoading) {
    return <WidgetLoader />;
  }

  if (!employee) {
    return null;
  }

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.dashboard]: props.isOnDashboard,
      })}
    >
      <div className={styles.widget}>
        {employee.currentTimeTrack ? (
          <RunningTimeTrackWidget
            employee={employee}
            timeTrack={employee.currentTimeTrack}
            isOnDashboard={props.isOnDashboard}
          />
        ) : (
          <EmptyTimeTrackWidget employee={employee} />
        )}
      </div>
    </div>
  );
}
