import { Container } from "./Grid";
import styles from "./Loader.module.scss";
import { FC, useEffect, useState } from "react";

export const Loader: FC<{ wait?: number }> = function ({ wait }) {
  const [visible, setVisible] = useState(!wait);

  useEffect(() => {
    const timeout = setTimeout(() => setVisible(true), wait);
    return () => clearTimeout(timeout);
  }, [wait]);

  if (!visible) {
    return null;
  }

  return (
    <Container className="text-center">
      <br />
      <div className={styles.loader} />
    </Container>
  );
};
