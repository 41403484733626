import { FC, useState } from "react";
import { TodosQuery } from "./__generated__/TodosQuery.graphql";
import { parseTodoConnectionFilters } from "../model/TodoConnectionSerialization";
import { Header, Tab } from "../../../components/Header";
import { defaultDoneFilters } from "../components/TodoListDefaultFilters";
import { STORE_THEN_NETWORK, useQuery } from "relay-hooks";
import loadable from "@loadable/component";
import { useHistory } from "react-router";
import { Icon } from "@mormahr/babel-transform-icon";
import { query } from "./Todos";
import {
  HeaderLoader,
  HeaderLoadingProvider,
} from "../../../components/HeaderLoading";

const TodoList = loadable(
  () => import(/* webpackChunkName: "TodoList" */ "../components/TodoList"),
);

export const TodosDone: FC = function TodosDone() {
  const history = useHistory();
  const filtersSetInUrl = parseTodoConnectionFilters(
    history.location.search,
    defaultDoneFilters,
  );
  const [initialFilters] = useState(filtersSetInUrl);
  const {
    data: props,
    error,
    isLoading,
  } = useQuery<TodosQuery>(query, initialFilters, {
    fetchPolicy: STORE_THEN_NETWORK,
  });

  if (error) {
    throw error;
  }

  return (
    <HeaderLoadingProvider>
      <Header title="Übersicht" subTitle="Todos" isLoading={isLoading}>
        <Tab
          to="/todo"
          exact
          icon={<Icon icon="clipboard-list" type="regular" />}
        >
          Offen
        </Tab>
        <Tab
          to="/todo/done"
          exact
          icon={<Icon icon="clipboard-check" type="regular" />}
        >
          Erledigt
        </Tab>
      </Header>
      <TodoList
        query={props ?? null}
        urlFilters={initialFilters}
        fallback={<HeaderLoader />}
      />
    </HeaderLoadingProvider>
  );
};
