import { TodoListEntry } from "./TodoListEntry";
import { createFragmentContainer } from "react-relay";
import { TodoListData_todos$data } from "./__generated__/TodoListData_todos.graphql";
import { useRelayEnvironment } from "relay-hooks";

export type Props = {
  todos: TodoListData_todos$data;
};

export function TodoListDataComponent({ todos }: Props) {
  const environment = useRelayEnvironment();
  const { edges } = todos;
  if (!edges) {
    return <div>...</div>;
  }

  return (
    <>
      {edges.map((edge) => {
        if (!edge || !edge.node) {
          return <span>...</span>;
        }

        return (
          <TodoListEntry
            key={edge.node.id}
            todo={edge.node}
            relayEnvironment={environment}
          />
        );
      })}
    </>
  );
}

export const TodoListData = createFragmentContainer(TodoListDataComponent, {
  todos: graphql`
    fragment TodoListData_todos on TodoConnection {
      edges {
        node {
          id
          ...TodoListEntry_todo
        }
      }
    }
  `,
});
