import { TimeTrackTimingMutations_setTimeTrackTimesMutation } from "./__generated__/TimeTrackTimingMutations_setTimeTrackTimesMutation.graphql";
import {
  doMutation,
  MutationCompletionStatus,
} from "../../../mutation/doMutation";
import { IEnvironment } from "relay-runtime";
import { addTimeTrackEventEdge } from "../../../util/addTimeTrackEventEdge";

const mutation = graphql`
  mutation TimeTrackTimingMutations_setTimeTrackTimesMutation(
    $input: setTimeTrackTimesInput!
  ) {
    setTimeTrackTimes(input: $input) {
      timeTrack {
        canApprove
        canApproveReason
        canRemove

        recordedStart
        recordedEnd
        roundedStart
        roundedEnd
        workUnits
      }

      event {
        id
        timeTrack {
          id
        }

        ...TimeTrackHistoryRow_event
      }
    }
  }
`;

export function setTimeTrackTimes(
  environment: IEnvironment,
  timeTrackId: string,
  start: Optional<string>,
  end: Optional<string>,
  onComplete: (status: MutationCompletionStatus) => void,
) {
  const variables = {
    input: {
      timeTrackId,
      start,
      end,
    },
  };

  doMutation<TimeTrackTimingMutations_setTimeTrackTimesMutation>({
    environment,
    mutation,
    variables,
    onComplete,

    updater(store) {
      const event = store
        .getRootField("setTimeTrackTimes")
        ?.getLinkedRecord("event");
      if (event) {
        addTimeTrackEventEdge(store, event);
      }
    },
  });
}
