import { Icon } from "@mormahr/babel-transform-icon";
import { useMemo, useState } from "react";
import { createFragmentContainer } from "react-relay";
import { RunningTimeTrackWidget_employee$data } from "./__generated__/RunningTimeTrackWidget_employee.graphql";
import { RunningTimeTrackWidget_timeTrack$data } from "./__generated__/RunningTimeTrackWidget_timeTrack.graphql";
import { CustomerChooser } from "../../components/CustomerChooser";
import {
  setTimeTrackCustomer,
  setTimeTrackTitle,
  setTimeTrackNote,
} from "../user/workspace/TimeTrackRowMutations";
import { FlatCheckbox } from "../../components/checkbox/FlatCheckbox";
import styles from "./TimeTrackWidget.module.scss";
import { AppToaster } from "../toaster";
import {
  Classes,
  Dialog,
  EditableText,
  FormGroup,
  Tooltip,
} from "@blueprintjs/core";
import { Button } from "../../components/button/Button";
import { Intent } from "../../util/Intent";
import { TimeTrackTitleField } from "@mormahr/portal-validation";
import {
  DateRangeField,
  OnChangeCallbackParameter,
} from "../../components/daterange/DateRangeField";
import {
  SubscriptionOptions,
  useSubscription,
} from "../../components/SubscriptionManager";
import { RunningTimeTrackWidgetSubscription } from "./__generated__/RunningTimeTrackWidgetSubscription.graphql";
import { doMutation } from "../../mutation/doMutation";
import { RunningTimeTrackWidget_setTimeTrackTimesMutation } from "./__generated__/RunningTimeTrackWidget_setTimeTrackTimesMutation.graphql";
import { RunningTimeTrackWidget_setTimeTrackEndMutation } from "./__generated__/RunningTimeTrackWidget_setTimeTrackEndMutation.graphql";
import {
  differenceInHours,
  getDate,
  getMonth,
  getYear,
  parseISO,
  setDate,
  setMonth,
  setYear,
} from "date-fns";
import { CustomerChooserButton } from "../../components/CustomerChooserButton";
import { useHistory } from "react-router";
import classNames from "classnames";
import { useRelayEnvironment } from "relay-hooks";
import { addTimeTrackEventEdge } from "../../util/addTimeTrackEventEdge";
import { TimeFromNow } from "../../components/TimeFromNow";
import { LiveStopwatch } from "../../components/LiveStopwatch";
import { LazyTimeTrackEditorDialog } from "../user/workspace/LazyTimeTrackEditorDialog";
import { TimeTrackTimeChooserPopover } from "../user/workspace/TimeTrackTimeChooserPopover";

type Props = {
  employee: RunningTimeTrackWidget_employee$data;
  timeTrack: RunningTimeTrackWidget_timeTrack$data;
  isOnDashboard?: boolean;
};

function replaceDatePart(
  from: Date | null | undefined,
  withDate: Date | null | undefined,
): Date | null | undefined {
  if (!from || !withDate) {
    return from;
  }

  return setYear(
    setMonth(setDate(from, getDate(withDate)), getMonth(withDate)),
    getYear(withDate),
  );
}

const TimeTrackSubscription = graphql`
  subscription RunningTimeTrackWidgetSubscription($id: ID!) {
    node(id: $id) {
      node {
        ... on TimeTrack {
          ...RunningTimeTrackWidget_timeTrack
        }
      }
    }
  }
`;

export const TimeTrackSubscriptionKind = {
  group: "RunningTimeTrackWidget",
  label: "Aktiver Zeiterfassungseintrag",
};

function getErrorMessage(value: string | undefined): string | undefined {
  try {
    TimeTrackTitleField.validateSync(value);
  } catch (err) {
    // @ts-expect-error
    return err.message;
  }
}

export function RunningTimeTrackWidgetComponent(props: Props) {
  const environment = useRelayEnvironment();
  const [finishTodo, setFinishTodo] = useState(true);
  const id = props.timeTrack ? props.timeTrack.id : null;
  const history = useHistory();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isTimeChooserOpen, setIsTimeChooserOpen] = useState(false);

  const subscriptionConfig = useMemo<
    SubscriptionOptions<RunningTimeTrackWidgetSubscription>
  >(
    () => ({
      kind: TimeTrackSubscriptionKind,
      enable: id !== null,
      subscription: TimeTrackSubscription,
      variables: {
        // @ts-expect-error: subscription is disabled if id is null
        id,
      },
    }),
    [id],
  );
  useSubscription<RunningTimeTrackWidgetSubscription>(subscriptionConfig);

  const [showEndTimeAlert, setShowEndTimeAlert] = useState(false);
  const [currentDates, setCurrentDates] = useState<OnChangeCallbackParameter>({
    startDate: new Date(),
    endDate: new Date(),
  });

  const onClick = () => {
    if (currentTimeTrack.recordedStart === null) {
      console.error("Invalid state");
      return;
    }

    const recordedStart = parseISO(currentTimeTrack.recordedStart);
    const recordedEnd = new Date();

    if (differenceInHours(recordedEnd, recordedStart) < 24) {
      stop(recordedEnd);
    } else {
      setCurrentDates({
        startDate: recordedStart,
        endDate: replaceDatePart(recordedEnd, recordedStart),
      });
      setShowEndTimeAlert(true);
    }
  };

  const stop = (recordedEnd: Date) => {
    const variables: RunningTimeTrackWidget_setTimeTrackEndMutation["variables"] =
      {
        input: {
          timeTrackId: props.timeTrack.id,
          end: recordedEnd.toISOString(),
          finishTodo,
          withWidget: true,
        },
      };

    doMutation<RunningTimeTrackWidget_setTimeTrackEndMutation>({
      environment,
      mutation: graphql`
        mutation RunningTimeTrackWidget_setTimeTrackEndMutation(
          $input: setTimeTrackEndInput!
        ) {
          setTimeTrackEnd(input: $input) {
            timeTrack {
              recordedStart
              roundedStart
              recordedEnd
              roundedEnd
              workUnits
              canApprove
              canApproveReason

              todo {
                done
                doneTs
              }

              ...TimeTrackEditorDialog_timeTrack
            }

            event {
              id
              timeTrack {
                id
              }

              ...TimeTrackHistoryRow_event
            }
          }
        }
      `,
      variables,
      updater: (store) => {
        const event = store
          .getRootField("setTimeTrackEnd")
          ?.getLinkedRecord("event");
        if (event) {
          addTimeTrackEventEdge(store, event);
        }

        const employeeProxy = store.get(props.employee.id);
        // @ts-ignore
        employeeProxy.setValue(null, "currentTimeTrack");
      },
      successToast: {
        message: "Zeiterfassung wurde erfolgreich gestoppt.",
        intent: Intent.SUCCESS,
        action: {
          icon: (
            <span className={Classes.ICON}>
              <Icon icon="pen" type="regular" />
            </span>
          ),
          text: "Bearbeiten",
          href: `/timetrack/${props.timeTrack.id}`,
          onClick: (event) => {
            // <AnchorButton renders a native <a> instead of a react-router <Link>. We want to
            // display a link to enable right click -> new tab opening, but if we navigate in tab
            // we don't want a full page reload, this is why we need to prevent regular navigation
            // here.
            event.preventDefault();
            event.stopPropagation();
            history.push(`/timetrack/${props.timeTrack.id}`);
          },
        },
      },
    });
  };

  const setTimes = (recordedStart: Date, recordedEnd: Date) => {
    const variables: RunningTimeTrackWidget_setTimeTrackTimesMutation["variables"] =
      {
        input: {
          timeTrackId: props.timeTrack.id,
          start: recordedStart.toISOString(),
          end: recordedEnd.toISOString(),
          finishTodo,
        },
      };

    doMutation<RunningTimeTrackWidget_setTimeTrackTimesMutation>({
      environment,
      mutation: graphql`
        mutation RunningTimeTrackWidget_setTimeTrackTimesMutation(
          $input: setTimeTrackTimesInput!
        ) {
          setTimeTrackTimes(input: $input) {
            timeTrack {
              recordedStart
              roundedStart
              recordedEnd
              roundedEnd
              workUnits

              todo {
                done
                doneTs
              }

              ...TimeTrackEditorDialog_timeTrack
            }
          }
        }
      `,
      variables,
      updater: (store) => {
        const employeeProxy = store.get(props.employee.id);
        // @ts-ignore
        employeeProxy.setValue(null, "currentTimeTrack");
      },
    });
  };

  const setCustomer = (customerId: Optional<string>) => {
    setTimeTrackCustomer(environment, props.timeTrack.id, customerId);
  };

  const setTitle = (value: string) => {
    setTimeTrackTitle(environment, props.timeTrack.id, value);
  };

  const setNote = (value: string) => {
    setTimeTrackNote(environment, props.timeTrack.id, value);
  };

  const currentTimeTrack = props.timeTrack;

  const { bottom, button, main, note, running, title, todo, top } = styles;

  return (
    <section
      className={running}
      data-cy="widget-timetrack-running"
      title="Laufender Zeiterfassungseintrag"
    >
      {isEditOpen && (
        <LazyTimeTrackEditorDialog
          timeTrack={currentTimeTrack}
          isOpen={isEditOpen}
          onClose={() => setIsEditOpen(false)}
        />
      )}
      {isTimeChooserOpen && (
        <TimeTrackTimeChooserPopover
          timeTrack={props.timeTrack}
          isOpen={isTimeChooserOpen}
          setOpen={setIsTimeChooserOpen}
        />
      )}
      <Dialog
        isOpen={showEndTimeAlert}
        title="Zeiterfassungseintrag beenden"
        canEscapeKeyClose
        canOutsideClickClose
        onClose={() => setShowEndTimeAlert(false)}
      >
        <form
          onSubmit={(event) => {
            if (
              currentDates.startDate !== null &&
              currentDates.startDate !== undefined &&
              currentDates.endDate !== null &&
              currentDates.endDate !== undefined &&
              differenceInHours(currentDates.endDate, currentDates.startDate) <
                24
            ) {
              setTimes(currentDates.startDate, currentDates.endDate);
            }

            event.preventDefault();
          }}
        >
          <div className={Classes.DIALOG_BODY}>
            <p>
              Der Zeiterfassungseintrag wurde vor über 24h gestartet. Ein
              Zeiterfassungseintrag kann maximal 24h lang sein, deshalb kann die
              Start- und Endzeit hier bearbeitet werden.
            </p>
            <FormGroup label={"Zeitraum"} labelFor={"tt-widg-dates"}>
              <DateRangeField
                id={"tt-widg-dates"}
                className={Classes.INPUT}
                startDate={currentDates.startDate}
                endDate={currentDates.endDate}
                onChange={setCurrentDates}
                directUpdate
                autoFocus
              />
            </FormGroup>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div
              className={classNames(Classes.DIALOG_FOOTER_ACTIONS, "space-x-2")}
            >
              <Tooltip content="Der Zeiterfassungseintrag wird nicht gestoppt">
                <Button
                  onClick={() => setShowEndTimeAlert(false)}
                  type="button"
                >
                  Abbrechen
                </Button>
              </Tooltip>
              <Button
                intent={Intent.PRIMARY}
                type="submit"
                disabled={
                  currentDates.startDate === null ||
                  currentDates.startDate === undefined ||
                  currentDates.endDate === null ||
                  currentDates.endDate === undefined
                }
              >
                Stoppen
              </Button>
            </div>
          </div>
        </form>
      </Dialog>

      <div className={top}>
        <Button
          title="Zeiten bearbeiten"
          minimal
          small
          sidebar={!props.isOnDashboard}
          onClick={() => setIsTimeChooserOpen(true)}
          className={styles["time-button"]}
        >
          {currentTimeTrack.recordedStart ? (
            <TimeFromNow date={currentTimeTrack.recordedStart} prefix="seit" />
          ) : (
            <Icon icon="stopwatch" type="regular" />
          )}
        </Button>{" "}
        <span>
          <Button
            title="Bearbeiten"
            minimal
            sidebar={!props.isOnDashboard}
            small
            // Blueprint placeholder color to match rest of widget
            className={styles.edit}
            onClick={() => setIsEditOpen((v) => !v)}
          >
            <Icon icon="pencil" type="regular" />
          </Button>
        </span>
      </div>

      <div className={main}>
        <div className={title} data-cy="widget-timetrack-title" title="Titel">
          <EditableText
            defaultValue={currentTimeTrack.title ?? undefined}
            key={currentTimeTrack.title}
            placeholder="Titel"
            onConfirm={(value) => {
              if (TimeTrackTitleField.isValidSync(value)) {
                setTitle(value);
              } else {
                AppToaster().show({
                  message: getErrorMessage(value),
                  intent: Intent.DANGER,
                });
              }
            }}
          />
        </div>
        <div className={note}>
          <EditableText
            defaultValue={currentTimeTrack.note ?? undefined}
            key={currentTimeTrack.note}
            placeholder="Interne Anmerkung"
            onConfirm={setNote}
            multiline
          />
        </div>
      </div>

      <div className={bottom}>
        <CustomerChooser
          customer={currentTimeTrack.associatedCustomer}
          changeCallback={(value: { id: string; displayName: string } | null) =>
            setCustomer(value ? value.id : null)
          }
          renderButton={(props) => (
            <CustomerChooserButton
              customer={currentTimeTrack.associatedCustomer}
              {...props}
            />
          )}
        />
      </div>

      {currentTimeTrack.associatedCustomer?.category?.note &&
      currentTimeTrack.associatedCustomer.category.note !== "" ? (
        <div className="mt-1 text-gray-400 text-md">
          {currentTimeTrack.associatedCustomer.category.note}
        </div>
      ) : null}

      {currentTimeTrack.associatedCustomer?.noticeText && (
        <div className="mt-2 text-gray-400 text-md">
          <Icon icon="triangle-exclamation" type="solid" />{" "}
          {currentTimeTrack.associatedCustomer.noticeText}
        </div>
      )}

      {currentTimeTrack.todo && (
        <div className={todo}>
          <label>
            <FlatCheckbox checked={finishTodo} onChange={setFinishTodo}>
              verknüpftes Todo abschließen
            </FlatCheckbox>
          </label>
        </div>
      )}

      <div
        className={button}
        onClick={onClick}
        role="button"
        title="Zeiterfassung stoppen"
        data-cy="widget-timetrack-stop"
      >
        <LiveStopwatch
          since={
            currentTimeTrack.recordedStart
              ? parseISO(currentTimeTrack.recordedStart)
              : null
          }
        />
      </div>
    </section>
  );
}

export const RunningTimeTrackWidget = createFragmentContainer(
  RunningTimeTrackWidgetComponent,
  {
    employee: graphql`
      fragment RunningTimeTrackWidget_employee on Employee {
        id
      }
    `,

    timeTrack: graphql`
      fragment RunningTimeTrackWidget_timeTrack on TimeTrack {
        # Immer beide Fragmente ändern!
        id

        recordedStart

        title
        note

        associatedCustomer {
          ...CustomerChooser_customer @relay(mask: false)
          noticeText
          category {
            note
          }
        }

        todo {
          id
        }

        ...TimeTrackRow_timeTrack
        ...TimeTrackEditorDialog_timeTrack
        ...TimeTrackTimeChooserPopover_timeTrack
      }
    `,
  },
);
