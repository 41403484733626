import { FC } from "react";
import { useFragment } from "relay-hooks";
import { TimeTrackHistoryRowTimeChange_event$key } from "./__generated__/TimeTrackHistoryRowTimeChange_event.graphql";

const fragment = graphql`
  fragment TimeTrackHistoryRowTimeChange_event on TimeTrackEvent {
    changedFields
  }
`;

export const TimeTrackHistoryRowTimeChange: FC<{
  event: TimeTrackHistoryRowTimeChange_event$key;
}> = function TimeTrackHistoryRowTimeChange(props) {
  const event = useFragment(fragment, props.event);
  const changedFields = event.changedFields as {
    [key: string]: [unknown, unknown];
  };

  if (!changedFields.recorded_start && !changedFields.recorded_end) {
    return null;
  }

  // const start = changedFields.recorded_start as
  //   | [string | null, string | null]
  //   | null;
  // const end = changedFields.recorded_end as
  //   | [string | null, string | null]
  //   | null;
  //
  // const fromDate = start?.[0]?.slice(0, 10)
  // const toDate = start?.[0]?.slice(0, 10)

  return <span>die Zeiten geändert</span>;
};
