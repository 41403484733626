import { FC, useEffect, useState } from "react";
import { useQuery, useRelayEnvironment } from "relay-hooks";
import { CreateTokenQuery } from "./__generated__/CreateTokenQuery.graphql";
import { Header } from "../../components/Header";
import { FormGroup, HTMLSelect } from "@blueprintjs/core";
import { useViewerPermission } from "../../app-controller/ViewerContext";
import { Container } from "../../components/Grid";
import { Button } from "../../components/button/Button";
import { doMutation } from "../../mutation/doMutation";
import { CreateTokenMutation } from "./__generated__/CreateTokenMutation.graphql";
import { MaybeAllowed } from "../../app-controller/ViewerUtils";
import { Redirect } from "react-router";

const query = graphql`
  query CreateTokenQuery {
    users(first: 50, includeHidden: true) {
      edges {
        node {
          id
          displayName
        }
      }
    }

    clients(first: 50) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

const mutation = graphql`
  mutation CreateTokenMutation($input: createTokenInput!) {
    createToken(input: $input) {
      token
    }
  }
`;

export const CreateToken: FC = function CreateToken() {
  const environment = useRelayEnvironment();
  const canCreateTokenForAllUsers = useViewerPermission(
    "canCreateTokenForAllUsers",
  );
  const { data, error, isLoading } = useQuery<CreateTokenQuery>(query, {});
  const [client, setClient] = useState<string | undefined>(undefined);
  const [user, setUser] = useState<string | undefined>(undefined);
  const [token, setToken] = useState<string>();

  useEffect(() => {
    setClient((client) =>
      client === undefined ? data?.clients?.edges?.[0]?.node?.id : client,
    );
    setUser((user) =>
      user === undefined ? data?.users?.edges?.[0]?.node?.id : user,
    );
  }, [data]);

  if (canCreateTokenForAllUsers === MaybeAllowed.NO) {
    return <Redirect to="/" />;
  }

  if (error) {
    throw error;
  }

  return (
    <>
      <Header title="Token erstellen" isLoading={isLoading} />
      <Container>
        {data && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (!client) {
                return;
              }

              doMutation<CreateTokenMutation>({
                environment,
                mutation,
                variables: {
                  input: {
                    clientId: client,
                    userId: user,
                  },
                },
                onComplete(status, response) {
                  setToken(response.createToken?.token);
                },
              });
            }}
          >
            <FormGroup label="Nutzer">
              <HTMLSelect
                value={user}
                onChange={(e) => setUser(e.currentTarget.value)}
              >
                {data.users?.edges?.map((edge) => (
                  <option key={edge!.node!.id} value={edge!.node!.id}>
                    {edge!.node!.displayName}
                  </option>
                ))}
              </HTMLSelect>
            </FormGroup>
            <FormGroup label="Client">
              <HTMLSelect
                value={client}
                onChange={(e) => setClient(e.currentTarget.value)}
              >
                {data.clients?.edges?.map((edge) => (
                  <option key={edge!.node!.id} value={edge!.node!.id}>
                    {edge!.node!.name}
                  </option>
                ))}
              </HTMLSelect>
            </FormGroup>
            <Button type="submit">Token erstellen</Button>
            {token && (
              <p className="my-4">
                <output>Token: {token}</output>
              </p>
            )}
          </form>
        )}
      </Container>
    </>
  );
};
