import { RecordProxy } from "relay-runtime";

export function isNodeIdInConnection(
  connection: RecordProxy,
  nodeId: string,
): boolean {
  for (const node of connection
    .getLinkedRecords("edges")!
    .map((edge) => edge.getLinkedRecord("node")!)) {
    if (node.getValue("id") === nodeId) {
      // Node already exists, so we short circuit
      return true;
    }
  }
  return false;
}
