import { ReactElement, ReactNode } from "react";
import { TodoConnectionProvider_query$key } from "./__generated__/TodoConnectionProvider_query.graphql";
import { TodoListData } from "./TodoListData";
import { PageInfo } from "../../../util/pagination/PageInfo";
import { NormalizedTodoConnectionFilters } from "../model/TodoConnectionFilter";
import styles from "./TodoList.module.scss";
import { HeaderLoader } from "../../../components/HeaderLoading";
import { usePagination } from "relay-hooks";
import { useRefetch } from "./useRefetch";

export type Props = {
  filters: NormalizedTodoConnectionFilters;
  total: number;
  renderFooter: Optional<
    (pageInfo: PageInfo, countDiplayed: number) => ReactNode
  >;
  query: TodoConnectionProvider_query$key | null;
  render?:
    | null
    | undefined
    | ((obj: TodoListRenderObject) => ReactElement<any, any> | null);
};

export interface TodoListRenderObject {
  /**
   * Total number of todos that have been fetched. Might be lower than the number of todos
   * that match the query. If there was an error loading the todos or the query is still loading,
   * the number will be returned as `0`.
   */
  numberOfTodosDisplayed: number;

  /**
   * This renders the actual todo list.
   */
  listContent: () => ReactNode;

  /**
   * PageInfo or null, if there was an error fetching or the query is still loading.
   */
  pageInfo: PageInfo | null;

  /**
   * Is loading additional data. A spinner should be shown.
   */
  loading: boolean;

  /**
   * This should be rendered in the footer position.
   */
  footer: () => ReactNode;
}

const fragment = graphql`
  fragment TodoConnectionProvider_query on Query
  @refetchable(queryName: "TodoConnectionProviderQuery")
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 30 }
    cursor: { type: "String" }
    order: { type: "[String!]", defaultValue: ["done", "priority", "due_ts"] }
    searchTerm: { type: "String", defaultValue: "" }
    onlyDone: { type: "Boolean", defaultValue: false }
    onlyNotDone: { type: "Boolean", defaultValue: false }
    onlyCreatedBy: { type: "[ID!]", defaultValue: [] }
    onlyEmployee: { type: "[ID!]", defaultValue: [] }
    includeUnassignedEmployee: { type: "Boolean", defaultValue: false }
    onlyCustomer: { type: "[ID!]", defaultValue: [] }
    onlyPriority: { type: "[String!]", defaultValue: [] }
    onlyDueBefore: { type: "DateTime", defaultValue: null }
    onlyDueAfter: { type: "DateTime", defaultValue: null }
  ) {
    todos(
      first: $count
      after: $cursor
      order: $order
      searchTerm: $searchTerm
      onlyDone: $onlyDone
      onlyNotDone: $onlyNotDone
      onlyCreatedBy: $onlyCreatedBy
      onlyEmployee: $onlyEmployee
      includeUnassignedEmployee: $includeUnassignedEmployee
      onlyCustomer: $onlyCustomer
      onlyPriority: $onlyPriority
      onlyDueBefore: $onlyDueBefore
      onlyDueAfter: $onlyDueAfter
    )
      @connection(
        key: "TodoConnection_todos"
        filters: [
          "order"
          "searchTerm"
          "onlyDone"
          "onlyNotDone"
          "onlyCreatedBy"
          "onlyEmployee"
          "includeUnassignedEmployee"
          "onlyCustomer"
          "onlyPriority"
          "onlyDueBefore"
          "onlyDueAfter"
        ]
      ) {
      edges {
        node {
          id
        }
      }
      ...TodoListData_todos
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export function renderTodoList({
  listContent,
  loading,
  footer,
}: TodoListRenderObject): ReactElement<any, any> | null {
  return (
    <div className={styles.table}>
      {listContent()}
      {loading && (
        <div>
          <HeaderLoader />
        </div>
      )}
      <div className={styles.footer}>{footer()}</div>
    </div>
  );
}

export const TodoConnectionProvider = ({
  filters,
  total,
  renderFooter,
  query,
  render,
}: Props) => {
  const { data, isLoading, refetch } = usePagination(
    fragment,
    // I _think_ the types are wrong and null values are allowed
    query as TodoConnectionProvider_query$key,
  );
  useRefetch(refetch, data?.todos, total, filters);
  const todos = data?.todos;

  const renderFn = render ? render : renderTodoList;

  return renderFn({
    numberOfTodosDisplayed: todos?.edges?.length ?? 0,
    listContent: function ListContent() {
      return todos && <TodoListData todos={todos} />;
    },
    pageInfo: todos?.pageInfo ?? null,
    loading: isLoading || query === null,
    footer: () =>
      todos &&
      todos.pageInfo &&
      renderFooter &&
      renderFooter(todos.pageInfo, todos?.edges?.length ?? 0),
  });
};
