import { Icon } from "@mormahr/babel-transform-icon";
import * as React from "react";
import styles from "./FlatCheckbox.module.scss";

export type Props = {
  checked: boolean;
  onChange: (checked: boolean) => void;
};

export function FlatCheckbox(props: React.PropsWithChildren<Props>) {
  const { checked, onChange } = props;
  return (
    <span
      className={styles.checkbox}
      role="checkbox"
      aria-checked={checked}
      onClick={() => onChange(!checked)}
      onKeyPress={(event) => {
        if (event.key === " ") {
          onChange(!checked);
        }
      }}
      tabIndex={1}
    >
      {checked ? (
        <Icon icon="square-check" type="regular" fixedWidth />
      ) : (
        <Icon icon="square" type="regular" fixedWidth />
      )}
      {React.Children.count(props.children) > 0 && " "}
      {props.children}
    </span>
  );
}
