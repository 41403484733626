export type TodoConnectionOrderElement =
  | "id"
  | "done"
  | "done_ts"
  | "employee"
  | "customer"
  | "due"
  | "due_ts"
  | "created_by"
  | "created_ts"
  | "priority"
  | "title"
  | "note"
  | "-id"
  | "-done"
  | "-done_ts"
  | "-employee"
  | "-customer"
  | "-due"
  | "-due_ts"
  | "-created_by"
  | "-created_ts"
  | "-priority"
  | "-title"
  | "-note";

export const todoConnectionOrderElements = [
  "id",
  "done",
  "done_ts",
  "employee",
  "customer",
  "due",
  "due_ts",
  "created_by",
  "created_ts",
  "priority",
  "title",
  "note",
  "-id",
  "-done",
  "-done_ts",
  "-employee",
  "-customer",
  "-due",
  "-due_ts",
  "-created_by",
  "-created_ts",
  "-priority",
  "-title",
  "-note",
];

export type SloppyTodoConnectionFilters = {
  order?:
    | Optional<TodoConnectionOrderElement>
    | Optional<TodoConnectionOrderElement[]>;
  searchTerm?: Optional<string>;
  onlyDone?: Optional<boolean>;
  onlyNotDone?: Optional<boolean>;
  onlyCreatedBy?: Optional<string> | Optional<string[]>;
  onlyEmployee?: Optional<string> | Optional<string[]>;
  includeUnassignedEmployee?: Optional<boolean>;
  onlyCustomer?: Optional<string> | Optional<string[]>;
  onlyPriority?: Optional<string> | Optional<string[]>;
  onlyDueBefore?: Optional<string>;
  onlyDueAfter?: Optional<string>;
};

export type NormalizedTodoConnectionFilters = {
  order: TodoConnectionOrderElement[];
  searchTerm: string;
  onlyDone: boolean;
  onlyNotDone: boolean;
  onlyCreatedBy: string[];
  onlyEmployee: string[];
  includeUnassignedEmployee: boolean;
  onlyCustomer: string[];
  onlyPriority: string[];
  onlyDueBefore: string | null;
  onlyDueAfter: string | null;
};

export const transformArray = (val: Optional<string | string[]>): string[] => {
  if (val == null) {
    return [];
  }

  if (Array.isArray(val)) {
    // TODO: This should be checked
    return val.filter(v => !!v);
  }

  return [val];
};

export const transformOrderArray = (
  val: Optional<TodoConnectionOrderElement | TodoConnectionOrderElement[]>,
): TodoConnectionOrderElement[] => {
  if (val == null) {
    return [];
  }

  if (Array.isArray(val)) {
    // TODO: This should be checked
    return val
      .filter(v => !!v)
      .filter(v => todoConnectionOrderElements.includes(v));
  }

  return transformOrderArray([val]);
};

export const transformOptionalString = (val: Optional<string>): string => {
  if (val !== undefined && val !== null) {
    return val;
  }

  return "";
};

export const defaultOrder: TodoConnectionOrderElement[] = [
  "done",
  "priority",
  "-created_ts",
];

export const transformFilters = (
  input: SloppyTodoConnectionFilters,
): NormalizedTodoConnectionFilters => {
  const order = transformOrderArray(input.order);
  const onlyDone = !!input.onlyDone;
  const onlyNotDone = !!input.onlyNotDone;

  if (onlyDone && onlyNotDone) {
    console.warn(
      "Filtering by onlyDone and onlyNotDone at the same time is forbidden",
    );
  }

  return {
    order: order.length > 0 ? order : defaultOrder,
    searchTerm: transformOptionalString(input.searchTerm),
    onlyDone,
    // Filtering by onlyDone and onlyNotDone is forbidden
    onlyNotDone: onlyNotDone && !onlyDone,
    onlyCreatedBy: transformArray(input.onlyCreatedBy),
    onlyEmployee: transformArray(input.onlyEmployee),
    includeUnassignedEmployee: !!input.includeUnassignedEmployee,
    onlyCustomer: transformArray(input.onlyCustomer),
    onlyPriority: transformArray(input.onlyPriority),
    onlyDueBefore:
      input.onlyDueBefore !== undefined ? input.onlyDueBefore : null,
    onlyDueAfter: input.onlyDueAfter !== undefined ? input.onlyDueAfter : null,
  };
};
