/**
 * @generated SignedSource<<014931fa6fcb5960b48b24795fb6f30d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CustomerDetail_query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CustomerTodos_query">;
  readonly " $fragmentType": "CustomerDetail_query";
};
export type CustomerDetail_query$key = {
  readonly " $data"?: CustomerDetail_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"CustomerDetail_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": [],
      "kind": "LocalArgument",
      "name": "onlyCustomer"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CustomerDetail_query",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "onlyCustomer",
          "variableName": "onlyCustomer"
        }
      ],
      "kind": "FragmentSpread",
      "name": "CustomerTodos_query"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "6a56a3146ff59dad6715439b8b3f75ce";

export default node;
