export type MaybeEmployee =
  | {
      type: "EMPLOYEE";
      employeeId: string;
      displayName: string;
      printName: string;
    }
  | { type: "UNKNOWN" }
  | { type: "NOT_AN_EMPLOYEE" };

export enum MaybeAllowed {
  /**
   * Can not be determined from the current state. Probably not loaded.
   */
  UNKNOWN,

  /**
   * We expect this to be yes, because we cached the information, but don't know for sure.
   * Preparation for the future, where caching is implemented.
   */
  PROBABLY_YES,

  /**
   * We expect this to be no, because we cached the information, but don't know for sure.
   * Preparation for the future, where caching is implemented.
   */
  PROBABLY_NO,

  /**
   * Last time we checked with the server it was yes.
   */
  YES,

  /**
   * Last time we checked with the server it was no.
   */
  NO,
}

export function expectYes(maybe: MaybeAllowed): boolean {
  return maybe === MaybeAllowed.YES || maybe === MaybeAllowed.PROBABLY_YES;
}

export type Permission =
  | "canApproveTimeTracks"
  | "canViewStatistics"
  | "shouldViewOtherUsers"
  | "canViewHourlyRates"
  | "canViewOthersPermissions"
  | "canViewCustomerBillings"
  | "canEditCustomerBaseData"
  | "canViewFullTimeTrackList"
  | "canViewLastTwoWeeksTimeTrackList"
  | "canViewTimeTrackEvents"
  | "canCreateTokenForAllUsers"
  | "canEditOrganization"
  | "permissionTimeTrackRemove";
export type Permissions = {
  [key in Permission]: boolean;
};
export const permissionKeys: Permission[] = [
  "canApproveTimeTracks",
  "canViewStatistics",
  "shouldViewOtherUsers",
  "canViewHourlyRates",
  "canViewOthersPermissions",
  "canViewCustomerBillings",
  "canEditCustomerBaseData",
  "canViewFullTimeTrackList",
  "canViewLastTwoWeeksTimeTrackList",
  "canViewTimeTrackEvents",
  "canCreateTokenForAllUsers",
  "canEditOrganization",
  "permissionTimeTrackRemove",
];
export const defaultPermissions: Permissions = Object.freeze({
  canApproveTimeTracks: false,
  canViewStatistics: false,
  shouldViewOtherUsers: false,
  canViewHourlyRates: false,
  canViewOthersPermissions: false,
  canViewCustomerBillings: false,
  canEditCustomerBaseData: false,
  canViewFullTimeTrackList: false,
  canViewLastTwoWeeksTimeTrackList: false,
  canViewTimeTrackEvents: false,
  canEditOrganization: false,
  canCreateTokenForAllUsers: false,
  permissionTimeTrackRemove: false,
});
