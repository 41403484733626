const defaultCurrencyOptions: Partial<Intl.NumberFormatOptions> = {
  style: "currency",
  currency: "EUR",
  useGrouping: true,
};

const regularCurrencyFormatter = new Intl.NumberFormat("de-DE", {
  ...defaultCurrencyOptions,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const roundedCurrencyFormatter = new Intl.NumberFormat("de-DE", {
  ...defaultCurrencyOptions,
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export interface Props {
  value: Optional<string>;
  rounded?: boolean;
}

export const Money = ({ value, rounded }: Props) => {
  if (value == null || value === "") {
    return <>{"? €"}</>;
  }

  if (!Intl || !Intl.NumberFormat) {
    return (
      <>{!rounded ? value.replace(".", ",") : value?.replace(/\.\d\d/, "")}</>
    );
  }

  const num = parseFloat(value);
  return (
    <>
      {(!rounded ? regularCurrencyFormatter : roundedCurrencyFormatter).format(
        num,
      )}
    </>
  );
};
