import { FC, useMemo, useState } from "react";
import { TimeTrackHistory_timeTrack$key } from "./__generated__/TimeTrackHistory_timeTrack.graphql";
import { useFragment } from "relay-hooks";
import { TimeTrackHistoryRow } from "./TimeTrackHistoryRow";
import { SubscriptionOptions, useSubscription } from "./SubscriptionManager";
import { TimeTrackHistorySubscription } from "./__generated__/TimeTrackHistorySubscription.graphql";
import { addTimeTrackEventEdge } from "../util/addTimeTrackEventEdge";
import { Icon } from "@mormahr/babel-transform-icon";

const fragment = graphql`
  fragment TimeTrackHistory_timeTrack on TimeTrack
  @argumentDefinitions(
    count: { type: Int, defaultValue: 100 }
    cursor: { type: String }
  )
  @refetchable(queryName: "TimeTrackHistoryPaginationQuery") {
    revision

    events(first: $count, after: $cursor)
      @connection(key: "TimeTrackHistory_events") {
      edges {
        node {
          id
          ...TimeTrackHistoryRow_event
        }
      }
    }
  }
`;

const subscription = graphql`
  subscription TimeTrackHistorySubscription(
    $timeTrackId: ID!
    $initialRevision: String!
  ) {
    timeTrackEvents(timeTrackId: $timeTrackId, fromRevision: $initialRevision) {
      event {
        id
        timeTrack {
          id
        }

        ...TimeTrackHistoryRow_event
      }
    }
  }
`;

export const TimeTrackEventSubscriptionKind = {
  group: "TimeTrackHistory",
  label: "Geschichte eines Zeiterfassungseintrags",
};

export const TimeTrackHistory: FC<{
  timeTrack: TimeTrackHistory_timeTrack$key;
}> = function TimeTrackHistory(props) {
  const timeTrack = useFragment(fragment, props.timeTrack);
  const [initialRevision] = useState(timeTrack.revision);

  const subscriptionConfig = useMemo<
    SubscriptionOptions<TimeTrackHistorySubscription>
  >(
    () => ({
      kind: TimeTrackEventSubscriptionKind,
      subscription,
      variables: {
        timeTrackId: timeTrack.id,
        initialRevision,
      },
      updater(store) {
        const event = store
          .getRootField("timeTrackEvents")
          ?.getLinkedRecord("event");
        if (event) {
          addTimeTrackEventEdge(store, event);
        }
      },
    }),
    [timeTrack.id, initialRevision],
  );
  useSubscription<TimeTrackHistorySubscription>(subscriptionConfig);

  return (
    <>
      <ul>
        {(timeTrack.events?.edges?.map((edge) => edge!.node!) ?? []).map(
          (event) => (
            <TimeTrackHistoryRow event={event} key={event.id} />
          ),
        )}
      </ul>
      {(timeTrack.events?.edges?.length || 0) === 0 && (
        <div className="w-full h-64 flex flex-col justify-center items-center space-y-4">
          <Icon
            icon="clock-rotate-left"
            type="regular"
            className="text-5xl text-gray-600"
          />
          <p className="text-gray-600">Keine aufgezeichneten Ereignisse</p>
        </div>
      )}
    </>
  );
};
