/**
 * @generated SignedSource<<1e7bf22b51617f44614c92943f5718a8>>
 * @relayHash 5033e6527535fe15a1c8bfa317a7b273
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ab413ccf18518c9716e08424b08b93218a59b254

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type createCallInput = {
  internalPhoneNumber: any;
  externalPhoneNumber: any;
  isOutbound: boolean;
  created: string;
  connected?: string | null;
  ended?: string | null;
  clientMutationId?: string | null;
};
export type CallPlaygroundCreateCallMutation$variables = {
  input: createCallInput;
};
export type CallPlaygroundCreateCallMutation$data = {
  readonly createCall: {
    readonly call: {
      readonly id: string;
    };
  } | null;
};
export type CallPlaygroundCreateCallMutation = {
  variables: CallPlaygroundCreateCallMutation$variables;
  response: CallPlaygroundCreateCallMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "createCallPayload",
    "kind": "LinkedField",
    "name": "createCall",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Call",
        "kind": "LinkedField",
        "name": "call",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CallPlaygroundCreateCallMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CallPlaygroundCreateCallMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "ab413ccf18518c9716e08424b08b93218a59b254",
    "metadata": {},
    "name": "CallPlaygroundCreateCallMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "86cf38f7e8c244381565c4117d513eea";

export default node;
