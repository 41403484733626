import { useMemo } from "react";
import { STORE_THEN_NETWORK, useQuery } from "relay-hooks";
import { Redirect, useParams } from "react-router-dom";
import { HeaderLoader } from "../../../components/HeaderLoading";
import { TimeTracksQuery } from "./__generated__/TimeTracksQuery.graphql";
import { Header } from "../../../components/Header";
import {
  useViewer,
  useViewerPermission,
} from "../../../app-controller/ViewerContext";
import { TimeTrackHeaderTabs } from "./TimeTrackHeaderTabs";
import { expectYes } from "../../../app-controller/ViewerUtils";
import { BigContainer } from "../../../components/Grid";
import { Heading } from "../../../components/Heading";
import loadable from "@loadable/component";

const TimeTrackWorkspace = loadable(
  () =>
    import(
      /* webpackChunkName: "TimeTrackWorkspace" */ "./TimeTrackWorkspaceWrapper"
    ),
);

const queryDocument = graphql`
  query TimeTracksQuery($username: String!) {
    user(username: $username) {
      displayName
      ...TimeTrackWorkspace_user
    }

    ...TimeTrackWorkspace_query
      @arguments(onlyCreatedByOrAssignedUser: $username, onlyUnapproved: false)
  }
`;

export function TimeTracks() {
  const { id: username } = useParams<{ id: string }>();
  const viewer = useViewer();
  const shouldViewOtherUsers = expectYes(
    useViewerPermission("shouldViewOtherUsers"),
  );
  const variables = useMemo(() => ({ username }), [username]);
  const {
    data: props,
    error,
    isLoading: cached,
  } = useQuery<TimeTracksQuery>(queryDocument, variables, {
    fetchPolicy: STORE_THEN_NETWORK,
  });

  if (error) {
    throw error;
  }

  if (props && !props.user) {
    return <Redirect to="/user/" />;
  }

  return (
    <>
      <Header
        title={
          props?.user ? (
            props.user.displayName
          ) : (
            <span className={"skeleton"}>Der Username</span>
          )
        }
        subTitle="Workspace"
        isLoading={cached}
        bigContainer
      >
        <TimeTrackHeaderTabs />
      </Header>
      {shouldViewOtherUsers || viewer!.username === username ? (
        <TimeTrackWorkspace
          user={props?.user}
          query={props}
          fallback={<HeaderLoader />}
        />
      ) : (
        <BigContainer>
          <br />
          <Heading level={2}>Keine Berechtigung</Heading>
          <p>Keine Berechtigung zum Anzeigen dieser Seite.</p>
        </BigContainer>
      )}
    </>
  );
}
