import { render } from "react-dom";
import * as serviceWorker from "./serviceWorker";
import "./tailwind.css";
import "./index.scss";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import Root from "./Root";
import { browserHistory } from "./browserHistory";
import { routes } from "./app/routes";
import { matchPath } from "react-router";
import { createBrowserEnvironment } from "./relayenv";
import { createAppService } from "./app-controller/AppService";
import { Router } from "react-router-dom";
import { DeviceInfo, iOS } from "./app/DeviceInfo";

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  environment: process.env.SENTRY_ENVIRONMENT ?? "development",
  release: process.env.SENTRY_RELEASE,

  autoSessionTracking: true,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(
        browserHistory,
        // @ts-expect-error
        routes,
        matchPath,
      ),
    }),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

Sentry.setTag("organization", process.env.SENTRY_ORGANIZATION ?? "development");

const deviceInfo: DeviceInfo = {
  isIOS: iOS(),
};

const appService = createAppService();
const relayEnvironment = createBrowserEnvironment(appService);

render(
  <Router history={browserHistory}>
    <Root
      environment={relayEnvironment}
      appService={appService}
      deviceInfo={deviceInfo}
    />
  </Router>,
  document.getElementById("root"),
);

// @ts-expect-error: Hot update interface not typed.
if (module.hot) {
  // @ts-expect-error
  module.hot.accept("./Root.tsx", () => {
    console.log("Hot update -> rerender");
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const NextRootContainer = require("./Root.tsx").default;
    render(<NextRootContainer />, document.getElementById("root"));
  });
}

serviceWorker.register();
