import { useCallback, useContext } from "react";
import { NormalizedTodoConnectionFilters } from "../app/todo/model/TodoConnectionFilter";
import { CommandManagerDispatchContext } from "./CommandManager";
import { Button, ButtonProps } from "./button/Button";

export function TodoCreateButton({
  filters,
  className,
  ...buttonProps
}: Omit<ButtonProps, "onClick"> & {
  filters: NormalizedTodoConnectionFilters;
}) {
  const dispatch = useContext(CommandManagerDispatchContext);
  const open = useCallback(() => {
    dispatch({
      type: "OPEN_TODO_CREATE",
      filters,
    });
  }, [filters, dispatch]);

  return (
    <div className={className}>
      <Button onClick={open} {...buttonProps} data-cy="todo-create-button" />
    </div>
  );
}
