import { FC } from "react";
import { useFragment } from "relay-hooks";
import { TimeTrackHistoryRowCustomerBillingChange_event$key } from "./__generated__/TimeTrackHistoryRowCustomerBillingChange_event.graphql";
import { Link } from "react-router-dom";

const fragment = graphql`
  fragment TimeTrackHistoryRowCustomerBillingChange_event on TimeTrackEvent {
    changedFields
    oldCustomerBilling {
      displayName
      link
    }
    newCustomerBilling {
      displayName
      link
    }
  }
`;

export const TimeTrackHistoryRowCustomerBillingChange: FC<{
  event: TimeTrackHistoryRowCustomerBillingChange_event$key;
}> = function TimeTrackHistoryRowCustomerBillingChange(props) {
  const event = useFragment(fragment, props.event);
  const changedFields = event.changedFields as {
    [key: string]: [unknown, unknown];
  };

  if (!changedFields.customer_billing) {
    return null;
  }

  if (!changedFields.customer_billing[0] && changedFields.customer_billing[1]) {
    // Added to
    if (event.newCustomerBilling) {
      return (
        <span>
          den Eintrag zur Abrechnung{" "}
          <Link
            to={event.newCustomerBilling.link}
            className="hover:underline font-bold"
          >
            {event.newCustomerBilling.displayName}
          </Link>{" "}
          hinzugefügt
        </span>
      );
    } else {
      return <span>den Eintrag zu einer Abrechnung hinzugefügt</span>;
    }
  } else if (
    changedFields.customer_billing[0] &&
    !changedFields.customer_billing[1]
  ) {
    // Removed
    if (event.newCustomerBilling) {
      return (
        <span>
          den Eintrag aus der Abrechnung{" "}
          <Link
            to={event.newCustomerBilling.link}
            className="hover:underline line-through"
          >
            {event.newCustomerBilling.displayName}
          </Link>{" "}
          entfernt
        </span>
      );
    } else {
      return <span>den Eintrag zu einer Abrechnung hinzugefügt</span>;
    }
  } else {
    if (event.newCustomerBilling && event.oldCustomerBilling) {
      return (
        <span>
          den Eintrag aus der Abrechnung{" "}
          <Link
            to={event.newCustomerBilling.link}
            className="hover:underline line-through"
          >
            {event.newCustomerBilling.displayName}
          </Link>{" "}
          in die Abrechnung{" "}
          <Link
            to={event.newCustomerBilling.link}
            className="hover:underline font-bold"
          >
            {event.newCustomerBilling.displayName}
          </Link>{" "}
          verschoben
        </span>
      );
    } else {
      return <span>den Eintrag in eine andere Abrechnung verschoben</span>;
    }
  }
};
