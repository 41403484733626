import { ReactChild, useCallback, useContext, useState } from "react";
import { Select } from "@blueprintjs/select";
import { EmployeeChooserIcon } from "./EmployeeChooserValue";
import { MenuItem, Spinner, Icon as BPIcon } from "@blueprintjs/core";
import { Button } from "./button/Button";
import { useViewerEmployee } from "../app-controller/ViewerContext";
import { CommonDataContext } from "./CommonDataContext";
import { normalizeSearchTerm } from "@mormahr/portal-utils";
import { PlainButton } from "./button/PlainButton";
import { MaybeEmployee } from "../app-controller/ViewerUtils";
import styles from "./EmployeeChooser.module.scss";

export const NoEmployeeString = "(Kein Mitarbeiter)";

export const EmployeeGraphQLT = graphql`
  fragment EmployeeChooser_employee on Employee {
    id
    displayName
    printName
  }
`;

export type ChangeCallbackType = (
  value: { id: string; displayName: string } | null,
) => void;
interface Employee {
  readonly id: string;
  readonly displayName: string;
  readonly printName: string;
}

export interface BaseProps {
  employee: Employee | null;
  changeCallback: ChangeCallbackType;

  disabled?: boolean;
  mode: "tiny" | "button" | "text" | "xxl-flex";
}

export type ChooserProps = BaseProps;

export interface InteractiveProps extends BaseProps {
  children: ReactChild;
  onChangeState: (open: boolean) => void;
  isOpen: boolean;
}

/**
 * `"null"` (as a string) is used because the `Select` Component treats `null`
 * in a special way and we need a null value to indicate that no employee has
 * been selected.
 */
const EmployeeSelect = Select.ofType<Employee | "null">();

function employeeTitle(
  employee: Employee | null,
  viewerEmployee: MaybeEmployee,
) {
  if (employee == null) {
    return "Kein Mitarbeiter zugewiesen";
  }

  if (viewerEmployee.type === "UNKNOWN") {
    return "Wird geladen";
  }

  if (viewerEmployee.type === "NOT_AN_EMPLOYEE") {
    return "Kein Mitarbeiter zugewiesen";
  }

  if (viewerEmployee.employeeId === employee?.id) {
    return "Du bist zugewiesen";
  } else {
    return `${employee?.displayName} zugewiesen`;
  }
}

function EmployeeChooserButton({
  mode,
  disabled,
  employee,
  onClick,
}: {
  mode: "tiny" | "button" | "text" | "xxl-flex";
  disabled?: boolean;
  employee: Employee | null;
  onClick: () => void;
}) {
  const viewerEmployee = useViewerEmployee();

  return (
    <div data-cy="employee-chooser">
      {mode === "button" ? (
        <Button
          rightIcon={<BPIcon icon="caret-down" />}
          disabled={disabled}
          type="button"
          onClick={onClick}
        >
          {employee ? employee.displayName : NoEmployeeString}
        </Button>
      ) : mode === "text" ? (
        <PlainButton noFancy onClick={onClick}>
          {employee ? employee.displayName : NoEmployeeString}
        </PlainButton>
      ) : mode === "xxl-flex" ? (
        <PlainButton
          onClick={onClick}
          title={employeeTitle(employee, viewerEmployee)}
        >
          <span className={styles["xxl-flex-icon"]}>
            <EmployeeChooserIcon
              value={
                employee === null || employee === undefined
                  ? null
                  : employee.displayName
              }
            />
          </span>
          <span className={styles["xxl-flex-text"]}>
            {employee ? employee.displayName : NoEmployeeString}
          </span>
        </PlainButton>
      ) : (
        <PlainButton
          onClick={onClick}
          title={employeeTitle(employee, viewerEmployee)}
        >
          <EmployeeChooserIcon
            value={
              employee === null || employee === undefined
                ? null
                : employee.displayName
            }
          />
        </PlainButton>
      )}
    </div>
  );
}

export function EmployeeChooser(props: ChooserProps) {
  const [isOpen, setIsOpen] = useState(false);

  // Keep the select mounted
  const [hasBeenOpen, setHasBeenOpen] = useState(false);

  if (isOpen || hasBeenOpen) {
    return (
      <EmployeeChooserInteractive
        {...props}
        onChangeState={setIsOpen}
        isOpen={isOpen}
      >
        <EmployeeChooserButton
          mode={props.mode}
          employee={props.employee}
          onClick={() => setIsOpen((state) => !state)}
        />
      </EmployeeChooserInteractive>
    );
  }

  return (
    <EmployeeChooserButton
      mode={props.mode}
      employee={props.employee}
      onClick={() => {
        setIsOpen(true);
        setHasBeenOpen(true);
      }}
    />
  );
}

function EmployeeChooserInteractive({
  employee,
  changeCallback,
  disabled,
  children,
  onChangeState,
  isOpen,
}: InteractiveProps) {
  const onChange = useCallback(
    (option: Employee | null) => changeCallback(option),
    [changeCallback],
  );

  const { hasLoaded, employees } = useContext(CommonDataContext);
  const employeeList: (Employee | "null")[] = ["null", ...(employees ?? [])];
  const viewerEmployee = useViewerEmployee();

  return (
    <EmployeeSelect
      popoverProps={{ isOpen, onClose: () => onChangeState(false) }}
      noResults={hasLoaded ? null : <Spinner size={20} />}
      itemsEqual={(a, b) =>
        (a === "null" && b === "null") ||
        (a !== "null" && b !== "null" && a.id === b.id)
      }
      items={employeeList}
      itemPredicate={(query, item) => {
        if (item === "null") {
          return query.trim() === "";
        }
        const normalizedSearchTerm = normalizeSearchTerm(query);
        return (
          normalizeSearchTerm(item.displayName).includes(
            normalizedSearchTerm,
          ) ||
          normalizeSearchTerm(item.printName).includes(normalizedSearchTerm)
        );
      }}
      itemRenderer={(item, { handleClick, modifiers }) => {
        if (!modifiers.matchesPredicate) {
          return null;
        }

        if (item === "null") {
          return (
            <MenuItem
              active={modifiers.active}
              key={"employee-null-value"}
              onClick={handleClick}
              text={NoEmployeeString}
              label={employee === null ? "Ausgewählt" : ""}
            />
          );
        }

        return (
          <MenuItem
            active={modifiers.active}
            key={item.id}
            onClick={handleClick}
            text={item.displayName}
            label={
              employee && employee.id === item.id
                ? "Ausgewählt"
                : viewerEmployee.type === "EMPLOYEE" &&
                  viewerEmployee.employeeId === item.id
                ? "Du"
                : ""
            }
          />
        );
      }}
      onItemSelect={(item) => {
        onChange(item === "null" ? null : item);
        onChangeState(false);
      }}
      disabled={disabled}
    >
      {children}
    </EmployeeSelect>
  );
}

EmployeeChooser.defaultProps = {
  mode: "tiny",
};
