import { AppMachine } from "./AppMachine";
import { interpret, Interpreter } from "xstate";
import { JSONValue } from "../util/JSONValue";
import { localStorageKey, processData } from "./AppMachineStorage";
import { AppContext, AppEvent, AppState, EventTypes } from "./AppMachineUtils";
import { isAfter } from "date-fns";

export function createAppService(): Interpreter<
  AppContext,
  any,
  AppEvent,
  AppState
> {
  const appService = interpret<AppContext, any, AppEvent, AppState>(AppMachine);

  appService.start();

  function readFromLocalStorage() {
    const storedState = localStorage.getItem(localStorageKey);
    if (storedState === null) {
      appService.send(EventTypes.readEmptyExpiryAndData);
      return;
    }

    let parsed: JSONValue = null;
    try {
      parsed = JSON.parse(storedState);
    } catch (err) {
      appService.send(EventTypes.readEmptyExpiryAndData);
      return;
    }

    const processed = processData(parsed);
    if (processed === null) {
      appService.send(EventTypes.readEmptyExpiryAndData);
      return;
    }

    if (!isAfter(processed.expiry, new Date())) {
      appService.send(EventTypes.readExpired);
      return;
    }

    appService.send({
      type: EventTypes.readCachedExpiryAndData,
      expiry: processed.expiry,
      viewer: processed.viewer,
      employee: processed.employee,
      permissions: processed.permissions,
      flags: processed.flags,
      preferences: processed.preferences,
    });
  }

  readFromLocalStorage();

  return appService;
}
