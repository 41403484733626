import { useFragment } from "relay-hooks";
import { CustomerRow_customer$key } from "./__generated__/CustomerRow_customer.graphql";
import { TextButton } from "../../components/button/TextButton";
import styles from "./CustomerOverview.module.scss";
import { Tag } from "@blueprintjs/core";
import classNames from "classnames";
import { FC } from "react";
import { CustomerRowStatistics } from "./CustomerRowStatistics";
import {
  useExpectPermission,
  usePreference,
} from "../../app-controller/ViewerContext";
import { CustomerRowChecks } from "./CustomerRowChecks";

export type Props = {
  customer: CustomerRow_customer$key;
  className?: string;
};

const customerFragment = graphql`
  fragment CustomerRow_customer on Customer
  @argumentDefinitions(
    disableStatistics: { type: "Boolean", defaultValue: false }
  ) {
    id
    displayName
    link
    archived
    reference

    ...CustomerRowChecks_customer

    statistics @skip(if: $disableStatistics) {
      ...CustomerRowStatistics_statistics
    }
  }
`;

export const CustomerRow: FC<Props> = function (props) {
  const customer = useFragment(customerFragment, props.customer);
  const customerOverviewAdditionalInfo = usePreference(
    "customerOverviewAdditionalInfo",
  );
  const canEditCustomerBaseData = useExpectPermission(
    "canEditCustomerBaseData",
  );

  return (
    <li className={styles.item}>
      <span
        className={classNames(
          styles.reference,
          "text-gray-500 dark:text-gray:600 tabular-nums",
        )}
      >
        {customer.reference !== null && (
          <TextButton href={customer.link} title={customer.reference} />
        )}
      </span>
      <span className={styles.main}>
        <TextButton href={customer.link} title={customer.displayName} />
        &nbsp;
        {customer.archived && <Tag minimal>archiviert</Tag>}
      </span>
      <span className={styles.statistics}>
        {customerOverviewAdditionalInfo === "STATISTICS" &&
          customer.statistics && (
            <CustomerRowStatistics statistics={customer.statistics} />
          )}
      </span>

      <span className={styles.checks}>
        {canEditCustomerBaseData && <CustomerRowChecks customer={customer} />}
      </span>
    </li>
  );
};
