/**
 * @generated SignedSource<<dd358657eb586ac11a890d0962db0b89>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CustomerRowStatistics_statistics$data = {
  readonly lastYear: string;
  readonly " $fragmentSpreads": FragmentRefs<"MiniGraph_statistics">;
  readonly " $fragmentType": "CustomerRowStatistics_statistics";
};
export type CustomerRowStatistics_statistics$key = {
  readonly " $data"?: CustomerRowStatistics_statistics$data;
  readonly " $fragmentSpreads": FragmentRefs<"CustomerRowStatistics_statistics">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CustomerRowStatistics_statistics",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MiniGraph_statistics"
    },
    {
      "alias": "lastYear",
      "args": [
        {
          "kind": "Literal",
          "name": "lastDays",
          "value": 365
        }
      ],
      "kind": "ScalarField",
      "name": "sumFor",
      "storageKey": "sumFor(lastDays:365)"
    }
  ],
  "type": "Statistics",
  "abstractKey": null
};

(node as any).hash = "428567d03aed21b916f7e3f5e8c7a776";

export default node;
