import { Component, ReactNode } from "react";
import loadable from "@loadable/component";
import * as React from "react";

const NotFoundErrorIllustration = loadable(
  () =>
    import(
      /* webpackChunkName: "NotFoundErrorIllustration" */ "./NotFoundErrorIllustration"
    ),
);

interface State {
  hasError: boolean;
}

export function Fallback() {
  return (
    <svg
      width={600}
      height={450}
      viewBox="0 0 800 600"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: "100%", height: "auto" }}
    />
  );
}

export default class NotFoundErrorIllustrationLazy extends Component<
  unknown,
  State
> {
  constructor(props: never) {
    super(props);
    this.state = { hasError: false };
  }

  // This is an error boundary because if the network is unavailable and we haven't downloaded all
  // assets it would crash the whole app because the error boundary that shows this illustration
  // would itself throw..
  static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render(): ReactNode {
    if (this.state.hasError) {
      return <Fallback />;
    }

    return <NotFoundErrorIllustration fallback={<Fallback />} />;
  }
}
