import { Formik } from "formik";
import {
  initialValue,
  TodoCreateForm,
  TodoCreationData,
} from "./TodoCreateForm";
import { Callout, Classes, Dialog } from "@blueprintjs/core";
import { createTodo } from "../mutation/createTodo";
import { NormalizedTodoConnectionFilters } from "../app/todo/model/TodoConnectionFilter";
import { Intent } from "../util/Intent";
import { Button } from "./button/Button";
import { useRelayEnvironment } from "relay-hooks";

export interface Props {
  isOpen: boolean;
  onClose: () => void;
  filters: NormalizedTodoConnectionFilters;
}

export function TodoCreateDialog({ isOpen, onClose, filters }: Props) {
  const environment = useRelayEnvironment();

  return (
    <Formik<TodoCreationData>
      initialValues={initialValue()}
      onSubmit={async (values, { setSubmitting, setStatus, resetForm }) => {
        try {
          await createTodo(environment, {
            input: {
              title: values.title,
              note: values.note,
              employeeId: values.employee?.id ?? null,
              customerId: values.customer?.id ?? null,
            },
            filters,
          });
        } catch (err) {
          setStatus({ error: "ServerError" });
          return;
        }

        setSubmitting(false);
        resetForm();
      }}
      onReset={onClose}
      children={(formik) => (
        <Dialog
          isOpen={isOpen}
          title="Todo erstellen"
          canEscapeKeyClose
          canOutsideClickClose
          onClose={onClose}
        >
          <form
            onSubmit={formik.handleSubmit}
            onReset={formik.handleReset}
            data-cy={"todo-create-form"}
          >
            <div className={Classes.DIALOG_BODY}>
              {formik.status?.error === "ServerError" && (
                <>
                  <Callout intent={Intent.DANGER} title="Fehler">
                    Ein Fehler beim erstellen des Todos ist aufgetreten.
                  </Callout>
                  <br />
                </>
              )}

              <TodoCreateForm formik={formik} disabled={formik.isSubmitting} />
            </div>
            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button type="submit" intent={Intent.PRIMARY}>
                  Todo erstellen
                </Button>
              </div>
            </div>
          </form>
        </Dialog>
      )}
    />
  );
}
