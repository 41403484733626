import { FC } from "react";
import { useFormikContext } from "formik";
import { CustomerEditPhoneNumbersModel } from "./CustomerEditPhoneNumbers";
import { EditableText } from "@blueprintjs/core";
import { Button } from "../../../components/button/Button";
import { Icon } from "@mormahr/babel-transform-icon";

export const CustomerEditPhoneNumberRow: FC<{
  phoneNumberId: string | null;
  remove: boolean;
  displayName: string;
  phoneNumber: string;
}> = function (props) {
  const formikContext = useFormikContext<CustomerEditPhoneNumbersModel>();

  return (
    <>
      <div>
        <a
          className="hover:underline"
          href={`tel:${props.phoneNumber.replace(" ", "")}`}
        >
          {props.phoneNumber}
        </a>
      </div>
      <div>
        <EditableText
          multiline={false}
          placeholder="Kein Name"
          value={props.displayName}
          onChange={(value) => {
            const phoneNumbers = formikContext.values.phoneNumbers.map(
              (number) => {
                if (number.phoneNumber === props.phoneNumber) {
                  return {
                    ...number,
                    displayName: value,
                  };
                } else {
                  return number;
                }
              },
            );

            formikContext.setFieldValue("phoneNumbers", phoneNumbers);
            formikContext.setFieldTouched("phoneNumbers");
          }}
        />
      </div>

      <div>
        <Button
          type="button"
          minimal
          onClick={() => {
            const phoneNumbers = formikContext.values.phoneNumbers.map(
              (number) => {
                if (number.phoneNumber === props.phoneNumber) {
                  return {
                    ...number,
                    remove: true,
                  };
                } else {
                  return number;
                }
              },
            );

            formikContext.setFieldValue("phoneNumbers", phoneNumbers);
          }}
          icon={<Icon icon="trash-can" type="regular" />}
        />
      </div>
    </>
  );
};
