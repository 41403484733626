import { RecordSourceSelectorProxy } from "relay-runtime";
import ConnectionHandler from "relay-connection-handler-plus";

export function increaseTotalCount(
  store: RecordSourceSelectorProxy,
  byCount = 1,
) {
  const connections = ConnectionHandler.getConnections(
    store.getRoot(),
    "TimeTrackPagination_timeTracks",
  );
  for (const connection of connections) {
    const totalCount = connection.getValue("totalCount");
    if (typeof totalCount === "number") {
      connection.setValue(totalCount + byCount, "totalCount");
    }
  }
}
