import * as React from "react";
import { Link } from "react-router-dom";

export type Props = {
  disabled?: Optional<boolean>;
  to?: Optional<string>;
  children?: React.ReactNode;
  className?: string;
} & (
  | React.HTMLAttributes<HTMLSpanElement>
  | React.AnchorHTMLAttributes<HTMLAnchorElement>
);

/**
 * Disabled if either disabled is set or href is undefined / null.
 */
export function DisableAwareLink(props: Props) {
  const { disabled, to, className, ...rest } = props;
  if (!disabled && to !== undefined && to !== null) {
    // @ts-ignore
    return <Link className={className} to={to} {...rest} />;
  } else {
    return (
      <span className={`crm-link-disabled ${className || ""}`} {...rest} />
    );
  }
}
