/**
 * @generated SignedSource<<682388615ac71b977099c099879a71e3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TimeTrackLink_timeTrack$data = {
  readonly link: string;
  readonly " $fragmentSpreads": FragmentRefs<"TimeTrackEditorDialog_timeTrack">;
  readonly " $fragmentType": "TimeTrackLink_timeTrack";
};
export type TimeTrackLink_timeTrack$key = {
  readonly " $data"?: TimeTrackLink_timeTrack$data;
  readonly " $fragmentSpreads": FragmentRefs<"TimeTrackLink_timeTrack">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TimeTrackLink_timeTrack",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "link",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TimeTrackEditorDialog_timeTrack"
    }
  ],
  "type": "TimeTrack",
  "abstractKey": null
};

(node as any).hash = "08505f6d74cde901aadb82a5db265650";

export default node;
