import { FC } from "react";
import { FormGroup, InputGroup, Intent } from "@blueprintjs/core";
import { useFormikContext } from "formik";

export interface CustomerEditReferenceModel {
  reference: string;
}

export const CustomerEditReference: FC = function () {
  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched: _touched,
  } = useFormikContext<CustomerEditReferenceModel>();

  const error = errors.reference;
  const touched = _touched.reference ?? false;
  const showError = error && touched;

  const helperText = (
    <>
      <div>Eindeutige Kundennummer.</div>
      {showError && (
        <div>
          <br />
          {error}
        </div>
      )}
    </>
  );

  return (
    <div data-cy="customer-reference">
      <FormGroup
        label="Kundennummer"
        labelInfo="(optional)"
        intent={showError ? Intent.DANGER : Intent.NONE}
        helperText={helperText}
      >
        <InputGroup
          value={values.reference}
          placeholder="Kundennummer"
          name="reference"
          intent={showError ? Intent.DANGER : Intent.NONE}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormGroup>
    </div>
  );
};
