import { useParams } from "react-router";
import { HeaderLoader } from "../../components/HeaderLoading";
import { CustomerBillingQuery } from "./__generated__/CustomerBillingQuery.graphql";
import { CustomerBillingNavbar } from "./CustomerBillingNavbar";
import { STORE_THEN_NETWORK, useQuery } from "relay-hooks";
import loadable from "@loadable/component";
import { FC } from "react";

const CustomerBillingDetail = loadable(
  () =>
    import(
      /* webpackChunkName: "CustomerBillingDetail" */ "./CustomerBillingDetail"
    ),
);

const customerBillingQuery = graphql`
  query CustomerBillingQuery($customerBillingId: ID!) {
    node(id: $customerBillingId) {
      __typename
      ... on CustomerBilling {
        ...CustomerBillingNavbar_customerBilling
        ...CustomerBillingDetail_customerBilling
          @arguments(customerBillingId: $customerBillingId)
      }
    }
  }
`;

export const CustomerBilling: FC = function CustomerBilling() {
  const { customerBillingId } = useParams<{ customerBillingId: string }>();
  const {
    data: props,
    error,
    isLoading,
  } = useQuery<CustomerBillingQuery>(
    customerBillingQuery,
    {
      customerBillingId,
    },
    { fetchPolicy: STORE_THEN_NETWORK },
  );

  if (error) {
    throw error;
  }

  if (props && props.node && props.node.__typename !== "CustomerBilling") {
    throw new Error();
  }

  return (
    <>
      <CustomerBillingNavbar
        customerBilling={
          props && props.node && props.node.__typename === "CustomerBilling"
            ? props?.node
            : null
        }
        isLoading={isLoading}
      />
      {props && props.node && props.node.__typename === "CustomerBilling" ? (
        <CustomerBillingDetail
          customerBilling={props.node}
          fallback={<HeaderLoader />}
        />
      ) : (
        <HeaderLoader />
      )}
    </>
  );
};
