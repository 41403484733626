import { BigContainer, Row } from "../../components/Grid";
import { DashboardQuery } from "./__generated__/DashboardQuery.graphql";
import { Statistics } from "./Statistics";
import MediaQuery from "react-responsive";
import { TimeTrackWidget } from "../widget/TimeTrackWidget";
import { DashboardTodoList, filterFor } from "./DashboardTodoList";
import { DashboardRevenue } from "./DashboardRevenue";
import { DashboardTips } from "./DashboardTips";
import { useService } from "@xstate/react";
import { useViewerPermission } from "../../app-controller/ViewerContext";
import { expectYes } from "../../app-controller/ViewerUtils";
import { Header } from "../../components/Header";
import { DashboardApproveTimeTracks } from "./DashboardApproveTimeTracks";
import { STORE_THEN_NETWORK, useQuery } from "relay-hooks";
import { useAppService } from "../../app-controller/AppServiceContext";
import { FC, useMemo } from "react";

const query = graphql`
  query DashboardQuery(
    $onlyEmployee: [ID!]
    $onlyNotDone: Boolean!
    $count: Int!
    $includeUnassignedEmployee: Boolean!
    $order: [String!]!
  ) {
    viewer {
      employee {
        ...TimeTrackWidget_employee
      }
    }
    statistics {
      ...Statistics_statistics
      ...DashboardRevenue_statistics
    }

    ...TodoConnectionProvider_query
      @arguments(
        onlyEmployee: $onlyEmployee
        onlyNotDone: $onlyNotDone
        count: $count
        includeUnassignedEmployee: $includeUnassignedEmployee
        order: $order
      )

    ...DashboardApproveTimeTracks_query
  }
`;

export const Dashboard: FC = function Dashboard() {
  const AppService = useAppService();
  const [state] = useService(AppService);
  const canViewStatistics = useViewerPermission("canViewStatistics");
  const canApproveTimeTracks = useViewerPermission("canApproveTimeTracks");

  const variables = useMemo(() => {
    return filterFor(
      state.value === "authenticated" || state.value === "probablyAuthenticated"
        ? state.context.employee?.id ?? null
        : null,
    );
  }, [state.context.employee?.id, state.value]);

  const {
    data: props,
    error,
    isLoading,
  } = useQuery<DashboardQuery>(query, variables, {
    fetchPolicy: STORE_THEN_NETWORK,
  });

  if (error) {
    throw error;
  }

  return (
    <>
      <Header
        title="Dein Tag"
        subTitle="Dashboard"
        isLoading={isLoading}
        bigContainer
      />
      <BigContainer>
        <MediaQuery maxWidth={991}>
          <TimeTrackWidget
            employee={props?.viewer?.employee ?? null}
            isLoading={!props}
            isOnDashboard
          />
        </MediaQuery>
        {expectYes(canViewStatistics) && (
          <>
            <Statistics statistics={props?.statistics ?? null} />
            <Row>
              <BigContainer>
                <DashboardRevenue statistics={props?.statistics ?? null} />
              </BigContainer>
            </Row>
            <br />
          </>
        )}

        <DashboardTips />
        {expectYes(canApproveTimeTracks) && (
          <DashboardApproveTimeTracks query={props ?? null} />
        )}
        <DashboardTodoList query={props ?? null} />
      </BigContainer>
    </>
  );
};
