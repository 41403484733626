import { JSONValue } from "../util/JSONValue";
import { isValid, parseISO } from "date-fns";
import { defaultPermissions, permissionKeys, Permissions } from "./ViewerUtils";
import {
  applyFlags,
  coercePreferences,
  Preferences,
  Flags,
} from "@mormahr/portal-utils";
import { NavigationItemWorkspaceDestinationPreference } from "./__generated__/ViewerContext_viewer.graphql";

export const localStorageKey = "app_state";

export interface ViewerInfo {
  id: string;
  displayName: string;
  username: string;
}

export interface EmployeeInfo {
  id: string;
  displayName: string;
}

export interface DataInLocalStorage {
  expiry: Date;
  viewer: ViewerInfo;
  employee: EmployeeInfo;
  permissions: Permissions;
  flags: Flags;
  preferences: Preferences;
}

export function processData(data: JSONValue): DataInLocalStorage | null {
  if (typeof data !== "object" || Array.isArray(data) || data === null) {
    return null;
  }

  if (typeof data.expiry !== "string") {
    return null;
  }
  const expiry: Date = parseISO(data.expiry);
  if (!isValid(expiry)) {
    return null;
  }

  const _permissions = data.permissions;
  if (
    typeof _permissions !== "object" ||
    Array.isArray(_permissions) ||
    _permissions === null
  ) {
    return null;
  }
  const permissions = { ...defaultPermissions };
  for (const permissionKey of permissionKeys) {
    const value = _permissions[permissionKey];
    if (typeof value !== "boolean") {
      return null;
    }
    permissions[permissionKey] = value;
  }

  const _featureFlags = data.featureFlags;
  if (
    typeof _featureFlags !== "object" ||
    Array.isArray(_featureFlags) ||
    _featureFlags === null
  ) {
    return null;
  }
  const flags = applyFlags(_featureFlags);

  const _viewer = data.viewer;
  if (
    typeof _viewer !== "object" ||
    Array.isArray(_viewer) ||
    _viewer === null
  ) {
    return null;
  }
  const viewerDisplayName = _viewer.displayName;
  if (typeof viewerDisplayName !== "string") {
    return null;
  }
  const viewerId = _viewer.id;
  if (typeof viewerId !== "string") {
    return null;
  }
  const viewerUsername = _viewer.username;
  if (typeof viewerUsername !== "string") {
    return null;
  }
  const viewer = {
    id: viewerId,
    displayName: viewerDisplayName,
    username: viewerUsername,
  };

  const _employee = data.employee;
  if (
    typeof _employee !== "object" ||
    Array.isArray(_employee) ||
    _employee === null
  ) {
    return null;
  }
  const employeeDisplayName = _employee.displayName;
  if (typeof employeeDisplayName !== "string") {
    return null;
  }
  const employeeId = _employee.id;
  if (typeof employeeId !== "string") {
    return null;
  }
  const employee = {
    id: employeeId,
    displayName: employeeDisplayName,
  };

  const preferences = coercePreferences(
    "preferences" in data &&
      typeof data.preferences === "object" &&
      !Array.isArray(data.preferences) &&
      data.preferences !== null
      ? (data.preferences as { [key: string]: string })
      : {},
  );

  return {
    expiry,
    viewer,
    employee,
    permissions,
    flags,
    preferences,
  };
}

export function stringifyData(data: DataInLocalStorage): string {
  return JSON.stringify({
    expiry: data.expiry.toISOString(),
    viewer: data.viewer,
    employee: data.employee,
    permissions: data.permissions,
    flags: data.flags,
    preferences: data.preferences,
  });
}
