import { FC } from "react";
import { ItemSelect, ItemUsableCategory } from "../../../components/ItemSelect";
import { useFormikContext } from "formik";
import { CustomerEditExtraBillingItemsModel } from "./CustomerEditExtraBillingItemsModel";

export const CustomerEditExtraBillingItemsCreator: FC<{
  filter: string[];
}> = function ({ filter }) {
  const formikContext = useFormikContext<CustomerEditExtraBillingItemsModel>();

  return (
    <>
      <div className="self-center">
        <ItemSelect
          item={null}
          usableCategory={ItemUsableCategory.extraBilling}
          exclude={filter}
          nullDisplay="Artikel hinzufügen"
          onChange={(item) => {
            const items = [...formikContext.values.extraBillingItems];
            items.push({ item, amount: 1 });
            formikContext.setFieldValue("extraBillingItems", items);
          }}
        />
      </div>
      <div className="self-center" />
      <div className="self-center justify-self-end" />
    </>
  );
};
