import { createRef, useCallback, useEffect, useState } from "react";
import { DateRangeInput } from "./DateRangeInput";
import { ConvertedDate } from "./convert";
import { Classes, Tag } from "@blueprintjs/core";
import classnames from "classnames";
import { roundEndTime, roundStartTime, workUnits } from "@mormahr/portal-utils";
import { Icon } from "@mormahr/babel-transform-icon";
import {
  addMinutes,
  differenceInMilliseconds,
  format,
  startOfMinute,
} from "date-fns";
import { Action } from "./useDateRangeReducer";
import {
  disabledIntentMap,
  intentInteractionMap,
  intentMap,
} from "../button/Button";
import { Intent } from "../../util/Intent";

export type OnChangeCallbackParameter = {
  startDate: Optional<Date>;
  endDate: Optional<Date>;
};

export type Props = {
  startDate: Optional<Date>;
  endDate: Optional<Date>;
  onChange: Optional<(data: OnChangeCallbackParameter) => void>;
  onAbort?: () => void;
  onError?: ({ hasError }: { hasError: boolean }) => void;

  /**
   * Call update directly when a valid value is entered
   */
  directUpdate?: boolean;

  disabled?: boolean;

  id?: string;
  name?: string;
  className?: string;

  autoFocus?: boolean;
};

function isSame(
  a: Date | undefined | null,
  b: Date | undefined | null,
): boolean {
  if (a === b) return true;

  if ((a === null || a === undefined) && (b === null || b === undefined))
    return true;

  if (
    !(a === null || a === undefined || b === null || b === undefined) &&
    a.toISOString() === b.toISOString()
  ) {
    return true;
  }

  return false;
}

export function DateRangeField(props: Props) {
  const {
    startDate,
    endDate,
    onChange,
    onAbort,
    onError,
    directUpdate,
    disabled,
    id,
    name,
    className,
    autoFocus,
  } = props;
  const [state, setState] = useState<ConvertedDate>({
    startDate,
    endDate,
    valid: true,
  });
  const dispatchRef = createRef<(action: Action) => void>();

  const callChange = useCallback(() => {
    /**
     * If direct update is enabled update is handled in handleChange
     */
    if (directUpdate) {
      return;
    }

    if (onChange) {
      onChange({
        startDate: state.startDate,
        endDate: state.endDate,
      });
    }
  }, [state, onChange, directUpdate]);

  const callAbort = useCallback(() => {
    /**
     * If direct update is enabled update is handled in handleChange
     */
    if (directUpdate) {
      return;
    }

    if (onAbort) {
      onAbort();
    }
  }, [onAbort, directUpdate]);

  const handleKeyCommand = useCallback(
    ({ key }) => {
      if (key === "Enter") {
        if (state.valid) {
          callChange();
        }
      } else if (key === "Escape") {
        callAbort();
      }
    },
    [callChange, callAbort, state],
  );

  const handleBlur = useCallback(() => {
    if (state.valid) {
      callChange();
    } else {
      callAbort();
    }
  }, [callChange, callAbort, state]);

  const { valid } = state;
  const handleChange = useCallback(
    (values: ConvertedDate) => {
      setState(values);
      if (valid !== values.valid) {
        if (onError) {
          onError({ hasError: values.valid });
        }
      }
    },
    [valid, onError],
  );

  useEffect(() => {
    if (!directUpdate) {
      return;
    }

    const isStartSame = isSame(state.startDate, startDate);
    const isEndSame = isSame(state.endDate, endDate);

    if ((!isStartSame || !isEndSame) && onChange && state.valid) {
      onChange({ startDate: state.startDate, endDate: state.endDate });
    }
  }, [
    onChange,
    startDate,
    endDate,
    state.startDate,
    state.endDate,
    state.valid,
    directUpdate,
  ]);

  const [time, setTime] = useState(() => format(new Date(), "HH:mm"));
  useEffect(() => {
    let id: ReturnType<typeof setTimeout>;
    function update() {
      const currentDate = new Date();
      const formatted = format(currentDate, "HH:mm");
      const nextUpdate = differenceInMilliseconds(
        currentDate,
        addMinutes(startOfMinute(currentDate), 1),
      );
      setTime(formatted);

      id = setTimeout(update, nextUpdate);
    }

    const currentDate = new Date();
    const nextUpdate = differenceInMilliseconds(
      currentDate,
      addMinutes(startOfMinute(currentDate), 1),
    );

    id = setTimeout(update, nextUpdate);
    return () => clearTimeout(id);
  });

  return (
    <div className="flex flex-auto flex-row space-x-2">
      <div className={classnames(Classes.INPUT_GROUP, "flex-grow")}>
        <DateRangeInput
          initialStartDate={startDate}
          initialEndDate={endDate}
          onChange={handleChange}
          currentDate={() => new Date()}
          prefillDate={true}
          onKeyCommand={handleKeyCommand}
          onBlur={handleBlur}
          disabled={disabled}
          id={id}
          name={name}
          className={classnames(className, Classes.INPUT)}
          autoFocus={autoFocus}
          ref={dispatchRef}
        />
        {state.startDate && state.endDate && (
          <span className={Classes.INPUT_ACTION}>
            <Tag minimal>
              {workUnits(
                roundStartTime(state.startDate),
                roundEndTime(
                  state.startDate,
                  roundStartTime(state.startDate),
                  state.endDate,
                ),
              )}{" "}
              AE
            </Tag>
          </span>
        )}
      </div>
      <div
        style={{ height: "30px" }}
        className="flex flex-row items-center shadow-sm rounded relative text-center leading-extra-tight"
      >
        <button
          className={classnames(
            "px-2 h-full border rounded-l bg-gradient-to-b",
            {
              ["cursor-default"]: disabled,
              ["active:shadow-inner"]: !disabled,
              [intentMap[Intent.NONE]]: !disabled,
              [intentInteractionMap[Intent.NONE]]: !disabled,
              [disabledIntentMap[Intent.NONE]]: disabled,
            },
          )}
          title={`${time} Uhr als Startzeit`}
          type="button"
          disabled={disabled}
          onClick={() =>
            dispatchRef?.current?.({
              type: "RECEIVE_START_DATE",
              startDate: new Date(),
            })
          }
        >
          <Icon icon="left-to-line" type="solid" />
        </button>
        <time
          className={classnames(
            "px-2 h-full flex flex-column items-center border-t border-b tabular-nums bg-gradient-to-b",
            {
              [intentMap[Intent.NONE]]: !disabled,
              [disabledIntentMap[Intent.NONE]]: disabled,
            },
          )}
        >
          <span>{time}</span>
        </time>
        <button
          className={classnames(
            "px-2 h-full border rounded-r bg-gradient-to-b",
            {
              ["cursor-default"]: disabled,
              ["active:shadow-inner"]: !disabled,
              [intentMap[Intent.NONE]]: !disabled,
              [intentInteractionMap[Intent.NONE]]: !disabled,
              [disabledIntentMap[Intent.NONE]]: disabled,
            },
          )}
          title={`${time} Uhr als Endzeit`}
          type="button"
          disabled={disabled}
          onClick={() =>
            dispatchRef?.current?.({
              type: "RECEIVE_END_DATE",
              endDate: new Date(),
            })
          }
        >
          <Icon icon="right-to-line" type="solid" />
        </button>
      </div>
    </div>
  );
}
