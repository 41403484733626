/**
 * @generated SignedSource<<1d55f093def8a236dd81968f242566c2>>
 * @relayHash 52cd53c01ffe92dbf765d7ae73336bf7
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 0dde603039f51d336760b4c671c01f529bc90118

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type setTimeTrackEmployeeInput = {
  timeTrackId: string;
  employeeId?: string | null;
  updateStoredPaidHourlyRate?: boolean | null;
  clientMutationId?: string | null;
};
export type TimeTrackRowMutations_SetEmployeeMutation$variables = {
  input: setTimeTrackEmployeeInput;
};
export type TimeTrackRowMutations_SetEmployeeMutation$data = {
  readonly setTimeTrackEmployee: {
    readonly timeTrack: {
      readonly canApprove: boolean;
      readonly canApproveReason: string | null;
      readonly canRemove: boolean;
      readonly associatedEmployee: {
        readonly " $fragmentSpreads": FragmentRefs<"EmployeeFatQuery_data">;
      } | null;
    };
    readonly event: {
      readonly id: string;
      readonly timeTrack: {
        readonly id: string;
      };
      readonly " $fragmentSpreads": FragmentRefs<"TimeTrackHistoryRow_event">;
    } | null;
  } | null;
};
export type TimeTrackRowMutations_SetEmployeeMutation = {
  variables: TimeTrackRowMutations_SetEmployeeMutation$variables;
  response: TimeTrackRowMutations_SetEmployeeMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canApprove",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canApproveReason",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canRemove",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "TimeTrack",
  "kind": "LinkedField",
  "name": "timeTrack",
  "plural": false,
  "selections": [
    (v5/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v8 = [
  (v7/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "link",
    "storageKey": null
  },
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TimeTrackRowMutations_SetEmployeeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "setTimeTrackEmployeePayload",
        "kind": "LinkedField",
        "name": "setTimeTrackEmployee",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TimeTrack",
            "kind": "LinkedField",
            "name": "timeTrack",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Employee",
                "kind": "LinkedField",
                "name": "associatedEmployee",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "EmployeeFatQuery_data"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TimeTrackEvent",
            "kind": "LinkedField",
            "name": "event",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "TimeTrackHistoryRow_event"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TimeTrackRowMutations_SetEmployeeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "setTimeTrackEmployeePayload",
        "kind": "LinkedField",
        "name": "setTimeTrackEmployee",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TimeTrack",
            "kind": "LinkedField",
            "name": "timeTrack",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Employee",
                "kind": "LinkedField",
                "name": "associatedEmployee",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TimeTrackEvent",
            "kind": "LinkedField",
            "name": "event",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "kind",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "date",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "changedFields",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Customer",
                "kind": "LinkedField",
                "name": "oldCustomer",
                "plural": false,
                "selections": (v8/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Customer",
                "kind": "LinkedField",
                "name": "newCustomer",
                "plural": false,
                "selections": (v8/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Employee",
                "kind": "LinkedField",
                "name": "oldEmployee",
                "plural": false,
                "selections": (v8/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Employee",
                "kind": "LinkedField",
                "name": "newEmployee",
                "plural": false,
                "selections": (v8/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CustomerBilling",
                "kind": "LinkedField",
                "name": "oldCustomerBilling",
                "plural": false,
                "selections": (v8/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CustomerBilling",
                "kind": "LinkedField",
                "name": "newCustomerBilling",
                "plural": false,
                "selections": (v8/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "0dde603039f51d336760b4c671c01f529bc90118",
    "metadata": {},
    "name": "TimeTrackRowMutations_SetEmployeeMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "81d89f2ebd8f4ae7abb0bb625e25a4fc";

export default node;
