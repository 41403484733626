import { Icon } from "@mormahr/babel-transform-icon";
import { PlainButton } from "./button/PlainButton";
import styles from "./HeaderSelectButton.module.scss";
import classNamesBind from "classnames/bind";

const cn = classNamesBind.bind(styles);

export interface Props {
  title: string;
  onClick?: () => void;
}

export function HeaderSelectButton({ title, onClick }: Props) {
  return (
    <PlainButton
      noFancy
      title={title}
      className={cn("button")}
      onClick={onClick}
      data-cy={"header-select-button"}
    >
      <Icon icon="chevron-down" type="regular" />
    </PlainButton>
  );
}
