import { commitMutation } from "react-relay";
import { ConnectionHandler, IEnvironment } from "relay-runtime";
import { transformFilters } from "../model/TodoConnectionFilter";
import { addTimeTrackEventEdge } from "../../../util/addTimeTrackEventEdge";
import { TodoMutations_CreateTimeTrackFromTodoMutation } from "./__generated__/TodoMutations_CreateTimeTrackFromTodoMutation.graphql";
import { TodoMutations_StartTimeTrackFromTodoMutation } from "./__generated__/TodoMutations_StartTimeTrackFromTodoMutation.graphql";

export function setTodoCustomer(
  environment: IEnvironment,
  todoId: string,
  customerId: Optional<string>,
) {
  const variables = {
    input: {
      todoId,
      customerId,
    },
  };

  commitMutation(environment, {
    mutation: graphql`
      mutation TodoMutations_SetCustomerMutation(
        $input: setTodoCustomerInput!
      ) {
        setTodoCustomer(input: $input) {
          todo {
            customer {
              ...CustomerFatQuery_data
            }
          }
        }
      }
    `,
    variables,
    updater: (store) => {
      const payload = store.getRootField("setTodoCustomer");
      const todo = store.get(todoId);
      if (!todo) {
        return;
      }

      if (!payload) {
        todo.setValue(null, "customer");
        return;
      }

      const newTodo = payload.getLinkedRecord("todo");
      if (!newTodo) {
        return;
      }

      const newCustomer = newTodo.getLinkedRecord("customer");

      if (newCustomer) {
        todo.setLinkedRecord(newCustomer, "customer");
      } else {
        todo.setValue(null, "customer");
      }
    },
  });
}

export function setTodoEmployee(
  environment: IEnvironment,
  todoId: string,
  employeeId: Optional<string>,
) {
  const variables = {
    input: {
      todoId,
      employeeId,
    },
  };

  commitMutation(environment, {
    mutation: graphql`
      mutation TodoMutations_SetEmployeeMutation(
        $input: setTodoEmployeeInput!
      ) {
        setTodoEmployee(input: $input) {
          todo {
            employee {
              ...EmployeeFatQuery_data
            }
          }
        }
      }
    `,
    variables,
    updater: (store) => {
      const payload = store.getRootField("setTodoEmployee");
      const todo = store.get(todoId);
      if (!todo) {
        return;
      }

      if (!payload) {
        todo.setValue(null, "employee");
        return;
      }

      const newTodo = payload.getLinkedRecord("todo");
      if (!newTodo) {
        return;
      }

      const newEmployee = newTodo.getLinkedRecord("employee");

      if (newEmployee) {
        todo.setLinkedRecord(newEmployee, "employee");
      } else {
        todo.setValue(null, "employee");
      }
    },
  });
}

export function setTodoTitle(
  environment: IEnvironment,
  todoId: string,
  value: string,
) {
  const variables = {
    input: {
      todoId,
      title: value,
    },
  };

  commitMutation(environment, {
    mutation: graphql`
      mutation TodoMutations_SetTodoTitleMutation($input: setTodoTitleInput!) {
        setTodoTitle(input: $input) {
          todo {
            title
          }
        }
      }
    `,
    variables,
  });
}

export function setTodoNote(
  environment: IEnvironment,
  todoId: string,
  value: string,
) {
  const variables = {
    input: {
      todoId,
      note: value,
    },
  };

  commitMutation(environment, {
    mutation: graphql`
      mutation TodoMutations_SetTodoNoteMutation($input: setTodoNoteInput!) {
        setTodoNote(input: $input) {
          todo {
            note
          }
        }
      }
    `,
    variables,
  });
}

export function setTodoDone(
  environment: IEnvironment,
  todoId: string,
  date?: Optional<Date>,
) {
  const variables = {
    input: {
      todoId,
      date: date || new Date(),
    },
  };

  commitMutation(environment, {
    mutation: graphql`
      mutation TodoMutations_SetTodoDoneMutation($input: setTodoDoneInput!) {
        setTodoDone(input: $input) {
          todo {
            done
            doneTs
          }
        }
      }
    `,
    variables,
  });
}

export function setTodoNotDone(environment: IEnvironment, todoId: string) {
  const variables = {
    input: {
      todoId,
    },
  };

  commitMutation(environment, {
    mutation: graphql`
      mutation TodoMutations_SetTodoNotDoneMutation(
        $input: setTodoNotDoneInput!
      ) {
        setTodoNotDone(input: $input) {
          todo {
            done
            doneTs
          }
        }
      }
    `,
    variables,
  });
}

export function setTodoPriority(
  environment: IEnvironment,
  todoId: string,
  todoPriorityKey: string,
) {
  const variables = {
    input: {
      todoId,
      priority: todoPriorityKey,
    },
  };

  commitMutation(environment, {
    mutation: graphql`
      mutation TodoMutations_SetTodoPriorityMutation(
        $input: setTodoPriorityInput!
      ) {
        setTodoPriority(input: $input) {
          todo {
            priority {
              ...TodoPriorityFatQuery_data
            }
          }
        }
      }
    `,
    variables,
  });
}

export function setTodoDue(
  environment: IEnvironment,
  todoId: string,
  due: Optional<Date>,
) {
  const variables = {
    input: {
      todoId,
      due,
    },
  };

  commitMutation(environment, {
    mutation: graphql`
      mutation TodoMutations_SetTodoDueMutation($input: setTodoDueInput!) {
        setTodoDue(input: $input) {
          todo {
            due
          }
        }
      }
    `,
    variables,
  });
}

export function createTimeTrackFromTodo(
  environment: IEnvironment,
  todoId: string,
) {
  const variables = {
    input: {
      todoId,
    },
  };

  commitMutation<TodoMutations_CreateTimeTrackFromTodoMutation>(environment, {
    mutation: graphql`
      mutation TodoMutations_CreateTimeTrackFromTodoMutation(
        $input: createTimeTrackFromTodoInput!
      ) {
        createTimeTrackFromTodo(input: $input) {
          todo {
            timeTrack {
              associatedEmployee {
                id
              }

              ...TimeTrackFatQuery_data
            }
          }

          timeTrackEvent {
            id
            timeTrack {
              id
            }

            ...TimeTrackHistoryRow_event
          }
        }
      }
    `,
    variables,
    updater: (store) => {
      const event = store
        .getRootField("createTimeTrackFromTodo")
        ?.getLinkedRecord("timeTrackEvent");
      if (event) {
        addTimeTrackEventEdge(store, event);
      }

      const payload = store.getRootField("createTimeTrackFromTodo");
      if (!payload) {
        return;
      }

      const todo = payload.getLinkedRecord("todo");
      if (!todo) {
        return;
      }

      const timeTrack = todo.getLinkedRecord("timeTrack");
      if (!timeTrack) {
        return;
      }

      const associatedEmployee = timeTrack.getLinkedRecord(
        "associatedEmployee",
      );

      if (associatedEmployee) {
        // @ts-ignore
        const employeeProxy = store.get(associatedEmployee.getField("id"));
        // @ts-ignore
        employeeProxy.setLinkedRecord(timeTrack, "currentTimeTrack");
      }

      const viewer = store.getRoot().getLinkedRecord("viewer");

      if (!viewer) {
        return;
      }

      const connection = ConnectionHandler.getConnection(
        viewer,
        "TimeTrackPagination_timeTracks",
      );

      if (!connection) {
        return;
      }

      const edge = ConnectionHandler.createEdge(
        store,
        connection,
        timeTrack,
        "TimeTrackEdge",
      );

      ConnectionHandler.insertEdgeBefore(connection, edge);
    },
  });
}

export function startTimeTrackFromTodo(
  environment: IEnvironment,
  todoId: string,
) {
  const variables = {
    input: {
      todoId,
    },
  };

  commitMutation<TodoMutations_StartTimeTrackFromTodoMutation>(environment, {
    mutation: graphql`
      mutation TodoMutations_StartTimeTrackFromTodoMutation(
        $input: startTimeTrackFromTodoInput!
      ) {
        startTimeTrackFromTodo(input: $input) {
          todo {
            employee {
              ...EmployeeFatQuery_data
            }

            timeTrack {
              associatedEmployee {
                id
              }

              ...TimeTrackFatQuery_data
            }
          }

          timeTrackEvent {
            id
            timeTrack {
              id
            }

            ...TimeTrackHistoryRow_event
          }
        }
      }
    `,
    variables,
    updater: (store) => {
      const event = store
        .getRootField("startTimeTrackFromTodo")
        ?.getLinkedRecord("timeTrackEvent");
      if (event) {
        addTimeTrackEventEdge(store, event);
      }

      const payload = store.getRootField("startTimeTrackFromTodo");
      // @ts-ignore
      const todo = payload.getLinkedRecord("todo");
      // @ts-ignore
      const timeTrack = todo.getLinkedRecord("timeTrack");
      // @ts-ignore
      const associatedEmployee = timeTrack.getLinkedRecord(
        "associatedEmployee",
      );
      if (associatedEmployee) {
        // @ts-ignore
        const employeeProxy = store.get(associatedEmployee.getValue("id"));
        // @ts-ignore
        employeeProxy.setLinkedRecord(timeTrack, "currentTimeTrack");
      }

      const viewer = store.getRoot().getLinkedRecord("viewer");

      if (!viewer) {
        return;
      }

      const connection = ConnectionHandler.getConnection(
        viewer,
        "TimeTrackPagination_timeTracks",
      );

      if (!connection) {
        return;
      }

      const edge = ConnectionHandler.createEdge(
        store,
        connection,
        // @ts-ignore
        timeTrack,
        "TimeTrackEdge",
      );

      ConnectionHandler.insertEdgeBefore(connection, edge);
    },
  });
}

export type CreateTodoOptions = {
  employeeId?: Optional<string>;
  customerId?: Optional<string>;
};

export function createTodo(
  environment: IEnvironment,
  { employeeId, customerId }: CreateTodoOptions,
  filters: Record<string, any>,
) {
  const variables = {
    input: {
      employeeId,
      customerId,
    },
  };

  commitMutation(environment, {
    mutation: graphql`
      mutation TodoMutations_CreateTodoMutation($input: createTodoInput!) {
        createTodo(input: $input) {
          todo {
            ...TodoFatQuery_data
          }
        }
      }
    `,
    variables,
    updater: (store) => {
      const payload = store.getRootField("createTodo");
      // @ts-ignore
      const todo = payload.getLinkedRecord("todo");

      const root = store.getRoot();

      const connection = ConnectionHandler.getConnection(
        root,
        "TodoConnection_todos",
        transformFilters(filters),
      );

      if (!connection) {
        return;
      }

      const edge = ConnectionHandler.createEdge(
        store,
        connection,
        // @ts-ignore
        todo,
        "TodoEdge",
      );

      ConnectionHandler.insertEdgeBefore(connection, edge);
    },
  });
}
