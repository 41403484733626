import loadable from "@loadable/component";
import { useQuery } from "relay-hooks";
import { CallsQuery } from "./__generated__/CallsQuery.graphql";
import { Loader } from "../../components/Loader";
import { FC } from "react";
import { Header } from "../../components/Header";
import { Container } from "../../components/Grid";

const CallsList = loadable(
  () => import(/* webpackChunkName: "CallsList" */ "./CallsList"),
);

const query = graphql`
  query CallsQuery {
    ...CallsList_query
  }
`;

export const CallsRoute: FC = function () {
  const { data } = useQuery<CallsQuery>(query);

  const content = data ? (
    <CallsList query={data} fallback={<Loader />} />
  ) : (
    <Loader />
  );

  return (
    <>
      <Header title="Übersicht" subTitle="Anrufe" isLoading={!data} />
      <Container>{content}</Container>
    </>
  );
};
