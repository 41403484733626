import { IToaster, Position, Toaster } from "@blueprintjs/core";

let toaster: IToaster | null = null;
export const AppToaster = function (): IToaster {
  if (toaster === null) {
    toaster = Toaster.create({
      className: "global-toaster",
      position: Position.TOP,
    });
  }

  return toaster;
};
