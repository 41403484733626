import { Tab } from "../../../components/Header";
import { Icon } from "@mormahr/babel-transform-icon";
import {
  useViewer,
  useViewerPermission,
} from "../../../app-controller/ViewerContext";
import { MaybeAllowed } from "../../../app-controller/ViewerUtils";

export function TimeTrackHeaderTabs() {
  const viewer = useViewer();
  const viewerUsername = viewer?.username ?? "me";
  const canViewFullTimeTrackList = useViewerPermission(
    "canViewFullTimeTrackList",
  );
  const canViewLastTwoWeeksTimeTrackList = useViewerPermission(
    "canViewLastTwoWeeksTimeTrackList",
  );

  if (
    (canViewFullTimeTrackList === MaybeAllowed.PROBABLY_NO ||
      canViewFullTimeTrackList === MaybeAllowed.NO) &&
    (canViewLastTwoWeeksTimeTrackList === MaybeAllowed.PROBABLY_NO ||
      canViewLastTwoWeeksTimeTrackList === MaybeAllowed.NO)
  ) {
    return null;
  }

  return (
    <>
      <Tab
        to={`/user/${viewerUsername}/workspace`}
        icon={<Icon icon="chart-line" type="solid" />}
      >
        Für dich
      </Tab>
      <Tab to={`/user/all/workspace`} icon={<Icon icon="users" type="solid" />}>
        Übersicht
      </Tab>
    </>
  );
}
