/**
 * @generated SignedSource<<83f9fde58541eaf9aef15a0488779a3d>>
 * @relayHash 3f6e7f6d26a472b1bd619eaa92c5595e
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 9510036e1eb3ce8b23418e9d4fb9c0f0a918df39

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type endCallInput = {
  callId: string;
  ended: string;
  clientMutationId?: string | null;
};
export type CallPlaygroundEndCallMutation$variables = {
  input: endCallInput;
};
export type CallPlaygroundEndCallMutation$data = {
  readonly endCall: {
    readonly call: {
      readonly id: string;
    };
  } | null;
};
export type CallPlaygroundEndCallMutation = {
  variables: CallPlaygroundEndCallMutation$variables;
  response: CallPlaygroundEndCallMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "endCallPayload",
    "kind": "LinkedField",
    "name": "endCall",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Call",
        "kind": "LinkedField",
        "name": "call",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CallPlaygroundEndCallMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CallPlaygroundEndCallMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "9510036e1eb3ce8b23418e9d4fb9c0f0a918df39",
    "metadata": {},
    "name": "CallPlaygroundEndCallMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "2c8c2592847fb0f3da8e547b7890a616";

export default node;
