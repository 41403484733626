import { FC } from "react";
import { NumericInput } from "@blueprintjs/core";
import { Button } from "../../../components/button/Button";
import { Icon } from "@mormahr/babel-transform-icon";
import { Item } from "../../../components/ItemSelect";
import { useFormikContext } from "formik";
import { CustomerEditExtraBillingItemsModel } from "./CustomerEditExtraBillingItemsModel";
import classNames from "classnames";

export const CustomerEditExtraBillingItemsRow: FC<{
  item: Item;
  amount: number;
}> = function ({ item, amount }) {
  const formikContext = useFormikContext<CustomerEditExtraBillingItemsModel>();
  const deleted = amount === 0;

  return (
    <>
      <div
        className={classNames("self-center", {
          "text-gray-500": deleted,
          "line-through": deleted,
        })}
      >
        {item.title} ({item.itemNumber})
      </div>
      <div className="self-center">
        <NumericInput
          fill
          key={amount === 0 ? "null" : "not-null"}
          defaultValue={amount.toString()}
          min={0}
          onValueChange={(amount) => {
            const items = formikContext.values.extraBillingItems.map((obj) => {
              if (obj.item.id === item.id) {
                return {
                  ...obj,
                  amount,
                };
              } else {
                return obj;
              }
            });
            formikContext.setFieldValue("extraBillingItems", items);
          }}
        />
      </div>
      <div className="self-center">
        <Button
          icon={<Icon icon="trash-can" type="regular" />}
          type="button"
          disabled={deleted}
          onClick={() => {
            const items = formikContext.values.extraBillingItems.map((obj) => {
              if (obj.item.id === item.id) {
                return {
                  ...obj,
                  amount: 0,
                };
              } else {
                return obj;
              }
            });
            formikContext.setFieldValue("extraBillingItems", items);
          }}
        />
      </div>
    </>
  );
};
