/**
 * @generated SignedSource<<e33940cd60d1ba62d8f0899cb0a46a04>>
 * @relayHash 4100de47227c3a1250ad701f85dc14ef
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 14a363c3559a45d6c9a35e3fdecc80bdf216e84e

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type setTodoCustomerInput = {
  todoId: string;
  customerId?: string | null;
  clientMutationId?: string | null;
};
export type TodoMutations_SetCustomerMutation$variables = {
  input: setTodoCustomerInput;
};
export type TodoMutations_SetCustomerMutation$data = {
  readonly setTodoCustomer: {
    readonly todo: {
      readonly customer: {
        readonly " $fragmentSpreads": FragmentRefs<"CustomerFatQuery_data">;
      } | null;
    };
  } | null;
};
export type TodoMutations_SetCustomerMutation = {
  variables: TodoMutations_SetCustomerMutation$variables;
  response: TodoMutations_SetCustomerMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TodoMutations_SetCustomerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "setTodoCustomerPayload",
        "kind": "LinkedField",
        "name": "setTodoCustomer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Todo",
            "kind": "LinkedField",
            "name": "todo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Customer",
                "kind": "LinkedField",
                "name": "customer",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "CustomerFatQuery_data"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TodoMutations_SetCustomerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "setTodoCustomerPayload",
        "kind": "LinkedField",
        "name": "setTodoCustomer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Todo",
            "kind": "LinkedField",
            "name": "todo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Customer",
                "kind": "LinkedField",
                "name": "customer",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "printName",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "14a363c3559a45d6c9a35e3fdecc80bdf216e84e",
    "metadata": {},
    "name": "TodoMutations_SetCustomerMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "97dea99dfb7914338e90aea924b1b23a";

export default node;
