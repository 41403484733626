import styles from "./Shell.module.scss";
import { Sidebar } from "./sidebar/Sidebar";
import { FC, memo } from "react";
import { useFragment } from "relay-hooks";
import { Shell_viewer$key } from "./__generated__/Shell_viewer.graphql";
import { Content } from "./Content";
import classnames from "classnames";
import { Helmet } from "react-helmet";

export interface Props {
  viewer: Shell_viewer$key | null;
  isLoading: boolean;
}

const fragment = graphql`
  fragment Shell_viewer on ViewerUser {
    ...Sidebar_viewer

    employee {
      ...Sidebar_employee
    }
  }
`;

const _Sidebar = memo(Sidebar);

export const ShellComponent: FC<Props> = function ({ isLoading, ...props }) {
  const viewer = useFragment(fragment, props.viewer);
  const enableDarkMode = false;

  return (
    <div className={styles.grid}>
      <Helmet>
        <html
          className={classnames(styles.html, { [styles.dark]: enableDarkMode })}
        />
        <body
          className={classnames(styles.html, { [styles.dark]: enableDarkMode })}
        />
      </Helmet>
      <_Sidebar
        employee={viewer ? viewer.employee : null}
        viewer={viewer}
        isLoading={isLoading}
      />
      <div
        className={classnames(styles.content, {
          "bp3-dark": enableDarkMode,
          dark: enableDarkMode,
        })}
      >
        <Content />
      </div>
    </div>
  );
};

export const Shell = memo(ShellComponent);
