/**
 * @generated SignedSource<<c34eaefe3b272b25a3be4336cb1e6868>>
 * @relayHash 80b38c61856aa9f4a6b995173bb84757
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID a144caa0d00eab1f4979b7fc00f06f390a5dadbf

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type createCustomerInput = {
  displayName: string;
  printName?: string | null;
  reference?: string | null;
  billedHourlyRate?: string | null;
  billingAddress?: string | null;
  categoryId?: string | null;
  phoneNumbers?: ReadonlyArray<EditPhoneNumberEntry | null> | null;
  extraBillingItems?: ReadonlyArray<EditCustomerItemEntry | null> | null;
  journeyItemId?: string | null;
  workItemId?: string | null;
  customerCategoryId?: string | null;
  email?: any | null;
  importReference?: string | null;
  importAddress?: string | null;
  clientMutationId?: string | null;
};
export type EditPhoneNumberEntry = {
  phoneNumberId?: string | null;
  remove?: boolean | null;
  displayName?: string | null;
  phoneNumber?: any | null;
};
export type EditCustomerItemEntry = {
  itemId: string;
  amount: number;
};
export type CustomerCreator_CreateCustomerMutation$variables = {
  input: createCustomerInput;
};
export type CustomerCreator_CreateCustomerMutation$data = {
  readonly createCustomer: {
    readonly createdCustomerEdge: {
      readonly node: {
        readonly id: string;
        readonly displayName: string;
        readonly printName: string;
        readonly reference: string | null;
        readonly " $fragmentSpreads": FragmentRefs<"CustomerFatQuery_data" | "CustomerRow_customer">;
      } | null;
      readonly cursor: string;
    };
  } | null;
};
export type CustomerCreator_CreateCustomerMutation = {
  variables: CustomerCreator_CreateCustomerMutation$variables;
  response: CustomerCreator_CreateCustomerMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "printName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reference",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomerCreator_CreateCustomerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "createCustomerPayload",
        "kind": "LinkedField",
        "name": "createCustomer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomerEdge",
            "kind": "LinkedField",
            "name": "createdCustomerEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Customer",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "CustomerFatQuery_data"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "CustomerRow_customer"
                  },
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CustomerCreator_CreateCustomerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "createCustomerPayload",
        "kind": "LinkedField",
        "name": "createCustomer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomerEdge",
            "kind": "LinkedField",
            "name": "createdCustomerEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Customer",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "link",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "archived",
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "missingRequiredCheckFields",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "missingRecommendedCheckFields",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Statistics",
                    "kind": "LinkedField",
                    "name": "statistics",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "lastWeeks",
                            "value": 13
                          }
                        ],
                        "concreteType": "WeeklyStatistics",
                        "kind": "LinkedField",
                        "name": "weekly",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "profit",
                            "storageKey": null
                          }
                        ],
                        "storageKey": "weekly(lastWeeks:13)"
                      },
                      {
                        "alias": "lastYear",
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "lastDays",
                            "value": 365
                          }
                        ],
                        "kind": "ScalarField",
                        "name": "sumFor",
                        "storageKey": "sumFor(lastDays:365)"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "a144caa0d00eab1f4979b7fc00f06f390a5dadbf",
    "metadata": {},
    "name": "CustomerCreator_CreateCustomerMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "d127776a772a7ee541459115ab1b40d4";

export default node;
