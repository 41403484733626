import { createMachine } from "xstate";

export const UpdateMachine = createMachine({
  id: "UpdateMachine",
  initial: "pending",
  states: {
    pending: {
      on: {
        START_UPDATING: {
          target: "updating",
        },
        IS_DEVELOPMENT_MODE: {
          target: "upToDate",
        },
        NO_UPDATE_FOUND: {
          target: "upToDate",
        },
      },
    },
    updating: {
      on: {
        FINISHED_UPDATE: {
          target: "updated",
        },
      },
    },
    updated: {
      type: "final",
    },
    upToDate: {
      type: "final",
    },
  },
});
