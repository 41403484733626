/**
 * @generated SignedSource<<293573cc26a97cd42e1f4f4291ea4ce1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ViewerContext_session$data = {
  readonly expires: string;
  readonly " $fragmentType": "ViewerContext_session";
};
export type ViewerContext_session$key = {
  readonly " $data"?: ViewerContext_session$data;
  readonly " $fragmentSpreads": FragmentRefs<"ViewerContext_session">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ViewerContext_session",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expires",
      "storageKey": null
    }
  ],
  "type": "Session",
  "abstractKey": null
};

(node as any).hash = "37200b4795dcdf3d5517cb4270e7fab5";

export default node;
