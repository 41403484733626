import { addDays, isAfter, parse, parseISO } from "date-fns";
import {
  extractDate,
  extractEndTime,
  extractStartTime,
  isDateEmpty,
  isTimeEmpty,
} from "./manipulate";
import { momentDateFormat, momentTimeFormat } from "./mask";

export type ConvertedDate = {
  startDate: Optional<Date>;
  endDate: Optional<Date>;
  valid: boolean;
};

const baseTimeRegex = /^\d\d:\d\d$/;
const baseDateRegex = /^\d\d\.\d\d\.\d\d$/;

const invalid = () => ({
  startDate: undefined,
  endDate: undefined,
  valid: false,
});

const referenceDate = parseISO("2020-01-01T00:00:00Z");

function _convertDate(
  dateSlice: string,
  timeSlice: string,
): Optional<Date | "invalid"> {
  const isValidTimeSlice = baseTimeRegex.test(timeSlice);
  const isEmptyTimeSlice = isTimeEmpty(timeSlice);
  const isValidDateSlice = baseDateRegex.test(dateSlice);
  const isEmptyDateSlice = isDateEmpty(dateSlice);

  if ((isValidDateSlice || isEmptyDateSlice) && isEmptyTimeSlice) {
    return null;
  }

  if (!isValidTimeSlice || !isValidDateSlice) {
    return "invalid";
  }

  return parse(
    `${dateSlice} ${timeSlice}`,
    `${momentDateFormat} ${momentTimeFormat}`,
    referenceDate,
  );
}

export function convert(date: string): ConvertedDate {
  const dateSlice = extractDate(date);
  const startTimeSlice = extractStartTime(date);
  const endTimeSlice = extractEndTime(date);

  const startMoment = _convertDate(dateSlice, startTimeSlice);
  let endMoment = _convertDate(dateSlice, endTimeSlice);

  if (startMoment === "invalid" || endMoment === "invalid") {
    return invalid();
  }

  if (startMoment && endMoment && isAfter(startMoment, endMoment)) {
    endMoment = addDays(endMoment, 1);
  }

  return {
    startDate: startMoment ? startMoment : null,
    endDate: endMoment ? endMoment : null,
    valid: true,
  };
}
