/**
 * @generated SignedSource<<9a8b9f4240c2c27d8cf0130ac5606855>>
 * @relayHash c63f491d88b425a271909efb518f6cb4
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 9dc6d0c1fba08f3ca1ae19aa7ca3c4948d982e6d

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TimeTrackStatus = "OPEN" | "APPROVED" | "IN_CUSTOMER_BILLING" | "IN_APPROVED_CUSTOMER_BILLING" | "%future added value";
export type approveTimeTrackInput = {
  timeTrackId: string;
  clientMutationId?: string | null;
};
export type TimeTrackRowMutations_ApproveTimeTrackMutation$variables = {
  input: approveTimeTrackInput;
};
export type TimeTrackRowMutations_ApproveTimeTrackMutation$data = {
  readonly approveTimeTrack: {
    readonly timeTrack: {
      readonly approved: boolean;
      readonly canApprove: boolean;
      readonly canApproveReason: string | null;
      readonly canEdit: boolean;
      readonly canRemove: boolean;
      readonly status: TimeTrackStatus;
    };
    readonly event: {
      readonly id: string;
      readonly timeTrack: {
        readonly id: string;
      };
      readonly " $fragmentSpreads": FragmentRefs<"TimeTrackHistoryRow_event">;
    } | null;
  } | null;
};
export type TimeTrackRowMutations_ApproveTimeTrackMutation = {
  variables: TimeTrackRowMutations_ApproveTimeTrackMutation$variables;
  response: TimeTrackRowMutations_ApproveTimeTrackMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "approved",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canApprove",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canApproveReason",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canEdit",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canRemove",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "TimeTrack",
  "kind": "LinkedField",
  "name": "timeTrack",
  "plural": false,
  "selections": [
    (v8/*: any*/)
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v11 = [
  (v10/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "link",
    "storageKey": null
  },
  (v8/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TimeTrackRowMutations_ApproveTimeTrackMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "approveTimeTrackPayload",
        "kind": "LinkedField",
        "name": "approveTimeTrack",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TimeTrack",
            "kind": "LinkedField",
            "name": "timeTrack",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TimeTrackEvent",
            "kind": "LinkedField",
            "name": "event",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "TimeTrackHistoryRow_event"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TimeTrackRowMutations_ApproveTimeTrackMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "approveTimeTrackPayload",
        "kind": "LinkedField",
        "name": "approveTimeTrack",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TimeTrack",
            "kind": "LinkedField",
            "name": "timeTrack",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TimeTrackEvent",
            "kind": "LinkedField",
            "name": "event",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "kind",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "date",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v10/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "changedFields",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Customer",
                "kind": "LinkedField",
                "name": "oldCustomer",
                "plural": false,
                "selections": (v11/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Customer",
                "kind": "LinkedField",
                "name": "newCustomer",
                "plural": false,
                "selections": (v11/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Employee",
                "kind": "LinkedField",
                "name": "oldEmployee",
                "plural": false,
                "selections": (v11/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Employee",
                "kind": "LinkedField",
                "name": "newEmployee",
                "plural": false,
                "selections": (v11/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CustomerBilling",
                "kind": "LinkedField",
                "name": "oldCustomerBilling",
                "plural": false,
                "selections": (v11/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CustomerBilling",
                "kind": "LinkedField",
                "name": "newCustomerBilling",
                "plural": false,
                "selections": (v11/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "9dc6d0c1fba08f3ca1ae19aa7ca3c4948d982e6d",
    "metadata": {},
    "name": "TimeTrackRowMutations_ApproveTimeTrackMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "defc8efc0a0dfbe999d6d85b8fa6b802";

export default node;
