import { FC } from "react";
import { TimeTrackHistoryRow_event$key } from "./__generated__/TimeTrackHistoryRow_event.graphql";
import { TimeTrackHistoryRowCustomerChange } from "./TimeTrackHistoryRowCustomerChange";
import { useFragment } from "relay-hooks";
import { TimeFromNow } from "./TimeFromNow";
import { TimeTrackHistoryRowTitleChange } from "./TimeTrackHistoryRowTitleChange";
import { TimeTrackHistoryRowBooleanChange } from "./TimeTrackHistoryRowBooleanChange";
import { TimeTrackHistoryRowTimeChange } from "./TimeTrackHistoryRowTimeChange";
import { TimeTrackHistoryRowCustomerBillingChange } from "./TimeTrackHistoryRowCustomerBillingChange";
import styles from "./TimeTrackHistoryRow.module.scss";
import { Icon } from "@mormahr/babel-transform-icon";
import { Stacked } from "./icon-util/Stacked";
import { TimeTrackHistoryRowEmployeeChange } from "./TimeTrackHistoryRowEmployeeChange";

const fragment = graphql`
  fragment TimeTrackHistoryRow_event on TimeTrackEvent {
    kind
    date
    user {
      displayName
    }
    changedFields

    ...TimeTrackHistoryRowCustomerChange_event
    ...TimeTrackHistoryRowEmployeeChange_event
    ...TimeTrackHistoryRowTitleChange_event
    ...TimeTrackHistoryRowTimeChange_event
    ...TimeTrackHistoryRowCustomerBillingChange_event
  }
`;

export const TimeTrackHistoryRow: FC<{
  event: TimeTrackHistoryRow_event$key;
}> = function TimeTrackHistoryRow(props) {
  const event = useFragment(fragment, props.event);
  const user = (
    <span className="font-bold">
      {event.user?.displayName || "Ein unbekannter Nutzer"}
    </span>
  );
  const time = (
    <span className="italic">
      <TimeFromNow date={event.date} />
    </span>
  );

  if (event.kind === "created") {
    return (
      <li className={styles.row}>
        <span className={styles.icon}>
          <Icon icon="circle-plus" type="regular" fixedWidth />
        </span>
        <p>
          {user} hat {time} den Eintrag erstellt.
        </p>
      </li>
    );
  } else if (event.kind === "removed") {
    return (
      <li className={styles.row}>
        <span className={styles.icon}>
          <Icon icon="circle-minus" type="regular" fixedWidth />
        </span>
        <p>
          {user} hat {time} den Eintrag entfernt.
        </p>
      </li>
    );
  } else if (event.kind === "restored") {
    return (
      <li className={styles.row}>
        <span className={styles.icon}>
          <Icon icon="circle-plus" type="regular" fixedWidth />
        </span>
        <p>
          {user} hat {time} den Eintrag wiederhergestellt.
        </p>
      </li>
    );
  }

  const changedFields = event.changedFields as {
    [key: string]: [unknown, unknown];
  };

  const phrases = [];
  let icon = <Icon icon="pencil" type="regular" fixedWidth />;

  if (changedFields.customer) {
    phrases.push(
      <TimeTrackHistoryRowCustomerChange event={event} key="customer" />,
    );
    icon = <Icon icon="user-doctor" type="regular" fixedWidth />;
  }

  if (changedFields.employee) {
    phrases.push(
      <TimeTrackHistoryRowEmployeeChange event={event} key="customer" />,
    );
    icon = <Icon icon="user-tie" type="regular" fixedWidth />;
  }

  if (changedFields.title) {
    phrases.push(<TimeTrackHistoryRowTitleChange event={event} key="title" />);
  }

  if (changedFields.note) {
    phrases.push(<span key="note">die Notiz geändert</span>);
  }

  if (changedFields.description) {
    phrases.push(<span key="description">die Beschreibung geändert</span>);
  }

  if (changedFields.journey) {
    phrases.push(
      <TimeTrackHistoryRowBooleanChange
        field={changedFields.journey as [boolean, boolean]}
        turnOn={<span>die Anfahrt aktiviert</span>}
        turnOff={<span>die Anfahrt deaktiviert</span>}
        key="journey"
      />,
    );
    if (changedFields.journey[1]) {
      icon = <Icon icon="car" type="regular" fixedWidth />;
    } else {
      icon = (
        <Stacked>
          <Icon icon="car" type="regular" fixedWidth className="fa-stack-1x" />
          <Icon
            icon="slash"
            type="regular"
            fixedWidth
            className="fa-stack-1x"
          />
        </Stacked>
      );
    }
  }

  if (changedFields.unbilled) {
    phrases.push(
      <TimeTrackHistoryRowBooleanChange
        field={changedFields.unbilled as [boolean, boolean]}
        turnOn={<span>die Abrechnung deaktiviert</span>}
        turnOff={<span>die Abrechnung aktiviert</span>}
        key="unbilled"
      />,
    );
    if (!changedFields.unbilled[1]) {
      icon = <Icon icon="money-bill-1" type="regular" fixedWidth />;
    } else {
      icon = (
        <Stacked>
          <Icon
            icon="money-bill-1"
            type="regular"
            fixedWidth
            className="fa-stack-1x"
          />
          <Icon
            icon="slash"
            type="regular"
            fixedWidth
            className="fa-stack-1x"
          />
        </Stacked>
      );
    }
  }

  if (changedFields.hidden_for_customer) {
    phrases.push(
      <TimeTrackHistoryRowBooleanChange
        field={changedFields.hidden_for_customer as [boolean, boolean]}
        turnOn={<span>die Kundensichtbarkeit deaktiviert</span>}
        turnOff={<span>die Kundensichtbarkeit aktiviert</span>}
        key="hiddenForCustomer"
      />,
    );
    if (!changedFields.hidden_for_customer[1]) {
      icon = <Icon icon="eye" type="regular" fixedWidth />;
    } else {
      icon = <Icon icon="eye-slash" type="regular" fixedWidth />;
    }
  }

  if (changedFields.approved) {
    phrases.push(
      <TimeTrackHistoryRowBooleanChange
        field={changedFields.approved as [boolean, boolean]}
        turnOn={<span>den Eintrag bestätigt</span>}
        turnOff={<span>die Bestätigung wiederrufen</span>}
        key="approved"
      />,
    );
    if (changedFields.approved[1]) {
      icon = <Icon icon="badge-check" type="regular" fixedWidth />;
    } else {
      icon = <Icon icon="badge" type="regular" fixedWidth />;
    }
  }

  if (changedFields.recorded_start || changedFields.recorded_end) {
    phrases.push(<TimeTrackHistoryRowTimeChange event={event} key="times" />);
    icon = <Icon icon="clock" type="regular" fixedWidth />;
  }

  if (changedFields.customer_billing) {
    phrases.push(
      <TimeTrackHistoryRowCustomerBillingChange
        event={event}
        key="customer_billing"
      />,
    );

    if (changedFields.customer_billing[1]) {
      icon = <Icon icon="sack-dollar" type="regular" fixedWidth />;
    } else {
      icon = (
        <Stacked>
          <Icon
            icon="sack-dollar"
            type="regular"
            fixedWidth
            className="fa-stack-1x"
          />
          <Icon
            icon="slash"
            type="regular"
            fixedWidth
            className="fa-stack-1x"
          />
        </Stacked>
      );
    }
  }

  if (phrases.length > 1) {
    icon = <Icon icon="pencil" type="regular" fixedWidth />;
  }

  const phraseOutput = phrases.flatMap((phrase, index) => {
    if (index < phrases.length - 2) {
      return [phrase, ", "];
    } else if (index === phrases.length - 2) {
      return [phrase, " und "];
    } else {
      return [phrase];
    }
  });

  return (
    <li className={styles.row}>
      <span className={styles.icon}>{icon}</span>
      <p className="leading-6">
        {user} hat {time}{" "}
        {phraseOutput.length > 0
          ? phraseOutput
          : "eine unbekannte Änderung vorgenommen"}
        .
      </p>
    </li>
  );
};
