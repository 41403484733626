import {
  ContextType,
  PureComponent,
  useCallback,
  useContext,
  useState,
  useEffect,
  FC,
  ReactElement,
} from "react";
import * as React from "react";
import {
  Hotkey,
  Hotkeys,
  HotkeysTarget,
  IHotkeysProps,
  IHotkeysTargetComponent,
} from "@blueprintjs/core";
import { PortalBarKeyCombo } from "./PortalBarConfig";
import {
  CommandManagerDispatchContext,
  CommandManagerStateContext,
  isPortalBarOpen,
} from "./CommandManager";
import loadable from "@loadable/component";

const PortalBar = loadable(
  () => import(/* webpackChunkName: "PortalBar" */ "./PortalBar"),
);

const PRELOAD_TIME = 1500;
const PRELOAD = false;

export const PortalBarRenderer: FC = function PortalBarRenderer() {
  const state = useContext(CommandManagerStateContext);
  const dispatch = useContext(CommandManagerDispatchContext);
  const [load, setLoad] = useState(false);

  const close = useCallback(() => {
    dispatch({
      type: "CLOSE_PORTAL_BAR",
    });
  }, [dispatch]);

  useEffect(() => {
    if (!PRELOAD) {
      return;
    }

    setTimeout(() => setLoad(true), PRELOAD_TIME);
  }, []);

  const isOpen = isPortalBarOpen(state);

  useEffect(() => {
    if (isOpen) {
      setLoad(true);
    }
  }, [isOpen]);

  if (!(isOpen || load)) {
    // Don't trigger loading immediately, except if user initiated
    return null;
  }

  return <PortalBar isOpen={isOpen} onClose={close} />;
};

// eslint-disable-next-line @typescript-eslint/ban-types
type _Props = {};

@HotkeysTarget
export class PortalBarHost
  extends PureComponent<_Props>
  implements IHotkeysTargetComponent {
  context!: ContextType<typeof CommandManagerDispatchContext>;

  open = (): void => {
    this.context({
      type: "OPEN_PORTAL_BAR",
    });
  };

  renderHotkeys(): ReactElement<IHotkeysProps> {
    return (
      <Hotkeys>
        <Hotkey
          global={true}
          combo={PortalBarKeyCombo}
          label="PortalBar zeigen"
          onKeyDown={this.open}
          // prevent typing "O" in omnibar input
          preventDefault={true}
        />
      </Hotkeys>
    );
  }

  render(): ReactElement {
    return <PortalBarRenderer />;
  }
}

PortalBarHost.contextType = CommandManagerDispatchContext;
