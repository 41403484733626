import { useEffect, useMemo, useState } from "react";
import { STORE_THEN_NETWORK, useQuery } from "relay-hooks";
import { Redirect } from "react-router-dom";
import { HeaderLoader } from "../../../components/HeaderLoading";
import { Header } from "../../../components/Header";
import {
  useViewer,
  useViewerPermission,
} from "../../../app-controller/ViewerContext";
import { TimeTracksAllQuery } from "./__generated__/TimeTracksAllQuery.graphql";
import { MaybeAllowed } from "../../../app-controller/ViewerUtils";
import { formatISO, subDays } from "date-fns";
import { TimeTrackHeaderTabs } from "./TimeTrackHeaderTabs";
import loadable from "@loadable/component";

const TimeTrackWorkspace = loadable(
  () =>
    import(
      /* webpackChunkName: "TimeTrackWorkspace" */ "./TimeTrackWorkspaceWrapper"
    ),
);

const queryDocument = graphql`
  query TimeTracksAllQuery($recordedStartAfter: DateTime) {
    viewer {
      ...TimeTrackWorkspace_user
    }

    ...TimeTrackWorkspace_query
      @arguments(
        onlyCreatedByOrAssignedUser: null
        onlyUnapproved: false
        recordedStartAfter: $recordedStartAfter
      )
  }
`;

export function TimeTracksAll() {
  const viewer = useViewer();
  const viewerUsername = viewer?.username ?? "me";
  const canViewFullTimeTrackList = useViewerPermission(
    "canViewFullTimeTrackList",
  );
  const canViewLastTwoWeeksTimeTrackList = useViewerPermission(
    "canViewLastTwoWeeksTimeTrackList",
  );

  const [recordedStartAfter, setRecordedStartAfter] = useState(() =>
    canViewLastTwoWeeksTimeTrackList === MaybeAllowed.PROBABLY_YES ||
    canViewLastTwoWeeksTimeTrackList === MaybeAllowed.YES
      ? formatISO(subDays(new Date(), 14))
      : null,
  );
  useEffect(() => {
    setRecordedStartAfter(
      canViewLastTwoWeeksTimeTrackList === MaybeAllowed.PROBABLY_YES ||
        canViewLastTwoWeeksTimeTrackList === MaybeAllowed.YES
        ? formatISO(subDays(new Date(), 14))
        : null,
    );
  }, [canViewLastTwoWeeksTimeTrackList]);

  const variables = useMemo(
    () => ({
      recordedStartAfter,
    }),
    [recordedStartAfter],
  );
  const {
    data: props,
    error,
    isLoading: cached,
  } = useQuery<TimeTracksAllQuery>(queryDocument, variables, {
    fetchPolicy: STORE_THEN_NETWORK,
  });

  if (
    canViewFullTimeTrackList === MaybeAllowed.NO &&
    canViewLastTwoWeeksTimeTrackList === MaybeAllowed.NO
  ) {
    return <Redirect to={`/user/${viewerUsername}/workspace`} />;
  }

  if (error) {
    throw error;
  }
  if (props && !props.viewer) {
    return <Redirect to="/user/" />;
  }

  return (
    <>
      <Header
        title="Alle Mitarbeiter"
        subTitle="Workspace"
        isLoading={cached}
        bigContainer
      >
        <TimeTrackHeaderTabs />
      </Header>
      <TimeTrackWorkspace
        showDownload
        user={props?.viewer}
        query={props}
        fallback={<HeaderLoader />}
      />
    </>
  );
}
