import { format } from "date-fns";
import { extractDate, isDateEmpty, withDate, withEndTime } from "./manipulate";
import { momentDateFormat } from "./mask";

export function extractFromEndDate(
  date: string,
  initialEndDate: Optional<Date>,
): string {
  const endDate = initialEndDate ? initialEndDate : null;
  const newDate = endDate ? format(endDate, momentDateFormat) : "";
  const endTime = endDate ? format(endDate, "HH:mm") : "";

  return withEndTime(
    isDateEmpty(extractDate(date)) ? withDate(date, newDate) : date,
    endTime,
  );
}
