/**
 * @generated SignedSource<<a848da8f460bd245f4406d7a61f85987>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TimeTrackHistoryRowCustomerBillingChange_event$data = {
  readonly changedFields: any;
  readonly oldCustomerBilling: {
    readonly displayName: string;
    readonly link: string;
  } | null;
  readonly newCustomerBilling: {
    readonly displayName: string;
    readonly link: string;
  } | null;
  readonly " $fragmentType": "TimeTrackHistoryRowCustomerBillingChange_event";
};
export type TimeTrackHistoryRowCustomerBillingChange_event$key = {
  readonly " $data"?: TimeTrackHistoryRowCustomerBillingChange_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"TimeTrackHistoryRowCustomerBillingChange_event">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "displayName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "link",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TimeTrackHistoryRowCustomerBillingChange_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "changedFields",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomerBilling",
      "kind": "LinkedField",
      "name": "oldCustomerBilling",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomerBilling",
      "kind": "LinkedField",
      "name": "newCustomerBilling",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "TimeTrackEvent",
  "abstractKey": null
};
})();

(node as any).hash = "cb7a45f5295d4aa73ff0f203eb984649";

export default node;
