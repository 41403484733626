/**
 * @generated SignedSource<<9283a4904c23d2c9fde353317b7c34b8>>
 * @relayHash e66b1f78e62934360623499fc81704bc
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 01b8c199608dcb344197580cdbd4f91cdf1bb074

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type setTodoNoteInput = {
  todoId: string;
  note: string;
  clientMutationId?: string | null;
};
export type TodoMutations_SetTodoNoteMutation$variables = {
  input: setTodoNoteInput;
};
export type TodoMutations_SetTodoNoteMutation$data = {
  readonly setTodoNote: {
    readonly todo: {
      readonly note: string | null;
    };
  } | null;
};
export type TodoMutations_SetTodoNoteMutation = {
  variables: TodoMutations_SetTodoNoteMutation$variables;
  response: TodoMutations_SetTodoNoteMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "note",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TodoMutations_SetTodoNoteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "setTodoNotePayload",
        "kind": "LinkedField",
        "name": "setTodoNote",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Todo",
            "kind": "LinkedField",
            "name": "todo",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TodoMutations_SetTodoNoteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "setTodoNotePayload",
        "kind": "LinkedField",
        "name": "setTodoNote",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Todo",
            "kind": "LinkedField",
            "name": "todo",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "01b8c199608dcb344197580cdbd4f91cdf1bb074",
    "metadata": {},
    "name": "TodoMutations_SetTodoNoteMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "a2b7b009e7e9b7cdf11af4be9e8e2423";

export default node;
