/**
 * @generated SignedSource<<40660317f99c490b26cc65b223175100>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Sidebar_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AuthenticationWidget_viewer">;
  readonly " $fragmentType": "Sidebar_viewer";
};
export type Sidebar_viewer$key = {
  readonly " $data"?: Sidebar_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"Sidebar_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Sidebar_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AuthenticationWidget_viewer"
    }
  ],
  "type": "ViewerUser",
  "abstractKey": null
};

(node as any).hash = "a8d918c5d05d2d6fd1f894a397e0931d";

export default node;
