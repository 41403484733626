/**
 * @generated SignedSource<<243324a679f614e1f83242593561b3bb>>
 * @relayHash ac73f535f086c8e88039b947aa7d9339
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 531e112f1ca3613e302dd0793eb023c515b6e850

import { ConcreteRequest, Query } from 'relay-runtime';
export type ItemSelectQuery$variables = {
  onlyUsableForWork: boolean;
  onlyUsableForJourney: boolean;
  onlyUsableForExtraBilling: boolean;
  onlyUsableForManual: boolean;
};
export type ItemSelectQuery$data = {
  readonly items: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly title: string;
        readonly itemNumber: string;
      } | null;
    } | null> | null;
  } | null;
};
export type ItemSelectQuery = {
  variables: ItemSelectQuery$variables;
  response: ItemSelectQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "onlyUsableForExtraBilling"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "onlyUsableForJourney"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "onlyUsableForManual"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "onlyUsableForWork"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "onlyUsableForExtraBilling",
        "variableName": "onlyUsableForExtraBilling"
      },
      {
        "kind": "Variable",
        "name": "onlyUsableForJourney",
        "variableName": "onlyUsableForJourney"
      },
      {
        "kind": "Variable",
        "name": "onlyUsableForManual",
        "variableName": "onlyUsableForManual"
      },
      {
        "kind": "Variable",
        "name": "onlyUsableForWork",
        "variableName": "onlyUsableForWork"
      }
    ],
    "concreteType": "ItemConnection",
    "kind": "LinkedField",
    "name": "items",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ItemEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Item",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "itemNumber",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ItemSelectQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "ItemSelectQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "id": "531e112f1ca3613e302dd0793eb023c515b6e850",
    "metadata": {},
    "name": "ItemSelectQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "51bb856903e6d1370a092430baa75c48";

export default node;
