import { useService } from "@xstate/react";
import { Loader } from "../components/Loader";
import App from "../app/App";
import { ViewerProvider } from "./ViewerContext";
import { QueryRenderer } from "react-relay";
import { StateHostQuery } from "./__generated__/StateHostQuery.graphql";
import { AppNonIdealState } from "../components/AppNonIdealState";
import { useRelayEnvironment } from "relay-hooks";
import { useAppService } from "./AppServiceContext";
import loadable from "@loadable/component";

const Login = loadable(
  () => import(/* webpackChunkName: "Login" */ "../app/auth/Login"),
);

export function StateHost() {
  const AppService = useAppService();
  const [state] = useService(AppService);
  const environment = useRelayEnvironment();

  if (state.value === "unauthenticated") {
    return <Login fallback={<Loader />} />;
  } else if (state.value === "probablyUnauthenticated") {
    // If we think the user is unauthenticated, try to load the necessary info from the server.
    // If the user is not authenticated relayenv will automatically transition AppService to
    // unauthenticated.
    return (
      <QueryRenderer<StateHostQuery>
        environment={environment}
        query={graphql`
          query StateHostQuery {
            viewer {
              ...ViewerContext_viewer
            }
            session {
              ...ViewerContext_session
            }
          }
        `}
        variables={{}}
        render={({ props }) => {
          return (
            <ViewerProvider
              viewer={props?.viewer ?? null}
              session={props?.session ?? null}
            >
              <Loader wait={5000} />
            </ViewerProvider>
          );
        }}
      />
    );
  } else if (state.value === "error") {
    return <AppNonIdealState />;
  } else {
    return <App />;
  }
}
