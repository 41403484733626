/**
 * @generated SignedSource<<e9dfae5ff0dd9c5323ad4c5df3dcd48e>>
 * @relayHash 85a30636c07a0509e39eb97121dc312c
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID dc5ffee5687fdf6f0664f955f62fd7fc3da6a6bf

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TimeTrackHistorySubscription$variables = {
  timeTrackId: string;
  initialRevision: string;
};
export type TimeTrackHistorySubscription$data = {
  readonly timeTrackEvents: {
    readonly event: {
      readonly id: string;
      readonly timeTrack: {
        readonly id: string;
      };
      readonly " $fragmentSpreads": FragmentRefs<"TimeTrackHistoryRow_event">;
    };
  } | null;
};
export type TimeTrackHistorySubscription = {
  variables: TimeTrackHistorySubscription$variables;
  response: TimeTrackHistorySubscription$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "initialRevision"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "timeTrackId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "fromRevision",
    "variableName": "initialRevision"
  },
  {
    "kind": "Variable",
    "name": "timeTrackId",
    "variableName": "timeTrackId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "TimeTrack",
  "kind": "LinkedField",
  "name": "timeTrack",
  "plural": false,
  "selections": [
    (v3/*: any*/)
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v6 = [
  (v5/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "link",
    "storageKey": null
  },
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TimeTrackHistorySubscription",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "TimeTrackEventSubscriptionValue",
        "kind": "LinkedField",
        "name": "timeTrackEvents",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TimeTrackEvent",
            "kind": "LinkedField",
            "name": "event",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "TimeTrackHistoryRow_event"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "TimeTrackHistorySubscription",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "TimeTrackEventSubscriptionValue",
        "kind": "LinkedField",
        "name": "timeTrackEvents",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TimeTrackEvent",
            "kind": "LinkedField",
            "name": "event",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "kind",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "date",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "changedFields",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Customer",
                "kind": "LinkedField",
                "name": "oldCustomer",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Customer",
                "kind": "LinkedField",
                "name": "newCustomer",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Employee",
                "kind": "LinkedField",
                "name": "oldEmployee",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Employee",
                "kind": "LinkedField",
                "name": "newEmployee",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CustomerBilling",
                "kind": "LinkedField",
                "name": "oldCustomerBilling",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CustomerBilling",
                "kind": "LinkedField",
                "name": "newCustomerBilling",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "dc5ffee5687fdf6f0664f955f62fd7fc3da6a6bf",
    "metadata": {},
    "name": "TimeTrackHistorySubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();

(node as any).hash = "6091cb4162dcab75784160e860155982";

export default node;
