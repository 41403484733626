import { QueryRenderer } from "react-relay";
import { RouteComponentProps } from "react-router";
import { CustomerEditRouteQuery } from "./__generated__/CustomerEditRouteQuery.graphql";
import { HeaderLoader } from "../../../components/HeaderLoading";
import { CustomerNavbarLoader } from "../CustomerNavbarLoader";
import { useRelayEnvironment } from "relay-hooks";
import loadable from "@loadable/component";
import { FC } from "react";

const CustomerEditWrapper = loadable(
  () =>
    import(
      /* webpackChunkName: "CustomerEditWrapper" */ "./CustomerEditWrapper"
    ),
);

export const CustomerEditRoute: FC<RouteComponentProps<{ id: string }>> =
  function ({ match }) {
    const id = match.params.id;
    const environment = useRelayEnvironment();

    return (
      <QueryRenderer<CustomerEditRouteQuery>
        environment={environment}
        query={graphql`
          query CustomerEditRouteQuery($id: ID!) {
            node(id: $id) {
              __typename
              ...CustomerEdit_customer
            }
          }
        `}
        variables={{ id }}
        render={({ error, props }) => {
          if (error) {
            throw error;
          }

          if (props && props?.node?.__typename !== "Customer") {
            // TODO
            return null;
          }

          return (
            <>
              <CustomerNavbarLoader id={id} subTitle="Kunde" />
              <CustomerEditWrapper
                id={id}
                customer={props?.node ?? null}
                fallback={<HeaderLoader />}
              />
            </>
          );
        }}
      />
    );
  };
