import { useEffect, useState } from "react";
import { RefetchFn } from "react-relay";
import { TodoConnectionProviderQuery } from "./__generated__/TodoConnectionProviderQuery.graphql";
import { deepEqual } from "../../../util/comparison/deepEqual";

export type ConnectionType = {
  pageInfo: {
    hasNextPage: boolean;
    endCursor: Optional<string>;
  };
  edges: Optional<ReadonlyArray<any>>;
};

type LoadMoreInfo =
  | { additionalCount: 0 }
  | { additionalCount: number; endCursor: string };

export function shouldLoadMore(
  connection: Optional<ConnectionType>,
  total: number,
): LoadMoreInfo {
  if (!connection) {
    return { additionalCount: 0 };
  }

  const { edges, pageInfo } = connection;
  if (!edges || edges.length >= total || !pageInfo.hasNextPage) {
    return { additionalCount: 0 };
  }

  if (pageInfo.endCursor == null) {
    throw new Error("No endCursor available.");
  }

  return {
    additionalCount: total - edges.length,
    endCursor: pageInfo.endCursor,
  };
}

export type LoadMoreArguments = {
  count?: number;
  cursor?: string;
};

export function loadMoreArguments(info: LoadMoreInfo): LoadMoreArguments {
  if (info.additionalCount !== 0) {
    return {
      count: info.additionalCount,
      // @ts-ignore
      cursor: info.endCursor,
    };
  } else {
    return {};
  }
}

export function useRefetch(
  refetch: RefetchFn<TodoConnectionProviderQuery>,
  connection: Optional<ConnectionType>,
  total: number,
  // eslint-disable-next-line @typescript-eslint/ban-types
  filters: Object,
) {
  const [currentFilters, setCurrentFilters] = useState(filters);

  useEffect(() => {
    const loadMoreInfo = shouldLoadMore(connection, total);

    if (
      deepEqual(currentFilters, filters) &&
      loadMoreInfo.additionalCount === 0
    ) {
      return;
    }

    if (!connection) {
      return;
    }

    setCurrentFilters(filters);

    refetch({ ...filters, ...loadMoreArguments(loadMoreInfo) });
  }, [connection, total, currentFilters, filters, refetch]);
}
