import { FC, useEffect } from "react";
import { Props } from "./CustomerEditDialog";
import { useQuery } from "relay-hooks";
import { CustomerEditDialogLazyWrapperQuery } from "./__generated__/CustomerEditDialogLazyWrapperQuery.graphql";
import loadable from "@loadable/component";
import { HeaderLoader } from "../../../components/HeaderLoading";

const CustomerEditDialog = loadable(
  () =>
    import(/* webpackChunkName: "CustomerEditDialog" */ "./CustomerEditDialog"),
);

const query = graphql`
  query CustomerEditDialogLazyWrapperQuery($customerId: ID!) {
    node(id: $customerId) {
      __typename

      ... on Customer {
        ...CustomerEditDialog_customer
      }
    }
  }
`;

export const CustomerEditDialogLazyWrapper: FC<
  Omit<Props, "customer"> & { id: string }
> = function ({ id, ...props }) {
  const { data, error, isLoading } =
    useQuery<CustomerEditDialogLazyWrapperQuery>(query, {
      customerId: id,
    });

  useEffect(() => {
    if (props.isOpen) {
      CustomerEditDialog.preload();
    }
  }, [props.isOpen]);

  if (error) {
    throw error;
  }

  if (isLoading) {
    return <HeaderLoader />;
  }

  if (!data || !data.node || data.node?.__typename !== "Customer") {
    return null;
  }

  return (
    <CustomerEditDialog
      fallback={<HeaderLoader />}
      customer={data.node}
      {...props}
    />
  );
};
