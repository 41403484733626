import { Button } from "./button/Button";
import { Customer, NoCustomerString } from "./CustomerChooser";
import { PlainButton } from "./button/PlainButton";
import { Icon as BPIcon } from "@blueprintjs/core";

export interface Props {
  customer: Customer | null;

  disabled?: boolean;
  mode?: "button" | "text";

  onClick?: () => void;
}

export function CustomerChooserButton({
  customer,
  disabled,
  mode = "text",
  ...buttonProps
}: Props) {
  return (
    <div data-cy="customer-chooser">
      {mode === "button" ? (
        <Button
          rightIcon={<BPIcon icon="caret-down" />}
          disabled={disabled}
          title="Kunde ändern"
          type="button"
          {...buttonProps}
        >
          {customer ? customer.displayName : NoCustomerString}
        </Button>
      ) : customer ? (
        <PlainButton title="Kunde ändern" ellipsis {...buttonProps}>
          {customer.displayName}
        </PlainButton>
      ) : (
        <PlainButton title="Kunde ändern" ellipsis {...buttonProps}>
          {NoCustomerString}
        </PlainButton>
      )}
    </div>
  );
}
