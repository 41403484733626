import { ReactNode } from "react";
import { Money } from "../../components/Money";
import styles from "./DashboardTopStat.module.scss";
import classNames from "classnames";

export interface Props {
  label: string | null;
  amount: string | null;
  trend?: ReactNode;
}

export function DashboardTopStat({ label, amount, trend }: Props) {
  return (
    <div className={styles.stat}>
      <div
        className={classNames(styles.label, {
          ["skeleton"]: label === null,
        })}
      >
        {label !== null ? label : "MONAT"}
      </div>
      <div
        className={classNames(styles.amount, {
          ["skeleton"]: amount === null,
        })}
      >
        <Money value={amount !== null ? amount : "0000"} rounded />
      </div>
      {trend && <div className={styles.trend}>{trend}</div>}
    </div>
  );
}
