import { FormGroup } from "@blueprintjs/core";
import {
  Item,
  ItemSelect,
  ItemUsableCategory,
} from "../../../components/ItemSelect";
import { FC } from "react";
import { useFormikContext } from "formik";

export interface CustomerEditWorkItemModel {
  workItem: Item | null;
}

export const CustomerEditWorkItem: FC = function () {
  const formikContext = useFormikContext<CustomerEditWorkItemModel>();

  return (
    <div data-cy="customer-work-item">
      <FormGroup label="Artikel für Arbeitszeit">
        <div className="flex w-full justify-between">
          <ItemSelect
            item={formikContext.values.workItem}
            usableCategory={ItemUsableCategory.work}
            onChange={(item) => formikContext.setFieldValue("workItem", item)}
          />
        </div>
      </FormGroup>
    </div>
  );
};
