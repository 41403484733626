import { Fragment } from "react";
import { createFragmentContainer } from "react-relay";
import { Statistics_statistics$data } from "./__generated__/Statistics_statistics.graphql";
import { Col, Row } from "../../components/Grid";
import { DashboardTopStat } from "./DashboardTopStat";
import styles from "./Statistics.module.scss";
import { Money } from "../../components/Money";
import currency from "currency.js";
import { Icon } from "@mormahr/babel-transform-icon";
import { format, getDay, getDaysInMonth, parseISO } from "date-fns";

export type Props = {
  statistics: Statistics_statistics$data | null;
};

export function MoneyDifference({
  current,
  reference,
}: {
  current: string;
  reference: string;
}) {
  const difference = currency(current).subtract(currency(reference));

  const amount = Math.round(difference.dollars());
  if (amount < 0) {
    return (
      <span className={styles.down}>
        <Icon icon="angles-down" type="regular" />{" "}
        <Money value={amount.toString() + ".00"} />
      </span>
    );
  } else {
    return (
      <span className={styles.up}>
        <Icon icon="angles-up" type="regular" /> +
        <Money value={amount.toString() + ".00"} />
      </span>
    );
  }
}

function CurrentMonthTrend({
  statistics,
}: {
  statistics: Statistics_statistics$data;
}) {
  const current = statistics.monthly[0];
  const previous = statistics.monthly[12];

  const currentDate = parseISO(current.monthStart);

  const percent = getDay(currentDate) / getDaysInMonth(currentDate);
  const scaledPrevious = currency(previous.profit).multiply(percent);

  return (
    <>
      <MoneyDifference
        current={current.profit}
        reference={scaledPrevious.toString()}
      />{" "}
      anteilig zu {format(parseISO(previous.monthStart), "yyyy")}
    </>
  );
}

export const StatisticsComponent = ({ statistics }: Props) => {
  return (
    <Fragment>
      <Row className={styles.stats}>
        <Col sm={{ size: 12 }} xl={{ size: 3 }}>
          <DashboardTopStat label="Heute" amount={statistics?.today ?? null} />
        </Col>
        <Col sm={{ size: 6 }} xl={{ size: 3, offset: 3 }}>
          <DashboardTopStat
            label={
              statistics
                ? format(parseISO(statistics.monthly[0].monthStart), "MMMM")
                : null
            }
            amount={statistics?.monthly[0].profit ?? null}
            trend={
              statistics ? (
                <CurrentMonthTrend statistics={statistics} />
              ) : (
                <span className={"skeleton"}>Vergleich zu Vorjahr</span>
              )
            }
          />
        </Col>
        <Col sm={{ size: 6 }} xl={{ size: 3 }}>
          <DashboardTopStat
            label={
              statistics
                ? format(parseISO(statistics.monthly[1].monthStart), "MMMM")
                : null
            }
            amount={statistics?.monthly[1].profit ?? null}
            trend={
              statistics ? (
                <>
                  <MoneyDifference
                    current={statistics?.monthly[1].profit}
                    reference={statistics?.monthly[13].profit}
                  />{" "}
                  zu{" "}
                  {format(parseISO(statistics?.monthly[13].monthStart), "yyyy")}
                </>
              ) : (
                <span className={"skeleton"}>Vergleich zu Vorjahr</span>
              )
            }
          />
        </Col>
      </Row>
    </Fragment>
  );
};

export const Statistics = createFragmentContainer(StatisticsComponent, {
  statistics: graphql`
    fragment Statistics_statistics on Statistics {
      today: sumFor(lastDays: 0)
      monthly(lastMonths: 24) {
        profit
        monthStart
      }
    }
  `,
});
