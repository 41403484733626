import * as React from "react";
import classnames from "classnames";
import styles from "./Heading.module.scss";

export function Heading({
  className,
  level,
  minimal,
  ...rest
}: React.HTMLAttributes<HTMLHeadingElement> & {
  level: 1 | 2 | 3 | 4 | 5 | 6;
  minimal?: boolean;
}) {
  const _level = level >= 1 && level <= 6 ? level : 1;
  const Tag = `h${_level}`;
  return (
    <Tag
      // @ts-ignore
      className={classnames(className, styles[Tag], {
        [styles.minimal]: minimal,
      })}
      {...rest}
    />
  );
}
