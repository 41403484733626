import { useFragment } from "relay-hooks";
import { Header } from "../../components/Header";
import { CustomerBillingNavbar_customerBilling$key } from "./__generated__/CustomerBillingNavbar_customerBilling.graphql";
import classNames from "classnames";
import { format, parseISO } from "date-fns";
import { FC } from "react";
import { useURLParams } from "../../util/useURLParams";
import { Link } from "react-router-dom";

export interface Props {
  customerBilling: CustomerBillingNavbar_customerBilling$key | null;
  isLoading: boolean;
}

const fragment = graphql`
  fragment CustomerBillingNavbar_customerBilling on CustomerBilling {
    month
    subject {
      displayName
      link
    }
  }
`;

export const CustomerBillingNavbar: FC<Props> = function (props) {
  const customerBilling = useFragment(fragment, props.customerBilling);

  const params = useURLParams();
  const _back = params.get("previous");
  const back: "customer" | "month" =
    _back === "customer"
      ? "customer"
      : _back === "month"
      ? "month"
      : customerBilling?.month
      ? "month"
      : "customer";

  const title = (
    <span className={classNames({ skeleton: customerBilling === null })}>
      {customerBilling?.subject.displayName ?? "Kunde"}
    </span>
  );

  const additionalSubTitle =
    customerBilling && customerBilling.month
      ? `: ${format(parseISO(customerBilling.month), "MMMM yyyy")}`
      : "";

  return (
    <Header
      title={title}
      subTitle={<span>Abrechnung{additionalSubTitle}</span>}
      isLoading={props.isLoading}
      back={
        customerBilling ? (
          back === "customer" ? (
            <Link to={customerBilling.subject.link + "/billing"}>
              {customerBilling.subject.displayName}
            </Link>
          ) : (
            <Link to={`/billings/${customerBilling.month}`}>
              {format(parseISO(customerBilling.month!), "MMMM yyyy")}
            </Link>
          )
        ) : null
      }
    />
  );
};
