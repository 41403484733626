import { FC, useCallback, useContext, useMemo } from "react";
import loadable from "@loadable/component";
import { useHotkeys } from "@blueprintjs/core";
import {
  CommandManagerDispatchContext,
  CommandManagerStateContext,
} from "./CommandManager";

const TimeTrackCreatorDialog = loadable(
  () =>
    import(
      /* webpackChunkName: "TimeTrackCreatorDialog" */ "./TimeTrackCreatorDialog"
    ),
);

export const TimeTrackCreatorHost: FC = function () {
  const dispatch = useContext(CommandManagerDispatchContext);
  const state = useContext(CommandManagerStateContext);
  const handleClose = useCallback(() => {
    dispatch({ type: "CLOSE_TIMETRACK_CREATE" });
  }, []);

  const hotkeys = useMemo(
    () => [
      {
        combo: "Shift+Y",
        global: true,
        preventDefault: true,
        label: "Zeiterfassungseintrag erstellen",
        onKeyDown: () =>
          dispatch({
            type: "OPEN_TIMETRACK_CREATE",
          }),
      },
    ],
    [dispatch],
  );
  useHotkeys(hotkeys);

  if (state.open && state.open.type === "TIMETRACK_CREATE") {
    return <TimeTrackCreatorDialog isOpen onClose={handleClose} />;
  }

  return null;
};
