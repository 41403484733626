import { FC } from "react";

export const WorkspaceUserIcon: FC<{
  className?: string;
}> = function ({ className }) {
  return (
    <svg
      viewBox="0 0 640 512"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill="#000000" fillRule="nonzero">
          <path d="M192,256 L192,304 C192,312.84 199.16,320 208,320 L208,320 L304,320 C312.84,320 320,312.84 320,304 L320,304 L320,256 L415.968846,255.999637 C410.834937,268.312812 408,281.824921 408,296 C408,319.609472 415.864389,341.379802 429.115845,358.830944 C389.58413,371.154664 359.894155,405.789633 354.781734,448.000035 L354.781734,448.000035 L48,448 C22.4,448 0,425.6 0,400 L0,400 L0,256 L192,256 Z M512,192 C482.502759,192 455.876334,204.275953 436.950242,223.998342 L0,224 L0,144 C0,118.4 22.4,96 48,96 L48,96 L128,96 L128,48 C128,22.4 150.4,0 176,0 L176,0 L336,0 C361.6,0 384,22.4 384,48 L384,48 L384,96 L464,96 C489.6,96 512,118.4 512,144 L512,144 Z M320,64 L192,64 L192,96 L320,96 L320,64 Z" />
          <g transform="translate(386.000000, 224.000000)">
            <path d="M126,144 C165.76875,144 198,111.76875 198,72 C198,32.23125 165.76875,0 126,0 C86.23125,0 54,32.23125 54,72 C54,111.76875 86.23125,144 126,144 Z M176.4,162 L167.00625,162 C154.51875,167.7375 140.625,171 126,171 C111.375,171 97.5375,167.7375 84.99375,162 L75.6,162 C33.8625,162 0,195.8625 0,237.6 L0,261 C0,275.90625 12.09375,288 27,288 L225,288 C239.90625,288 252,275.90625 252,261 L252,237.6 C252,195.8625 218.1375,162 176.4,162 Z" />
          </g>
        </g>
      </g>
    </svg>
  );
};
