/**
 * @generated SignedSource<<728de131a37dbc84bb1b5c88905a71fa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TimeTrackHistoryRowEmployeeChange_event$data = {
  readonly changedFields: any;
  readonly oldEmployee: {
    readonly displayName: string;
    readonly link: string | null;
  } | null;
  readonly newEmployee: {
    readonly displayName: string;
    readonly link: string | null;
  } | null;
  readonly " $fragmentType": "TimeTrackHistoryRowEmployeeChange_event";
};
export type TimeTrackHistoryRowEmployeeChange_event$key = {
  readonly " $data"?: TimeTrackHistoryRowEmployeeChange_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"TimeTrackHistoryRowEmployeeChange_event">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "displayName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "link",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TimeTrackHistoryRowEmployeeChange_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "changedFields",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Employee",
      "kind": "LinkedField",
      "name": "oldEmployee",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Employee",
      "kind": "LinkedField",
      "name": "newEmployee",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "TimeTrackEvent",
  "abstractKey": null
};
})();

(node as any).hash = "edeb6cacb9854b207eda3317423c8372";

export default node;
