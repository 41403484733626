import { CustomerCategoriesQuery } from "./__generated__/CustomerCategoriesQuery.graphql";
import { Header } from "../../../components/Header";
import { HeaderLoader } from "../../../components/HeaderLoading";
import { OrganizationTabs } from "../OrganizationTabs";
import { STORE_THEN_NETWORK, useQuery } from "relay-hooks";
import loadable from "@loadable/component";
import { FC } from "react";

const CustomerCategoriesOverview = loadable(
  () =>
    import(
      /* webpackChunkName: "CustomerCategoriesOverview" */ "./CustomerCategoriesOverview"
    ),
);

const query = graphql`
  query CustomerCategoriesQuery {
    ...CustomerCategoriesOverview_query
  }
`;

export const CustomerCategories: FC = function CustomerCategories() {
  const {
    data: props,
    error,
    isLoading,
  } = useQuery<CustomerCategoriesQuery>(
    query,
    {},
    { fetchPolicy: STORE_THEN_NETWORK },
  );

  if (error) {
    throw error;
  }

  return (
    <>
      <Header
        title="Kundenkategorien"
        subTitle="Einstellungen"
        children={<OrganizationTabs />}
        isLoading={isLoading}
      />
      {props ? (
        <CustomerCategoriesOverview query={props} fallback={<HeaderLoader />} />
      ) : (
        <HeaderLoader />
      )}
    </>
  );
};
