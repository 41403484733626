import { useState } from "react";
import { Container } from "../../components/Grid";
import { CustomerOverview_query$key } from "./__generated__/CustomerOverview_query.graphql";
import { CustomerRow } from "./CustomerRow";
import { CustomerCreatorButton } from "./create/CustomerCreatorButton";
import { isEdgeAndNodeNotNullWithCursorPredicate } from "@mormahr/portal-utils";
import { Spinner } from "@blueprintjs/core";
import { Toolbar } from "../../components/Toolbar";
import styles from "./CustomerOverview.module.scss";
import classNames from "classnames";
import { Button } from "../../components/button/Button";
import {
  useExpectPermission,
  usePreference,
} from "../../app-controller/ViewerContext";
import { CustomerOverviewAdditionalInfoSelect } from "./CustomerOverviewAdditionalInfoSelect";
import { usePagination } from "relay-hooks";

export type Props = {
  query: CustomerOverview_query$key;
  archived: boolean;
};

const fragment = graphql`
  fragment CustomerOverview_query on Query
  @refetchable(queryName: "CustomerOverviewQuery")
  @argumentDefinitions(
    onlyArchived: { type: "Boolean", defaultValue: false }
    count: { type: "Int", defaultValue: 200 }
    cursor: { type: "String" }
  ) {
    customers(first: $count, after: $cursor, onlyArchived: $onlyArchived)
      @connection(
        key: "CustomerOverview_customers"
        filters: ["onlyArchived"]
      ) {
      edges {
        cursor
        node {
          ...CustomerRow_customer @arguments(disableStatistics: $onlyArchived)
        }
      }

      pageInfo {
        hasNextPage
      }
    }
  }
`;

export function CustomerOverview(props: Props) {
  const { query, archived } = props;
  const {
    data: { customers },
    loadNext,
  } = usePagination(fragment, query);
  const [isLoading, setIsLoading] = useState(false);
  const canViewStatistics = useExpectPermission("canViewStatistics");
  const canEditCustomerBaseData = useExpectPermission(
    "canEditCustomerBaseData",
  );
  const customerOverviewAdditionalInfo = usePreference(
    "customerOverviewAdditionalInfo",
  );

  const customerEdges =
    (customers &&
      customers.edges &&
      customers.edges.filter(isEdgeAndNodeNotNullWithCursorPredicate)) ||
    [];

  return (
    <Container>
      <Toolbar className="justify-between">
        <CustomerCreatorButton />
        {!archived && <CustomerOverviewAdditionalInfoSelect />}
      </Toolbar>
      {!customers ? (
        <p>Kunden konnten nicht geladen werden.</p>
      ) : (
        <>
          <ul className={styles.list}>
            <li className={classNames(styles.item, styles.header)}>
              <span className={styles.reference}>Nr.</span>
              <span className={styles.main}>Name</span>
              {customerOverviewAdditionalInfo === "STATISTICS" &&
                canViewStatistics &&
                !archived && <span className={styles.statistics}>Quartal</span>}
              {(customerOverviewAdditionalInfo === "REQUIRED_CHECKS" ||
                customerOverviewAdditionalInfo === "RECOMMENDED_CHECKS") &&
                canEditCustomerBaseData &&
                !archived && <span className={styles.checks}>Checks</span>}
            </li>
            {customerEdges.map((edge) => (
              <CustomerRow customer={edge.node} key={edge.cursor} />
            ))}
            {isLoading && (
              <li className={classNames(styles.item, styles.footer)}>
                <Spinner size={10} key="loader" />
              </li>
            )}
            {!customers.pageInfo.hasNextPage ? (
              <li
                className={classNames(
                  styles.item,
                  styles.footer,
                  "text-gray-500 dark:text-gray:600",
                )}
              >
                Keine weiteren Kunden.
              </li>
            ) : (
              <Button
                disabled={isLoading}
                onClick={() => {
                  loadNext(100, {
                    onComplete: () => {
                      setIsLoading(false);
                    },
                  });
                  setIsLoading(true);
                }}
              >
                Mehr laden
              </Button>
            )}
          </ul>
        </>
      )}
    </Container>
  );
}
