import NotFoundErrorIllustration from "../components/NotFoundErrorIllustrationLazy";
import { Link } from "react-router-dom";

export function NotFoundRoute() {
  return (
    <div className="flex flex-col items-center m-8 md:mx-auto md:w-144 text-center">
      <div aria-hidden className="md:w-96">
        <NotFoundErrorIllustration />
      </div>
      <h2 className="font-bold">Die Seite konnte nicht gefunden werden.</h2>
      <p className="w-3/4 mx-auto py-2">
        Bitte überprüfe die URL, besuche das{" "}
        <Link to="/" className="underline">
          Dashboard
        </Link>{" "}
        oder benutze das Navigationsmenü.
      </p>
    </div>
  );
}
