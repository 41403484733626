import { FC, useMemo } from "react";
import { Point, smoothed } from "./smooth";
import { MiniGraph_statistics$key } from "./__generated__/MiniGraph_statistics.graphql";
import { useFragment } from "react-relay";
import styles from "./MiniGraph.module.scss";

export interface Props {
  statistics: MiniGraph_statistics$key;
  width: number;
  height: number;

  /** on each side */
  verticalPadding: number;
}

const fragment = graphql`
  fragment MiniGraph_statistics on Statistics {
    weekly(lastWeeks: 13) {
      profit
    }
  }
`;

function pointTemplate(
  literals: TemplateStringsArray,
  ...placeholders: Point[]
): string {
  let result = "";

  // interleave the literals with the placeholders
  for (let i = 0; i < placeholders.length; i++) {
    result += literals[i];
    result +=
      placeholders[i][0].toFixed(3) + "," + placeholders[i][1].toFixed(3);
  }

  // add the last literal
  result += literals[literals.length - 1];
  return result;
}

export const MiniGraph: FC<Props> = function (props) {
  const { weekly } = useFragment(fragment, props.statistics);
  const { width, height, verticalPadding } = props;

  const { strokePath, fillPath, viewBox } = useMemo(() => {
    if (weekly.length < 1) {
      return { path: "", viewBox: "0 0 0 0" };
    }
    const values = weekly.map(({ profit }) => parseFloat(profit));
    const min = values.reduce((a, b) => Math.min(a, b), 0);
    const max = values.reduce((a, b) => Math.max(a, b), 0);
    const offset = min;
    const range = Math.max(max - min, 10);
    const increment = width / (values.length - 1);
    const insetHeight = height - verticalPadding - verticalPadding;
    const points = values.map(
      (profit, i): Point => [
        i * increment,
        ((profit - offset) / range) * insetHeight + verticalPadding,
      ],
    );
    const commands = smoothed(points);
    let strokePath = pointTemplate`M ${[points[0][0], points[0][1]]} `;
    for (const command of commands) {
      strokePath += pointTemplate`C ${command[1]} ${command[2]} ${command[0]} `;
    }

    let fillPath = strokePath;

    fillPath += pointTemplate`L ${[points[points.length - 1][0], 0]}`;
    fillPath += pointTemplate`L ${[width, 0]}`;
    fillPath += pointTemplate`L ${[0, 0]}`;

    const viewBox = [0, 0, width, height].join(" ");

    return { strokePath, fillPath, viewBox };
  }, [weekly, width, height, verticalPadding]);

  return (
    <svg viewBox={viewBox} width={width} height={height} className={styles.svg}>
      <path d={`M 1,0 L 1,${height}`} className={styles.border} />
      <g height={height}>
        <path d={fillPath} className={styles.fill} />
        <path d={strokePath} className={styles.stroke} />
      </g>
    </svg>
  );
};
