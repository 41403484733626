import { memo } from "react";
import { QueryRenderer } from "react-relay";
import { ViewerProvider } from "../app-controller/ViewerContext";
import { AppQuery } from "./__generated__/AppQuery.graphql";
import { CommonDataProvider } from "../components/CommonDataContext";
import { PortalBarHost } from "../components/PortalBarHost";
import { SubscriptionManager } from "../components/SubscriptionManager";
import { Shell } from "./Shell";
import { AppNonIdealState } from "../components/AppNonIdealState";
import { CommandManager } from "../components/CommandManager";
import { TodoCreateQuickDialogHost } from "../components/TodoCreateQuickDialogHost";
import { useRelayEnvironment } from "relay-hooks";
import { TimeTrackCreatorHost } from "../components/TimeTrackCreatorHost";
import { StopwatchGlobals } from "../components/Stopwatch";
import { HeaderLoadingProvider } from "../components/HeaderLoading";
import loadable from "@loadable/component";

const AlertController = loadable(
  () =>
    import(
      /* webpackChunkName: "AlertController" */ "../components/AlertController"
    ),
);

function App() {
  const environment = useRelayEnvironment();
  return (
    <QueryRenderer<AppQuery>
      environment={environment}
      query={graphql`
        query AppQuery {
          viewer {
            ...ViewerContext_viewer
            ...Shell_viewer
          }

          session {
            ...ViewerContext_session
          }

          ...CommonDataContext_query
        }
      `}
      variables={{}}
      render={({ error, props }) => {
        if (error) {
          return <AppNonIdealState />;
        }

        if (!!props && !props.viewer) {
          window.location.pathname = "/login";
        }

        const viewer = props ? props.viewer : null;

        return (
          <SubscriptionManager>
            <ViewerProvider viewer={viewer} session={props?.session ?? null}>
              <CommonDataProvider query={props ?? null}>
                <CommandManager>
                  <PortalBarHost />
                  <TodoCreateQuickDialogHost />
                  <TimeTrackCreatorHost />
                  <AlertController />
                  <StopwatchGlobals />
                  <HeaderLoadingProvider>
                    <Shell viewer={viewer} isLoading={!props} />
                  </HeaderLoadingProvider>
                </CommandManager>
              </CommonDataProvider>
            </ViewerProvider>
          </SubscriptionManager>
        );
      }}
    />
  );
}

const AppMemoized = memo(App);
export default AppMemoized;
