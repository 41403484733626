import loadable from "@loadable/component";
import { FC } from "react";
import type { Props } from "./TimeTrackEditorDialog";
import { HeaderLoader } from "../../../components/HeaderLoading";

const TimeTrackEditorDialog = loadable(
  () =>
    import(
      /* webpackChunkName: "TimeTrackEditorDialog" */ "./TimeTrackEditorDialog"
    ),
);

export const LazyTimeTrackEditorDialog: FC<Props> = function (props) {
  return <TimeTrackEditorDialog {...props} fallback={<HeaderLoader />} />;
};
