import { Icon } from "@mormahr/babel-transform-icon";
import { TextButton } from "../../components/button/TextButton";
import { useService } from "@xstate/react";
import { UpdateService } from "../../system/UpdateService";

export function UpdateInfo() {
  const [state] = useService(UpdateService);

  if (state.value === "updating") {
    return <div>Anwendung wird aktualisiert...</div>;
  }

  if (state.value === "updated") {
    return (
      <div>
        Update installiert. Bitte neu laden.{" "}
        <TextButton title="Neu laden" onClick={() => window.location.reload()}>
          <Icon icon="rotate" type="regular" />
        </TextButton>
      </div>
    );
  }

  return null;
}
