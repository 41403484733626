import { STORE_THEN_NETWORK, useQuery } from "relay-hooks";
import { ItemsOverviewQuery } from "./__generated__/ItemsOverviewQuery.graphql";
import { Header } from "../../components/Header";
import { OrganizationTabs } from "../organization/OrganizationTabs";
import loadable from "@loadable/component";
import { FC } from "react";
import { HeaderLoader } from "../../components/HeaderLoading";

const ItemsList = loadable(
  () => import(/* webpackChunkName: "ItemsList" */ "./ItemsList"),
);

const query = graphql`
  query ItemsOverviewQuery {
    ...ItemsList_props
  }
`;

export const ItemsOverview: FC = function () {
  const {
    error,
    data: props,
    isLoading,
  } = useQuery<ItemsOverviewQuery>(
    query,
    {},
    { fetchPolicy: STORE_THEN_NETWORK },
  );

  if (error) {
    throw error;
  }

  return (
    <>
      <Header
        title="Artikel"
        subTitle="Übersicht"
        children={<OrganizationTabs />}
        isLoading={isLoading}
      />
      <ItemsList props={props ?? null} fallback={<HeaderLoader />} />
    </>
  );
};
