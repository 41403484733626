import { FC, useCallback, useState } from "react";
import { Icon } from "@mormahr/babel-transform-icon";
import { Button } from "../../../components/button/Button";
import { CustomerCreator } from "./CustomerCreator";

export const CustomerCreatorButton: FC = function () {
  const [open, setOpen] = useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <>
      <Button
        type="button"
        onClick={() => setOpen(true)}
        icon={<Icon icon="plus" type="regular" />}
      >
        Neuer Kunde
      </Button>
      <CustomerCreator isOpen={open} onClose={handleClose} />
    </>
  );
};
