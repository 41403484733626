import { FC, ReactNode, useMemo } from "react";
import { Select } from "@blueprintjs/select";
import { useQuery } from "relay-hooks";
import { ItemSelectQuery } from "./__generated__/ItemSelectQuery.graphql";
import { normalizeSearchTerm } from "@mormahr/portal-utils";
import { MenuItem } from "@blueprintjs/core";
import { Button } from "./button/Button";
import { Icon as BPIcon } from "@blueprintjs/core/lib/esm/components/icon/icon";

graphql`
  fragment ItemSelect_item on Item {
    id
    title
    itemNumber
  }
`;

const query = graphql`
  query ItemSelectQuery(
    $onlyUsableForWork: Boolean!
    $onlyUsableForJourney: Boolean!
    $onlyUsableForExtraBilling: Boolean!
    $onlyUsableForManual: Boolean!
  ) {
    items(
      onlyUsableForWork: $onlyUsableForWork
      onlyUsableForJourney: $onlyUsableForJourney
      onlyUsableForExtraBilling: $onlyUsableForExtraBilling
      onlyUsableForManual: $onlyUsableForManual
    ) {
      edges {
        node {
          ...ItemSelect_item @relay(mask: false)
        }
      }
    }
  }
`;

export enum ItemUsableCategory {
  work = "work",
  journey = "journey",
  extraBilling = "extraBilling",
  manual = "manual",
}

export type Item = {
  id: string;
  title: string;
  itemNumber: string;
};

export interface ItemSelectProps {
  /**
   * Currently selected item.
   */
  item: Item | null;

  /**
   * Exclude these IDs.
   */
  exclude?: string[];

  /**
   * Only items for a certain category will be shown.
   */
  usableCategory: ItemUsableCategory;

  /**
   * Change / select handler.
   */
  onChange: (item: Item) => void;

  disabled?: boolean;

  nullDisplay?: ReactNode;
}

const ItemSelectPrimitive = Select.ofType<Item>();

const collator = new Intl.Collator("de", {
  numeric: true,
  sensitivity: "base",
});

export const ItemSelect: FC<ItemSelectProps> = function ({
  item,
  exclude,
  usableCategory,
  onChange,
  disabled,
  nullDisplay,
}) {
  const variables = useMemo(
    () => ({
      onlyUsableForWork: usableCategory === ItemUsableCategory.work,
      onlyUsableForJourney: usableCategory === ItemUsableCategory.journey,
      onlyUsableForExtraBilling:
        usableCategory === ItemUsableCategory.extraBilling,
      onlyUsableForManual: usableCategory === ItemUsableCategory.manual,
    }),
    [usableCategory],
  );

  const { data } = useQuery<ItemSelectQuery>(query, variables);
  const items = useMemo(() => {
    if (!data) {
      return [];
    }

    const exclusionSet = new Set<string>(exclude);

    const _items = data
      .items!.edges!.map((edge) => edge!.node!)
      .filter((node) => !exclusionSet.has(node.id));
    _items.sort((a, b) => collator.compare(a.itemNumber, b.itemNumber));
    return _items;
  }, [data, exclude]);

  return (
    <ItemSelectPrimitive
      itemsEqual={(a, b) => a.id === b.id}
      items={items}
      itemPredicate={(query, item) => {
        const normalizedQuery = normalizeSearchTerm(query);

        return (
          normalizeSearchTerm(item.title).includes(normalizedQuery) ||
          item.itemNumber.startsWith(query)
        );
      }}
      itemRenderer={(currentItem, { handleClick, modifiers }) => {
        if (!modifiers.matchesPredicate) {
          return null;
        }

        return (
          <MenuItem
            active={modifiers.active}
            key={currentItem.id}
            onClick={handleClick}
            text={`${currentItem.title} (${currentItem.itemNumber})`}
            label={item && item.id === currentItem.id ? "Ausgewählt" : ""}
          />
        );
      }}
      onItemSelect={(item) => {
        onChange(item);
      }}
      disabled={disabled}
    >
      {item ? (
        <Button type="button" rightIcon={<BPIcon icon="caret-down" />}>
          {item.title} ({item.itemNumber})
        </Button>
      ) : (
        <Button type="button" rightIcon={<BPIcon icon="caret-down" />}>
          {nullDisplay ?? "Artikel auswählen"}
        </Button>
      )}
    </ItemSelectPrimitive>
  );
};
