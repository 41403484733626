/**
 * @generated SignedSource<<01138050a6144f7c280b8441afa3da91>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TimeTrackHistoryRowCustomerChange_event$data = {
  readonly changedFields: any;
  readonly oldCustomer: {
    readonly displayName: string;
    readonly link: string;
  } | null;
  readonly newCustomer: {
    readonly displayName: string;
    readonly link: string;
  } | null;
  readonly " $fragmentType": "TimeTrackHistoryRowCustomerChange_event";
};
export type TimeTrackHistoryRowCustomerChange_event$key = {
  readonly " $data"?: TimeTrackHistoryRowCustomerChange_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"TimeTrackHistoryRowCustomerChange_event">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "displayName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "link",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TimeTrackHistoryRowCustomerChange_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "changedFields",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Customer",
      "kind": "LinkedField",
      "name": "oldCustomer",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Customer",
      "kind": "LinkedField",
      "name": "newCustomer",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "TimeTrackEvent",
  "abstractKey": null
};
})();

(node as any).hash = "cd982d7473c58ef70155224be0ee80f5";

export default node;
