import { Redirect, useParams } from "react-router-dom";
import { CustomerBillingsQuery } from "./__generated__/CustomerBillingsQuery.graphql";
import { CustomerNavbarLoader } from "../CustomerNavbarLoader";
import { HeaderLoader } from "../../../components/HeaderLoading";
import { STORE_THEN_NETWORK, useQuery } from "relay-hooks";
import { useExpectPermission } from "../../../app-controller/ViewerContext";
import loadable from "@loadable/component";
import { FC } from "react";

const CustomerBillingsOverview = loadable(
  () =>
    import(
      /* webpackChunkName: "CustomerBillingsOverview" */ "../../../components/billings/CustomerBillingsOverview"
    ),
);

const queryDocument = graphql`
  query CustomerBillingsQuery($customerId: ID!, $onlyCustomer: [ID!]!) {
    node(id: $customerId) {
      __typename

      ... on Customer {
        ...CustomerBillingsOverview_customer
      }
    }

    ...CustomerBillingsOverview_query @arguments(onlyCustomer: $onlyCustomer)
  }
`;

export const CustomerBillings: FC = function CustomerBillings() {
  const canViewCustomerBillings = useExpectPermission(
    "canViewCustomerBillings",
    true,
  );
  const { customerId } = useParams<{ customerId: string }>();
  const {
    data: props,
    error,
    isLoading,
  } = useQuery<CustomerBillingsQuery>(
    queryDocument,
    {
      customerId,
      onlyCustomer: [customerId],
    },
    { fetchPolicy: STORE_THEN_NETWORK },
  );

  if (!canViewCustomerBillings) {
    return <Redirect to="/" />;
  }

  if (error) {
    throw error;
  }

  if (props && (!props.node || props.node.__typename !== "Customer")) {
    throw new Error();
  }

  const node =
    props && props.node && props.node.__typename === "Customer"
      ? props.node
      : null;

  return (
    <>
      <CustomerNavbarLoader
        id={customerId}
        subTitle="Abrechnungen"
        isLoading={isLoading}
      />
      <CustomerBillingsOverview
        customer={node}
        query={props ?? null}
        fallback={<HeaderLoader />}
      />
    </>
  );
};
