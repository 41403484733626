import * as React from "react";
import { NavLink } from "react-router-dom";
import classnames from "classnames";
import styles from "./Navigation.module.scss";

export type Props = {
  icon: React.ReactNode;
  label: string;
  link: string;
  exact?: boolean;
  disabled?: Optional<boolean>;
  onClick?: ((event: React.MouseEvent<HTMLAnchorElement>) => void) | undefined;

  /**
   * Display a red badge in the upper right corner,
   * similar to how they are displayed on smartphones.
   *
   * The content is placed in a container and doesn't have to wrapped.
   */
  badge?: React.ReactNode | null | undefined;
};

export function NavigationItem(props: Props) {
  const { icon, label, link, disabled, onClick, badge } = props;
  return (
    <li>
      <NavLink
        to={link}
        exact={props.exact ?? true}
        className={classnames(styles.item, {
          [styles.disabled]: disabled,
        })}
        activeClassName={styles.active}
        onClick={disabled ? (e) => e.preventDefault() : onClick}
      >
        {badge && (
          <div className={styles.badge}>
            <span>{badge}</span>
          </div>
        )}
        <div className={styles.icon}>{icon}</div>
        <div className={styles.label}>{label}</div>
      </NavLink>
    </li>
  );
}
