/**
 * @generated SignedSource<<68f14b9293a159fb5d99e9ad53754bc7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Statistics_statistics$data = {
  readonly today: string;
  readonly monthly: ReadonlyArray<{
    readonly profit: string;
    readonly monthStart: string;
  }>;
  readonly " $fragmentType": "Statistics_statistics";
};
export type Statistics_statistics$key = {
  readonly " $data"?: Statistics_statistics$data;
  readonly " $fragmentSpreads": FragmentRefs<"Statistics_statistics">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Statistics_statistics",
  "selections": [
    {
      "alias": "today",
      "args": [
        {
          "kind": "Literal",
          "name": "lastDays",
          "value": 0
        }
      ],
      "kind": "ScalarField",
      "name": "sumFor",
      "storageKey": "sumFor(lastDays:0)"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "lastMonths",
          "value": 24
        }
      ],
      "concreteType": "MonthlyStatistics",
      "kind": "LinkedField",
      "name": "monthly",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "profit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "monthStart",
          "storageKey": null
        }
      ],
      "storageKey": "monthly(lastMonths:24)"
    }
  ],
  "type": "Statistics",
  "abstractKey": null
};

(node as any).hash = "c721c58397a9624092b5b48b7a26947e";

export default node;
