import { CustomerDetail } from "./CustomerDetail";
import { CustomerQuery } from "./__generated__/CustomerQuery.graphql";
import { RouteComponentProps } from "react-router";
import { STORE_THEN_NETWORK, useQuery } from "relay-hooks";
import { FC } from "react";

const query = graphql`
  query CustomerQuery($id: ID!, $onlyCustomer: [ID!]!) {
    node(id: $id) {
      ...CustomerDetail_customer
    }

    ...CustomerDetail_query @arguments(onlyCustomer: $onlyCustomer)
  }
`;

export const Customer: FC<
  RouteComponentProps<{ id: string }>
> = function Customer({
  match: {
    params: { id },
  },
}) {
  const { data: props, error, isLoading } = useQuery<CustomerQuery>(
    query,
    {
      id: id,
      onlyCustomer: [id],
    },
    { fetchPolicy: STORE_THEN_NETWORK },
  );

  if (error) {
    throw error;
  }

  return (
    <CustomerDetail
      id={id}
      isLoading={isLoading}
      customer={props?.node ?? null}
      query={props ?? null}
    />
  );
};
