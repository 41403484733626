/** x, y */
export type Point = [number, number];
/** Destination, CP1, CP2 */
export type DrawCommand = [Point, Point, Point];

// Properties of a line
function line(pointA: Point, pointB: Point) {
  const lengthX = pointB[0] - pointA[0];
  const lengthY = pointB[1] - pointA[1];
  return {
    length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
    angle: Math.atan2(lengthY, lengthX),
  };
}

// Position of a control point
// I:  - current (array) [x, y]: current point coordinates
//     - previous (array) [x, y]: previous point coordinates
//     - next (array) [x, y]: next point coordinates
//     - reverse (boolean, optional): sets the direction
// O:  - (array) [x,y]: a tuple of coordinates
function controlPoint(
  current: Point,
  previous: Point | null | undefined,
  next: Point | null | undefined,
  reverse: boolean,
): Point {
  // When 'current' is the first or last point of the array
  // 'previous' or 'next' don't exist.
  // Replace with 'current'
  const p = previous || current;
  const n = next || current;
  // The smoothing ratio
  const smoothing = 0.2;
  // Properties of the opposed-line
  const o = line(p, n);
  // If is end-control-point, add PI to the angle to go backward
  const angle = o.angle + (reverse ? Math.PI : 0);
  const length = o.length * smoothing;
  // The control point position is relative to the current point
  const x = current[0] + Math.cos(angle) * length;
  const y = current[1] + Math.sin(angle) * length;
  return [x, y];
}

export function smoothed(array: Point[]): DrawCommand[] {
  const result: DrawCommand[] = [];
  if (array.length === 0) {
    return result;
  }

  for (let i = 1; i < array.length; i++) {
    result.push([
      array[i],
      controlPoint(array[i - 1], array[i - 2], array[i], false),
      controlPoint(array[i], array[i - 1], array[i + 1], true),
    ]);
  }

  return result;
}
