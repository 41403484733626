/**
 * @generated SignedSource<<a38206a3d61b3166f03a2f385d3edd18>>
 * @relayHash c898264acbe4cf68aaf5fcd45eca9dff
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 9d407f8a1824303d10dc881b9a3edb4135c80cb3

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type createTokenInput = {
  userId?: string | null;
  clientId: string;
  expires?: string | null;
  allowExisting?: boolean | null;
  clientMutationId?: string | null;
};
export type CreateTokenMutation$variables = {
  input: createTokenInput;
};
export type CreateTokenMutation$data = {
  readonly createToken: {
    readonly token: string;
  } | null;
};
export type CreateTokenMutation = {
  variables: CreateTokenMutation$variables;
  response: CreateTokenMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "createTokenPayload",
    "kind": "LinkedField",
    "name": "createToken",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateTokenMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateTokenMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "9d407f8a1824303d10dc881b9a3edb4135c80cb3",
    "metadata": {},
    "name": "CreateTokenMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "c2652159d86282979ce2ba07262a4c29";

export default node;
