/**
 * @generated SignedSource<<051679986589b8c1472ff508f17284b2>>
 * @relayHash ba68b7359215751f7d8f1004115f78be
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID d44f01ceba2f49f1e9c48b6a1dc6fb4f2af9a3c7

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CustomersRouteQuery$variables = {
  onlyArchived: boolean;
};
export type CustomersRouteQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CustomerOverview_query">;
};
export type CustomersRouteQuery = {
  variables: CustomersRouteQuery$variables;
  response: CustomersRouteQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "onlyArchived"
  }
],
v1 = {
  "kind": "Variable",
  "name": "onlyArchived",
  "variableName": "onlyArchived"
},
v2 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 200
  },
  (v1/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomersRouteQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "CustomerOverview_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CustomersRouteQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CustomerConnection",
        "kind": "LinkedField",
        "name": "customers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomerEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Customer",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "link",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "archived",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "reference",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "missingRequiredCheckFields",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "missingRecommendedCheckFields",
                    "storageKey": null
                  },
                  {
                    "condition": "onlyArchived",
                    "kind": "Condition",
                    "passingValue": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Statistics",
                        "kind": "LinkedField",
                        "name": "statistics",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "lastWeeks",
                                "value": 13
                              }
                            ],
                            "concreteType": "WeeklyStatistics",
                            "kind": "LinkedField",
                            "name": "weekly",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "profit",
                                "storageKey": null
                              }
                            ],
                            "storageKey": "weekly(lastWeeks:13)"
                          },
                          {
                            "alias": "lastYear",
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "lastDays",
                                "value": 365
                              }
                            ],
                            "kind": "ScalarField",
                            "name": "sumFor",
                            "storageKey": "sumFor(lastDays:365)"
                          }
                        ],
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "filters": [
          "onlyArchived"
        ],
        "handle": "connection",
        "key": "CustomerOverview_customers",
        "kind": "LinkedHandle",
        "name": "customers"
      }
    ]
  },
  "params": {
    "id": "d44f01ceba2f49f1e9c48b6a1dc6fb4f2af9a3c7",
    "metadata": {},
    "name": "CustomersRouteQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "5c756697ff45d361c6ac254cd972418d";

export default node;
