/**
 * @generated SignedSource<<85f87e7220c02ae131b2d1fb7340a31c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TodoListData_todos$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"TodoListEntry_todo">;
    } | null;
  } | null> | null;
  readonly " $fragmentType": "TodoListData_todos";
};
export type TodoListData_todos$key = {
  readonly " $data"?: TodoListData_todos$data;
  readonly " $fragmentSpreads": FragmentRefs<"TodoListData_todos">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TodoListData_todos",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TodoEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Todo",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "TodoListEntry_todo"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TodoConnection",
  "abstractKey": null
};

(node as any).hash = "be669e2f8f505efbb6ee6d8e1e230602";

export default node;
