/**
 * @generated SignedSource<<23f92289e3fb2f81da535c67d9b7c18f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TimeTrackHistoryRowTitleChange_event$data = {
  readonly changedFields: any;
  readonly " $fragmentType": "TimeTrackHistoryRowTitleChange_event";
};
export type TimeTrackHistoryRowTitleChange_event$key = {
  readonly " $data"?: TimeTrackHistoryRowTitleChange_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"TimeTrackHistoryRowTitleChange_event">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TimeTrackHistoryRowTitleChange_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "changedFields",
      "storageKey": null
    }
  ],
  "type": "TimeTrackEvent",
  "abstractKey": null
};

(node as any).hash = "359d62a62f538236de5db8b11597f292";

export default node;
