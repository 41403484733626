import { MouseEvent, ReactNode } from "react";
import { Intent } from "../../util/Intent";
import classNames from "classnames";
import styles from "./Button.module.scss";
import { Link } from "react-router-dom";

export interface BaseButtonProps {
  /**
   * Main content / text of the button. Prefer the {@link icon} or {@link rightIcon} props for
   * icons.
   */
  children?: ReactNode;

  /**
   * Additional css classes applied to the <botton>. Should only be used in rare cases.
   */
  className?: string;

  /**
   * Icon on left side of main content
   */
  icon?: ReactNode;

  /**
   * Icon on right side of main content
   */
  rightIcon?: ReactNode;

  /**
   * Click handler
   * @param event
   */
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;

  /**
   * By default the button is rendered with a neutral style, but it can can be customized using
   * intents. The primary intent should be used sparingly.
   */
  intent?: Intent;

  /**
   * Apply sidebar modifications.
   */
  sidebar?: boolean | undefined;

  /**
   * Show a loading indicator overlay.
   */
  loading?: boolean;

  /**
   * Disables interactions.
   */
  disabled?: boolean;

  /**
   * HTML title attribute
   */
  title?: string;

  /**
   * Transparent background until hovered.
   */
  minimal?: boolean;

  /**
   * Smaller style.
   */
  small?: boolean;

  /**
   * Testing selector
   */
  "data-cy"?: string;
}

export interface ButtonProps extends BaseButtonProps {
  type?: "submit" | "reset" | "button";
  name?: string;
}

export interface LinkButtonProps extends BaseButtonProps {
  href: string;
}

export const intentMap: { [key in Intent]: string } = {
  [Intent.NONE]:
    "from-gray-50 to-gray-100 border-gray-300 text-black dark:from-gray-800 dark:to-gray-900 dark:border-gray-900 dark:text-white",
  [Intent.PRIMARY]:
    "from-primary-600 to-primary-700 border-primary-800 text-white",
  [Intent.SUCCESS]: "from-green-600 to-green-700 border-green-800 text-white",
  [Intent.WARNING]:
    "from-yellow-600 to-yellow-700 border-yellow-800 text-white",
  [Intent.DANGER]: "from-red-600 to-red-700 border-red-800 text-white",
};

export const disabledIntentMap: { [key in Intent]: string } = {
  [Intent.NONE]:
    "bg-gray-200 border-gray-300 text-gray-400 dark:bg-gray-700 dark:border-gray-800 dark:text-gray-300",
  [Intent.PRIMARY]: "bg-primary-500 border-primary-600 text-primary-200",
  [Intent.SUCCESS]: "bg-green-500 border-green-600 text-green-200",
  [Intent.WARNING]: "bg-yellow-500 border-yellow-600 text-yellow-200",
  [Intent.DANGER]: "bg-red-500 border-red-600 text-red-200",
};

export const intentInteractionMap: { [key in Intent]: string } = {
  [Intent.NONE]:
    "hover:from-gray-100 hover:to-gray-200 active:bg-none active:bg-gray-300 active:text-gray-800 dark:hover:text-white dark:hover:from-gray-700 dark:hover:to-gray-800 dark:active:bg-none dark:active:bg-gray-700 dark:active:text-gray-200",
  [Intent.PRIMARY]:
    "hover:from-primary-700 hover:to-primary-800 active:bg-none active:bg-primary-800 active:text-gray-200",
  [Intent.SUCCESS]:
    "hover:from-green-700 hover:to-green-800 active:bg-none active:bg-green-800 active:text-gray-200",
  [Intent.WARNING]:
    "hover:from-yellow-700 hover:to-yellow-800 active:bg-none active:bg-yellow-800 active:text-gray-200",
  [Intent.DANGER]:
    "hover:from-red-700 hover:to-red-800 active:bg-none active:bg-red-800 active:text-gray-200",
};

export const minimalIntentMap: { [key in Intent]: string } = {
  [Intent.NONE]: "text-black dark:text-white",
  [Intent.PRIMARY]: "text-primary-700",
  [Intent.SUCCESS]: "text-green-700",
  [Intent.WARNING]: "text-yellow-700",
  [Intent.DANGER]: "text-red-700",
};

export const disabledMinimalIntentMap: { [key in Intent]: string } = {
  [Intent.NONE]: "text-gray-400 dark:text-gray-400",
  [Intent.PRIMARY]: "text-primary-400",
  [Intent.SUCCESS]: "text-green-400",
  [Intent.WARNING]: "text-yellow-400",
  [Intent.DANGER]: "text-red-400",
};

export const minimalIntentInteractionMap: { [key in Intent]: string } = {
  [Intent.NONE]:
    "hover:bg-gray-200 active:bg-gray-300 active:text-gray-800 dark:hover:text-white dark:hover:bg-gray-800 dark:active:bg-gray-700 dark:active:text-gray-200",
  [Intent.PRIMARY]:
    "hover:bg-primary-100 hover:text-primary-800 active:bg-primary-200 active:text-primary-900",
  [Intent.SUCCESS]:
    "hover:bg-green-100 hover:text-green-800 active:bg-green-200 active:text-green-900",
  [Intent.WARNING]:
    "hover:bg-yellow-100 hover:text-yellow-800 active:bg-yellow-200 active:text-yellow-900",
  [Intent.DANGER]:
    "hover:bg-red-100 hover:text-red-800 active:bg-red-200 active:text-red-900",
};

export const sidebarIntentMap: { [key in Intent]: string } = {
  [Intent.NONE]: "from-gray-800 to-gray-900 border-gray-900 text-white",
  [Intent.PRIMARY]:
    "from-primary-600 to-primary-700 border-primary-800 text-white",
  [Intent.SUCCESS]: "from-green-600 to-green-700 border-green-800 text-white",
  [Intent.WARNING]:
    "from-yellow-600 to-yellow-700 border-yellow-800 text-white",
  [Intent.DANGER]: "from-red-600 to-red-700 border-red-800 text-white",
};

export const sidebarDisabledIntentMap: { [key in Intent]: string } = {
  [Intent.NONE]: "bg-gray-700 border-gray-800 text-gray-300",
  [Intent.PRIMARY]: "bg-primary-500 border-primary-600 text-primary-200",
  [Intent.SUCCESS]: "bg-green-500 border-green-600 text-green-200",
  [Intent.WARNING]: "bg-yellow-500 border-yellow-600 text-yellow-200",
  [Intent.DANGER]: "bg-red-500 border-red-600 text-red-200",
};

export const sidebarIntentInteractionMap: { [key in Intent]: string } = {
  [Intent.NONE]:
    "hover:from-gray-700 hover:to-gray-800 active:bg-none active:bg-gray-700 active:text-gray-200",
  [Intent.PRIMARY]:
    "hover:from-primary-700 hover:to-primary-800 active:bg-none active:bg-primary-800 active:text-gray-200",
  [Intent.SUCCESS]:
    "hover:from-green-700 hover:to-green-800 active:bg-none active:bg-green-800 active:text-gray-200",
  [Intent.WARNING]:
    "hover:from-yellow-700 hover:to-yellow-800 active:bg-none active:bg-yellow-800 active:text-gray-200",
  [Intent.DANGER]:
    "hover:from-red-700 hover:to-red-800 active:bg-none active:bg-red-800 active:text-gray-200",
};

export const sidebarMinimalIntentMap: { [key in Intent]: string } = {
  [Intent.NONE]: "text-white",
  [Intent.PRIMARY]: "text-primary-700",
  [Intent.SUCCESS]: "text-green-700",
  [Intent.WARNING]: "text-yellow-700",
  [Intent.DANGER]: "text-red-700",
};

export const sidebarDisabledMinimalIntentMap: { [key in Intent]: string } = {
  [Intent.NONE]: "text-gray-400",
  [Intent.PRIMARY]: "text-primary-400",
  [Intent.SUCCESS]: "text-green-400",
  [Intent.WARNING]: "text-yellow-400",
  [Intent.DANGER]: "text-red-400",
};

export const sidebarMinimalIntentInteractionMap: { [key in Intent]: string } = {
  [Intent.NONE]:
    "hover:text-white hover:bg-gray-800 active:bg-gray-700 active:text-gray-200",
  [Intent.PRIMARY]:
    "hover:bg-primary-100 hover:text-primary-800 active:bg-primary-200 active:text-primary-900",
  [Intent.SUCCESS]:
    "hover:bg-green-100 hover:text-green-800 active:bg-green-200 active:text-green-900",
  [Intent.WARNING]:
    "hover:bg-yellow-100 hover:text-yellow-800 active:bg-yellow-200 active:text-yellow-900",
  [Intent.DANGER]:
    "hover:bg-red-100 hover:text-red-800 active:bg-red-200 active:text-red-900",
};

function wrapWithSpanIfValue(
  value: ReactNode | null | undefined,
): ReactNode | null {
  return value ? <span>{value}</span> : null;
}

export function Button(props: ButtonProps | LinkButtonProps) {
  const intent = props.intent ?? Intent.NONE;
  const minimal = !!props.minimal;
  const sidebar = !!props.sidebar;

  const className = classNames(
    // can be used to disable global styling, for example in Sidebar.module.scss
    "p-button font-normal",
    props.disabled
      ? minimal
        ? sidebar
          ? sidebarDisabledMinimalIntentMap[intent]
          : disabledMinimalIntentMap[intent]
        : sidebar
        ? sidebarDisabledIntentMap[intent]
        : disabledIntentMap[intent]
      : minimal
      ? sidebar
        ? sidebarMinimalIntentMap[intent]
        : minimalIntentMap[intent]
      : sidebar
      ? sidebarIntentMap[intent]
      : intentMap[intent],
    !props.disabled &&
      (minimal
        ? sidebar
          ? sidebarMinimalIntentInteractionMap[intent]
          : minimalIntentInteractionMap[intent]
        : sidebar
        ? sidebarIntentInteractionMap[intent]
        : intentInteractionMap[intent]),
    !minimal && !props.disabled && "bg-gradient-to-b",
    !minimal && "border shadow-sm",
    props.small ? "px-1.5 py-1" : "px-3 py-2",
    "rounded relative text-center leading-extra-tight hover:no-underline",
    !props.disabled && "active:shadow-inner",
    props.disabled && "cursor-default",
    props.className,
  );

  const content = (
    <>
      {props.loading && (
        <span className="absolute left-0 right-0 top-0 bottom-0 inline-flex items-center justify-center">
          <svg
            viewBox="0 0 100 100"
            className={classNames(styles.loading, "h-4")}
          >
            <circle
              cx="50"
              cy="50"
              r="47"
              fill="none"
              stroke="currentColor"
              strokeWidth="6"
              strokeDasharray="50 200"
            />
          </svg>
        </span>
      )}
      <span
        className={classNames(
          props.loading && "opacity-0",
          "inline-flex space-x-2",
        )}
      >
        {wrapWithSpanIfValue(props.icon)}
        {wrapWithSpanIfValue(props.children)}
        {wrapWithSpanIfValue(props.rightIcon)}
      </span>
    </>
  );

  if (
    props.disabled ||
    !("href" in props) ||
    props.href === null ||
    props.href === undefined
  ) {
    // This handles buttons *and disabled links*

    return (
      <button
        onClick={props.onClick}
        className={className}
        disabled={props.disabled}
        title={props.title}
        // @ts-expect-error: complains because it doesn't exist on links, but we don't care
        type={props.type}
        // @ts-expect-error: complains because it doesn't exist on links, but we don't care
        name={props.name}
        data-cy={props["data-cy"]}
      >
        {content}
      </button>
    );
  } else {
    return (
      <Link
        to={props.href}
        component={({ navigate: _, ...props }: any) => (
          <a
            href={props.href}
            className={className}
            title={props.title}
            data-cy={props["data-cy"]}
            {...props}
          >
            {content}
          </a>
        )}
      />
    );
  }
}
