import { FC } from "react";
import { FormGroup, HTMLSelect } from "@blueprintjs/core";
import {
  useExpectPermission,
  usePreference,
} from "../../app-controller/ViewerContext";
import { IEnvironment } from "relay-runtime";
import { PreferenceValue } from "@mormahr/portal-utils";
import { doMutation } from "../../mutation/doMutation";
import { CustomerOverviewAdditionalInfoSelectMutation } from "./__generated__/CustomerOverviewAdditionalInfoSelectMutation.graphql";
import { useRelayEnvironment } from "relay-hooks";
import styles from "./CustomerOverviewAdditionalInfoSelect.module.scss";

const mutation = graphql`
  mutation CustomerOverviewAdditionalInfoSelectMutation(
    $input: setUserCustomerOverviewAdditionalInfoPreferenceInput!
  ) {
    setUserCustomerOverviewAdditionalInfoPreference(input: $input) {
      user {
        customerOverviewAdditionalInfoPreference
      }
    }
  }
`;

function setCustomerOverviewAdditionalInfo(
  environment: IEnvironment,
  value: PreferenceValue<"customerOverviewAdditionalInfo">,
) {
  doMutation<CustomerOverviewAdditionalInfoSelectMutation>({
    environment,
    mutation,
    variables: {
      input: { customerOverviewAdditionalInfoPreference: value },
    },
  });
}

export const CustomerOverviewAdditionalInfoSelect: FC = function () {
  const environment = useRelayEnvironment();
  const customerOverviewAdditionalInfo = usePreference(
    "customerOverviewAdditionalInfo",
  );
  const canViewStatistics = useExpectPermission("canViewStatistics");
  const canEditCustomerBaseData = useExpectPermission(
    "canEditCustomerBaseData",
  );

  if (!canViewStatistics && !canEditCustomerBaseData) {
    return null;
  }

  return (
    <FormGroup label="Zusatzinfo" inline className={styles.group}>
      <HTMLSelect
        value={customerOverviewAdditionalInfo}
        onChange={(value) =>
          setCustomerOverviewAdditionalInfo(
            environment,
            value.currentTarget
              .value as PreferenceValue<"customerOverviewAdditionalInfo">,
          )
        }
      >
        <option value="NONE">Keine</option>
        {canViewStatistics && <option value="STATISTICS">Statistiken</option>}
        {canEditCustomerBaseData && (
          <>
            <option value="REQUIRED_CHECKS">Checks</option>
            <option value="RECOMMENDED_CHECKS">Checks (+ Empfohlene)</option>
          </>
        )}
      </HTMLSelect>
    </FormGroup>
  );
};
