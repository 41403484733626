import {
  FC,
  MouseEventHandler,
  ReactChild,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { Select } from "@blueprintjs/select";
import { MenuItem, Spinner } from "@blueprintjs/core";
import { CommonDataContext } from "./CommonDataContext";
import { normalizeSearchTerm } from "@mormahr/portal-utils";
import { CustomerCreator } from "../app/customer/create/CustomerCreator";

export const NoCustomerString = "(Kein Kunde)";

export const CustomerGraphQLT = graphql`
  fragment CustomerChooser_customer on Customer {
    id
    displayName
    printName
    reference
  }
`;

export type ChangeCallbackType = (
  value: { id: string; displayName: string } | null,
) => void;

export interface Customer {
  readonly id: string;
  readonly displayName: string;
  readonly printName: string;
  readonly reference: string | null;
}

export interface BaseProps {
  customer: Customer | null;
  changeCallback: ChangeCallbackType;
  /**
   * Disables the option for "no customer" / returning null.
   */
  disableNoSelection?: boolean;
  disabled?: boolean;
}

export interface ButtonProps {
  onClick: () => void;
}

export interface ChooserProps extends BaseProps {
  renderButton: (props: ButtonProps) => ReactChild;
}

export interface InteractiveProps extends BaseProps {
  children: ReactChild;
  onChangeState: (open: boolean) => void;
  isOpen: boolean;
}

/**
 * `"null"` (as a string) is used because the `Select` Component treats `null`
 * in a special way and we need a null value to indicate that no customer has
 * been selected.
 */
const CustomerSelect = Select.ofType<
  Customer | { type: "null" } | { type: "create-new"; name: string }
>();

export function CustomerChooser({ renderButton, ...rest }: ChooserProps) {
  const [isOpen, setIsOpen] = useState(false);

  // Keep the select mounted
  const [hasBeenOpen, setHasBeenOpen] = useState(false);

  if (isOpen || hasBeenOpen) {
    return (
      <CustomerChooserInteractive
        {...rest}
        onChangeState={setIsOpen}
        isOpen={isOpen}
      >
        {renderButton({ onClick: () => setIsOpen((state) => !state) })}
      </CustomerChooserInteractive>
    );
  }

  return (
    <>
      {renderButton({
        onClick: () => {
          setIsOpen(true);
          setHasBeenOpen(true);
        },
      })}
    </>
  );
}

export const CustomerChooserInteractive: FC<InteractiveProps> = function ({
  customer,
  changeCallback,
  disableNoSelection,
  disabled,
  children,
  onChangeState,
  isOpen,
}) {
  const onChange = useCallback(
    (option: Customer | null) => changeCallback(option),
    [changeCallback],
  );

  const { customers, hasLoaded } = useContext(CommonDataContext);
  const customerList: (Customer | { type: "null" })[] = useMemo(
    () =>
      disableNoSelection
        ? customers ?? []
        : [{ type: "null" }, ...(customers ?? [])],
    [disableNoSelection, customers],
  );
  const [creatingCustomer, setCreatingCustomer] = useState<
    false | { name: string }
  >(false);

  return (
    <>
      {creatingCustomer && (
        <CustomerCreator
          isOpen
          suggestedName={creatingCustomer.name}
          onClose={() => setCreatingCustomer(false)}
          onCreated={(customer) => {
            onChange(customer);
            setCreatingCustomer(false);
            onChangeState(false);
          }}
        />
      )}
      <CustomerSelect
        popoverProps={{ isOpen, onClose: () => onChangeState(false) }}
        noResults={hasLoaded ? null : <Spinner size={20} />}
        itemsEqual={(a, b) =>
          ("type" in a &&
            "type" in b &&
            ((a.type === "null" && b.type === "null") ||
              (a.type === "create-new" && b.type === "create-new"))) ||
          (!("type" in a) && !("type" in b) && a.id === b.id)
        }
        items={customerList}
        itemPredicate={(query, item) => {
          if ("type" in item) {
            if (item.type === "null") {
              return query.trim() === "";
            }
            if (item.type === "create-new") {
              return false;
            }
          }

          const normalizedSearchTerm = normalizeSearchTerm(query);
          return (
            (normalizeSearchTerm(item.displayName).includes(
              normalizedSearchTerm,
            ) ||
              normalizeSearchTerm(item.printName).includes(
                normalizedSearchTerm,
              ) ||
              item.reference?.includes(normalizedSearchTerm)) ??
            false
          );
        }}
        itemRenderer={(item, { handleClick, modifiers }) => {
          if (!modifiers.matchesPredicate) {
            return null;
          }

          if ("type" in item) {
            if (item.type === "null") {
              return (
                <MenuItem
                  active={modifiers.active}
                  key={"customer-null-value"}
                  onClick={handleClick}
                  text={NoCustomerString}
                  label={customer === null ? "Ausgewählt" : ""}
                />
              );
            }

            if (item.type === "create-new") {
              return null;
            }
          }

          return (
            <MenuItem
              active={modifiers.active}
              key={item.id}
              onClick={handleClick}
              text={item.displayName}
              label={customer && customer.id === item.id ? "Ausgewählt" : ""}
            />
          );
        }}
        onItemSelect={(item) => {
          if ("type" in item) {
            if (item.type === "create-new") {
              setCreatingCustomer({ name: item.name });
            } else {
              onChange(null);
              onChangeState(false);
            }
          } else {
            onChange(item);
            onChangeState(false);
          }
        }}
        disabled={disabled}
        createNewItemFromQuery={(name) => ({
          type: "create-new",
          name,
        })}
        createNewItemRenderer={(
          query: string,
          active: boolean,
          handleClick: MouseEventHandler<HTMLElement>,
        ) => (
          <MenuItem
            active={active}
            key={"customer-new"}
            onClick={handleClick}
            text={`Neuer Kunde "${query}"`}
          />
        )}
      >
        {children}
      </CustomerSelect>
    </>
  );
};
