import { Container, Row } from "../../components/Grid";
import { useFragment } from "relay-hooks";
import { CustomerDetail_customer$key } from "./__generated__/CustomerDetail_customer.graphql";
import { CustomerDetail_query$key } from "./__generated__/CustomerDetail_query.graphql";
import { HeaderLoader } from "../../components/HeaderLoading";
import { CustomerNavbarLoader } from "./CustomerNavbarLoader";
import styles from "./CustomerDetail.module.scss";
import { Icon } from "@mormahr/babel-transform-icon";
import loadable from "@loadable/component";
import { FC } from "react";

const CustomerStatistics = loadable(
  () =>
    import(/* webpackChunkName: "CustomerStatistics" */ "./CustomerStatistics"),
);
const CustomerHistory = loadable(
  () => import(/* webpackChunkName: "CustomerHistory" */ "./CustomerHistory"),
);
const CustomerTodos = loadable(
  () => import(/* webpackChunkName: "CustomerTodos" */ "./CustomerTodos"),
);

type Props = {
  isLoading: boolean;
  customer: CustomerDetail_customer$key | null;
  query: CustomerDetail_query$key | null;
  id: string;
};

const customerFragment = graphql`
  fragment CustomerDetail_customer on Customer {
    id

    archived
    noticeText

    canViewStatistics
    statistics {
      ...CustomerStatistics_statistics
    }
    ...CustomerTodos_customer
    ...CustomerHistory_customer
  }
`;

const queryFragment = graphql`
  fragment CustomerDetail_query on Query
  @argumentDefinitions(onlyCustomer: { type: "[ID!]", defaultValue: [] }) {
    ...CustomerTodos_query @arguments(onlyCustomer: $onlyCustomer)
  }
`;

export const CustomerDetail: FC<Props> = function (props) {
  const customer = useFragment(customerFragment, props.customer);
  const query = useFragment(queryFragment, props.query);
  const { id, isLoading } = props;
  return (
    <>
      <CustomerNavbarLoader id={id} subTitle="Kunde" isLoading={isLoading} />
      <Container>
        {customer && query ? (
          <>
            <Row>
              <Container>
                {!customer.archived ? (
                  customer.canViewStatistics &&
                  customer?.statistics && (
                    <CustomerStatistics
                      statistics={customer.statistics}
                      fallback={<HeaderLoader />}
                    />
                  )
                ) : (
                  <p className={styles.archived}>
                    <Icon icon="box-archive" type="solid" /> Archiviert
                  </p>
                )}
              </Container>
              {customer.noticeText && (
                <Container>
                  <div className="my-4 text-center text-lg">
                    <Icon icon="triangle-exclamation" type="solid" />{" "}
                    {customer.noticeText}
                  </div>
                </Container>
              )}
              <CustomerTodos
                query={query}
                customer={customer}
                fallback={<HeaderLoader />}
              />
            </Row>
            <Row>
              <Container>
                <CustomerHistory
                  customer={customer}
                  fallback={<HeaderLoader />}
                />
              </Container>
            </Row>
          </>
        ) : (
          <HeaderLoader />
        )}

        <br />
      </Container>
    </>
  );
};
