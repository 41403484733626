import { FC, useState } from "react";
import { CustomerEditDialogLazyWrapper } from "./edit/CustomerEditDialogLazyWrapper";
import { CustomerEditField } from "./edit/CustomerEditField";
import { Button } from "../../components/button/Button";
import { CustomerCheckField } from "@mormahr/portal-utils";
import { Icon } from "@mormahr/babel-transform-icon";
import styles from "./CustomerOverview.module.scss";
import { CustomerRowChecks_customer$key } from "./__generated__/CustomerRowChecks_customer.graphql";
import { usePreference } from "../../app-controller/ViewerContext";
import { useFragment } from "relay-hooks";
import classNames from "classnames";
import { checksListFormat } from "./utils/checksListFormat";
import { toFieldName } from "./utils/toFieldName";

export interface Props {
  customer: CustomerRowChecks_customer$key;
}

const fragment = graphql`
  fragment CustomerRowChecks_customer on Customer {
    id
    archived
    missingRequiredCheckFields
    missingRecommendedCheckFields
  }
`;

export const CustomerRowChecks: FC<Props> = function (props) {
  const customer = useFragment(fragment, props.customer);
  const customerOverviewAdditionalInfo = usePreference(
    "customerOverviewAdditionalInfo",
  );
  const [isEditOpen, setIsEditOpen] = useState(false);

  const missingFields =
    customerOverviewAdditionalInfo === "RECOMMENDED_CHECKS"
      ? [
          ...(customer.missingRequiredCheckFields ?? []),
          ...(customer.missingRecommendedCheckFields ?? []),
        ]
      : customer.missingRequiredCheckFields ?? [];

  const missingMap: Partial<{ [key in CustomerCheckField]: boolean }> =
    Object.fromEntries(missingFields.map((k) => [k, true]));

  return (
    <>
      {isEditOpen && (
        <CustomerEditDialogLazyWrapper
          id={customer.id}
          isOpen={isEditOpen}
          onClose={() => setIsEditOpen(false)}
          editVerb="vervollständigen"
          visibleFields={{
            [CustomerEditField.billingAddress]: missingMap.billingAddress,
            [CustomerEditField.reference]: missingMap.reference,
            [CustomerEditField.category]: missingMap.category,
            [CustomerEditField.email]: missingMap.email,
            [CustomerEditField.workItem]: missingMap.workItem,
            [CustomerEditField.journeyItem]: missingMap.journeyItem,
          }}
        />
      )}
      {(customerOverviewAdditionalInfo === "RECOMMENDED_CHECKS" ||
        customerOverviewAdditionalInfo === "REQUIRED_CHECKS") &&
        !customer.archived && (
          <>
            <span>
              {missingFields.length > 0 ? (
                <Button
                  minimal
                  small
                  onClick={() => {
                    setIsEditOpen(true);
                  }}
                >
                  {checksListFormat(missingFields.map(toFieldName))}
                </Button>
              ) : (
                ""
              )}
            </span>
            {customer.missingRequiredCheckFields &&
            customer.missingRequiredCheckFields.length > 0 ? (
              <Icon
                icon="circle-exclamation"
                type="light"
                className={classNames(styles["check-icon"], "text-red-500")}
              />
            ) : customer.missingRecommendedCheckFields &&
              customer.missingRecommendedCheckFields.length > 0 &&
              customerOverviewAdditionalInfo === "RECOMMENDED_CHECKS" ? (
              <Icon
                icon="circle-arrow-up"
                type="light"
                className={classNames(styles["check-icon"], "text-yellow-500")}
              />
            ) : (
              <Icon
                icon="circle-check"
                type="light"
                className={classNames(styles["check-icon"], "text-green-500")}
              />
            )}
          </>
        )}
    </>
  );
};
