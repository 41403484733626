import { HeaderLoader } from "../../components/HeaderLoading";
import { TimeTrackRouteQuery } from "./__generated__/TimeTrackRouteQuery.graphql";
import { Header } from "../../components/Header";
import { STORE_THEN_NETWORK, useQuery } from "relay-hooks";
import loadable from "@loadable/component";
import { Col, Container, Row } from "../../components/Grid";
import { TimeTrackHistory } from "../../components/TimeTrackHistory";
import { useExpectPermission } from "../../app-controller/ViewerContext";
import { useParams } from "react-router";
import { FC } from "react";
import { Heading } from "../../components/Heading";
import { Toolbar } from "../../components/Toolbar";

const TimeTrackEditorForm = loadable(
  () =>
    import(
      /* webpackChunkName: "TimeTrackEditorForm" */ "../../components/TimeTrackEditorForm"
    ),
);

const query = graphql`
  query TimeTrackRouteQuery($id: ID!) {
    timeTrack(id: $id) {
      ...TimeTrackEditorForm_timeTrack
      ...TimeTrackHistory_timeTrack
    }
  }
`;

export const TimeTrackRoute: FC = function TimeTrackRoute() {
  const { id } = useParams<{ id: string }>();
  const showHistory = useExpectPermission("canViewTimeTrackEvents");

  const {
    data: props,
    error,
    isLoading,
  } = useQuery<TimeTrackRouteQuery>(
    query,
    { id },
    { fetchPolicy: STORE_THEN_NETWORK },
  );

  if (error) {
    throw error;
  }

  return (
    <>
      <Header title="Zeiterfassungseintrag" isLoading={isLoading} />
      {props && !props.timeTrack ? (
        <p>Zeiterfassungseintrag konnte nicht geladen werden.</p>
      ) : !props || !props.timeTrack ? (
        <HeaderLoader />
      ) : (
        <Container>
          <Row>
            <Col xl={6}>
              <TimeTrackEditorForm
                timeTrack={props?.timeTrack}
                fallback={<HeaderLoader />}
              />
            </Col>
            {showHistory && props?.timeTrack && (
              <Col xl={6}>
                <Toolbar>
                  <Heading level={2} minimal>
                    Historie
                  </Heading>
                </Toolbar>
                <TimeTrackHistory timeTrack={props.timeTrack} />
              </Col>
            )}
          </Row>
        </Container>
      )}
    </>
  );
};
