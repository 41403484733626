import { useFragment } from "relay-hooks";
import { DashboardApproveTimeTracks_query$key } from "./__generated__/DashboardApproveTimeTracks_query.graphql";
import { HeaderLoader } from "../../components/HeaderLoading";
import { Heading } from "../../components/Heading";
import { FC } from "react";
import loadable from "@loadable/component";

const DashboardApproveTimeTracksList = loadable(
  () =>
    import(
      /* webpackChunkName: "DashboardApproveTimeTracksList" */ "./DashboardApproveTimeTracksList"
    ),
);

export interface Props {
  query: DashboardApproveTimeTracks_query$key | null;
}

const fragment = graphql`
  fragment DashboardApproveTimeTracks_query on Query {
    ...DashboardApproveTimeTracksList_query
  }
`;

export const DashboardApproveTimeTracks: FC<Props> = function (props) {
  const query = useFragment(fragment, props.query);

  return (
    <div className="mb-6">
      <Heading level={2}>Überprüfen und Genehmigen</Heading>
      <DashboardApproveTimeTracksList
        query={query ?? null}
        fallback={<HeaderLoader />}
      />
    </div>
  );
};
