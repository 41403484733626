import { FC } from "react";
import { HeaderLoader } from "../../components/HeaderLoading";
import { UserQuery } from "./__generated__/UserQuery.graphql";
import { Header } from "../../components/Header";
import { useParams } from "react-router";
import { useQuery } from "relay-hooks";
import loadable from "@loadable/component";

const UserDetail = loadable(
  () => import(/* webpackChunkName: "UserDetail" */ "./UserDetail"),
);

const query = graphql`
  query UserQuery($username: String!) {
    user(username: $username) {
      displayName
      ...UserDetail_user
    }
  }
`;

export const User: FC = function User() {
  const { id: username } = useParams<{ id: string }>();
  const { data: props, error } = useQuery<UserQuery>(query, { username });

  if (error) {
    throw error;
  }

  return (
    <>
      <Header
        title={
          props && props.user ? (
            props.user.displayName
          ) : (
            <span className={"skeleton"}>Nutzername</span>
          )
        }
        subTitle="Nutzer"
      />
      {props && !props.user ? (
        <p>Nutzer konnte nicht gefunden werden.</p>
      ) : (
        <UserDetail user={props?.user ?? null} fallback={<HeaderLoader />} />
      )}
    </>
  );
};
