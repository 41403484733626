import { createContext, Dispatch, ReactNode, useReducer } from "react";
import { NormalizedTodoConnectionFilters } from "../app/todo/model/TodoConnectionFilter";

export interface PortalBarModalType {
  type: "PORTAL_BAR";
}

export interface TodoCreateModalType {
  type: "TODO_CREATE";

  /**
   * TodoList filters
   */
  readonly filters: NormalizedTodoConnectionFilters;
}

export interface TimeTrackCreateModalType {
  type: "TIMETRACK_CREATE";
}

export type CommandType =
  | PortalBarModalType
  | TodoCreateModalType
  | TimeTrackCreateModalType;

export interface State {
  open: CommandType | null;
}

export interface OpenPortalBarAction {
  type: "OPEN_PORTAL_BAR";
}

export interface ClosePortalBarAction {
  type: "CLOSE_PORTAL_BAR";
}

export interface OpenTodoCreateModalAction {
  type: "OPEN_TODO_CREATE";
  readonly filters: NormalizedTodoConnectionFilters;
}

export interface CloseTodoCreateModalAction {
  type: "CLOSE_TODO_CREATE";
}

export interface OpenTimeTrackCreateModalAction {
  type: "OPEN_TIMETRACK_CREATE";
}

export interface CloseTimeTrackCreateModalAction {
  type: "CLOSE_TIMETRACK_CREATE";
}

export type Action =
  | OpenPortalBarAction
  | ClosePortalBarAction
  | OpenTodoCreateModalAction
  | CloseTodoCreateModalAction
  | OpenTimeTrackCreateModalAction
  | CloseTimeTrackCreateModalAction;

export function isPortalBarOpen(state: State): boolean {
  return state.open?.type === "PORTAL_BAR";
}

export function isTodoCreateModalOpen(state: State): boolean {
  return state.open?.type === "TODO_CREATE";
}

export function reducer(state: State, action: Action): State {
  if (process.env.NODE_ENV === "development") {
    console.log("CommandManager", state, action);
  }

  switch (action.type) {
    case "OPEN_PORTAL_BAR":
      return {
        ...state,
        open: {
          type: "PORTAL_BAR",
        },
      };
    case "CLOSE_PORTAL_BAR":
      return {
        ...state,
        open: null,
      };
    case "OPEN_TODO_CREATE":
      return {
        ...state,
        open: {
          type: "TODO_CREATE",
          filters: action.filters,
        },
      };
    case "CLOSE_TODO_CREATE":
      return {
        ...state,
        open: null,
      };
    case "OPEN_TIMETRACK_CREATE":
      return {
        ...state,
        open: {
          type: "TIMETRACK_CREATE",
        },
      };
    case "CLOSE_TIMETRACK_CREATE":
      return {
        ...state,
        open: null,
      };
  }
}

export const initialState: State = {
  open: null,
};

export const CommandManagerStateContext = createContext<State>(initialState);
export const CommandManagerDispatchContext = createContext<Dispatch<Action>>(
  () => {},
);

CommandManagerStateContext.displayName = "CommandManagerState";
CommandManagerDispatchContext.displayName = "CommandManagerDispatch";

export interface Props {
  children: ReactNode;
}

export function CommandManager({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <CommandManagerDispatchContext.Provider value={dispatch}>
      <CommandManagerStateContext.Provider value={state}>
        {children}
      </CommandManagerStateContext.Provider>
    </CommandManagerDispatchContext.Provider>
  );
}
