import { useCallback, useState } from "react";
import styles from "./SnakeGame.module.scss";
import classNamesBind from "classnames/bind";
import { Icon } from "@mormahr/babel-transform-icon";
import ClipLoader from "react-spinners/ClipLoader";
import loadable from "@loadable/component";

const SnakeGameWrapper = loadable(
  () => import(/* webpackChunkName: "SnakeGameWrapper" */ "./SnakeGameWrapper"),
);

const cn = classNamesBind.bind(styles);

export function SnakeGame() {
  const [isPlaying, setIsPlaying] = useState(false);

  const play = useCallback(() => {
    setIsPlaying(true);
  }, []);

  return (
    <div className={cn("game", { "is-playing": isPlaying })}>
      {!isPlaying && (
        <button className={cn("start")} onClick={play}>
          <Icon icon="play" type="solid" />
        </button>
      )}
      <SnakeGameWrapper
        isPlaying={isPlaying}
        fallback={
          <div className={cn("loader")}>
            <ClipLoader size={50} color="#F9FAFA" />
          </div>
        }
      />
    </div>
  );
}
