import { formatDistance, formatISO, parseISO } from "date-fns";
import { ReactElement, useEffect, useMemo, useState } from "react";

function format(from: Date, to: Date, prefix: string): string {
  return formatDistance(from, to, { addSuffix: true }).replace("vor", prefix);
}

interface Props {
  /** Date to relatively display */
  date: Date | string;

  /**
   * "vor" vs. "seit"
   */
  prefix?: string;

  children?: (obj: { time: ReactElement; isInFuture: boolean }) => ReactElement;
}

export function TimeFromNow({
  date,
  children,
  prefix = "vor",
}: Props): ReactElement | null {
  const _date = date instanceof Date ? date : parseISO(date);
  const _string = date instanceof Date ? formatISO(date) : date;

  const [currentDate, setCurrentDate] = useState<Date>(() => new Date());
  const text = useMemo(
    () => format(_date, currentDate, prefix),
    [_date, currentDate, prefix],
  );

  useEffect(() => {
    const id = setInterval(() => setCurrentDate(new Date()), 5000);
    return () => clearInterval(id);
  }, []);

  const time = <time dateTime={_string}>{text}</time>;

  if (children) {
    return children({ time, isInFuture: _date > currentDate });
  } else {
    return time;
  }
}
