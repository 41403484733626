import { FC } from "react";
import { DeclarativeMutationConfig } from "react-relay";
import {
  createCustomerInput,
  CustomerCreator_CreateCustomerMutation,
} from "./__generated__/CustomerCreator_CreateCustomerMutation.graphql";
import { Form, Formik } from "formik";
import { CustomerCreateSchema } from "@mormahr/portal-validation";
import { AppToaster } from "../../toaster";
import { Classes, Dialog } from "@blueprintjs/core";
import { Button } from "../../../components/button/Button";
import { Intent } from "../../../util/Intent";
import { useRelayEnvironment } from "relay-hooks";
import { CustomerEditDisplayName } from "../edit/CustomerEditDisplayName";
import { CustomerEditPrintName } from "../edit/CustomerEditPrintName";
import { Customer } from "../../../components/CustomerChooser";
import { CustomerEditReference } from "../edit/CustomerEditReference";
import { CustomerEditBillingAddress } from "../edit/CustomerEditBillingAddress";
import { MaybeAllowed } from "../../../app-controller/ViewerUtils";
import { CustomerEditCategory } from "../edit/CustomerEditCategory";
import {
  useFeatureFlag,
  useViewerPermission,
} from "../../../app-controller/ViewerContext";
import { CustomerEditWorkItem } from "../edit/CustomerEditWorkItem";
import { CustomerEditBilledHourlyRate } from "../edit/CustomerEditBilledHourlyRate";
import { CustomerEditJourneyItem } from "../edit/CustomerEditJourneyItem";
import { CustomerEditExtraBillingItems } from "../edit/CustomerEditExtraBillingItems";
import { CustomerCreatorModel } from "./CustomerCreatorModel";
import {
  doMutation,
  MutationCompletionStatus,
} from "../../../mutation/doMutation";
import classNames from "classnames";
import { CustomerEditPhoneNumbers } from "../edit/CustomerEditPhoneNumbers";

const mutation = graphql`
  mutation CustomerCreator_CreateCustomerMutation(
    $input: createCustomerInput!
  ) {
    createCustomer(input: $input) {
      createdCustomerEdge {
        node {
          ...CustomerFatQuery_data
          ...CustomerRow_customer
          ...CustomerChooser_customer @relay(mask: false)
        }
        cursor
      }
    }
  }
`;

const configs: DeclarativeMutationConfig[] = [
  {
    type: "RANGE_ADD",
    parentID: "client:root",
    connectionInfo: [
      {
        key: "CustomerOverview_customers",
        rangeBehavior: "prepend",
        filters: { onlyArchived: false },
      },
    ],
    edgeName: "createdCustomerEdge",
  },
];

function toSubmit(
  model: CustomerCreatorModel,
  rights: {
    canViewHourlyRates: boolean;
    canEditCustomerBaseData: boolean;
    monthlyCustomerBillings: boolean;
  },
): createCustomerInput {
  const phoneNumbers = model.phoneNumbers.filter(
    (obj) => !(obj.remove && !obj.phoneNumberId),
  );

  return {
    displayName: model.displayName,
    printName: model.printName,
    reference: model.reference,
    billingAddress: model.billingAddress,
    billedHourlyRate:
      rights.canViewHourlyRates && rights.canEditCustomerBaseData
        ? model.billedHourlyRate
        : undefined,
    workItemId: model.workItem?.id ?? null,
    journeyItemId: model.journeyItem?.id ?? null,
    categoryId: rights.canEditCustomerBaseData
      ? model.category?.id ?? null
      : undefined,
    extraBillingItems: rights.monthlyCustomerBillings
      ? model.extraBillingItems.map((item) => ({
          itemId: item.item.id,
          amount: item.amount,
        }))
      : undefined,
    phoneNumbers,
  };
}

export const CustomerCreator: FC<{
  isOpen: boolean;
  suggestedName?: string;
  onClose: () => void;
  onCreated?: (customer: Customer) => void;
}> = ({ isOpen, suggestedName, onClose, onCreated }) => {
  const environment = useRelayEnvironment();
  const canViewHourlyRates = useViewerPermission("canViewHourlyRates");
  const canEditCustomerBaseData = useViewerPermission(
    "canEditCustomerBaseData",
  );
  const monthlyCustomerBillings = useFeatureFlag("monthlyCustomerBillings");

  return (
    <Formik<CustomerCreatorModel>
      initialValues={{
        displayName: suggestedName ?? "",
        printName: "",
        reference: "",
        billingAddress: "",
        billedHourlyRate: undefined,
        workItem: null,
        journeyItem: null,
        category: null,
        extraBillingItems: [],
        phoneNumbers: [],
      }}
      validationSchema={CustomerCreateSchema}
      onReset={() => onClose()}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        doMutation<CustomerCreator_CreateCustomerMutation>({
          environment,
          mutation,
          variables: {
            input: toSubmit(values, {
              canViewHourlyRates: canViewHourlyRates === MaybeAllowed.YES,
              canEditCustomerBaseData:
                canEditCustomerBaseData === MaybeAllowed.YES,
              monthlyCustomerBillings:
                monthlyCustomerBillings === MaybeAllowed.YES,
            }),
          },
          successToast: {
            message: "Kunde wurde erfolgreich erstellt",
            intent: Intent.SUCCESS,
          },
          onComplete(status, response) {
            setSubmitting(false);

            if (status === MutationCompletionStatus.success) {
              resetForm();
              onCreated?.(response.createCustomer!.createdCustomerEdge!.node!);
            }
          },
          configs,
        });
      }}
    >
      {({ isValid, resetForm, isSubmitting, dirty }) => (
        <>
          <Dialog
            title="Kunde erstellen"
            isOpen={isOpen}
            onClose={() => resetForm()}
            canEscapeKeyClose={!dirty}
            canOutsideClickClose={!dirty}
          >
            <Form>
              <div className={Classes.DIALOG_BODY}>
                <CustomerEditDisplayName autoFocus />
                <CustomerEditPrintName showPlaceholderFromDisplayName />
                <CustomerEditReference />
                <CustomerEditBillingAddress />
                {canEditCustomerBaseData === MaybeAllowed.YES && (
                  <CustomerEditCategory />
                )}
                <h2 className="mt-8 mb-4 font-bold text-base">
                  Telefonnummern
                </h2>
                <CustomerEditPhoneNumbers />

                {canEditCustomerBaseData === MaybeAllowed.YES && (
                  <>
                    <details>
                      <summary>Erweitert</summary>
                      <h2 className="mt-4 mb-4 font-bold text-base">
                        Abrechnung
                      </h2>
                      <CustomerEditWorkItem />
                      {canViewHourlyRates === MaybeAllowed.YES && (
                        <CustomerEditBilledHourlyRate allowUndefined />
                      )}
                      <CustomerEditJourneyItem />

                      {monthlyCustomerBillings === MaybeAllowed.YES && (
                        <>
                          <h2 className="mt-8 mb-4 font-bold text-base">
                            Monatliche Zusatzartikel
                          </h2>
                          <CustomerEditExtraBillingItems />
                        </>
                      )}
                    </details>
                  </>
                )}
              </div>
              <div className={Classes.DIALOG_FOOTER}>
                <div
                  className={classNames(
                    Classes.DIALOG_FOOTER_ACTIONS,
                    "space-x-2",
                  )}
                >
                  <Button intent={Intent.NONE} minimal type="reset">
                    Abbrechen
                  </Button>{" "}
                  <Button
                    intent={Intent.PRIMARY}
                    disabled={!isValid}
                    loading={isSubmitting}
                    type="submit"
                  >
                    Kunde erstellen
                  </Button>
                </div>
              </div>
            </Form>
          </Dialog>
        </>
      )}
    </Formik>
  );
};
