import { doMutation } from "../../../mutation/doMutation";
import { TimeTrackRowMutations_SetCustomerMutation } from "./__generated__/TimeTrackRowMutations_SetCustomerMutation.graphql";
import { TimeTrackRowMutations_SetEmployeeMutation } from "./__generated__/TimeTrackRowMutations_SetEmployeeMutation.graphql";
import { TimeTrackRowMutations_SetTitleMutation } from "./__generated__/TimeTrackRowMutations_SetTitleMutation.graphql";
import { TimeTrackRowMutations_SetNoteMutation } from "./__generated__/TimeTrackRowMutations_SetNoteMutation.graphql";
import { TimeTrackRowMutations_ApproveTimeTrackMutation } from "./__generated__/TimeTrackRowMutations_ApproveTimeTrackMutation.graphql";
import { TimeTrackRowMutations_RevokeTimeTrackApprovalMutation } from "./__generated__/TimeTrackRowMutations_RevokeTimeTrackApprovalMutation.graphql";
import { TimeTrackRowMutations_SetTimeTrackJourneyMutation } from "./__generated__/TimeTrackRowMutations_SetTimeTrackJourneyMutation.graphql";
import { TimeTrackRowMutations_SetTimeTrackUnbilledMutation } from "./__generated__/TimeTrackRowMutations_SetTimeTrackUnbilledMutation.graphql";
import { IEnvironment } from "relay-runtime";
import { addTimeTrackEventEdge } from "../../../util/addTimeTrackEventEdge";
import { TimeTrackRowMutations_SetTimeTrackHiddenForCustomerMutation } from "./__generated__/TimeTrackRowMutations_SetTimeTrackHiddenForCustomerMutation.graphql";

export function setTimeTrackCustomer(
  environment: IEnvironment,
  timeTrackId: string,
  customerId: Optional<string>,
) {
  const variables = {
    input: {
      timeTrackId,
      customerId,
    },
  };

  doMutation<TimeTrackRowMutations_SetCustomerMutation>({
    environment,
    mutation: graphql`
      mutation TimeTrackRowMutations_SetCustomerMutation(
        $input: setTimeTrackCustomerInput!
      ) {
        setTimeTrackCustomer(input: $input) {
          timeTrack {
            canApprove
            canApproveReason
            canRemove

            associatedCustomer {
              ...CustomerFatQuery_data
            }
          }

          event {
            id
            timeTrack {
              id
            }

            ...TimeTrackHistoryRow_event
          }
        }
      }
    `,
    variables,
    updater: (store) => {
      const event = store
        .getRootField("setTimeTrackCustomer")
        ?.getLinkedRecord("event");
      if (event) {
        addTimeTrackEventEdge(store, event);
      }
      const payload = store.getRootField("setTimeTrackCustomer");
      const timeTrack = store.get(timeTrackId);
      if (!payload) {
        // @ts-ignore
        timeTrack.setValue(null, "associatedCustomer");
        return;
      }

      const newTimeTrack = payload.getLinkedRecord("timeTrack");

      // @ts-ignore
      const newCustomer = newTimeTrack.getLinkedRecord("associatedCustomer");

      if (newCustomer) {
        // @ts-ignore
        timeTrack.setLinkedRecord(newCustomer, "associatedCustomer");
      } else {
        // @ts-ignore
        timeTrack.setValue(null, "associatedCustomer");
      }
    },
  });
}

export function setTimeTrackEmployee(
  environment: IEnvironment,
  timeTrackId: string,
  employeeId: Optional<string>,
) {
  const variables = {
    input: {
      timeTrackId,
      employeeId,
    },
  };

  doMutation<TimeTrackRowMutations_SetEmployeeMutation>({
    environment,
    mutation: graphql`
      mutation TimeTrackRowMutations_SetEmployeeMutation(
        $input: setTimeTrackEmployeeInput!
      ) {
        setTimeTrackEmployee(input: $input) {
          timeTrack {
            canApprove
            canApproveReason
            canRemove

            associatedEmployee {
              ...EmployeeFatQuery_data
            }
          }

          event {
            id
            timeTrack {
              id
            }

            ...TimeTrackHistoryRow_event
          }
        }
      }
    `,
    variables,
    updater: (store) => {
      const event = store
        .getRootField("setTimeTrackEmployee")
        ?.getLinkedRecord("event");
      if (event) {
        addTimeTrackEventEdge(store, event);
      }

      const payload = store.getRootField("setTimeTrackEmployee");
      const timeTrack = store.get(timeTrackId);
      if (!payload) {
        // @ts-ignore
        timeTrack.setValue(null, "associatedEmployee");
        return;
      }

      const newTimeTrack = payload.getLinkedRecord("timeTrack");

      // @ts-ignore
      const newEmployee = newTimeTrack.getLinkedRecord("associatedEmployee");

      if (newEmployee) {
        // @ts-ignore
        timeTrack.setLinkedRecord(newEmployee, "associatedEmployee");
      } else {
        // @ts-ignore
        timeTrack.setValue(null, "associatedEmployee");
      }
    },
  });
}

export function setTimeTrackNote(
  environment: IEnvironment,
  timeTrackId: string,
  value: string,
) {
  const variables = {
    input: {
      timeTrackId,
      note: value,
    },
  };

  doMutation<TimeTrackRowMutations_SetNoteMutation>({
    environment,
    mutation: graphql`
      mutation TimeTrackRowMutations_SetNoteMutation(
        $input: setTimeTrackNoteInput!
      ) {
        setTimeTrackNote(input: $input) {
          timeTrack {
            note
            canRemove
          }

          event {
            id
            timeTrack {
              id
            }

            ...TimeTrackHistoryRow_event
          }
        }
      }
    `,
    variables,

    updater(store) {
      const event = store
        .getRootField("setTimeTrackNote")
        ?.getLinkedRecord("event");
      if (event) {
        addTimeTrackEventEdge(store, event);
      }
    },
  });
}

export function setTimeTrackTitle(
  environment: IEnvironment,
  timeTrackId: string,
  value: string,
) {
  const variables = {
    input: {
      timeTrackId,
      title: value,
    },
  };

  doMutation<TimeTrackRowMutations_SetTitleMutation>({
    environment,
    mutation: graphql`
      mutation TimeTrackRowMutations_SetTitleMutation(
        $input: setTimeTrackTitleInput!
      ) {
        setTimeTrackTitle(input: $input) {
          timeTrack {
            canApprove
            canApproveReason
            canRemove

            title
          }

          event {
            id
            timeTrack {
              id
            }

            ...TimeTrackHistoryRow_event
          }
        }
      }
    `,
    variables,
    optimisticUpdater(store) {
      store.get(timeTrackId)?.setValue(value, "title");
    },
    updater(store) {
      const event = store
        .getRootField("setTimeTrackTitle")
        ?.getLinkedRecord("event");
      if (event) {
        addTimeTrackEventEdge(store, event);
      }
    },
  });
}

export function approveTimeTrack(
  environment: IEnvironment,
  timeTrackId: string,
) {
  const variables = {
    input: {
      timeTrackId,
    },
  };

  doMutation<TimeTrackRowMutations_ApproveTimeTrackMutation>({
    environment,
    mutation: graphql`
      mutation TimeTrackRowMutations_ApproveTimeTrackMutation(
        $input: approveTimeTrackInput!
      ) {
        approveTimeTrack(input: $input) {
          timeTrack {
            approved
            canApprove
            canApproveReason
            canEdit
            canRemove

            status
          }

          event {
            id
            timeTrack {
              id
            }

            ...TimeTrackHistoryRow_event
          }
        }
      }
    `,
    variables,
    optimisticUpdater(store) {
      store.get(timeTrackId)?.setValue(true, "approved");
    },
    updater(store) {
      const event = store
        .getRootField("approveTimeTrack")
        ?.getLinkedRecord("event");
      if (event) {
        addTimeTrackEventEdge(store, event);
      }
    },
  });
}

export function revokeTimeTrackApproval(
  environment: IEnvironment,
  timeTrackId: string,
) {
  const variables = {
    input: {
      timeTrackId,
    },
  };

  doMutation<TimeTrackRowMutations_RevokeTimeTrackApprovalMutation>({
    environment,
    mutation: graphql`
      mutation TimeTrackRowMutations_RevokeTimeTrackApprovalMutation(
        $input: revokeTimeTrackApprovalInput!
      ) {
        revokeTimeTrackApproval(input: $input) {
          timeTrack {
            approved
            canApprove
            canApproveReason
            canEdit
            canRemove

            status
          }

          event {
            id
            timeTrack {
              id
            }

            ...TimeTrackHistoryRow_event
          }
        }
      }
    `,
    variables,
    optimisticUpdater(store) {
      store.get(timeTrackId)?.setValue(false, "approved");
    },
    updater(store) {
      const event = store
        .getRootField("revokeTimeTrackApproval")
        ?.getLinkedRecord("event");
      if (event) {
        addTimeTrackEventEdge(store, event);
      }
    },
  });
}

export function setTimeTrackJourney(
  environment: IEnvironment,
  timeTrackId: string,
  journey: boolean,
) {
  const variables = {
    input: {
      timeTrackId,
      journey,
    },
  };

  doMutation<TimeTrackRowMutations_SetTimeTrackJourneyMutation>({
    environment,
    mutation: graphql`
      mutation TimeTrackRowMutations_SetTimeTrackJourneyMutation(
        $input: setTimeTrackJourneyInput!
      ) {
        setTimeTrackJourney(input: $input) {
          timeTrack {
            journey
          }

          event {
            id
            timeTrack {
              id
            }

            ...TimeTrackHistoryRow_event
          }
        }
      }
    `,
    variables,
    optimisticUpdater(store) {
      store.get(timeTrackId)?.setValue(journey, "journey");
    },
    updater(store) {
      const event = store
        .getRootField("setTimeTrackJourney")
        ?.getLinkedRecord("event");
      if (event) {
        addTimeTrackEventEdge(store, event);
      }
    },
  });
}

export function setTimeTrackUnbilled(
  environment: IEnvironment,
  timeTrackId: string,
  unbilled: boolean,
) {
  const variables = {
    input: {
      timeTrackId,
      unbilled,
    },
  };

  doMutation<TimeTrackRowMutations_SetTimeTrackUnbilledMutation>({
    environment,
    mutation: graphql`
      mutation TimeTrackRowMutations_SetTimeTrackUnbilledMutation(
        $input: setTimeTrackUnbilledInput!
      ) {
        setTimeTrackUnbilled(input: $input) {
          timeTrack {
            unbilled
            hiddenForCustomer
          }

          event {
            id
            timeTrack {
              id
            }

            ...TimeTrackHistoryRow_event
          }
        }
      }
    `,
    variables,
    optimisticUpdater(store) {
      store.get(timeTrackId)?.setValue(unbilled, "unbilled");
      if (!unbilled) {
        store.get(timeTrackId)?.setValue(false, "hiddenForCustomer");
      }
    },
    updater(store) {
      const event = store
        .getRootField("setTimeTrackUnbilled")
        ?.getLinkedRecord("event");
      if (event) {
        addTimeTrackEventEdge(store, event);
      }
    },
  });
}

export function setTimeTrackHiddenForCustomer(
  environment: IEnvironment,
  timeTrackId: string,
  hiddenForCustomer: boolean,
) {
  const variables = {
    input: {
      timeTrackId,
      hiddenForCustomer,
    },
  };

  doMutation<TimeTrackRowMutations_SetTimeTrackHiddenForCustomerMutation>({
    environment,
    mutation: graphql`
      mutation TimeTrackRowMutations_SetTimeTrackHiddenForCustomerMutation(
        $input: setTimeTrackHiddenForCustomerInput!
      ) {
        setTimeTrackHiddenForCustomer(input: $input) {
          timeTrack {
            unbilled
            hiddenForCustomer
          }

          event {
            id
            timeTrack {
              id
            }

            ...TimeTrackHistoryRow_event
          }
        }
      }
    `,
    variables,
    optimisticUpdater(store) {
      store.get(timeTrackId)?.setValue(hiddenForCustomer, "hiddenForCustomer");
      if (hiddenForCustomer) {
        store.get(timeTrackId)?.setValue(true, "unbilled");
      }
    },
    updater(store) {
      const event = store
        .getRootField("setTimeTrackHiddenForCustomer")
        ?.getLinkedRecord("event");
      if (event) {
        addTimeTrackEventEdge(store, event);
      }
    },
  });
}
