import { commitMutation } from "react-relay";
import { ConnectionHandler, IEnvironment } from "relay-runtime";
import {
  NormalizedTodoConnectionFilters,
  transformFilters,
} from "../app/todo/model/TodoConnectionFilter";
import {
  createTodo_CreateTodoMutation,
  createTodoInput,
} from "./__generated__/createTodo_CreateTodoMutation.graphql";
import invariant from "invariant";

const mutation = graphql`
  mutation createTodo_CreateTodoMutation($input: createTodoInput!) {
    createTodo(input: $input) {
      todo {
        ...TodoFatQuery_data
      }
    }
  }
`;

export interface ICreateTodoOptions {
  input: createTodoInput;
  filters: NormalizedTodoConnectionFilters;
}

export function createTodo(
  environment: IEnvironment,
  { input, filters }: ICreateTodoOptions,
) {
  const variables = {
    input,
  };

  return new Promise<void>((resolve, reject) => {
    commitMutation<createTodo_CreateTodoMutation>(environment, {
      mutation,
      variables,
      onCompleted: (_, errors) => {
        if (errors && errors.length !== 0) {
          reject();
        } else {
          resolve();
        }
      },
      onError: (err) => reject(err),
      updater: (store) => {
        const payload = store.getRootField("createTodo");
        const todo = payload.getLinkedRecord("todo");
        invariant(todo !== null, "Response doesn't include a todo");

        const root = store.getRoot();

        const connection = ConnectionHandler.getConnection(
          root,
          "TodoConnection_todos",
          transformFilters(filters),
        );

        if (!connection) {
          return;
        }

        const edge = ConnectionHandler.createEdge(
          store,
          connection,
          todo,
          "TodoEdge",
        );

        ConnectionHandler.insertEdgeBefore(connection, edge);
      },
    });
  });
}
