import { Dashboard } from "./dashboard/Dashboard";
import { Todos } from "./todo/overview/Todos";
import { TimeTrackRoute } from "./timetrack/TimeTrackRoute";
import { ItemsOverview } from "./item/ItemsOverview";
import { Users } from "./user/Users";
import { User } from "./user/User";
import { TimeTracksAll } from "./user/workspace/TimeTracksAll";
import { TimeTracks } from "./user/workspace/TimeTracks";
import { DateRangeField } from "../components/daterange/DateRangeField";
import { TodoCreateButton } from "../components/TodoCreateButton";
import { transformFilters } from "./todo/model/TodoConnectionFilter";
import { defaultFilters } from "./todo/components/TodoListDefaultFilters";
import { Icon } from "@mormahr/babel-transform-icon";
import { ButtonPlayground } from "./dev/ButtonPlayground";
import { RouteConfig } from "react-router-config";
import { useViewerUsername } from "../app-controller/ViewerContext";
import { Redirect } from "react-router-dom";
import { HeaderLoader } from "../components/HeaderLoading";
import { CustomersRoute } from "./customer/CustomersRoute";
import { Customer } from "./customer/Customer";
import { CustomerEditRoute } from "./customer/edit/CustomerEditRoute";
import { CustomerBillings } from "./customer/billing/CustomerBillings";
import { CustomerBilling } from "./billing/CustomerBilling";
import { CustomerCategories } from "./organization/customer-categories/CustomerCategories";
import { CustomerBillingsMonth } from "./billings/CustomerBillingsMonth";
import { NotFoundRoute } from "./NotFoundRoute";
import { OAuthAuthorizeRoute } from "./oauth/OAuthAuthorizeRoute";
import { CreateToken } from "./tools/CreateToken";
import { TodosDone } from "./todo/overview/TodosDone";
import { CallPlayground } from "./dev/CallPlayground";
import { DailyQuarterHourlyHistogram } from "./insights/DailyQuarterHourlyHistogram";
import { CallsRoute } from "./call/Calls";

function UserRedirect() {
  const username = useViewerUsername();
  if (!username) {
    return <HeaderLoader />;
  }
  return <Redirect to={`/user/${username}`} />;
}

function UserWorkspaceRedirect() {
  const username = useViewerUsername();
  if (!username) {
    return <HeaderLoader />;
  }
  return <Redirect to={`/user/${username}/workspace`} />;
}

function CustomerBillingRedirect({ match }: any) {
  return <Redirect to={`/billing/${match.params.customerBillingId}`} />;
}

export const routes: RouteConfig[] = [
  { exact: true, path: "/", component: Dashboard },

  { exact: true, path: "/oauth/authorize", component: OAuthAuthorizeRoute },

  { exact: true, path: "/user", component: Users },

  {
    exact: true,
    // Currently not implemented in web app. Deeplink from iOS app.
    path: "/review",
    // "all" will redirect to "me" if user doesn't have necessary rights.
    render: function ReviewRedirect(): JSX.Element {
      return <Redirect to="/user/all/workspace" />;
    },
  },

  {
    exact: true,
    path: "/user/me",
    component: UserRedirect,
  },
  {
    path: "/user/me/workspace",
    component: UserWorkspaceRedirect,
  },
  { exact: true, path: "/user/:id", component: User },
  { path: "/user/all/workspace", component: TimeTracksAll },
  { path: "/user/:id/workspace", component: TimeTracks },

  { exact: true, path: "/customer", component: CustomersRoute },
  { exact: true, path: "/customer/:id", component: Customer },
  { exact: true, path: "/customer/:id/edit", component: CustomerEditRoute },
  {
    exact: true,
    path: "/customer/:customerId/billing",
    component: CustomerBillings,
  },
  {
    exact: true,
    path: "/customer/:customerId/billing/:customerBillingId",
    component: CustomerBillingRedirect,
  },

  {
    exact: true,
    path: "/billing/:customerBillingId",
    component: CustomerBilling,
  },

  {
    exact: true,
    path: "/billings/:month",
    component: CustomerBillingsMonth,
  },

  { exact: true, path: "/todo", component: Todos },
  { exact: true, path: "/todo/done", component: TodosDone },

  { path: "/timetrack/:id", component: TimeTrackRoute },

  {
    exact: true,
    path: "/organization/customer-categories",
    component: CustomerCategories,
  },

  { path: "/item", component: ItemsOverview },

  { path: "/tools/token", component: CreateToken },

  { path: "/insights/histogram", component: DailyQuarterHourlyHistogram },

  { path: "/calls", component: CallsRoute },

  ...(process.env.NODE_ENV !== "production"
    ? [
        {
          exact: true,
          path: "/dev/date-range",
          component: DevDateRangeField,
        },
        {
          exact: true,
          path: "/dev/todo-create",
          component: DevTodoCreateButton,
        },
        {
          exact: true,
          path: "/dev/button",
          component: DevButtonPlayground,
        },
        {
          exact: true,
          path: "/dev/calls",
          component: CallPlayground,
        },
      ]
    : []),

  {
    component: NotFoundRoute,
  },
];

function DevButtonPlayground() {
  return <ButtonPlayground />;
}

function DevDateRangeField() {
  return (
    <DateRangeField
      startDate={null}
      endDate={null}
      onChange={console.log.bind(console, "onChange")}
      onAbort={console.log.bind(console, "onAbort")}
    />
  );
}

function DevTodoCreateButton() {
  return (
    <TodoCreateButton
      filters={transformFilters(defaultFilters)}
      icon={<Icon icon="plus" type="regular" />}
    >
      Todo erstellen
    </TodoCreateButton>
  );
}
