import { Icon } from "@mormahr/babel-transform-icon";
import { createFragmentContainer } from "react-relay";
import { AuthenticationWidget_viewer$data } from "./__generated__/AuthenticationWidget_viewer.graphql";
import { TextButton } from "../../components/button/TextButton";
import styles from "./AuthenticationWidget.module.scss";
import { settingsLink } from "./util/settingsLink";
import { SubscriptionInfoBadge } from "../../components/SubscriptionInfo";
import { useService } from "@xstate/react";
import { useAppService } from "../../app-controller/AppServiceContext";

export type Props = {
  viewer: AuthenticationWidget_viewer$data | null;
};

export function AuthenticationWidgetComponent({ viewer }: Props) {
  const AppService = useAppService();
  const [state] = useService(AppService);

  if (
    state.value === "authenticated" ||
    state.value === "probablyAuthenticated"
  ) {
    const _viewer = state.context.viewer;
    if (_viewer === undefined) {
      throw new Error("Not possible");
    }

    let passwordInfo = null;
    if (viewer && viewer.passwordInfo && viewer.passwordInfo.pwned) {
      passwordInfo = (
        <span className={styles.warning}>
          {" "}
          <TextButton href={settingsLink(_viewer)} title="Passwort Warnung">
            <Icon
              icon="triangle-exclamation"
              type="solid"
              title="Passwort Warnung"
            />
          </TextButton>
        </span>
      );
    }

    return (
      <span data-cy="auth-widget-logged-in">
        Angemeldet als{" "}
        <TextButton href={settingsLink(_viewer)} title={_viewer.displayName}>
          {_viewer.displayName}
        </TextButton>
        {passwordInfo} <SubscriptionInfoBadge />
      </span>
    );
  }

  return <span>Nicht angemeldet</span>;
}

export const AuthenticationWidget = createFragmentContainer(
  AuthenticationWidgetComponent,
  {
    viewer: graphql`
      fragment AuthenticationWidget_viewer on ViewerUser {
        passwordInfo {
          pwned
        }
      }
    `,
  },
);
