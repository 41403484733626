/**
 * @generated SignedSource<<cd7841ea11c5700c1838544278959c40>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CustomerCategoryPimple_customerCategory$data = {
  readonly title: string;
  readonly color: string;
  readonly " $fragmentType": "CustomerCategoryPimple_customerCategory";
};
export type CustomerCategoryPimple_customerCategory$key = {
  readonly " $data"?: CustomerCategoryPimple_customerCategory$data;
  readonly " $fragmentSpreads": FragmentRefs<"CustomerCategoryPimple_customerCategory">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CustomerCategoryPimple_customerCategory",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "color",
      "storageKey": null
    }
  ],
  "type": "CustomerCategory",
  "abstractKey": null
};

(node as any).hash = "91cfb8c09e50a5dd3c2d24d5a878a07e";

export default node;
