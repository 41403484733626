/**
 * @generated SignedSource<<fa484fd376e6e843a8985c029039dacb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AuthenticationWidget_viewer$data = {
  readonly passwordInfo: {
    readonly pwned: boolean | null;
  };
  readonly " $fragmentType": "AuthenticationWidget_viewer";
};
export type AuthenticationWidget_viewer$key = {
  readonly " $data"?: AuthenticationWidget_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"AuthenticationWidget_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AuthenticationWidget_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PasswordInfo",
      "kind": "LinkedField",
      "name": "passwordInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pwned",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ViewerUser",
  "abstractKey": null
};

(node as any).hash = "d6d1625f123260b59808eb2425f967a3";

export default node;
