import { FC } from "react";
import { FormGroup } from "@blueprintjs/core";
import { useFormikContext } from "formik";
import {
  CustomerCategorySelect,
  CustomerEditCategoryModel,
} from "../CustomerCategorySelect";
import { expectYes } from "../../../app-controller/ViewerUtils";
import { useViewerPermission } from "../../../app-controller/ViewerContext";
import { Link } from "react-router-dom";

export const CustomerEditCategory: FC = function () {
  const { errors, touched: _touched } =
    useFormikContext<CustomerEditCategoryModel>();
  const canEditOrganization = expectYes(
    useViewerPermission("canEditOrganization"),
  );

  const error = errors.category;
  const touched = _touched.category ?? false;
  const showError = error && touched;

  const helperText = (
    <>
      <div>
        {canEditOrganization ? (
          <span>
            Die Kategorien können, inklusive Reihenfolge, in den{" "}
            <Link to="/organization/customer-categories">
              Einstellungen der Firma
            </Link>{" "}
            bearbeitet werden.
          </span>
        ) : (
          <span>
            Die Kategorien können von einem Administrator bearbeitet werden.
          </span>
        )}
      </div>
      {showError && (
        <div>
          <br />
          {error}
        </div>
      )}
    </>
  );

  return (
    <div data-cy="customer-category">
      <FormGroup label="Kundenkategorie" helperText={helperText}>
        <CustomerCategorySelect />
      </FormGroup>
    </div>
  );
};
