import { PropsWithChildren } from "react";
import classnames from "classnames";
import { useViewerPermission } from "../../app-controller/ViewerContext";
import { expectYes } from "../../app-controller/ViewerUtils";
import { CustomerCategoryPimple } from "../../components/CustomerCategoryPimple";
import { Header, Tab } from "../../components/Header";
import { Icon } from "@mormahr/babel-transform-icon";
import { CustomerNavbar_customer$key } from "./__generated__/CustomerNavbar_customer.graphql";
import { CustomerChooser } from "../../components/CustomerChooser";
import { HeaderSelectButton } from "../../components/HeaderSelectButton";
import { useHistory } from "react-router";
import { useFragment } from "relay-hooks";
import { Link } from "react-router-dom";

export interface Props {
  customerId: string;
  customer: CustomerNavbar_customer$key | null;
  subTitle: string;
  isLoading?: boolean;
}

const customerFragment = graphql`
  fragment CustomerNavbar_customer on Customer {
    id
    displayName
    printName
    reference
    archived

    category {
      ...CustomerCategoryPimple_customerCategory
    }
  }
`;

export function CustomerNavbar({
  customerId,
  customer: _customer,
  subTitle,
  isLoading,
  children,
}: PropsWithChildren<Props>) {
  const customer = useFragment(customerFragment, _customer);
  const canViewCustomerBillings = useViewerPermission(
    "canViewCustomerBillings",
  );
  const history = useHistory();

  return (
    <Header
      title={
        <span className={classnames({ ["skeleton"]: !customer })}>
          {customer ? customer.displayName : "Kunde"}{" "}
          <CustomerCategoryPimple customerCategory={customer?.category} />
        </span>
      }
      titleMenuButton={
        <CustomerChooser
          customer={customer}
          disableNoSelection={true}
          changeCallback={(newCustomer) => {
            if (customer && newCustomer) {
              history.push(
                history.location.pathname.replace(customer.id, newCustomer.id),
              );
            }
          }}
          renderButton={(props) => (
            <HeaderSelectButton title="Kunde ändern" {...props} />
          )}
        />
      }
      subTitle={subTitle}
      isLoading={isLoading}
      back={
        !customer?.archived ? (
          <Link to="/customer">Kunden</Link>
        ) : (
          <Link to="/customer?archived">Kunden</Link>
        )
      }
    >
      <Tab
        exact
        to={`/customer/${customerId}`}
        icon={<Icon icon="user-doctor" type="solid" />}
      >
        Übersicht
      </Tab>
      <Tab
        exact
        to={`/customer/${customerId}/edit`}
        icon={<Icon icon="user-pen" type="solid" />}
      >
        Stammdaten
      </Tab>
      {expectYes(canViewCustomerBillings) && (
        <Tab
          to={`/customer/${customerId}/billing`}
          icon={<Icon icon="chart-user" type="solid" />}
        >
          Abrechnungen
        </Tab>
      )}
      {children}
    </Header>
  );
}
