import {
  NormalizedTodoConnectionFilters,
  SloppyTodoConnectionFilters,
  transformFilters,
} from "./TodoConnectionFilter";
import { parse, ParseOptions, stringify, StringifyOptions } from "query-string";
import { deepEqual } from "../../../util/comparison/deepEqual";

const options: StringifyOptions & ParseOptions = {
  arrayFormat: "comma",
  parseBooleans: true,
};

export function linkFor(
  filters: SloppyTodoConnectionFilters,
  defaultFilters: NormalizedTodoConnectionFilters,
): string {
  return `/todo?${serializeTodoConnectionFilters(
    transformFilters(filters),
    defaultFilters,
  )}`;
}

export function serializeTodoConnectionFilters(
  filters: NormalizedTodoConnectionFilters,
  defaultFilters: NormalizedTodoConnectionFilters = transformFilters({}),
): string {
  // Shallow-copy filters
  const _filters = {
    ...filters,
  };

  Object.keys(filters).forEach(key => {
    // @ts-ignore
    const value = filters[key];

    // Remove values matching the default value
    // @ts-ignore
    if (deepEqual(value, defaultFilters[key])) {
      // @ts-ignore
      _filters[key] = undefined;
    }
  });

  return stringify(_filters, options);
}

export function parseTodoConnectionFilters(
  queryString: string,
  defaultFilters: NormalizedTodoConnectionFilters = transformFilters({}),
): NormalizedTodoConnectionFilters {
  const parsed = parse(queryString, options);
  return transformFilters({
    ...defaultFilters,
    ...parsed,
  });
}
