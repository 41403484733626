import { useCallback } from "react";
import { CustomerBillingsMonthQuery } from "./__generated__/CustomerBillingsMonthQuery.graphql";
import { useParams } from "react-router";
import { HeaderLoader } from "../../components/HeaderLoading";
import { STORE_THEN_NETWORK, useQuery } from "relay-hooks";
import { Header } from "../../components/Header";
import { format, parseISO } from "date-fns";
import { Redirect, useHistory } from "react-router-dom";
import {
  useExpectPermission,
  useFeatureFlag,
} from "../../app-controller/ViewerContext";
import { CustomerBillingsMonthSelector } from "../../components/billings/CustomerBillingsMonthSelector";
import { HeaderSelectButton } from "../../components/HeaderSelectButton";
import loadable from "@loadable/component";
import { MaybeAllowed } from "../../app-controller/ViewerUtils";

const CustomerBillingsMonthlyOverview = loadable(
  () =>
    import(
      /* webpackChunkName: "CustomerBillingsMonthlyOverview" */ "../../components/billings/CustomerBillingsMonthlyOverview"
    ),
);

const query = graphql`
  query CustomerBillingsMonthQuery($month: YearMonth!) {
    ...CustomerBillingsMonthlyOverview_query
      @arguments(month: $month, onlyCustomer: [])
  }
`;

export function CustomerBillingsMonth() {
  const canViewCustomerBillings = useExpectPermission(
    "canViewCustomerBillings",
    true,
  );
  const featureMonthlyCustomerBillings = useFeatureFlag(
    "monthlyCustomerBillings",
  );
  const { month } = useParams<{ month: string }>();
  const {
    error,
    data: props,
    isLoading,
  } = useQuery<CustomerBillingsMonthQuery>(
    query,
    {
      month,
    },
    { fetchPolicy: STORE_THEN_NETWORK },
  );

  const history = useHistory();
  const changeMonthHandler = useCallback(
    (month: string) => {
      history.push(`/billings/${month}`);
    },
    [history],
  );

  if (
    !canViewCustomerBillings ||
    featureMonthlyCustomerBillings === MaybeAllowed.NO
  ) {
    return <Redirect to="/" />;
  }

  if (error) {
    throw error;
  }

  const title = format(parseISO(month), "MMMM yyyy");

  return (
    <>
      <Header
        title={title}
        titleMenuButton={
          <CustomerBillingsMonthSelector
            onSelect={changeMonthHandler}
            selected={month}
          >
            <HeaderSelectButton title="Monat ändern" />
          </CustomerBillingsMonthSelector>
        }
        subTitle="Abrechnungen"
        isLoading={isLoading}
      />
      <CustomerBillingsMonthlyOverview
        query={props ?? null}
        month={month}
        fallback={<HeaderLoader />}
      />
    </>
  );
}
