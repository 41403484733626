/**
 * @generated SignedSource<<7b66d9387d88269d98440836229381c7>>
 * @relayHash fcbe4de033cac5d240e4334641057d36
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 5db0811ceb05239595c67fce07086efa356c1241

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type setTimeTrackJourneyInput = {
  timeTrackId: string;
  journey: boolean;
  clientMutationId?: string | null;
};
export type TimeTrackRowMutations_SetTimeTrackJourneyMutation$variables = {
  input: setTimeTrackJourneyInput;
};
export type TimeTrackRowMutations_SetTimeTrackJourneyMutation$data = {
  readonly setTimeTrackJourney: {
    readonly timeTrack: {
      readonly journey: boolean;
    };
    readonly event: {
      readonly id: string;
      readonly timeTrack: {
        readonly id: string;
      };
      readonly " $fragmentSpreads": FragmentRefs<"TimeTrackHistoryRow_event">;
    } | null;
  } | null;
};
export type TimeTrackRowMutations_SetTimeTrackJourneyMutation = {
  variables: TimeTrackRowMutations_SetTimeTrackJourneyMutation$variables;
  response: TimeTrackRowMutations_SetTimeTrackJourneyMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "journey",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "TimeTrack",
  "kind": "LinkedField",
  "name": "timeTrack",
  "plural": false,
  "selections": [
    (v3/*: any*/)
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v6 = [
  (v5/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "link",
    "storageKey": null
  },
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TimeTrackRowMutations_SetTimeTrackJourneyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "setTimeTrackJourneyPayload",
        "kind": "LinkedField",
        "name": "setTimeTrackJourney",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TimeTrack",
            "kind": "LinkedField",
            "name": "timeTrack",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TimeTrackEvent",
            "kind": "LinkedField",
            "name": "event",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "TimeTrackHistoryRow_event"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TimeTrackRowMutations_SetTimeTrackJourneyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "setTimeTrackJourneyPayload",
        "kind": "LinkedField",
        "name": "setTimeTrackJourney",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TimeTrack",
            "kind": "LinkedField",
            "name": "timeTrack",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TimeTrackEvent",
            "kind": "LinkedField",
            "name": "event",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "kind",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "date",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "changedFields",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Customer",
                "kind": "LinkedField",
                "name": "oldCustomer",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Customer",
                "kind": "LinkedField",
                "name": "newCustomer",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Employee",
                "kind": "LinkedField",
                "name": "oldEmployee",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Employee",
                "kind": "LinkedField",
                "name": "newEmployee",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CustomerBilling",
                "kind": "LinkedField",
                "name": "oldCustomerBilling",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CustomerBilling",
                "kind": "LinkedField",
                "name": "newCustomerBilling",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "5db0811ceb05239595c67fce07086efa356c1241",
    "metadata": {},
    "name": "TimeTrackRowMutations_SetTimeTrackJourneyMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "59bc434214f8a06862f4be63af50eb05";

export default node;
