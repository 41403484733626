import "../style/grid.scss";
import { DetailedHTMLProps, HTMLAttributes } from "react";
import classNames from "classnames";

export type DivProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & { "data-cy"?: string };

export function FluidContainer(props: DivProps) {
  return (
    <div
      {...props}
      className={classNames("grid-container-fluid", props.className)}
    />
  );
}

export function Container(props: DivProps) {
  return (
    <div {...props} className={classNames("grid-container", props.className)} />
  );
}

export function BigContainer(props: DivProps) {
  return (
    <div
      {...props}
      className={classNames("big-grid-container", props.className)}
    />
  );
}

export type NumberSizes = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
export type StringSizes =
  | "1"
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9"
  | "10"
  | "11"
  | "12";
export type NumberOrStringSize = NumberSizes | StringSizes;

export interface RowProps extends DivProps {
  xs?: NumberOrStringSize;
  sm?: NumberOrStringSize;
  md?: NumberOrStringSize;
  lg?: NumberOrStringSize;
  xl?: NumberOrStringSize;
}

export function Row({ xs, sm, md, lg, xl, className, ...props }: RowProps) {
  const classes = ["grid-row"];
  if (xs !== undefined) classes.push(`grid-row-cols-${xs}`);
  if (sm !== undefined) classes.push(`grid-row-cols-sm-${sm}`);
  if (md !== undefined) classes.push(`grid-row-cols-md-${md}`);
  if (lg !== undefined) classes.push(`grid-row-cols-lg-${lg}`);
  if (xl !== undefined) classes.push(`grid-row-cols-xl-${xl}`);
  if (className) classes.push(className);

  return <div {...props} className={classes.join(" ")} />;
}

export type ColSizeProp =
  | NumberOrStringSize
  | {
      size?: true | NumberOrStringSize | "auto";
      order?: NumberOrStringSize;
      offset?: NumberOrStringSize;
    };

export interface ColProps extends DivProps {
  xs?: ColSizeProp;
  sm?: ColSizeProp;
  md?: ColSizeProp;
  lg?: ColSizeProp;
  xl?: ColSizeProp;
}

function columnSizeSizeClasses(
  colSize: true | NumberOrStringSize | "auto" | undefined,
  colWidth: "sm" | "md" | "lg" | "xl",
) {
  return colSize === true
    ? `grid-col-${colWidth}`
    : colSize === "auto"
    ? `grid-col-${colWidth}-auto`
    : `grid-col-${colWidth}-${colSize}`;
}
function columnSizeSizeClassesXs(
  colSize: true | NumberOrStringSize | "auto" | undefined,
) {
  return colSize === true
    ? `grid-col`
    : colSize === "auto"
    ? `grid-col-auto`
    : `grid-col-${colSize}`;
}
function columnSizeClasses(
  colSize: ColSizeProp | undefined,
  colWidth: "sm" | "md" | "lg" | "xl",
  classes: string[],
) {
  if (colSize || colSize === "") {
    if (typeof colSize === "object") {
      classes.push(columnSizeSizeClasses(colSize.size, colWidth));
      if (colSize.order)
        classes.push(`grid-order-${colWidth}-${colSize.order}`);
      if (colSize.offset)
        classes.push(`grid-offset-${colWidth}-${colSize.offset}`);
    } else {
      classes.push(columnSizeSizeClasses(colSize, colWidth));
    }
  }
}

export function Col({ xs, sm, md, lg, xl, className, ...props }: ColProps) {
  const classes: string[] = [];
  if (xs || xs === "") {
    if (typeof xs === "object") {
      classes.push(columnSizeSizeClassesXs(xs.size));
      if (xs.order) classes.push(`grid-order-${xs.order}`);
      if (xs.offset) classes.push(`grid-offset-${xs.offset}`);
    } else {
      classes.push(columnSizeSizeClassesXs(xs));
    }
  }
  if (sm || sm === "") columnSizeClasses(sm, "sm", classes);
  if (sm || sm === "") columnSizeClasses(sm, "sm", classes);
  if (lg || lg === "") columnSizeClasses(lg, "lg", classes);
  if (xl || xl === "") columnSizeClasses(xl, "xl", classes);
  if (classes.length === 0) classes.push("grid-col");
  if (className) classes.push(className);

  return <div {...props} className={classes.join(" ")} />;
}
