import { FC } from "react";
import { FlagKey } from "../../../utils";
import { useFeatureFlag } from "../app-controller/ViewerContext";
import { MaybeAllowed } from "../app-controller/ViewerUtils";
import { Redirect } from "react-router";

export const FeatureFlagRedirect: FC<{ flag: FlagKey; to?: string }> =
  function ({ flag, to = "/" }) {
    const state = useFeatureFlag(flag);

    if (state === MaybeAllowed.NO) {
      return <Redirect to={to} />;
    }

    return null;
  };
