import { FC } from "react";
import styles from "./Stopwatch.module.scss";

export const StopwatchGlobals: FC = function () {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: "0", height: "0" }}
    >
      <filter id="stopwatch-dropshadow-dark" height="130%">
        <feDropShadow
          dx="-1.5"
          dy="-2"
          stdDeviation="1.5"
          floodColor="rgb(32,43,51)"
          floodOpacity="0.3"
        />
      </filter>
      <filter id="stopwatch-dropshadow-light" height="130%">
        <feDropShadow
          dx="-1.5"
          dy="-2"
          stdDeviation="1.5"
          floodColor="rgb(206,217,224)"
          floodOpacity="0.2"
        />
      </filter>
    </svg>
  );
};

export const Stopwatch: FC<{ seconds: number }> = function ({ seconds }) {
  const minutes = seconds / 60;
  const hours = minutes / 60;

  const minuteRotation = (minutes / 60) * 360;
  const hourRotation = (hours / 12) * 360;

  return (
    <svg
      viewBox="0 0 416 512"
      className={styles.svg}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M377.9,184 L400.5,161.4 C405.2,156.7 405.2,149.1 400.5,144.4 L383.5,127.4 C378.8,122.7 371.2,122.7 366.5,127.4 L345.8,148.1 C314.7,120.6 275.4,102.2 232,97.3 L232,86 L184,86 L184,97.4 C80.4,109.3 0,197.2 0,304 C0,418.9 93.1,512 208,512 C322.9,512 416,418.9 416,304 C416,259.3 401.9,217.9 377.9,184 Z M208,464 C119.6,464 48,392.4 48,304 C48,215.6 119.6,144 208,144 C296.4,144 368,215.6 368,304 C368,392.4 296.4,464 208,464 Z"
        fill="currentColor"
        fillRule="nonzero"
        className={styles.body}
      />
      <path
        d="M232,87.3 L232,48 L260,48 C266.6,48 272,42.6 272,36 L272,12 C272,5.4 266.6,0 260,0 L156,0 C149.4,0 144,5.4 144,12 L144,36 C144,42.6 149.4,48 156,48 L184,48 L184,87.4 C232,87.4 184,87.4 232,87.3 Z"
        fill="currentColor"
        fillRule="nonzero"
        className={styles.head}
      />

      <rect
        x="184"
        y="212"
        width="48"
        height="130"
        rx="12"
        fill="currentColor"
        className={styles.hour}
        style={{ transform: `rotateZ(${hourRotation}deg)` }}
      />
      <rect
        x="188"
        y="192"
        width="40"
        height="160"
        rx="12"
        fill="currentColor"
        className={styles.minute}
        style={{ transform: `rotateZ(${minuteRotation}deg)` }}
      />
    </svg>
  );
};
