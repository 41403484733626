import { FC } from "react";
import { useFormikContext } from "formik";
import classNames from "classnames";
import { CustomerEditPhoneNumberRow } from "./CustomerEditPhoneNumberRow";
import { CustomerEditPhoneNumberCreator } from "./CustomerEditPhoneNumberCreator";
import styles from "./CustomerEditPhoneNumbers.module.scss";

export interface CustomerEditPhoneNumbersModel {
  phoneNumbers: {
    phoneNumberId: string | null;
    remove: boolean;
    displayName: string;
    phoneNumber: string;
  }[];
}

export const CustomerEditPhoneNumbers: FC = function () {
  const formikContext = useFormikContext<CustomerEditPhoneNumbersModel>();
  const phoneNumbers = formikContext.values.phoneNumbers;

  if (formikContext.errors.phoneNumbers) {
    console.error(formikContext.errors.phoneNumbers);
  }

  return (
    <div className="mb-6">
      <div
        className={classNames(
          "grid gap-x-4 gap-y-4 items-baseline",
          styles.grid,
        )}
      >
        {phoneNumbers
          .filter((obj) => !obj.remove)
          .map(({ phoneNumberId, phoneNumber, displayName, remove }) => (
            <CustomerEditPhoneNumberRow
              phoneNumberId={phoneNumberId}
              phoneNumber={phoneNumber}
              displayName={displayName}
              remove={remove}
              key={phoneNumberId ?? phoneNumber}
            />
          ))}
      </div>
      <CustomerEditPhoneNumberCreator />
    </div>
  );
};
