import { FC, useContext, useEffect, useMemo } from "react";
import * as React from "react";
import { useFragment } from "relay-hooks";
import {
  ViewerContext_viewer$data,
  ViewerContext_viewer$key,
} from "./__generated__/ViewerContext_viewer.graphql";
import {
  SubscriptionOptions,
  useSubscription,
} from "../components/SubscriptionManager";
import { ViewerContextSubscription } from "./__generated__/ViewerContextSubscription.graphql";
import { ViewerContext_session$key } from "./__generated__/ViewerContext_session.graphql";
import { parseISO } from "date-fns";
import {
  expectYes,
  MaybeAllowed,
  MaybeEmployee,
  Permission,
  Permissions,
} from "./ViewerUtils";
import { useService } from "@xstate/react/lib";
import { AppContext, AppEvent, AppState, EventTypes } from "./AppMachineUtils";
import { ViewerInfo } from "./AppMachineStorage";
import { useAppService } from "./AppServiceContext";
import {
  defaultFlags,
  Flags,
  FlagKey,
  Preferences,
  defaultPreferences,
  PreferenceValue,
  PreferenceKey,
} from "@mormahr/portal-utils";

const sessionFragment = graphql`
  fragment ViewerContext_session on Session {
    expires
  }
`;

const viewerFragment = graphql`
  fragment ViewerContext_viewer on ViewerUser {
    id
    displayName
    username

    # For AlertController
    email
    emailVerified

    workspaceLayoutPreference
    dashboardStatisticsGraphAdditionalInfoPreference
    customerOverviewAdditionalInfoPreference
    navigationItemWorkspaceDestinationPreference
    customerCategoryIndicatorNoCategoryStylePreference

    canApproveTimeTracks
    canViewStatistics
    shouldViewOtherUsers
    canViewHourlyRates
    canViewOthersPermissions
    canViewCustomerBillings
    canEditCustomerBaseData
    canViewFullTimeTrackList
    canViewLastTwoWeeksTimeTrackList
    canViewTimeTrackEvents
    canEditOrganization
    canCreateTokenForAllUsers
    permissionTimeTrackRemove
    flags

    employee {
      id
      displayName
      printName
    }
  }
`;

export type ViewerContextValue =
  | (Omit<ViewerContext_viewer$data, "flags"> & { flags: Flags })
  | null;

const ViewerContext = React.createContext<ViewerContextValue>(null);
ViewerContext.displayName = "ViewerContext";

export type Props = {
  viewer: ViewerContext_viewer$key | null;
  session: ViewerContext_session$key | null;
  children: React.ReactNode;
};

const ViewerSubscription = graphql`
  subscription ViewerContextSubscription($id: ID!) {
    node(id: $id) {
      node {
        ... on User {
          ...ViewerContext_viewer
        }
      }
    }
  }
`;

export const ViewerContextSubscriptionKind = {
  group: "ViewerContext",
  label: "Nutzerdaten",
};

function extractPermissions(viewer: ViewerContext_viewer$data): Permissions {
  return {
    canApproveTimeTracks: viewer.canApproveTimeTracks,
    canViewStatistics: viewer.canViewStatistics,
    shouldViewOtherUsers: viewer.shouldViewOtherUsers,
    canViewHourlyRates: viewer.canViewHourlyRates,
    canViewOthersPermissions: viewer.canViewOthersPermissions,
    canViewCustomerBillings: viewer.canViewCustomerBillings,
    canEditCustomerBaseData: viewer.canEditCustomerBaseData,
    canViewFullTimeTrackList: viewer.canViewFullTimeTrackList,
    canViewLastTwoWeeksTimeTrackList: viewer.canViewLastTwoWeeksTimeTrackList,
    canViewTimeTrackEvents: viewer.canViewTimeTrackEvents,
    canEditOrganization: viewer.canEditOrganization,
    canCreateTokenForAllUsers: viewer.canCreateTokenForAllUsers,
    permissionTimeTrackRemove: viewer.permissionTimeTrackRemove,
  };
}

function extractFeatureFlags(viewer: ViewerContext_viewer$data): Flags {
  return {
    ...defaultFlags,
    ...Object.fromEntries(viewer.flags.map((key) => [key, true])),
  };
}

export const ViewerProvider: FC<Props> = function (props) {
  const session = useFragment(sessionFragment, props.session);
  const viewer = useFragment(viewerFragment, props.viewer);
  const id = viewer ? viewer.id : null;
  const live = viewer?.flags?.includes("live") ?? false;

  const subscriptionConfig = useMemo<
    SubscriptionOptions<ViewerContextSubscription>
  >(
    () => ({
      kind: ViewerContextSubscriptionKind,
      enable: live && id !== null,
      subscription: ViewerSubscription,
      variables: {
        // @ts-expect-error: subscription is disabled if id is null
        id,
      },
    }),
    [id, live],
  );
  useSubscription<ViewerContextSubscription>(subscriptionConfig);
  const AppService = useAppService();

  useEffect(() => {
    if (viewer !== null && session !== null) {
      if (viewer.employee === null) {
        AppService.send(EventTypes.receiveNonEmployee);
      } else {
        AppService.send({
          type: EventTypes.receiveExpiryAndDataFromNetwork,
          viewer: {
            id: viewer.id,
            displayName: viewer.displayName,
            username: viewer.username,
          },
          employee: {
            id: viewer.employee.id,
            displayName: viewer.employee.displayName,
          },
          expiry: parseISO(session.expires),
          permissions: extractPermissions(viewer),
          flags: extractFeatureFlags(viewer),
          preferences: {
            workspaceLayout:
              viewer.workspaceLayoutPreference as PreferenceValue<"workspaceLayout">,
            dashboardStatisticsGraphAdditionalInfo:
              viewer.dashboardStatisticsGraphAdditionalInfoPreference as PreferenceValue<"dashboardStatisticsGraphAdditionalInfo">,
            customerOverviewAdditionalInfo:
              viewer.customerOverviewAdditionalInfoPreference as PreferenceValue<"customerOverviewAdditionalInfo">,
            navigationItemWorkspaceDestination:
              viewer.navigationItemWorkspaceDestinationPreference as PreferenceValue<"navigationItemWorkspaceDestination">,
            customerCategoryIndicatorNoCategoryStyle:
              viewer.customerCategoryIndicatorNoCategoryStylePreference as PreferenceValue<"customerCategoryIndicatorNoCategoryStyle">,
          },
        });
      }
    }
  }, [viewer, session]);

  if (viewer === null || session === null) {
    return <ViewerContext.Provider value={null} children={props.children} />;
  } else {
    return (
      <ViewerContext.Provider
        value={{
          ...viewer,
          ...extractPermissions(viewer),
          flags: extractFeatureFlags(viewer),
        }}
        children={props.children}
      />
    );
  }
};

export function useViewerEmployee(): MaybeEmployee {
  const viewer = useContext(ViewerContext);
  if (viewer === null) {
    return {
      type: "UNKNOWN",
    };
  }

  if (viewer.employee === null) {
    return {
      type: "NOT_AN_EMPLOYEE",
    };
  }

  return {
    type: "EMPLOYEE",
    employeeId: viewer.employee.id,
    displayName: viewer.employee.displayName,
    printName: viewer.employee.printName,
  };
}

export function useViewerId(): string | null {
  const AppService = useAppService();
  const [state] = useService<AppContext, AppEvent, AppState>(AppService);

  if (
    state.matches("authenticated") ||
    state.matches("probablyAuthenticated")
  ) {
    return state.context.viewer.id;
  } else {
    return null;
  }
}

export function useViewer(): ViewerInfo | null {
  const AppService = useAppService();
  const [state] = useService<AppContext, AppEvent, AppState>(AppService);

  if (
    state.matches("authenticated") ||
    state.matches("probablyAuthenticated")
  ) {
    return state.context.viewer;
  } else {
    return null;
  }
}

export function useViewerUsername(): string | null {
  const AppService = useAppService();
  const [state] = useService<AppContext, AppEvent, AppState>(AppService);

  if (
    state.matches("authenticated") ||
    state.matches("probablyAuthenticated")
  ) {
    return state.context.viewer!.username;
  } else {
    return null;
  }
}

export function usePreferences(): Preferences {
  const AppService = useAppService();
  const [state] = useService<AppContext, AppEvent, AppState>(AppService);

  return state.context.preferences ?? defaultPreferences;
}

export function usePreference<Key extends PreferenceKey>(
  key: Key,
): PreferenceValue<Key> {
  const preferences = usePreferences();
  return preferences[key] as PreferenceValue<Key>;
}

/**
 * Get if a feature is enabled for the current user.
 * Defaults to false if info is not available.
 *
 * @param feature name of feature
 * @return is feature enabled
 */
export function useFeatureFlag(feature: FlagKey): MaybeAllowed {
  const AppService = useAppService();
  const [state] = useService<AppContext, AppEvent, AppState>(AppService);

  switch (state.value) {
    case "pending":
      return MaybeAllowed.UNKNOWN;
    case "probablyAuthenticated":
      // TODO: Is actually safe
      return state.context.flags?.[feature]
        ? MaybeAllowed.PROBABLY_YES
        : MaybeAllowed.PROBABLY_NO;
    case "authenticated":
      // TODO: Is actually safe
      return state.context.flags?.[feature]
        ? MaybeAllowed.YES
        : MaybeAllowed.NO;
    case "probablyUnauthenticated":
      return MaybeAllowed.PROBABLY_NO;
    case "unauthenticated":
    case "error":
    default:
      return MaybeAllowed.NO;
  }
}

/**
 * Related to {@link useViewerPermission}, but coerces the returned value to a boolean, by treating
 * both {@link MaybeAllowed.YES} and {@link MaybeAllowed.PROBABLY_YES} as true and everything else
 * as false. This is the most common use case, so this method should be preferred over using
 * {@link useViewerPermission} directly. Without having a cached or current value
 * {@link MaybeAllowed.UNKNOWN}, it is treated as false by default, but can be changed with the
 * second parameter.
 *
 * @param permission which permission to observe
 * @param treatUnknownAs what to return, if the permissions are unknown
 */
export function useExpectPermission(
  permission: Permission,
  treatUnknownAs = false,
): boolean {
  const value = useViewerPermission(permission);
  return value === MaybeAllowed.UNKNOWN ? treatUnknownAs : expectYes(value);
}

/**
 * Subscribe to the value of the permission. The return value differentiates between the cached
 * value {@link MaybeAllowed.PROBABLY_YES} and {@link MaybeAllowed.PROBABLY_NO}, the current
 * value, fetched since the beginning of the session (meaning since page load or via subscription)
 * {@link MaybeAllowed.YES} and {@link MaybeAllowed.NO} and not having any value, because there
 * isn't a cached value {@link MaybeAllowed.UNKNOWN}.
 *
 * @param permission which permission to observe
 */
export function useViewerPermission(permission: Permission): MaybeAllowed {
  const AppService = useAppService();
  const [state] = useService<AppContext, AppEvent, AppState>(AppService);

  switch (state.value) {
    case "pending":
      return MaybeAllowed.UNKNOWN;
    case "probablyAuthenticated":
      // TODO: Is actually safe
      return state.context.permissions?.[permission]
        ? MaybeAllowed.PROBABLY_YES
        : MaybeAllowed.PROBABLY_NO;
    case "authenticated":
      // TODO: Is actually safe
      return state.context.permissions?.[permission]
        ? MaybeAllowed.YES
        : MaybeAllowed.NO;
    case "probablyUnauthenticated":
      return MaybeAllowed.PROBABLY_NO;
    case "unauthenticated":
    case "error":
    default:
      return MaybeAllowed.NO;
  }
}
