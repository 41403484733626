/**
 * @generated SignedSource<<5c5387b32f9c2fe1aea30359e8331e81>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TodoConnectionProvider_query$data = {
  readonly todos: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      } | null;
    } | null> | null;
    readonly pageInfo: {
      readonly hasNextPage: boolean;
      readonly endCursor: string | null;
    };
    readonly " $fragmentSpreads": FragmentRefs<"TodoListData_todos">;
  } | null;
  readonly " $fragmentType": "TodoConnectionProvider_query";
};
export type TodoConnectionProvider_query$key = {
  readonly " $data"?: TodoConnectionProvider_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"TodoConnectionProvider_query">;
};

import TodoConnectionProviderQuery_graphql from './TodoConnectionProviderQuery.graphql';
const node: ReaderFragment = (function(){
var v0 = [
  "todos"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": 30,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "includeUnassignedEmployee"
    },
    {
      "defaultValue": [],
      "kind": "LocalArgument",
      "name": "onlyCreatedBy"
    },
    {
      "defaultValue": [],
      "kind": "LocalArgument",
      "name": "onlyCustomer"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "onlyDone"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "onlyDueAfter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "onlyDueBefore"
    },
    {
      "defaultValue": [],
      "kind": "LocalArgument",
      "name": "onlyEmployee"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "onlyNotDone"
    },
    {
      "defaultValue": [],
      "kind": "LocalArgument",
      "name": "onlyPriority"
    },
    {
      "defaultValue": [
        "done",
        "priority",
        "due_ts"
      ],
      "kind": "LocalArgument",
      "name": "order"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "searchTerm"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": TodoConnectionProviderQuery_graphql
    }
  },
  "name": "TodoConnectionProvider_query",
  "selections": [
    {
      "alias": "todos",
      "args": [
        {
          "kind": "Variable",
          "name": "includeUnassignedEmployee",
          "variableName": "includeUnassignedEmployee"
        },
        {
          "kind": "Variable",
          "name": "onlyCreatedBy",
          "variableName": "onlyCreatedBy"
        },
        {
          "kind": "Variable",
          "name": "onlyCustomer",
          "variableName": "onlyCustomer"
        },
        {
          "kind": "Variable",
          "name": "onlyDone",
          "variableName": "onlyDone"
        },
        {
          "kind": "Variable",
          "name": "onlyDueAfter",
          "variableName": "onlyDueAfter"
        },
        {
          "kind": "Variable",
          "name": "onlyDueBefore",
          "variableName": "onlyDueBefore"
        },
        {
          "kind": "Variable",
          "name": "onlyEmployee",
          "variableName": "onlyEmployee"
        },
        {
          "kind": "Variable",
          "name": "onlyNotDone",
          "variableName": "onlyNotDone"
        },
        {
          "kind": "Variable",
          "name": "onlyPriority",
          "variableName": "onlyPriority"
        },
        {
          "kind": "Variable",
          "name": "order",
          "variableName": "order"
        },
        {
          "kind": "Variable",
          "name": "searchTerm",
          "variableName": "searchTerm"
        }
      ],
      "concreteType": "TodoConnection",
      "kind": "LinkedField",
      "name": "__TodoConnection_todos_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TodoEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Todo",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TodoListData_todos"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "9b65111cea85236c6a4ff1311d959551";

export default node;
