/**
 * @generated SignedSource<<f6c4503fcb61930f0346d5cd81c7560b>>
 * @relayHash 6530de33933ec959bd84c4787f04d944
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 483ae2753e6486e486ee0ec5e05f9a828088fa18

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type NavigationTodoCountSubscription$variables = {};
export type NavigationTodoCountSubscription$data = {
  readonly viewerTodoCount: number;
};
export type NavigationTodoCountSubscription = {
  variables: NavigationTodoCountSubscription$variables;
  response: NavigationTodoCountSubscription$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "includeUnassignedEmployee",
        "value": true
      }
    ],
    "kind": "ScalarField",
    "name": "viewerTodoCount",
    "storageKey": "viewerTodoCount(includeUnassignedEmployee:true)"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NavigationTodoCountSubscription",
    "selections": (v0/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NavigationTodoCountSubscription",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "483ae2753e6486e486ee0ec5e05f9a828088fa18",
    "metadata": {},
    "name": "NavigationTodoCountSubscription",
    "operationKind": "subscription",
    "text": null
  }
};
})();

(node as any).hash = "70cf74822454ded1194d7b615ebce122";

export default node;
