/**
 * @generated SignedSource<<37e760b59bf5f4e841ad88c143bb75a2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EmptyTimeTrackWidget_employee$data = {
  readonly id: string;
  readonly currentTimeTrack: {
    readonly id: string;
  } | null;
  readonly " $fragmentType": "EmptyTimeTrackWidget_employee";
};
export type EmptyTimeTrackWidget_employee$key = {
  readonly " $data"?: EmptyTimeTrackWidget_employee$data;
  readonly " $fragmentSpreads": FragmentRefs<"EmptyTimeTrackWidget_employee">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EmptyTimeTrackWidget_employee",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "TimeTrack",
      "kind": "LinkedField",
      "name": "currentTimeTrack",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Employee",
  "abstractKey": null
};
})();

(node as any).hash = "2dbbaa17776f3e9bbf97a627ec1b96ca";

export default node;
