import { assign, createMachine } from "xstate";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { MutationConfig, MutationParameters, PayloadError } from "relay-runtime";
import { ActivityConfig, StateMachine } from "xstate/lib/types";

export const MUTATION_WARNING_TIMEOUT = 2000;

export interface MutationContext<TOperation extends MutationParameters> {
  variables: TOperation["variables"];
  response: null | TOperation["response"];
  errors: null | Error[] | ReadonlyArray<PayloadError>;
}

export interface PendingMutationContext<TOperation extends MutationParameters>
  extends MutationContext<TOperation> {
  response: null;
  errors: null;
}

export interface SuccessMutationContext<TOperation extends MutationParameters>
  extends MutationContext<TOperation> {
  response: TOperation["response"];
  errors: null;
}

export type ErrorMutationContext<TOperation extends MutationParameters> = MutationContext<TOperation>

export enum MutationState {
  pending = "pending",
  waiting = "waiting",
  success = "success",
  error = "error",
}

export enum MutationEventType {
  ERROR = "ERROR",
  COMPLETED_WITH_PAYLOAD_ERRORS = "COMPLETED_WITH_PAYLOAD_ERRORS",
  COMPLETED = "COMPLETED",
}

/**
 * General network error. This error is thrown via the {@link MutationConfig.onError} callback.
 */
export interface ErrorEvent {
  readonly type: MutationEventType.ERROR;
  readonly error: Error;
}

/**
 * GraphQL payload errors occurred.
 * These are passed with the {@link MutationConfig.onCompleted} callback.
 */
export interface CompletedWithPayloadErrorsEvent<
  TOperation extends MutationParameters
> {
  readonly type: MutationEventType.COMPLETED_WITH_PAYLOAD_ERRORS;
  readonly response: TOperation["response"];
  readonly payloadErrors: ReadonlyArray<PayloadError>;
}

/**
 * Operation was successfully completed.
 */
export interface CompletedEvent<TOperation extends MutationParameters> {
  readonly type: MutationEventType.COMPLETED;
  readonly response: TOperation["response"];
}

export type MutationEvent<TOperation extends MutationParameters> =
  | CompletedEvent<TOperation>
  | CompletedWithPayloadErrorsEvent<TOperation>
  | ErrorEvent;

export type MutationMachineState<TOperation extends MutationParameters> =
  | {
      value: MutationState.pending;
      context: PendingMutationContext<TOperation>;
    }
  | {
      value: MutationState.success;
      context: SuccessMutationContext<TOperation>;
    }
  | { value: MutationState.error; context: ErrorMutationContext<TOperation> };

export enum MutationActivity {
  waitingInfo = "waitingInfo",
  successInfo = "successInfo",
  errorInfo = "errorInfo",
}

export interface MutationMachineOptions<TOperation extends MutationParameters> {
  activities: Record<
    MutationActivity,
    ActivityConfig<MutationContext<TOperation>, MutationEvent<TOperation>>
  >;
}

export function createMutationMachine<TOperation extends MutationParameters>(
  variables: TOperation["variables"],
  options: MutationMachineOptions<TOperation>,
): StateMachine<
  MutationContext<TOperation>,
  any,
  MutationEvent<TOperation>,
  MutationMachineState<TOperation>
> {
  const setError = assign<MutationContext<TOperation>, ErrorEvent>(
    (context, event) => ({
      errors: [event.error],
    }),
  );

  const setPayloadErrors = assign<
    MutationContext<TOperation>,
    CompletedWithPayloadErrorsEvent<TOperation>
  >((context, event) => ({
    errors: event.payloadErrors,
    response: event.response,
  }));

  const setResponse = assign<
    MutationContext<TOperation>,
    CompletedEvent<TOperation>
  >((context, event) => ({
    response: event.response,
  }));

  return createMachine<
    MutationContext<TOperation>,
    MutationEvent<TOperation>,
    MutationMachineState<TOperation>
  >(
    {
      id: "MutationMachine",
      context: {
        variables,
        response: null,
        errors: null,
      },
      initial: "pending",
      states: {
        [MutationState.pending]: {
          on: {
            [MutationEventType.COMPLETED]: {
              target: MutationState.success,
              actions: [setResponse],
            },
            [MutationEventType.COMPLETED_WITH_PAYLOAD_ERRORS]: {
              target: MutationState.error,
              actions: [setPayloadErrors],
            },
            [MutationEventType.ERROR]: {
              target: MutationState.error,
              actions: [setError],
            },
          },
          after: {
            [MUTATION_WARNING_TIMEOUT]: MutationState.waiting,
          },
        },
        [MutationState.waiting]: {
          activities: [MutationActivity.waitingInfo],
          on: {
            [MutationEventType.COMPLETED]: {
              target: MutationState.success,
              actions: [setResponse],
            },
            [MutationEventType.COMPLETED_WITH_PAYLOAD_ERRORS]: {
              target: MutationState.error,
              actions: [setPayloadErrors],
            },
            [MutationEventType.ERROR]: {
              target: MutationState.error,
              actions: [setError],
            },
          },
        },
        [MutationState.success]: {
          type: "final",
          activities: [MutationActivity.successInfo],
        },
        [MutationState.error]: {
          type: "final",
          activities: [MutationActivity.errorInfo],
        },
      },
    },
    {
      activities: options.activities,
    },
  );
}
