import { FC } from "react";
import { FormGroup, Intent, TextArea } from "@blueprintjs/core";
import { useFormikContext } from "formik";

export interface CustomerEditBillingAddressModel {
  billingAddress: string;
}

export const CustomerEditBillingAddress: FC = function () {
  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched: _touched,
  } = useFormikContext<CustomerEditBillingAddressModel>();

  const error = errors.billingAddress;
  const touched = _touched.billingAddress ?? false;
  const showError = error && touched;

  const helperText = (
    <>
      <div>
        Die Rechnungsanschrift inklusive Name wird direkt als Adresslabel
        verwendet.
      </div>
      {showError && (
        <div>
          <br />
          {error}
        </div>
      )}
    </>
  );

  return (
    <div data-cy="customer-billing-address">
      <FormGroup
        label="Rechnungsadresse"
        helperText={helperText}
        intent={showError ? Intent.DANGER : Intent.NONE}
      >
        <TextArea
          value={values.billingAddress}
          placeholder=""
          name="billingAddress"
          intent={showError ? Intent.DANGER : Intent.NONE}
          onChange={handleChange}
          onBlur={handleBlur}
          growVertically
          fill
        />
      </FormGroup>
    </div>
  );
};
