import { FC } from "react";

export const WorkspaceUsersIcon: FC<{
  className?: string;
}> = function ({ className }) {
  return (
    <svg
      viewBox="0 0 717 512"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M274,429.8 C274,436.198872 275.144784,442.330366 277.240717,448.000848 L48,448 C22.656,448 0.44832,426.04576 0.0067008,400.767021 L0,400 L0,256 L192,256 L192,304 C192,312.734762 198.990539,319.829286 207.685009,319.996965 L208,320 L295.833997,320.00081 C297.754029,330.113123 301.758657,339.493639 307.381597,347.675836 C287.493945,360.550693 274.266541,382.836806 274.003981,408.229578 L274,409 Z M336,0 C361.344,0 383.55168,21.95424 383.993299,47.2329792 L384,48 L384,96 L464,96 C489.344,96 511.55168,117.95424 511.993299,143.232979 L512,144 L512,192 C482.502759,192 455.876334,204.275953 436.950242,223.998342 L0,224 L0,144 C0,118.656 21.95424,96.44832 47.2329792,96.0067008 L48,96 L128,96 L128,48 C128,22.656 149.95424,0.44832 175.232979,0.0067008 L176,0 L336,0 Z M320,64 L192,64 L192,96 L320,96 L320,64 Z"
          fill="#000000"
          fillRule="nonzero"
        />
        <g transform="translate(306, 224)" fill="#000000" fillRule="nonzero">
          <path d="M61.65,123.428571 C84.3192187,123.428571 102.75,104.978571 102.75,82.2857143 C102.75,59.5928571 84.3192187,41.1428571 61.65,41.1428571 C38.9807812,41.1428571 20.55,59.5928571 20.55,82.2857143 C20.55,104.978571 38.9807812,123.428571 61.65,123.428571 Z M349.35,123.428571 C372.019219,123.428571 390.45,104.978571 390.45,82.2857143 C390.45,59.5928571 372.019219,41.1428571 349.35,41.1428571 C326.680781,41.1428571 308.25,59.5928571 308.25,82.2857143 C308.25,104.978571 326.680781,123.428571 349.35,123.428571 Z M369.9,144 L328.8,144 C317.4975,144 307.286719,148.564286 299.837344,155.957143 C325.7175,170.164286 344.084062,195.814286 348.065625,226.285714 L390.45,226.285714 C401.816719,226.285714 411,217.092857 411,205.714286 L411,185.142857 C411,162.45 392.569219,144 369.9,144 Z M205.5,144 C245.251406,144 277.425,111.792857 277.425,72 C277.425,32.2071429 245.251406,0 205.5,0 C165.748594,0 133.575,32.2071429 133.575,72 C133.575,111.792857 165.748594,144 205.5,144 Z M254.82,164.571429 L249.489844,164.571429 C236.132344,171 221.297813,174.857143 205.5,174.857143 C189.702188,174.857143 174.931875,171 161.510156,164.571429 L156.18,164.571429 C115.336875,164.571429 82.2,197.742857 82.2,238.628571 L82.2,257.142857 C82.2,274.178571 96.0070313,288 113.025,288 L297.975,288 C314.992969,288 328.8,274.178571 328.8,257.142857 L328.8,238.628571 C328.8,197.742857 295.663125,164.571429 254.82,164.571429 Z M111.162656,155.957143 C103.713281,148.564286 93.5025,144 82.2,144 L41.1,144 C18.4307813,144 0,162.45 0,185.142857 L0,205.714286 C0,217.092857 9.18328125,226.285714 20.55,226.285714 L62.8701563,226.285714 C66.9159375,195.814286 85.2825,170.164286 111.162656,155.957143 Z" />
        </g>
        <g transform="translate(386, 224)" fill="#000000" fillRule="nonzero">
          <path d="M126,144 C165.76875,144 198,111.76875 198,72 C198,32.23125 165.76875,0 126,0 C86.23125,0 54,32.23125 54,72 C54,111.76875 86.23125,144 126,144 Z M176.4,162 L167.00625,162 C154.51875,167.7375 140.625,171 126,171 C111.375,171 97.5375,167.7375 84.99375,162 L75.6,162 C33.8625,162 0,195.8625 0,237.6 L0,261 C0,275.90625 12.09375,288 27,288 L225,288 C239.90625,288 252,275.90625 252,261 L252,237.6 C252,195.8625 218.1375,162 176.4,162 Z" />
        </g>
      </g>
    </svg>
  );
};
