import { Icon } from "@mormahr/babel-transform-icon";
import { CustomerCategoryPimple_customerCategory$key } from "./__generated__/CustomerCategoryPimple_customerCategory.graphql";
import { useFragment } from "relay-hooks";
import { usePreference } from "../app-controller/ViewerContext";

export interface Props {
  customerCategory?:
    | CustomerCategoryPimple_customerCategory$key
    | null
    | undefined;
}

const fragment = graphql`
  fragment CustomerCategoryPimple_customerCategory on CustomerCategory {
    title
    color
  }
`;

export function CustomerCategoryPimple(props: Props) {
  const customerCategory = useFragment(
    fragment,
    props.customerCategory ?? null,
  );
  const customerCategoryIndicatorNoCategoryStyle = usePreference(
    "customerCategoryIndicatorNoCategoryStyle",
  );

  if (!customerCategory) {
    if (customerCategoryIndicatorNoCategoryStyle !== "OUTLINE") {
      return null;
    }

    return (
      <span className="flex-shrink-0" title="Keine Kundenkategorie">
        <Icon icon="circle" type="regular" />
      </span>
    );
  }

  return (
    <span
      className="flex-shrink-0"
      style={{ color: customerCategory.color }}
      title={customerCategory.title}
    >
      <Icon icon="circle" type="solid" />
    </span>
  );
}
