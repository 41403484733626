import { Icon } from "@mormahr/babel-transform-icon";
import { Component } from "react";
import { createFragmentContainer } from "react-relay";
import { TodoListEntry_todo$data } from "./__generated__/TodoListEntry_todo.graphql";
import {
  setTodoCustomer,
  setTodoEmployee,
  setTodoNote,
  setTodoPriority,
  setTodoTitle,
  startTimeTrackFromTodo,
} from "../mutations/TodoMutations";
import { CustomerChooser } from "../../../components/CustomerChooser";
import { EmployeeChooser } from "../../../components/EmployeeChooser";
import { TodoDoneButton } from "./TodoDoneButton";
import { TextButton } from "../../../components/button/TextButton";
import { TimeTrackLink } from "../../../components/link/TimeTrackLink";
import { TableAction } from "../../../components/action/TableAction";
import styles from "./TodoListEntry.module.scss";
import {
  Classes,
  ContextMenuTarget,
  EditableText,
  IContextMenuTargetComponent,
  Menu,
  MenuItem,
} from "@blueprintjs/core";
import classnames from "classnames";
import { CustomerChooserButton } from "../../../components/CustomerChooserButton";
import { IEnvironment } from "relay-runtime";
import classNames from "classnames";
import { parseISO, formatRelative } from "date-fns";
import { TimeFromNow } from "../../../components/TimeFromNow";

export type Props = {
  todo: TodoListEntry_todo$data;
  relayEnvironment: IEnvironment;
};

const TODO_PRIORITY_URGENT = "urgent";
const TODO_PRIORITY_IMPORTANT = "important";
const TODO_PRIORITY_NORMAL = "normal";
const TODO_PRIORITY_LOW = "low";
type PriorityKey = "urgent" | "important" | "normal" | "low";

@ContextMenuTarget
export class TodoListEntryComponent
  extends Component<Props>
  implements IContextMenuTargetComponent
{
  setTodoTitle = (value: string) => {
    setTodoTitle(this.props.relayEnvironment, this.props.todo.id, value);
  };

  setTodoNote = (value: string) => {
    setTodoNote(this.props.relayEnvironment, this.props.todo.id, value);
  };

  setTodoCustomer = (value: Optional<string>) => {
    setTodoCustomer(this.props.relayEnvironment, this.props.todo.id, value);
  };

  setTodoEmployee = (value: Optional<string>) => {
    setTodoEmployee(this.props.relayEnvironment, this.props.todo.id, value);
  };

  startTimeTrackFromTodo = () => {
    startTimeTrackFromTodo(this.props.relayEnvironment, this.props.todo.id);
  };

  setPriority(key: PriorityKey) {
    setTodoPriority(this.props.relayEnvironment, this.props.todo.id, key);
  }

  renderContextMenu() {
    return (
      <Menu>
        <li className={Classes.MENU_HEADER}>Priorität</li>
        <MenuItem
          text="Dringend"
          onClick={() => this.setPriority(TODO_PRIORITY_URGENT)}
          active={this.props.todo.priority.key === TODO_PRIORITY_URGENT}
          icon={
            <div className={classnames(styles.icon, styles.urgent)}>
              <Icon icon="triangle-exclamation" type="solid" fixedWidth />
            </div>
          }
        />
        <MenuItem
          text="Wichtig"
          onClick={() => this.setPriority(TODO_PRIORITY_IMPORTANT)}
          active={this.props.todo.priority.key === TODO_PRIORITY_IMPORTANT}
          icon={
            <div className={classnames(styles.icon, styles.important)}>
              <Icon icon="exclamation" type="solid" fixedWidth />
            </div>
          }
        />
        <MenuItem
          text="Normal"
          onClick={() => this.setPriority(TODO_PRIORITY_NORMAL)}
          active={this.props.todo.priority.key === TODO_PRIORITY_NORMAL}
          icon={
            <div className={classnames(styles.icon, styles.normal)}>
              <Icon icon="exclamation" type="solid" fixedWidth />
            </div>
          }
        />
        <MenuItem
          text="Niedrig"
          onClick={() => this.setPriority(TODO_PRIORITY_LOW)}
          active={this.props.todo.priority.key === TODO_PRIORITY_LOW}
          icon={
            <div className={classnames(styles.icon, styles.low)}>
              <Icon icon="arrow-down" type="solid" fixedWidth />
            </div>
          }
        />
      </Menu>
    );
  }

  render() {
    const { todo } = this.props;

    return (
      <div className={styles.todo} data-cy="todo-list-entry">
        <div className={styles.done}>
          <TodoDoneButton todo={todo} />
        </div>
        <div className={styles.title}>
          <EditableText
            defaultValue={todo.title ?? undefined}
            placeholder="Titel"
            onConfirm={this.setTodoTitle}
          />
          {todo.due && !todo.done && (
            <TimeFromNow
              date={todo.due}
              children={({ time, isInFuture }) => (
                <span
                  className={classNames("text-gray-500 ml-4", {
                    "text-red-500": !isInFuture,
                  })}
                >
                  fällig {time}
                </span>
              )}
            />
          )}
        </div>
        <div
          className={classnames(styles.blip, {
            [styles.urgent]: todo.priority.key === TODO_PRIORITY_URGENT,
            [styles.important]: todo.priority.key === TODO_PRIORITY_IMPORTANT,
            [styles.low]: todo.priority.key === TODO_PRIORITY_LOW,
          })}
        />
        <div className={styles.customer}>
          <CustomerChooser
            customer={todo.customer}
            changeCallback={(
              value: { id: string; displayName: string } | null,
            ) => this.setTodoCustomer(value ? value.id : null)}
            renderButton={(props) => (
              <CustomerChooserButton customer={todo.customer} {...props} />
            )}
          />
        </div>
        <div className={styles.employee}>
          <EmployeeChooser
            employee={todo.employee}
            changeCallback={(employee) => {
              this.setTodoEmployee(employee ? employee.id : null);
            }}
          />
        </div>
        <div className={styles.action}>
          <TableAction>
            {todo.timeTrack ? (
              <TimeTrackLink timeTrack={todo.timeTrack}>
                <Icon icon="arrow-up-right-from-square" type="regular" />
              </TimeTrackLink>
            ) : !todo.done ? (
              <TextButton
                title="Zeiterfassung starten"
                onClick={this.startTimeTrackFromTodo}
                disabled={todo.done}
              >
                <Icon icon="stopwatch" type="regular" />
              </TextButton>
            ) : null}
          </TableAction>
        </div>
      </div>
    );
  }
}

export const TodoListEntry = createFragmentContainer(TodoListEntryComponent, {
  todo: graphql`
    fragment TodoListEntry_todo on Todo {
      id
      title
      note
      ...TodoDoneButton_todo

      due

      done

      priority {
        key
      }

      timeTrack {
        id

        ...TimeTrackLink_timeTrack
      }

      customer {
        ...CustomerChooser_customer @relay(mask: false)
      }

      employee {
        ...EmployeeChooser_employee @relay(mask: false)
      }
    }
  `,
});
