/**
 * @generated SignedSource<<07f3e613cb0f312902310aa9e97959b1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CustomerCategoryIndicatorNoCategoryStylePreference = "NONE" | "OUTLINE" | "%future added value";
export type CustomerOverviewAdditionalInfoPreference = "NONE" | "STATISTICS" | "REQUIRED_CHECKS" | "RECOMMENDED_CHECKS" | "%future added value";
export type DashboardStatisticsGraphAdditionalInfoPreference = "NONE" | "REGRESSION" | "LAST_YEAR" | "MOVING_AVERAGE_EIGHT_WEEKS" | "MOVING_AVERAGE_TWELVE_WEEKS" | "%future added value";
export type FeatureFlagKey = "alerts" | "search" | "live" | "christmas" | "tips" | "darkMode" | "snake" | "timeTrackDescription" | "monthlyCustomerBillings" | "subscriptionActivityButton" | "calls" | "insightsDailyQuarterHourlyHistogram" | "halloween" | "customerLocations" | "%future added value";
export type NavigationItemWorkspaceDestinationPreference = "CURRENT_USER" | "ALL_USERS" | "%future added value";
export type WorkspaceLayoutPreference = "COMPACT" | "EXPANDED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ViewerContext_viewer$data = {
  readonly id: string;
  readonly displayName: string;
  readonly username: string;
  readonly email: any | null;
  readonly emailVerified: boolean | null;
  readonly workspaceLayoutPreference: WorkspaceLayoutPreference;
  readonly dashboardStatisticsGraphAdditionalInfoPreference: DashboardStatisticsGraphAdditionalInfoPreference;
  readonly customerOverviewAdditionalInfoPreference: CustomerOverviewAdditionalInfoPreference;
  readonly navigationItemWorkspaceDestinationPreference: NavigationItemWorkspaceDestinationPreference;
  readonly customerCategoryIndicatorNoCategoryStylePreference: CustomerCategoryIndicatorNoCategoryStylePreference;
  readonly canApproveTimeTracks: boolean;
  readonly canViewStatistics: boolean;
  readonly shouldViewOtherUsers: boolean;
  readonly canViewHourlyRates: boolean;
  readonly canViewOthersPermissions: boolean;
  readonly canViewCustomerBillings: boolean;
  readonly canEditCustomerBaseData: boolean;
  readonly canViewFullTimeTrackList: boolean;
  readonly canViewLastTwoWeeksTimeTrackList: boolean;
  readonly canViewTimeTrackEvents: boolean;
  readonly canEditOrganization: boolean;
  readonly canCreateTokenForAllUsers: boolean;
  readonly permissionTimeTrackRemove: boolean;
  readonly flags: ReadonlyArray<FeatureFlagKey>;
  readonly employee: {
    readonly id: string;
    readonly displayName: string;
    readonly printName: string;
  } | null;
  readonly " $fragmentType": "ViewerContext_viewer";
};
export type ViewerContext_viewer$key = {
  readonly " $data"?: ViewerContext_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"ViewerContext_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ViewerContext_viewer",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emailVerified",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workspaceLayoutPreference",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dashboardStatisticsGraphAdditionalInfoPreference",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerOverviewAdditionalInfoPreference",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "navigationItemWorkspaceDestinationPreference",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerCategoryIndicatorNoCategoryStylePreference",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canApproveTimeTracks",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canViewStatistics",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shouldViewOtherUsers",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canViewHourlyRates",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canViewOthersPermissions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canViewCustomerBillings",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canEditCustomerBaseData",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canViewFullTimeTrackList",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canViewLastTwoWeeksTimeTrackList",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canViewTimeTrackEvents",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canEditOrganization",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canCreateTokenForAllUsers",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "permissionTimeTrackRemove",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "flags",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Employee",
      "kind": "LinkedField",
      "name": "employee",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "printName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ViewerUser",
  "abstractKey": null
};
})();

(node as any).hash = "1331d84bafa4fdfcb1f464808882c581";

export default node;
