import { HeaderLoader } from "../../components/HeaderLoading";
import { UsersQuery } from "./__generated__/UsersQuery.graphql";
import { Redirect } from "react-router-dom";
import { Header } from "../../components/Header";
import { useViewerPermission } from "../../app-controller/ViewerContext";
import { expectYes } from "../../app-controller/ViewerUtils";
import { STORE_THEN_NETWORK, useQuery } from "relay-hooks";
import loadable from "@loadable/component";
import { FC } from "react";

const UserList = loadable(
  () => import(/* webpackChunkName: "UserList" */ "./UserList"),
);

const query = graphql`
  query UsersQuery {
    ...UserList_query
  }
`;

export const Users: FC = function Users() {
  const shouldViewOtherUsers = useViewerPermission("shouldViewOtherUsers");
  const {
    data: props,
    error,
    isLoading,
  } = useQuery<UsersQuery>(query, {}, { fetchPolicy: STORE_THEN_NETWORK });

  if (!expectYes(shouldViewOtherUsers)) {
    return <Redirect to="/" />;
  }

  if (error) {
    throw error;
  }

  return (
    <>
      <Header title="Übersicht" subTitle="Nutzer" isLoading={isLoading} />
      {props ? (
        <UserList query={props} fallback={<HeaderLoader />} />
      ) : (
        <HeaderLoader />
      )}
    </>
  );
};
