import { FC, ReactElement } from "react";

export const TimeTrackHistoryRowBooleanChange: FC<{
  field: [boolean, boolean];
  turnOff: ReactElement;
  turnOn: ReactElement;
}> = function TimeTrackHistoryRowBooleanChange({ field, turnOff, turnOn }) {
  if (!field[0] && field[1]) {
    return turnOn;
  } else if (field[0] && !field[1]) {
    return turnOff;
  } else {
    throw new Error("Invalid change");
  }
};
