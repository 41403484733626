import { createContext, useContext } from "react";
import { Interpreter } from "xstate";
import { AppContext, AppEvent, AppState } from "./AppMachineUtils";

export type AppServiceType = Interpreter<AppContext, any, AppEvent, AppState>;

export const AppServiceContext = createContext<
  Interpreter<AppContext, any, AppEvent, AppState>
  // @ts-expect-error
>(null);

export function useAppService(): AppServiceType {
  return useContext(AppServiceContext);
}
