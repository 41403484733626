import { FC } from "react";
import styles from "./CustomerEditExtraBillingItems.module.scss";

import { CustomerEditExtraBillingItemsCreator } from "./CustomerEditExtraBillingItemsCreator";
import classNames from "classnames";
import { CustomerEditExtraBillingItemsRow } from "./CustomerEditExtraBillingItemsRow";
import { useFormikContext } from "formik";
import { CustomerEditExtraBillingItemsModel } from "./CustomerEditExtraBillingItemsModel";

export const CustomerEditExtraBillingItems: FC = function () {
  const formikContext = useFormikContext<CustomerEditExtraBillingItemsModel>();
  const items = formikContext.values.extraBillingItems;

  return (
    <div className={classNames("grid gap-x-4 gap-y-4 mb-6", styles.grid)}>
      {items.map(({ item, amount }) => (
        <CustomerEditExtraBillingItemsRow
          item={item}
          amount={amount}
          key={item.id}
        />
      ))}
      <CustomerEditExtraBillingItemsCreator
        filter={items.map((item) => item.item.id)}
      />
    </div>
  );
};
