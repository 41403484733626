import {
  commitMutation,
  GraphQLTaggedNode,
  IEnvironment,
  MutationParameters,
  PayloadError,
  SelectorStoreUpdater,
} from "relay-runtime";
import { interpret } from "xstate";
import {
  createMutationMachine,
  MutationActivity,
  MutationEventType,
  MutationState,
} from "./MutationMachine";
import { AppToaster } from "../app/toaster";
import { Classes, Intent, IToastProps } from "@blueprintjs/core";
import { Icon } from "@mormahr/babel-transform-icon";
import { DeclarativeMutationConfig } from "relay-runtime/lib/mutations/RelayDeclarativeMutationConfig";

export enum MutationCompletionStatus {
  success,
  error,
}

export interface DoMutationConfig<TOperation extends MutationParameters> {
  environment: IEnvironment;
  variables: TOperation["variables"];
  mutation: GraphQLTaggedNode;
  optimisticResponse?: TOperation["response"];
  optimisticUpdater?: SelectorStoreUpdater<TOperation["response"]> | null;
  updater?: SelectorStoreUpdater<TOperation["response"]> | null;
  configs?: DeclarativeMutationConfig[];

  /**
   * Custom error message shown for any errors.
   */
  errorMessage?: string;

  /**
   * Custom success message.
   * By default, no success message will be shown. Only when one is provided a toast will be shown.
   * @deprecated use successToast instead.
   */
  successMessage?: string;

  /**
   * Custom success toast options.
   * By default, no toast will ne shown. Only when this property is provided, one will be shown.
   *
   * Replaces {@link successMessage}.
   */
  successToast?: IToastProps;

  /**
   * Called when final state is reached.
   */
  onComplete?: (
    status: MutationCompletionStatus,
    response: TOperation["response"],
    errors: ReadonlyArray<PayloadError> | null | undefined,
  ) => void;
}

export function doMutation<TOperation extends MutationParameters>({
  environment,
  ...config
}: DoMutationConfig<TOperation>) {
  const machine = createMutationMachine<TOperation>(config.variables, {
    activities: {
      [MutationActivity.waitingInfo]: () => {
        const toast = AppToaster().show({
          message: "Aktion wurde noch nicht abgeschlossen und dauert lange...",
          intent: Intent.WARNING,
          icon: (
            <span className={Classes.ICON}>
              <Icon icon="hourglass-end" type="regular" />
            </span>
          ),
        });

        return () => AppToaster().dismiss(toast);
      },
      [MutationActivity.successInfo]: () => {
        if (config.successToast) {
          AppToaster().show(config.successToast);
        } else if (config.successMessage) {
          AppToaster().show({
            message: config.successMessage,
            intent: Intent.SUCCESS,
          });
        }
      },
      [MutationActivity.errorInfo]: (ctx) => {
        if (
          Array.isArray(ctx.errors) &&
          ctx.errors.length === 1 &&
          ctx.errors[0].message === "Unauthorized"
        ) {
          AppToaster().show({
            message:
              "Die Änderung konnte nicht durchgeführt werden, da Sie nicht über die nötigen " +
              "Benutzerrechte verfügen. Bitte wenden Sie sich an den Administrator.",
            intent: Intent.DANGER,
            timeout: 10000,
            icon: (
              <span className={Classes.ICON}>
                <Icon icon="user-xmark" type="solid" />
              </span>
            ),
          });
        } else {
          AppToaster().show({
            message: config.errorMessage ?? "Ein Fehler ist aufgetreten.",
            intent: Intent.DANGER,
          });
        }
      },
    },
  });
  const service = interpret(machine);
  service.start();

  service.onDone(() => {
    config.onComplete?.(
      service.state.value === MutationState.error
        ? MutationCompletionStatus.error
        : MutationCompletionStatus.success,
      service.state.context.response!,
      service.state.context.errors,
    );
  });

  commitMutation<TOperation>(environment, {
    ...config,
    onCompleted(response, payloadErrors) {
      if (
        payloadErrors === null ||
        payloadErrors === undefined ||
        payloadErrors.length === 0
      ) {
        service.send({
          type: MutationEventType.COMPLETED,
          response,
        });
      } else {
        service.send({
          type: MutationEventType.COMPLETED_WITH_PAYLOAD_ERRORS,
          response,
          payloadErrors,
        });
      }
    },
    onError(error) {
      service.send({
        type: MutationEventType.ERROR,
        error,
      });
    },
  });
}
