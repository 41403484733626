import { FC, useMemo } from "react";
import { CallWidgetHost_employee$key } from "./__generated__/CallWidgetHost_employee.graphql";
import {
  SubscriptionOptions,
  useSubscription,
} from "../../../components/SubscriptionManager";
import { useFragment } from "relay-hooks";
import { CallWidgetHostSubscription } from "./__generated__/CallWidgetHostSubscription.graphql";
import { useFeatureFlag } from "../../../app-controller/ViewerContext";
import { expectYes } from "../../../app-controller/ViewerUtils";
import loadable from "@loadable/component";

const CallWidget = loadable(
  () => import(/* webpackChunkName: "CallWidget" */ "./CallWidget"),
);

const CurrentCallSubscription = graphql`
  subscription CallWidgetHostSubscription {
    currentCall {
      ...CallWidget_call
    }
  }
`;

export const CallWidgetSubscriptionKind = {
  group: "CallWidget",
  label: "Aktueller Anruf",
};

const fragment = graphql`
  fragment CallWidgetHost_employee on Employee {
    currentCall {
      ...CallWidget_call
    }
  }
`;

export const CallWidgetHost: FC<{
  employee: CallWidgetHost_employee$key | null;
}> = function (props) {
  const enable = expectYes(useFeatureFlag("calls"));
  const subscriptionConfig = useMemo<
    SubscriptionOptions<CallWidgetHostSubscription>
  >(
    () => ({
      kind: CallWidgetSubscriptionKind,
      enable,
      subscription: CurrentCallSubscription,
      variables: {},
      updater(store) {
        const currentCall = store.getRootField("currentCall");
        const employee = store
          .getRoot()
          .getLinkedRecord("viewer")
          ?.getLinkedRecord("employee");

        if (currentCall !== null) {
          employee?.setLinkedRecord(currentCall, "currentCall");
        } else {
          employee?.setValue(null, "currentCall");
        }
      },
    }),
    [enable],
  );
  useSubscription<CallWidgetHostSubscription>(subscriptionConfig);
  const employee = useFragment(fragment, props.employee);

  if (enable && employee && employee.currentCall) {
    return <CallWidget call={employee.currentCall} />;
  } else {
    return null;
  }
};
