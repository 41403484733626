import loadable from "@loadable/component";
import { FC } from "react";
import { HeaderLoader } from "../../components/HeaderLoading";

const OAuthAuthorize = loadable(
  () => import(/* webpackChunkName: "OAuthAuthorize" */ "./OAuthAuthorize"),
);

export const OAuthAuthorizeRoute: FC = function () {
  return <OAuthAuthorize fallback={<HeaderLoader />} />;
};
