import { Icon } from "@mormahr/babel-transform-icon";
import { useCallback } from "react";
import { createFragmentContainer } from "react-relay";
import { EmptyTimeTrackWidget_employee$data } from "./__generated__/EmptyTimeTrackWidget_employee.graphql";
import styles from "./TimeTrackWidget.module.scss";
import { doMutation } from "../../mutation/doMutation";
import { EmptyTimeTrackWidget_createTimeTrackMutation } from "./__generated__/EmptyTimeTrackWidget_createTimeTrackMutation.graphql";
import { increaseTotalCount } from "../timetrack/mutations/TimeTrackMutations";
import ConnectionHandler from "relay-connection-handler-plus";
import { useRelayEnvironment } from "relay-hooks";
import { addTimeTrackEventEdge } from "../../util/addTimeTrackEventEdge";
import { isNodeIdInConnection } from "../../util/isNodeIdInConnection";
import { LiveStopwatch } from "../../components/LiveStopwatch";

type Props = {
  employee: EmptyTimeTrackWidget_employee$data;
};

export function EmptyTimeTrackWidgetComponent({ employee }: Props) {
  const environment = useRelayEnvironment();

  const onClick = useCallback(
    function onClick() {
      const variables: EmptyTimeTrackWidget_createTimeTrackMutation["variables"] =
        {
          input: {
            employeeId: employee.id,
            recordedStart: new Date().toISOString(),
            withWidget: true,
          },
        };

      doMutation<EmptyTimeTrackWidget_createTimeTrackMutation>({
        environment,
        mutation: graphql`
          mutation EmptyTimeTrackWidget_createTimeTrackMutation(
            $input: createTimeTrackInput!
          ) {
            createTimeTrack(input: $input) {
              createdTimeTrackEdge {
                cursor
                node {
                  # Immer beide Fragmente ändern!
                  id

                  recordedStart
                  roundedStart

                  createdBy {
                    id
                    username
                  }

                  ...TimeTrackRow_timeTrack
                }
              }

              event {
                id
                timeTrack {
                  id
                }

                ...TimeTrackHistoryRow_event
              }
            }
          }
        `,
        variables,
        updater: (store) => {
          const event = store
            .getRootField("createTimeTrack")
            ?.getLinkedRecord("event");
          if (event) {
            addTimeTrackEventEdge(store, event);
          }

          const payload = store.getRootField("createTimeTrack");

          const timeTrack = payload!
            .getLinkedRecord("createdTimeTrackEdge")!
            .getLinkedRecord("node");
          const employeeProxy = store.get(employee.id);
          employeeProxy!.setLinkedRecord(timeTrack, "currentTimeTrack");

          increaseTotalCount(store);

          const user = timeTrack.getLinkedRecord("createdBy")!;

          const connections = ConnectionHandler.getConnections(
            store.getRoot(),
            "TimeTrackPagination_timeTracks",
            (variables) =>
              !variables.onlyCreatedByOrAssignedUser ||
              variables.onlyCreatedByOrAssignedUser ===
                user.getValue("username") ||
              variables.onlyCreatedByOrAssignedUser === user.getValue("id"),
          );

          for (const connection of connections) {
            if (isNodeIdInConnection(connection, timeTrack.getValue("id"))) {
              continue;
            }

            ConnectionHandler.insertEdgeBefore(
              connection,
              payload!.getLinkedRecord("createdTimeTrackEdge")!,
            );
          }
        },
      });
    },
    [environment, employee],
  );

  return (
    <div className="flex justify-around">
      <div
        onClick={onClick}
        className={styles.button}
        role="button"
        title="Zeiterfassung starten"
        data-cy="widget-timetrack-start"
      >
        <LiveStopwatch />
      </div>
    </div>
  );
}

export const EmptyTimeTrackWidget = createFragmentContainer(
  EmptyTimeTrackWidgetComponent,
  {
    employee: graphql`
      fragment EmptyTimeTrackWidget_employee on Employee {
        id

        currentTimeTrack {
          id
        }
      }
    `,
  },
);
