/**
 * @generated SignedSource<<f1b37baad1fd0784174bca63a3d13813>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RunningTimeTrackWidget_timeTrack$data = {
  readonly id: string;
  readonly recordedStart: string | null;
  readonly title: string | null;
  readonly note: string | null;
  readonly associatedCustomer: {
    readonly id: string;
    readonly displayName: string;
    readonly printName: string;
    readonly reference: string | null;
    readonly noticeText: string | null;
    readonly category: {
      readonly note: string;
    } | null;
  } | null;
  readonly todo: {
    readonly id: string;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"TimeTrackRow_timeTrack" | "TimeTrackEditorDialog_timeTrack" | "TimeTrackTimeChooserPopover_timeTrack">;
  readonly " $fragmentType": "RunningTimeTrackWidget_timeTrack";
};
export type RunningTimeTrackWidget_timeTrack$key = {
  readonly " $data"?: RunningTimeTrackWidget_timeTrack$data;
  readonly " $fragmentSpreads": FragmentRefs<"RunningTimeTrackWidget_timeTrack">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "note",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RunningTimeTrackWidget_timeTrack",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "recordedStart",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Customer",
      "kind": "LinkedField",
      "name": "associatedCustomer",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "printName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reference",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "noticeText",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CustomerCategory",
          "kind": "LinkedField",
          "name": "category",
          "plural": false,
          "selections": [
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Todo",
      "kind": "LinkedField",
      "name": "todo",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TimeTrackRow_timeTrack"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TimeTrackEditorDialog_timeTrack"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TimeTrackTimeChooserPopover_timeTrack"
    }
  ],
  "type": "TimeTrack",
  "abstractKey": null
};
})();

(node as any).hash = "0685a773f9f1ab7ebe440f6e7f73755a";

export default node;
