import { FC } from "react";
import { FormGroup, InputGroup, Intent } from "@blueprintjs/core";
import { useFormikContext } from "formik";
import { CustomerEditDisplayNameModel } from "./CustomerEditDisplayName";

export interface CustomerEditPrintNameModel
  extends CustomerEditDisplayNameModel {
  printName: string;
}

export const CustomerEditPrintName: FC<{
  showPlaceholderFromDisplayName?: boolean;
}> = function (props) {
  const {
    values,
    handleChange,
    handleBlur,
    setFieldValue,
    errors,
    touched: _touched,
  } = useFormikContext<CustomerEditPrintNameModel>();

  const error = errors.printName;
  const touched = _touched.printName ?? false;
  const showError = error && touched;
  const showValueFromDisplayName =
    props.showPlaceholderFromDisplayName && !touched && values.printName === "";

  const helperText = (
    <>
      <div>
        Der Druckname wird auf Abrechnungen oder gegenüber dem Kunden verwendet.
      </div>
      {showError && (
        <div>
          <br />
          {error}
        </div>
      )}
    </>
  );

  return (
    <div data-cy="customer-printname">
      <FormGroup
        label="Druckname"
        labelInfo="(benötigt)"
        intent={showError ? Intent.DANGER : Intent.NONE}
        helperText={helperText}
      >
        <InputGroup
          value={
            showValueFromDisplayName ? values.displayName : values.printName
          }
          placeholder="Druckname"
          name="printName"
          intent={showError ? Intent.DANGER : Intent.NONE}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={() => {
            if (showValueFromDisplayName) {
              setFieldValue("printName", values.displayName);
            }
          }}
        />
      </FormGroup>
    </div>
  );
};
