/**
 * @generated SignedSource<<da7a9d56b44f34b440298c9798266ece>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TimeTrackWidget_employee$data = {
  readonly id: string;
  readonly currentTimeTrack: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"RunningTimeTrackWidget_timeTrack">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"EmptyTimeTrackWidget_employee" | "RunningTimeTrackWidget_employee">;
  readonly " $fragmentType": "TimeTrackWidget_employee";
};
export type TimeTrackWidget_employee$key = {
  readonly " $data"?: TimeTrackWidget_employee$data;
  readonly " $fragmentSpreads": FragmentRefs<"TimeTrackWidget_employee">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TimeTrackWidget_employee",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "TimeTrack",
      "kind": "LinkedField",
      "name": "currentTimeTrack",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RunningTimeTrackWidget_timeTrack"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EmptyTimeTrackWidget_employee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RunningTimeTrackWidget_employee"
    }
  ],
  "type": "Employee",
  "abstractKey": null
};
})();

(node as any).hash = "22ee6eb0696b26519ebb62a9d1876d36";

export default node;
