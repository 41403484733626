/**
 * @generated SignedSource<<e5a407f377dbe7352790b8dc18732844>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CustomerRow_customer$data = {
  readonly id: string;
  readonly displayName: string;
  readonly link: string;
  readonly archived: boolean;
  readonly reference: string | null;
  readonly statistics?: {
    readonly " $fragmentSpreads": FragmentRefs<"CustomerRowStatistics_statistics">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"CustomerRowChecks_customer">;
  readonly " $fragmentType": "CustomerRow_customer";
};
export type CustomerRow_customer$key = {
  readonly " $data"?: CustomerRow_customer$data;
  readonly " $fragmentSpreads": FragmentRefs<"CustomerRow_customer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "disableStatistics"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CustomerRow_customer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "link",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "archived",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reference",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CustomerRowChecks_customer"
    },
    {
      "condition": "disableStatistics",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Statistics",
          "kind": "LinkedField",
          "name": "statistics",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CustomerRowStatistics_statistics"
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Customer",
  "abstractKey": null
};

(node as any).hash = "f06b2c0bbd412efc9c4cbfb31ca51c86";

export default node;
