/**
 * @generated SignedSource<<73599c958a7c029df3e561bff7816b03>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DashboardApproveTimeTracks_query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DashboardApproveTimeTracksList_query">;
  readonly " $fragmentType": "DashboardApproveTimeTracks_query";
};
export type DashboardApproveTimeTracks_query$key = {
  readonly " $data"?: DashboardApproveTimeTracks_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardApproveTimeTracks_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DashboardApproveTimeTracks_query",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardApproveTimeTracksList_query"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "199dc77214a9161d491410dcc23498e1";

export default node;
