import { Icon } from "@mormahr/babel-transform-icon";
import { useViewerEmployee } from "../app-controller/ViewerContext";

export type Props = {
  value: Optional<string>;
  extended: boolean;
  highlightMissing: boolean;
};

export const EmployeeChooserIcon = ({ value }: { value: Optional<string> }) => {
  const viewerEmployee = useViewerEmployee();

  if (value == null || value === "") {
    return (
      <Icon
        icon="user-slash"
        type="regular"
        fixedWidth
        title="Kein Mitarbeiter zugewiesen"
      />
    );
  }

  if (viewerEmployee.type === "UNKNOWN") {
    return (
      <Icon icon="spinner" type="regular" fixedWidth title="Wird geladen" />
    );
  }

  if (viewerEmployee.type === "NOT_AN_EMPLOYEE") {
    return (
      <Icon
        icon="user"
        type="regular"
        fixedWidth
        title="Kein Mitarbeiter zugewiesen"
      />
    );
  }

  if (viewerEmployee.displayName === value) {
    return (
      <Icon
        icon="user-check"
        type="regular"
        fixedWidth
        title="Du bist zugewiesen"
      />
    );
  } else {
    return (
      <Icon
        icon="user-group"
        type="regular"
        fixedWidth
        title={`${value.trim()} zugewiesen`}
      />
    );
  }
};

export const EmployeeChooserValue = ({
  value,
  extended,
  highlightMissing,
}: Props) => {
  const className =
    (value == null || value === "") && highlightMissing ? "text-danger" : "";

  if (extended && (value == null || value === "")) {
    return (
      <span className={className}>
        {value} <EmployeeChooserIcon value={value} />
      </span>
    );
  } else {
    return (
      <span className={className}>
        <EmployeeChooserIcon value={value} />
      </span>
    );
  }
};

EmployeeChooserValue.defaultProps = {
  highlightMissing: false,
  extended: false,
};
