import { Select } from "@blueprintjs/select";
import { FC, useMemo } from "react";
import { MenuItem } from "@blueprintjs/core";
import { addMonths, format, isBefore, parseISO } from "date-fns";

const MonthSelect = Select.ofType<string>();

export interface Props {
  onSelect: (month: string) => void;
  selected: string;
}

function generateMonths(): string[] {
  const months: string[] = [];
  let current = parseISO("2019-01");
  const end = addMonths(new Date(), 1);

  while (isBefore(current, end)) {
    months.push(format(current, "yyyy-MM"));
    current = addMonths(current, 1);
  }

  months.reverse();
  return months;
}

export const CustomerBillingsMonthSelector: FC<Props> = function CustomerBillingsMonthSelector({
  onSelect,
  selected,
  children,
}) {
  const items = useMemo(generateMonths, []);

  return (
    <MonthSelect
      items={items}
      itemRenderer={(item, { handleClick, modifiers }) => {
        if (!modifiers.matchesPredicate) {
          return null;
        }

        return (
          <MenuItem
            active={modifiers.active}
            onClick={handleClick}
            text={format(parseISO(item), "MMMM yyyy")}
            label={item === selected ? "Ausgewählt" : ""}
            key={item}
          />
        );
      }}
      onItemSelect={onSelect}
    >
      {children}
    </MonthSelect>
  );
};
