import createAutoCorrectedDatePipe from "text-mask-addons/dist/createAutoCorrectedDatePipe";
import invariant from "invariant";

const datePipePattern = "dd.mm.yy   HH:MM - HH:MM";
export const autoCorrectedDatePipe = createAutoCorrectedDatePipe(
  datePipePattern,
);

export const dateMask = [/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/];
export const dateTimeSeparator = [" ", " ", " "];
export const timeMask = [/\d/, /\d/, ":", /\d/, /\d/];
export const timeSeparator = [" ", "-", " "];

export const endTimeOffset =
  dateMask.length +
  dateTimeSeparator.length +
  timeMask.length +
  timeSeparator.length;

export const startTimeOffset = dateMask.length + dateTimeSeparator.length;

export const mask = [
  ...dateMask,
  ...dateTimeSeparator,
  ...timeMask,
  ...timeSeparator,
  ...timeMask,
];

export const maskLength = mask.length;

export function assertModelLength(value: string) {
  invariant(
    value.length === maskLength,
    `model value is malformed, length must be ${maskLength}, is ${value.length}`,
  );
}

export const emptyValue = "__.__.__   __:__ - __:__";
invariant(emptyValue.length === maskLength, "emptyValue malformed");
invariant(datePipePattern.length === maskLength, "datePipePattern malformed");

export const momentDateFormat = "dd.MM.yy";
export const momentTimeFormat = "HH:mm";
