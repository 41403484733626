import { ButtonHTMLAttributes } from "react";
import classNames from "classnames";
import styles from "./PlainButton.module.scss";
import { Classes } from "@blueprintjs/core";

export interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  title?: string;

  /**
   * Disable the fancy styling.
   */
  noFancy?: boolean | null | undefined;

  /**
   * Muted style.
   */
  muted?: boolean | null | undefined;

  /**
   * Truncate the text by displaying an ellipsis.
   */
  ellipsis?: boolean | null | undefined;

  /**
   * If set to true, the button will be shown with a background, indicating it'
   * active. It'll also have an appropriate ARIA role. Should be set to true or
   * false if the button is a toggle button semantically, not true/undefined.
   */
  toggleState?: boolean;
}

/**
 * Button without any styling.
 * Removes user-agent styling.
 */
export function PlainButton({
  className,
  disabled,
  noFancy,
  muted,
  ellipsis,
  toggleState,
  children,
  ...rest
}: Props) {
  return (
    <button
      className={classNames(
        styles.button,
        {
          [styles.fancy]: !noFancy,
          [styles.muted]: muted,
          [styles.disabled]: disabled,
          [styles.ellipsis]: ellipsis,
          [styles.active]: toggleState === true,
          [styles.toggle]: toggleState !== undefined,
        },
        className,
      )}
      role={toggleState !== undefined ? "switch" : undefined}
      aria-checked={
        toggleState === true
          ? "true"
          : toggleState === false
          ? "false"
          : undefined
      }
      disabled={disabled}
      children={
        ellipsis ? (
          <span
            className={classNames(styles.inner, {
              [Classes.TEXT_OVERFLOW_ELLIPSIS]: ellipsis,
            })}
          >
            {children}
          </span>
        ) : (
          children
        )
      }
      {...rest}
    />
  );
}
