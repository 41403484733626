import { defaultOrder, transformFilters } from "../model/TodoConnectionFilter";

export const defaultFilters = transformFilters({
  onlyNotDone: true,
  order: defaultOrder,
});

export const defaultDoneFilters = transformFilters({
  onlyDone: true,
  onlyNotDone: false,
  order: defaultOrder,
});
