import { MouseEvent, FC } from "react";
import { DisableAwareLink } from "../link/DisableAwareLink";
import styles from "./TextButton.module.scss";
import classnames from "classnames";

export type Props = {
  disabled?: boolean;
  title: string;
  onClick?:
    | ((event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void)
    | undefined;
  href?: Optional<string>;
  className?: Optional<string>;
};

export const TextButton: FC<Props> = function (props) {
  const { disabled, title, onClick, href, children, className } = props;

  function handleClick(
    event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
  ) {
    if (!disabled && onClick) {
      onClick(event);
    }
  }

  if (href !== undefined && href !== null) {
    return (
      <DisableAwareLink
        className={classnames(
          styles.button,
          { [styles.disabled]: disabled },
          className,
        )}
        aria-label={title}
        role="button"
        title={title}
        onClick={handleClick}
        disabled={disabled}
        to={href}
      >
        {children != null ? children : title}
      </DisableAwareLink>
    );
  }

  return (
    <button
      className={classnames(
        styles.button,
        { [styles.disabled]: disabled },
        className,
      )}
      aria-label={title}
      role="button"
      title={title}
      onClick={handleClick}
    >
      {children != null ? children : title}
    </button>
  );
};
