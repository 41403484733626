import { RecordProxy, RecordSourceSelectorProxy } from "relay-runtime";
import ConnectionHandler from "relay-connection-handler-plus";

export function addTimeTrackEventEdge(
  store: RecordSourceSelectorProxy,
  event: RecordProxy,
) {
  const timeTrackEventId = event.getValue("id")!;
  const timeTrackId = event
    .getLinkedRecord("timeTrack")!
    .getValue("id")! as string;
  const timeTrack = store.get(timeTrackId);
  if (!timeTrack) {
    return;
  }

  const connection = ConnectionHandler.getConnection(
    timeTrack,
    "TimeTrackHistory_events",
  );
  if (!connection) {
    return;
  }

  for (const node of connection
    .getLinkedRecords("edges")!
    .map((edge) => edge.getLinkedRecord("node")!)) {
    if (node.getValue("id") === timeTrackEventId) {
      // Node already exists, so we short circuit
      return;
    }
  }

  const edge = ConnectionHandler.createEdge(
    store,
    connection,
    event,
    "TimeTrackEvent",
  );
  const connectionEdge = ConnectionHandler.buildConnectionEdge(
    store,
    connection,
    edge,
  );
  if (!connectionEdge) {
    return;
  }

  ConnectionHandler.insertEdgeBefore(connection, connectionEdge);
}
