import { FC } from "react";
import { MiniGraph } from "../../components/graph/MiniGraph";
import { CustomerRowStatistics_statistics$key } from "./__generated__/CustomerRowStatistics_statistics.graphql";
import styles from "./CustomerRowStatistics.module.scss";
import { useFragment } from "relay-hooks";
import classNames from "classnames";
import { Money } from "../../components/Money";

export interface Props {
  statistics: CustomerRowStatistics_statistics$key;
}

const fragment = graphql`
  fragment CustomerRowStatistics_statistics on Statistics {
    ...MiniGraph_statistics
    lastYear: sumFor(lastDays: 365)
  }
`;

export const CustomerRowStatistics: FC<Props> = function (props) {
  const statistics = useFragment(fragment, props.statistics);

  return (
    <div className={styles.wrapper}>
      <div
        className={classNames(styles["last-year"], "pr-4 text-right")}
        title="Profit in den letzten 365 Tagen"
      >
        <span className="text-xl tabular-nums">
          <Money value={statistics.lastYear} />
        </span>
        <span className="text-xs text-gray-400 -mt-1">Im laufenden Jahr</span>
      </div>
      <div className={styles.graph}>
        <MiniGraph
          statistics={statistics}
          height={70}
          width={200}
          verticalPadding={8}
        />
      </div>
    </div>
  );
};
