/**
 * @generated SignedSource<<83ca67ef25e9cdb8c4db18c6b804b5ab>>
 * @relayHash 86d400b5a8e9db65fe9467afc59e2e19
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID f76e311db6c26dc2ccc457b6d23c51b5227df82d

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type setTodoEmployeeInput = {
  todoId: string;
  employeeId?: string | null;
  clientMutationId?: string | null;
};
export type TodoMutations_SetEmployeeMutation$variables = {
  input: setTodoEmployeeInput;
};
export type TodoMutations_SetEmployeeMutation$data = {
  readonly setTodoEmployee: {
    readonly todo: {
      readonly employee: {
        readonly " $fragmentSpreads": FragmentRefs<"EmployeeFatQuery_data">;
      } | null;
    };
  } | null;
};
export type TodoMutations_SetEmployeeMutation = {
  variables: TodoMutations_SetEmployeeMutation$variables;
  response: TodoMutations_SetEmployeeMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TodoMutations_SetEmployeeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "setTodoEmployeePayload",
        "kind": "LinkedField",
        "name": "setTodoEmployee",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Todo",
            "kind": "LinkedField",
            "name": "todo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Employee",
                "kind": "LinkedField",
                "name": "employee",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "EmployeeFatQuery_data"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TodoMutations_SetEmployeeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "setTodoEmployeePayload",
        "kind": "LinkedField",
        "name": "setTodoEmployee",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Todo",
            "kind": "LinkedField",
            "name": "todo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Employee",
                "kind": "LinkedField",
                "name": "employee",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayName",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "f76e311db6c26dc2ccc457b6d23c51b5227df82d",
    "metadata": {},
    "name": "TodoMutations_SetEmployeeMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "2bc8ab545c48d33a7ea14862530dd7b2";

export default node;
