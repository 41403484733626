import { FC } from "react";
import { FormGroup, InputGroup, Intent } from "@blueprintjs/core";
import { useFormikContext } from "formik";

export interface CustomerEditBilledHourlyRateModel {
  billedHourlyRate: string | undefined;
}
export const CustomerEditBilledHourlyRate: FC<{ allowUndefined?: boolean }> =
  function ({ allowUndefined }) {
    const {
      values,
      handleChange,
      handleBlur,
      errors,
      touched: _touched,
    } = useFormikContext<CustomerEditBilledHourlyRateModel>();

    const error = errors.billedHourlyRate;
    const touched = _touched.billedHourlyRate ?? false;
    const showError = error && touched;

    const helperText = (
      <>
        <div>Stundensatz, der in der Statistik angezeigt wird.</div>
        {showError && (
          <div>
            <br />
            {error}
          </div>
        )}
      </>
    );

    return (
      <div data-cy="customer-hourlyrate">
        <FormGroup
          label="Abzurechnender Stundensatz"
          labelInfo="(benötigt)"
          intent={showError ? Intent.DANGER : Intent.NONE}
          helperText={helperText}
        >
          <InputGroup
            value={values.billedHourlyRate}
            name="billedHourlyRate"
            intent={showError ? Intent.DANGER : Intent.NONE}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={allowUndefined ? "Standard" : ""}
          />
        </FormGroup>
      </div>
    );
  };
