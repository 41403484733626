import { FC } from "react";
import { TimeTrackHistoryRowEmployeeChange_event$key } from "./__generated__/TimeTrackHistoryRowEmployeeChange_event.graphql";
import { useFragment } from "relay-hooks";

const fragment = graphql`
  fragment TimeTrackHistoryRowEmployeeChange_event on TimeTrackEvent {
    changedFields
    oldEmployee {
      displayName
      link
    }
    newEmployee {
      displayName
      link
    }
  }
`;

export const TimeTrackHistoryRowEmployeeChange: FC<{
  event: TimeTrackHistoryRowEmployeeChange_event$key;
}> = function TimeTrackHistoryRowEmployeeChange(props) {
  const event = useFragment(fragment, props.event);
  const changedFields = event.changedFields as {
    [key: string]: [unknown, unknown];
  };

  if (!changedFields.employee) {
    return null;
  }

  if (event.oldEmployee && event.newEmployee) {
    return (
      <span>
        den Kunden von{" "}
        <span className="line-through">{event.oldEmployee.displayName}</span> zu{" "}
        <span className="font-bold">{event.newEmployee.displayName}</span>{" "}
        geändert
      </span>
    );
  } else if (event.oldEmployee) {
    return (
      <span>
        den Kunden{" "}
        <span className="line-through">{event.oldEmployee.displayName}</span>{" "}
        entfernt
      </span>
    );
  } else if (event.newEmployee) {
    return (
      <span>
        den Kunden{" "}
        <span className="font-bold">{event.newEmployee.displayName}</span>{" "}
        ausgewählt
      </span>
    );
  } else {
    throw new Error("Invalid difference");
  }
};
