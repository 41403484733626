import {
  CacheConfig,
  Environment,
  Network,
  Observable,
  RecordSource,
  RequestParameters,
  Store,
  UploadableMap,
  Variables,
} from "relay-runtime";
import {
  AppContext,
  AppEvent,
  AppState,
  EventTypes,
} from "./app-controller/AppMachineUtils";
import ConnectionHandler from "relay-connection-handler-plus";
import { GraphQLResponse } from "relay-runtime/lib/network/RelayNetworkTypes";
import { Interpreter } from "xstate";
import { createClient, Sink } from "graphql-ws";
import { GraphQLError } from "graphql";

export function createBrowserEnvironment(
  appService: Interpreter<AppContext, any, AppEvent, AppState>,
): Environment {
  const subscriptionsClient = createClient({
    url: `${window.location.protocol === "http:" ? "ws:" : "wss:"}//${
      window.location.host
    }/graphql`,
  });

  function handlerProvider(handle: string): any {
    switch (handle) {
      case "connection":
        return ConnectionHandler;
      default:
        throw new Error(`no handler configured for ${handle}`);
    }
  }

  function fetchQuery(
    request: RequestParameters,
    variables: Variables,
    cacheConfig: CacheConfig,
    uploadables?: UploadableMap | null,
  ) {
    return Observable.create<GraphQLResponse>((sink) => {
      fetch("/graphql", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          documentId: request.id,
          query: request.text,
          variables,
        }),
      })
        .then(async (response) => {
          try {
            return await response.json();
          } catch (e) {
            appService.send(EventTypes.receiveNetworkError, {
              error: new Error("Service unavailable"),
            });
            throw e;
          }
        })
        .then((json) => {
          if (json.error && json.error.action === "login") {
            appService.send(EventTypes.receiveAuthenticationErrorFromNetwork);
            throw new Error(json.error);
          }
          return json;
        })
        .then((json) => {
          sink.next(json);
          sink.complete();
        })
        .catch((err) => {
          sink.error(err);
        });
    });
  }

  function fetchOrSubscribe(
    operation: RequestParameters,
    variables: Variables,
  ) {
    return Observable.create((sink: Sink<GraphQLResponse>) => {
      const query = operation.text ?? operation.id;
      if (!query) {
        return sink.error(new Error("Operation text cannot be empty"));
      }
      return subscriptionsClient.subscribe(
        {
          operationName: operation.name,
          query,
          variables,
        },
        {
          next(result) {
            // @ts-expect-error: Oh well 🤷🏻‍
            sink.next(result);
          },
          complete() {
            sink.complete();
          },
          error: (err) => {
            if (err instanceof Error) {
              return sink.error(err);
            }

            if (err instanceof CloseEvent) {
              return sink.error(
                // reason will be available on clean closes
                new Error(
                  `Socket closed with event ${err.code} ${err.reason || ""}`,
                ),
              );
            }

            return sink.error(
              new Error(
                (err as GraphQLError[])
                  .map(({ message }) => message)
                  .join(", "),
              ),
            );
          },
        },
      );
    });
  }

  return new Environment({
    handlerProvider,
    network: Network.create(fetchQuery, fetchOrSubscribe),
    store: new Store(new RecordSource()),
  });
}
