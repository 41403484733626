/**
 * @generated SignedSource<<83f01824b91d706dfd7a0a3e8037dcb4>>
 * @relayHash 876fc0e0ee05a215aff6630ab9d30ec7
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 9d9d4cbf284ee4b348487d308d443e8e40c339ff

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TodoConnectionProviderQuery$variables = {
  count?: number | null;
  cursor?: string | null;
  includeUnassignedEmployee?: boolean | null;
  onlyCreatedBy?: ReadonlyArray<string> | null;
  onlyCustomer?: ReadonlyArray<string> | null;
  onlyDone?: boolean | null;
  onlyDueAfter?: string | null;
  onlyDueBefore?: string | null;
  onlyEmployee?: ReadonlyArray<string> | null;
  onlyNotDone?: boolean | null;
  onlyPriority?: ReadonlyArray<string> | null;
  order?: ReadonlyArray<string> | null;
  searchTerm?: string | null;
};
export type TodoConnectionProviderQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"TodoConnectionProvider_query">;
};
export type TodoConnectionProviderQuery = {
  variables: TodoConnectionProviderQuery$variables;
  response: TodoConnectionProviderQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": 30,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": false,
    "kind": "LocalArgument",
    "name": "includeUnassignedEmployee"
  },
  {
    "defaultValue": ([]/*: any*/),
    "kind": "LocalArgument",
    "name": "onlyCreatedBy"
  },
  {
    "defaultValue": ([]/*: any*/),
    "kind": "LocalArgument",
    "name": "onlyCustomer"
  },
  {
    "defaultValue": false,
    "kind": "LocalArgument",
    "name": "onlyDone"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "onlyDueAfter"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "onlyDueBefore"
  },
  {
    "defaultValue": ([]/*: any*/),
    "kind": "LocalArgument",
    "name": "onlyEmployee"
  },
  {
    "defaultValue": false,
    "kind": "LocalArgument",
    "name": "onlyNotDone"
  },
  {
    "defaultValue": ([]/*: any*/),
    "kind": "LocalArgument",
    "name": "onlyPriority"
  },
  {
    "defaultValue": [
      "done",
      "priority",
      "due_ts"
    ],
    "kind": "LocalArgument",
    "name": "order"
  },
  {
    "defaultValue": "",
    "kind": "LocalArgument",
    "name": "searchTerm"
  }
],
v1 = {
  "kind": "Variable",
  "name": "includeUnassignedEmployee",
  "variableName": "includeUnassignedEmployee"
},
v2 = {
  "kind": "Variable",
  "name": "onlyCreatedBy",
  "variableName": "onlyCreatedBy"
},
v3 = {
  "kind": "Variable",
  "name": "onlyCustomer",
  "variableName": "onlyCustomer"
},
v4 = {
  "kind": "Variable",
  "name": "onlyDone",
  "variableName": "onlyDone"
},
v5 = {
  "kind": "Variable",
  "name": "onlyDueAfter",
  "variableName": "onlyDueAfter"
},
v6 = {
  "kind": "Variable",
  "name": "onlyDueBefore",
  "variableName": "onlyDueBefore"
},
v7 = {
  "kind": "Variable",
  "name": "onlyEmployee",
  "variableName": "onlyEmployee"
},
v8 = {
  "kind": "Variable",
  "name": "onlyNotDone",
  "variableName": "onlyNotDone"
},
v9 = {
  "kind": "Variable",
  "name": "onlyPriority",
  "variableName": "onlyPriority"
},
v10 = {
  "kind": "Variable",
  "name": "order",
  "variableName": "order"
},
v11 = {
  "kind": "Variable",
  "name": "searchTerm",
  "variableName": "searchTerm"
},
v12 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/),
  (v8/*: any*/),
  (v9/*: any*/),
  (v10/*: any*/),
  (v11/*: any*/)
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "note",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "link",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "printName",
  "storageKey": null
},
v19 = [
  (v13/*: any*/),
  (v17/*: any*/),
  (v18/*: any*/)
],
v20 = [
  (v13/*: any*/),
  (v17/*: any*/),
  (v18/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "reference",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TodoConnectionProviderQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          {
            "kind": "Variable",
            "name": "cursor",
            "variableName": "cursor"
          },
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "TodoConnectionProvider_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TodoConnectionProviderQuery",
    "selections": [
      {
        "alias": null,
        "args": (v12/*: any*/),
        "concreteType": "TodoConnection",
        "kind": "LinkedField",
        "name": "todos",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TodoEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Todo",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v14/*: any*/),
                  (v15/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "done",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "due",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TodoPriority",
                    "kind": "LinkedField",
                    "name": "priority",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "key",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TimeTrack",
                    "kind": "LinkedField",
                    "name": "timeTrack",
                    "plural": false,
                    "selections": [
                      (v13/*: any*/),
                      (v16/*: any*/),
                      (v14/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "description",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "descriptionHTML",
                        "storageKey": null
                      },
                      (v15/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "recordedStart",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "recordedEnd",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "journey",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "unbilled",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hiddenForCustomer",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "canEdit",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "approved",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "canApprove",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "canRevokeApproval",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "canRemove",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Employee",
                        "kind": "LinkedField",
                        "name": "associatedEmployee",
                        "plural": false,
                        "selections": (v19/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Customer",
                        "kind": "LinkedField",
                        "name": "associatedCustomer",
                        "plural": false,
                        "selections": (v20/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Call",
                        "kind": "LinkedField",
                        "name": "call",
                        "plural": false,
                        "selections": [
                          (v13/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "connected",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "ended",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isOutbound",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Customer",
                            "kind": "LinkedField",
                            "name": "customer",
                            "plural": false,
                            "selections": [
                              (v13/*: any*/),
                              (v17/*: any*/),
                              (v16/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Customer",
                    "kind": "LinkedField",
                    "name": "customer",
                    "plural": false,
                    "selections": (v20/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Employee",
                    "kind": "LinkedField",
                    "name": "employee",
                    "plural": false,
                    "selections": (v19/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v12/*: any*/),
        "filters": [
          "order",
          "searchTerm",
          "onlyDone",
          "onlyNotDone",
          "onlyCreatedBy",
          "onlyEmployee",
          "includeUnassignedEmployee",
          "onlyCustomer",
          "onlyPriority",
          "onlyDueBefore",
          "onlyDueAfter"
        ],
        "handle": "connection",
        "key": "TodoConnection_todos",
        "kind": "LinkedHandle",
        "name": "todos"
      }
    ]
  },
  "params": {
    "id": "9d9d4cbf284ee4b348487d308d443e8e40c339ff",
    "metadata": {},
    "name": "TodoConnectionProviderQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "9b65111cea85236c6a4ff1311d959551";

export default node;
