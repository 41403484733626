import { Icon } from "@mormahr/babel-transform-icon";
import { FC, useMemo, useState } from "react";
import * as React from "react";
import { NavigationItem } from "./NavigationItem";
import { settingsLink } from "./util/settingsLink";
import styles from "./Navigation.module.scss";
import { useService } from "@xstate/react";
import {
  SubscriptionOptions,
  useSubscription,
} from "../../components/SubscriptionManager";
import { NavigationTodoCountSubscription } from "./__generated__/NavigationTodoCountSubscription.graphql";
import {
  useFeatureFlag,
  useViewerPermission,
} from "../../app-controller/ViewerContext";
import { expectYes } from "../../app-controller/ViewerUtils";
import { useAppService } from "../../app-controller/AppServiceContext";
import { format } from "date-fns";
import { NavigationItemWorkspace } from "./NavigationItemWorkspace";

const TodoSubscription = graphql`
  subscription NavigationTodoCountSubscription {
    viewerTodoCount(includeUnassignedEmployee: true)
  }
`;

export const TodoSubscriptionKind = {
  group: "TodoCount",
  label: "Todo Anzahl",
};

export type Props = {
  onClick?: ((event: React.MouseEvent) => void) | undefined;
};

export const Navigation: FC<Props> = function (props) {
  const { onClick } = props;
  const AppService = useAppService();
  const [viewer] = useService(AppService);
  const shouldViewOtherUsers = expectYes(
    useViewerPermission("shouldViewOtherUsers"),
  );
  const canEditOrganization = expectYes(
    useViewerPermission("canEditOrganization"),
  );
  const canUseMonthlyBillings = expectYes(
    useFeatureFlag("monthlyCustomerBillings"),
  );
  const enableCalls = expectYes(useFeatureFlag("calls"));
  const [currentMonth] = useState(() => format(new Date(), "yyyy-MM"));

  const [todoCount, setTodoCount] = useState(0);
  const todoBadge =
    todoCount === 0 ? null : todoCount > 9 ? "9+" : `${todoCount}`;

  const subscriptionConfig = useMemo<
    SubscriptionOptions<NavigationTodoCountSubscription>
  >(
    () => ({
      kind: TodoSubscriptionKind,
      subscription: TodoSubscription,
      variables: {
        includeUnassignedEmployee: true,
      },
      onNext(response) {
        const count = response?.viewerTodoCount;
        if (count !== undefined) {
          setTodoCount(count);
        }
      },
    }),
    [],
  );
  useSubscription<NavigationTodoCountSubscription>(subscriptionConfig);

  return (
    <nav>
      <ul className={styles.main}>
        <NavigationItem
          icon={<Icon icon="chart-line" type="solid" />}
          label="Dashboard"
          link="/"
          onClick={onClick}
        />
        <NavigationItemWorkspace onClick={onClick} />
        <NavigationItem
          icon={<Icon icon="clipboard-list" type="solid" />}
          label="Todos"
          link="/todo"
          onClick={onClick}
          badge={todoBadge}
          exact={false}
        />
        <NavigationItem
          icon={<Icon icon="phone-office" type="solid" />}
          label="Anrufe"
          link="/calls"
          onClick={onClick}
          disabled={!enableCalls}
        />
      </ul>
      <ul className={styles.secondary}>
        <NavigationItem
          icon={<Icon icon="user-doctor" type="solid" />}
          label="Kunden"
          link="/customer"
          onClick={onClick}
        />
        <NavigationItem
          icon={<Icon icon="users" type="solid" />}
          label="Mitarbeiter"
          link="/user"
          onClick={onClick}
          disabled={!shouldViewOtherUsers}
        />
        <NavigationItem
          icon={<Icon icon="money-check-dollar-pen" type="solid" />}
          label="Rechnung"
          link={`/billings/${currentMonth}`}
          onClick={onClick}
          disabled={!canUseMonthlyBillings}
        />
        <NavigationItem
          icon={<Icon icon="building" type="solid" />}
          label="Firma"
          link="/organization/customer-categories"
          onClick={onClick}
          disabled={!canEditOrganization}
        />
        <NavigationItem
          icon={<Icon icon="user-gear" type="solid" />}
          label="Settings"
          link={settingsLink(viewer.context.viewer)}
          onClick={onClick}
        />
      </ul>
    </nav>
  );
};
