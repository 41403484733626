import { Classes, FormGroup } from "@blueprintjs/core";
import { EmployeeChooser } from "./EmployeeChooser";
import classnames from "classnames";
import { CustomerChooser } from "./CustomerChooser";
import styles from "./PeopleEditorPartial.module.scss";
import { PeopleArrowIcon } from "./PeopleArrowIcon";
import { FormikProps } from "formik";
import { CustomerChooserButton } from "./CustomerChooserButton";

export interface IPeopleEditorPartialValues {
  employee: {
    id: string;
    displayName: string;
    printName: string;
  } | null;
  customer: {
    id: string;
    displayName: string;
    printName: string;
    reference: string | null;
  } | null;
}

export interface Props<TValues extends IPeopleEditorPartialValues> {
  disabled?: boolean;
  values: IPeopleEditorPartialValues;
  formik: FormikProps<TValues>;
}

export function PeopleEditorPartial<
  TValues extends IPeopleEditorPartialValues,
>({ disabled, values, formik: { setFieldValue } }: Props<TValues>) {
  return (
    <div className={styles.people}>
      <FormGroup label={"Mitarbeiter"} disabled={disabled}>
        <EmployeeChooser
          employee={values.employee}
          changeCallback={(employee) => setFieldValue("employee", employee)}
          mode={disabled ? "text" : "button"}
          disabled={disabled}
        />
      </FormGroup>
      <PeopleArrowIcon
        className={classnames({ [Classes.TEXT_DISABLED]: disabled })}
      />
      <FormGroup label={"Kunde"} disabled={disabled}>
        <CustomerChooser
          customer={values.customer}
          changeCallback={(customer) => setFieldValue("customer", customer)}
          disabled={disabled}
          renderButton={(props) => (
            <CustomerChooserButton
              customer={values.customer}
              mode={disabled ? "text" : "button"}
              {...props}
            />
          )}
        />
      </FormGroup>
    </div>
  );
}
