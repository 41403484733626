import { Icon } from "@mormahr/babel-transform-icon";
import { useState } from "react";
import { Button, H5, Popover } from "@blueprintjs/core";
import {
  SubscriptionDescriptor,
  useSubscriptionManagerState,
} from "./SubscriptionManager";
import styles from "./SubscriptionInfo.module.scss";
import { maxBy, orderBy } from "lodash";
import { isAfter, subMinutes } from "date-fns";
import { NBSP } from "../util/nbsp";
import { useFeatureFlag } from "../app-controller/ViewerContext";
import { expectYes } from "../app-controller/ViewerUtils";

export function SubscriptionInfoBadge() {
  const subscriptionActivityButton = expectYes(
    useFeatureFlag("subscriptionActivityButton"),
  );
  const [isOpen, setIsOpen] = useState(false);

  if (!subscriptionActivityButton) {
    return null;
  }

  return (
    <Popover
      isOpen={isOpen}
      onInteraction={(nextOpenState) => setIsOpen(nextOpenState)}
      target={
        <Button minimal small icon={<Icon icon="wave-pulse" type="solid" />} />
      }
      content={<SubscriptionInfoContent />}
    />
  );
}

export function SubscriptionInfoContent() {
  const { grouped } = useSubscriptionManagerState();
  const groups = orderBy(
    Object.entries(grouped),
    (group) => group[1][0].kind.label,
  );

  return (
    <div className={styles.wrapper}>
      <H5>Aktive Live-Aktualisierungen</H5>
      <ul className={styles.list}>
        {groups.map(([group, elements]) => (
          <li className={styles.row} key={group}>
            <div className={styles.label}>{elements[0].kind.label}</div>
            <div className={styles.status}>
              <SubscriptionStatus elements={elements} />
            </div>
            <div className={styles.use}>
              [{NBSP}
              {elements.length.toString()}
              {NBSP}]
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export function SubscriptionStatus({
  elements,
}: {
  elements: SubscriptionDescriptor[];
}) {
  const error = elements.filter((element) => element.error !== null).length > 0;
  if (error) {
    return (
      <span className={styles.error} title="Es ist ein Fehler aufgetreten.">
        <Icon icon="exclamation" type="solid" fixedWidth />
      </span>
    );
  }

  const latest = maxBy(elements, "lastReceived");
  if (latest !== undefined && latest.lastReceived !== null) {
    if (isAfter(latest.lastReceived, subMinutes(Date.now(), 1))) {
      return (
        <span
          className={styles.indicator}
          title="Die neuste Aktualisierung wurde vor ein paar Sekunden empfangen."
        >
          <Icon icon="circle" type="solid" fixedWidth />
        </span>
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
}
