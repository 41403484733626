/**
 * @generated SignedSource<<b54bf55be1d7f8bd9ccb66fdb99cfaef>>
 * @relayHash 88b3a1751fc0d4b07520b8f2f0d55879
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID a1b0b1032b9088453adb6d9d70e3e6a0748d3a38

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DailyQuarterHourlyHistogramQuery$variables = {
  start?: string | null;
  end?: string | null;
};
export type DailyQuarterHourlyHistogramQuery$data = {
  readonly statistics: {
    readonly " $fragmentSpreads": FragmentRefs<"DailyQuarterHourlyHistogramContent_data">;
  } | null;
};
export type DailyQuarterHourlyHistogramQuery = {
  variables: DailyQuarterHourlyHistogramQuery$variables;
  response: DailyQuarterHourlyHistogramQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start"
},
v2 = [
  {
    "kind": "Variable",
    "name": "end",
    "variableName": "end"
  },
  {
    "kind": "Variable",
    "name": "start",
    "variableName": "start"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DailyQuarterHourlyHistogramQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Statistics",
        "kind": "LinkedField",
        "name": "statistics",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "kind": "FragmentSpread",
            "name": "DailyQuarterHourlyHistogramContent_data"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "DailyQuarterHourlyHistogramQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Statistics",
        "kind": "LinkedField",
        "name": "statistics",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "DailyQuarterHourlyHistogramEntry",
            "kind": "LinkedField",
            "name": "dailyQuarterHourlyHistogram",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "day",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "time",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "average",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "normalized",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "a1b0b1032b9088453adb6d9d70e3e6a0748d3a38",
    "metadata": {},
    "name": "DailyQuarterHourlyHistogramQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "c53838c84697b35b6ba78875ac049bb0";

export default node;
