/**
 * @generated SignedSource<<db5d645382938be47f532f18a626d343>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CustomerCheckField = "reference" | "billingAddress" | "category" | "email" | "workItem" | "journeyItem" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CustomerRowChecks_customer$data = {
  readonly id: string;
  readonly archived: boolean;
  readonly missingRequiredCheckFields: ReadonlyArray<CustomerCheckField> | null;
  readonly missingRecommendedCheckFields: ReadonlyArray<CustomerCheckField> | null;
  readonly " $fragmentType": "CustomerRowChecks_customer";
};
export type CustomerRowChecks_customer$key = {
  readonly " $data"?: CustomerRowChecks_customer$data;
  readonly " $fragmentSpreads": FragmentRefs<"CustomerRowChecks_customer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CustomerRowChecks_customer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "archived",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "missingRequiredCheckFields",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "missingRecommendedCheckFields",
      "storageKey": null
    }
  ],
  "type": "Customer",
  "abstractKey": null
};

(node as any).hash = "814ed74d9c3fa2792ccc0a0eef5d4492";

export default node;
