import { FC } from "react";
import classNames from "classnames";
import styles from "./Toolbar.module.scss";

export const Toolbar: FC<{ className?: string }> = function Toolbar({
  children,
  className,
}) {
  return (
    <div className={classNames(styles.toolbar, className)}>{children}</div>
  );
};
