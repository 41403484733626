/**
 * @generated SignedSource<<5459d66918b6dd8a59b931088a781004>>
 * @relayHash d47a7cea07fb1d3f409efad3079df670
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 0c6964ad2a6d553aecd3f4d754807a578a419427

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DashboardStatisticsGraphAdditionalInfoPreference = "NONE" | "REGRESSION" | "LAST_YEAR" | "MOVING_AVERAGE_EIGHT_WEEKS" | "MOVING_AVERAGE_TWELVE_WEEKS" | "%future added value";
export type setUserDashboardStatisticsGraphAdditionalInfoPreferenceInput = {
  dashboardStatisticsGraphAdditionalInfoPreference: DashboardStatisticsGraphAdditionalInfoPreference;
  clientMutationId?: string | null;
};
export type DashboardRevenueSetStatisticsMutation$variables = {
  input: setUserDashboardStatisticsGraphAdditionalInfoPreferenceInput;
};
export type DashboardRevenueSetStatisticsMutation$data = {
  readonly setUserDashboardStatisticsGraphAdditionalInfoPreference: {
    readonly user: {
      readonly dashboardStatisticsGraphAdditionalInfoPreference: DashboardStatisticsGraphAdditionalInfoPreference;
    };
  } | null;
};
export type DashboardRevenueSetStatisticsMutation = {
  variables: DashboardRevenueSetStatisticsMutation$variables;
  response: DashboardRevenueSetStatisticsMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dashboardStatisticsGraphAdditionalInfoPreference",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DashboardRevenueSetStatisticsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "setUserDashboardStatisticsGraphAdditionalInfoPreferencePayload",
        "kind": "LinkedField",
        "name": "setUserDashboardStatisticsGraphAdditionalInfoPreference",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ViewerUser",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DashboardRevenueSetStatisticsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "setUserDashboardStatisticsGraphAdditionalInfoPreferencePayload",
        "kind": "LinkedField",
        "name": "setUserDashboardStatisticsGraphAdditionalInfoPreference",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ViewerUser",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "0c6964ad2a6d553aecd3f4d754807a578a419427",
    "metadata": {},
    "name": "DashboardRevenueSetStatisticsMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "2c9f4328314b45ce895f880b95916498";

export default node;
