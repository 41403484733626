import { FC, useMemo } from "react";
import { useQuery } from "relay-hooks";
import {
  DailyQuarterHourlyHistogramQuery,
  DailyQuarterHourlyHistogramQuery$variables,
} from "./__generated__/DailyQuarterHourlyHistogramQuery.graphql";
import { Header } from "../../components/Header";
import { Container } from "../../components/Grid";
import { FeatureFlagRedirect } from "../../components/FeatureFlagRedirect";
import loadable from "@loadable/component";
import { HeaderLoader } from "../../components/HeaderLoading";
import {
  info,
  Option,
  optionToVariables,
} from "./DailyQuarterHourlyHistogramOptions";
import { useURLParams } from "../../util/useURLParams";
import { DailyQuarterHourlyHistogramToolbar } from "./DailyQuarterHourlyHistogramToolbar";

const DailyQuarterHourlyHistogramContent = loadable(
  () =>
    import(
      /* webpackChunkName: "DailyQuarterHourlyHistogramContent" */ "./DailyQuarterHourlyHistogramContent"
    ),
);

const query = graphql`
  query DailyQuarterHourlyHistogramQuery($start: DateTime, $end: DateTime) {
    statistics {
      ...DailyQuarterHourlyHistogramContent_data
        @arguments(start: $start, end: $end)
    }
  }
`;

export const DailyQuarterHourlyHistogram: FC = function () {
  const _option = useURLParams().get("filter");
  const option: Option =
    // eslint-disable-next-line no-prototype-builtins
    _option && info.hasOwnProperty(_option) ? (_option as Option) : "ALL_TIME";

  const variables = useMemo<DailyQuarterHourlyHistogramQuery$variables>(() => {
    return optionToVariables(option, new Date());
  }, [option]);
  const { data, isLoading, error } = useQuery<DailyQuarterHourlyHistogramQuery>(
    query,
    variables,
  );

  if (error) {
    throw error;
  }

  return (
    <>
      <Header title="Histogram" subTitle="Insights" isLoading={isLoading} />
      <FeatureFlagRedirect flag="insightsDailyQuarterHourlyHistogram" />
      <Container>
        <DailyQuarterHourlyHistogramToolbar option={option} />
        {data && data.statistics && (
          <DailyQuarterHourlyHistogramContent
            data={data.statistics}
            fallback={<HeaderLoader />}
          />
        )}
      </Container>
    </>
  );
};
