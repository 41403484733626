import { FC } from "react";
import { TimeTrackHistoryRowCustomerChange_event$key } from "./__generated__/TimeTrackHistoryRowCustomerChange_event.graphql";
import { useFragment } from "relay-hooks";
import { Link } from "react-router-dom";

const fragment = graphql`
  fragment TimeTrackHistoryRowCustomerChange_event on TimeTrackEvent {
    changedFields
    oldCustomer {
      displayName
      link
    }
    newCustomer {
      displayName
      link
    }
  }
`;

export const TimeTrackHistoryRowCustomerChange: FC<{
  event: TimeTrackHistoryRowCustomerChange_event$key;
}> = function TimeTrackHistoryRowCustomerChange(props) {
  const event = useFragment(fragment, props.event);
  const changedFields = event.changedFields as {
    [key: string]: [unknown, unknown];
  };

  if (!changedFields.customer) {
    return null;
  }

  if (event.oldCustomer && event.newCustomer) {
    return (
      <span>
        den Kunden von{" "}
        <Link
          to={event.oldCustomer.link}
          className="hover:underline line-through"
        >
          {event.oldCustomer.displayName}
        </Link>{" "}
        zu{" "}
        <Link to={event.newCustomer.link} className="hover:underline font-bold">
          {event.newCustomer.displayName}
        </Link>{" "}
        geändert
      </span>
    );
  } else if (event.oldCustomer) {
    return (
      <span>
        den Kunden{" "}
        <Link
          to={event.oldCustomer.link}
          className="hover:underline line-through"
        >
          {event.oldCustomer.displayName}
        </Link>{" "}
        entfernt
      </span>
    );
  } else if (event.newCustomer) {
    return (
      <span>
        den Kunden{" "}
        <Link to={event.newCustomer.link} className="hover:underline font-bold">
          {event.newCustomer.displayName}
        </Link>{" "}
        ausgewählt
      </span>
    );
  } else {
    throw new Error("Invalid difference");
  }
};
