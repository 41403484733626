/**
 * @generated SignedSource<<ea21d04e81982194bdfe5e7e5960bb27>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CustomerNavbar_customer$data = {
  readonly id: string;
  readonly displayName: string;
  readonly printName: string;
  readonly reference: string | null;
  readonly archived: boolean;
  readonly category: {
    readonly " $fragmentSpreads": FragmentRefs<"CustomerCategoryPimple_customerCategory">;
  } | null;
  readonly " $fragmentType": "CustomerNavbar_customer";
};
export type CustomerNavbar_customer$key = {
  readonly " $data"?: CustomerNavbar_customer$data;
  readonly " $fragmentSpreads": FragmentRefs<"CustomerNavbar_customer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CustomerNavbar_customer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "printName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reference",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "archived",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomerCategory",
      "kind": "LinkedField",
      "name": "category",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CustomerCategoryPimple_customerCategory"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Customer",
  "abstractKey": null
};

(node as any).hash = "c3d80986bf8a16b06d11fbaf7d6cd242";

export default node;
