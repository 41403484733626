import { assign, createMachine } from "xstate";
import { localStorageKey, stringifyData } from "./AppMachineStorage";
import {
  AppContext,
  AppEvent,
  AppState,
  EventTypes,
  initialContext,
  ReadCachedExpiryAndDataEvent,
  ReceiveErrorEvent,
  ReceiveExpiryAndDataFromNetworkEvent,
} from "./AppMachineUtils";
import * as Sentry from "@sentry/react";

const captureException = (context: AppContext, event: ReceiveErrorEvent) => {
  Sentry.captureException(event.error);
};

const setSentryUser = (
  context: AppContext,
  event: ReadCachedExpiryAndDataEvent | ReceiveExpiryAndDataFromNetworkEvent,
) => {
  Sentry.setUser({
    id: event.viewer.id,
    username: event.viewer.username,
  });
};

const resetSentryUser = () => {
  Sentry.setUser(null);
};

const setViewerData = assign(
  (
    context: AppContext,
    event: ReadCachedExpiryAndDataEvent | ReceiveExpiryAndDataFromNetworkEvent,
  ) => ({
    expiry: event.expiry,
    viewer: event.viewer,
    employee: event.employee,
    permissions: event.permissions,
    flags: event.flags,
    preferences: event.preferences,
  }),
);

const resetViewerData = assign<AppContext, AppEvent>({
  expiry: undefined,
  viewer: undefined,
  employee: undefined,
  permissions: undefined,
  flags: undefined,
});

export function resetLocalStorage() {
  localStorage.removeItem(localStorageKey);
}

export function saveToLocalStorage(
  context: AppContext,
  event: ReceiveExpiryAndDataFromNetworkEvent,
) {
  localStorage.setItem(
    localStorageKey,
    stringifyData({
      expiry: event.expiry,
      viewer: event.viewer,
      employee: event.employee,
      permissions: event.permissions,
      flags: event.flags,
      preferences: event.preferences,
    }),
  );
}

export const AppMachine = createMachine<AppContext, AppEvent, AppState>({
  id: "AppController",
  context: initialContext,
  initial: "pending",
  states: {
    pending: {
      // @ts-ignore
      on: {
        [EventTypes.readCachedExpiryAndData]: {
          target: "probablyAuthenticated",
          actions: [setViewerData],
        },
        [EventTypes.readEmptyExpiryAndData]: {
          target: "probablyUnauthenticated",
          actions: [resetLocalStorage, resetViewerData, resetSentryUser],
        },
        [EventTypes.receiveExpiryAndDataFromNetwork]: {
          target: "authenticated",
          actions: [saveToLocalStorage, setViewerData, setSentryUser],
        },
        [EventTypes.receiveAuthenticationErrorFromNetwork]: {
          target: "unauthenticated",
          actions: [resetLocalStorage, resetViewerData, resetSentryUser],
        },
        [EventTypes.receiveNonEmployee]: {
          target: "unauthenticated",
          actions: [resetLocalStorage, resetViewerData, resetSentryUser],
        },
        [EventTypes.receiveNetworkError]: {
          target: "error",
          actions: [captureException, resetViewerData, resetSentryUser],
        },
        [EventTypes.readExpired]: {
          target: "probablyUnauthenticated",
          actions: [resetLocalStorage, resetViewerData, resetSentryUser],
        },
      },
    },

    probablyAuthenticated: {
      // @ts-ignore
      on: {
        [EventTypes.receiveExpiryAndDataFromNetwork]: {
          target: "authenticated",
          actions: [saveToLocalStorage, setViewerData, setSentryUser],
        },
        [EventTypes.receiveAuthenticationErrorFromNetwork]: {
          target: "unauthenticated",
          actions: [resetLocalStorage, resetViewerData, resetSentryUser],
        },
        [EventTypes.receiveNonEmployee]: {
          target: "unauthenticated",
          actions: [resetLocalStorage, resetViewerData, resetSentryUser],
        },
        [EventTypes.receiveNetworkError]: {
          target: "error",
          actions: [captureException, resetViewerData, resetSentryUser],
        },
      },
    },

    probablyUnauthenticated: {
      // @ts-ignore
      on: {
        [EventTypes.receiveExpiryAndDataFromNetwork]: {
          target: "authenticated",
          actions: [saveToLocalStorage, setViewerData, setSentryUser],
        },
        [EventTypes.receiveAuthenticationErrorFromNetwork]: {
          target: "unauthenticated",
          actions: [resetLocalStorage, resetViewerData, resetSentryUser],
        },
        [EventTypes.receiveNonEmployee]: {
          target: "unauthenticated",
          actions: [resetLocalStorage, resetViewerData, resetSentryUser],
        },
        [EventTypes.receiveNetworkError]: {
          target: "error",
          actions: [captureException, resetViewerData, resetSentryUser],
        },
      },
    },

    authenticated: {
      // @ts-ignore
      // TODO: make saveToLocalStorage an entry action
      on: {
        // Update cached data
        [EventTypes.receiveExpiryAndDataFromNetwork]: {
          target: "authenticated",
          actions: [saveToLocalStorage, setViewerData, setSentryUser],
        },
        [EventTypes.receiveAuthenticationErrorFromNetwork]: {
          target: "unauthenticated",
          actions: [resetLocalStorage, resetViewerData, resetSentryUser],
        },
        [EventTypes.receiveNonEmployee]: {
          target: "unauthenticated",
          actions: [resetLocalStorage, resetViewerData, resetSentryUser],
        },
        [EventTypes.receiveNetworkError]: {
          target: "error",
          actions: [captureException, resetViewerData, resetSentryUser],
        },
      },
    },

    unauthenticated: {
      // @ts-ignore
      on: {
        [EventTypes.receiveNetworkError]: {
          target: "error",
          actions: [captureException, resetViewerData, resetSentryUser],
        },
      },
    },

    error: {
      type: "final",
    },
  },
});
