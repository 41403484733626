import { useState } from "react";
import {
  renderTodoList,
  TodoConnectionProvider,
} from "../todo/components/TodoConnectionProvider";
import { DashboardQuery$data } from "./__generated__/DashboardQuery.graphql";
import { PageInfo } from "../../util/pagination/PageInfo";
import {
  defaultOrder,
  transformFilters,
} from "../todo/model/TodoConnectionFilter";
import { Heading } from "../../components/Heading";
import { Link } from "react-router-dom";
import {
  useFeatureFlag,
  useViewerEmployee,
} from "../../app-controller/ViewerContext";
import { SnakeGame } from "../../components/snake/SnakeGame";
import { SuccessMessage } from "../../components/SuccessMessage";
import styles from "./DashboardTodoList.module.scss";
import classNamesBind from "classnames/bind";
import { expectYes } from "../../app-controller/ViewerUtils";

const cn = classNamesBind.bind(styles);

export type Props = {
  query: DashboardQuery$data | null;
};

export const DashboardTodoListRequestedCount = 20;

export const filterFor = (employeeId: Optional<string>) => ({
  onlyEmployee: employeeId != null ? [employeeId] : [],
  onlyNotDone: true,
  includeUnassignedEmployee: true,
  count: DashboardTodoListRequestedCount,
  order: defaultOrder,
});

export const DashboardTodoList = ({ query }: Props) => {
  const total = DashboardTodoListRequestedCount;
  const viewerEmployee = useViewerEmployee();
  const [_showGame, setShowGame] = useState(false);
  const snakeFeature = expectYes(useFeatureFlag("snake"));
  const showGame = snakeFeature && _showGame;

  const filters = transformFilters(
    filterFor(
      viewerEmployee.type === "EMPLOYEE" ? viewerEmployee.employeeId : null,
    ),
  );

  return (
    <TodoConnectionProvider
      filters={filters}
      total={total}
      renderFooter={(pageInfo: PageInfo) => {
        if (pageInfo.hasNextPage) {
          return (
            <p>
              <Link to="/todo" className={"text-gray-500 dark:text-gray:600"}>
                Weitere Todos können unter &quot;Todos&quot; eingesehen werden.
              </Link>
            </p>
          );
        }

        return null;
      }}
      query={query}
      render={(obj) => {
        if (obj.numberOfTodosDisplayed === 0 && query !== null) {
          return (
            <>
              <Heading level={2}>Todos</Heading>
              <p className="lead">Zugewiesen an dich oder frei</p>
              {!showGame ? (
                <SuccessMessage
                  onContextMenuCapture={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setShowGame(true);
                  }}
                >
                  Keine offenen Todos
                </SuccessMessage>
              ) : (
                <div className={cn("game")}>
                  <SnakeGame />
                </div>
              )}
            </>
          );
        }

        return (
          <>
            <Heading level={2}>Todos</Heading>
            <p className="lead">Zugewiesen an dich oder frei</p>
            {renderTodoList(obj)}
          </>
        );
      }}
    />
  );
};
