/**
 * @generated SignedSource<<9a131481b3f32adae8915361099a90bd>>
 * @relayHash d51308410d5c0e9068c3a2087b4b627b
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 4d9655ffc7637b792b1c3fc38f0553e7ab68c85b

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CustomerOverviewQuery$variables = {
  count?: number | null;
  cursor?: string | null;
  onlyArchived?: boolean | null;
};
export type CustomerOverviewQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CustomerOverview_query">;
};
export type CustomerOverviewQuery = {
  variables: CustomerOverviewQuery$variables;
  response: CustomerOverviewQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": 200,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": false,
    "kind": "LocalArgument",
    "name": "onlyArchived"
  }
],
v1 = {
  "kind": "Variable",
  "name": "onlyArchived",
  "variableName": "onlyArchived"
},
v2 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v1/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomerOverviewQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          {
            "kind": "Variable",
            "name": "cursor",
            "variableName": "cursor"
          },
          (v1/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "CustomerOverview_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CustomerOverviewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CustomerConnection",
        "kind": "LinkedField",
        "name": "customers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomerEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Customer",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "link",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "archived",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "reference",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "missingRequiredCheckFields",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "missingRecommendedCheckFields",
                    "storageKey": null
                  },
                  {
                    "condition": "onlyArchived",
                    "kind": "Condition",
                    "passingValue": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Statistics",
                        "kind": "LinkedField",
                        "name": "statistics",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "lastWeeks",
                                "value": 13
                              }
                            ],
                            "concreteType": "WeeklyStatistics",
                            "kind": "LinkedField",
                            "name": "weekly",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "profit",
                                "storageKey": null
                              }
                            ],
                            "storageKey": "weekly(lastWeeks:13)"
                          },
                          {
                            "alias": "lastYear",
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "lastDays",
                                "value": 365
                              }
                            ],
                            "kind": "ScalarField",
                            "name": "sumFor",
                            "storageKey": "sumFor(lastDays:365)"
                          }
                        ],
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "filters": [
          "onlyArchived"
        ],
        "handle": "connection",
        "key": "CustomerOverview_customers",
        "kind": "LinkedHandle",
        "name": "customers"
      }
    ]
  },
  "params": {
    "id": "4d9655ffc7637b792b1c3fc38f0553e7ab68c85b",
    "metadata": {},
    "name": "CustomerOverviewQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "11f03fc81c5140d6e2b5ef36507303ae";

export default node;
