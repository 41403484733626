import { FC } from "react";
import { FormGroup, InputGroup, Intent } from "@blueprintjs/core";
import { useFormikContext } from "formik";

export interface CustomerEditDisplayNameModel {
  displayName: string;
}

export const CustomerEditDisplayName: FC<{
  autoFocus?: boolean;
}> = function ({ autoFocus }) {
  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched: _touched,
  } = useFormikContext<CustomerEditDisplayNameModel>();

  const error = errors.displayName;
  const touched = _touched.displayName ?? false;
  const showError = error && touched;

  const helperText = (
    <>
      <div>
        Der Anzeigename wird überall im System gegenüber Mitarbeitern verwendet,
        er sollte kurz sein und auf redundante Begriffe wie „Praxis“ verzichten.
      </div>
      {showError && (
        <div>
          <br />
          {error}
        </div>
      )}
    </>
  );

  return (
    <div data-cy="customer-displayname">
      <FormGroup
        label="Anzeigename"
        labelInfo="(benötigt)"
        intent={showError ? Intent.DANGER : Intent.NONE}
        helperText={helperText}
      >
        <InputGroup
          value={values.displayName}
          placeholder="Anzeigename"
          name="displayName"
          intent={showError ? Intent.DANGER : Intent.NONE}
          onChange={handleChange}
          onBlur={handleBlur}
          autoFocus={autoFocus}
        />
      </FormGroup>
    </div>
  );
};
