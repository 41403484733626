import { Icon } from "@mormahr/babel-transform-icon";
import { PropsWithChildren } from "react";
import classNamesBind from "classnames/bind";
import styles from "./SuccessMessage.module.scss";
import { HTMLDivProps } from "@blueprintjs/core";

const cn = classNamesBind.bind(styles);

export function SuccessMessage(props: PropsWithChildren<HTMLDivProps>) {
  return (
    <div className={cn("wrapper")} {...props}>
      <div className={cn("icon")}>
        <Icon icon="circle-check" type="light" />
      </div>
      <p className={cn("message")}>{props.children}</p>
    </div>
  );
}
