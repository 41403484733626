import { MenuItem, Spinner, Icon as BPIcon } from "@blueprintjs/core";
import {
  isEdgeAndNodeNotNullPredicate,
  normalizeSearchTerm,
} from "@mormahr/portal-utils";
import { Icon } from "@mormahr/babel-transform-icon";
import { Select } from "@blueprintjs/select";
import { useQuery } from "relay-hooks";
import { CustomerCategorySelectCustomerCategoriesQuery } from "./__generated__/CustomerCategorySelectCustomerCategoriesQuery.graphql";
import { Button } from "../../components/button/Button";
import { FC, useMemo } from "react";
import { useFormikContext } from "formik";

type CustomerSelectItem =
  | {
      readonly id: string;
      readonly title: string;
      readonly description: string;
      readonly color: string;
    }
  | "null";

const SelectElement = Select.ofType<CustomerSelectItem>();

const categoriesQuery = graphql`
  query CustomerCategorySelectCustomerCategoriesQuery {
    customerCategories(first: 1000) {
      edges {
        node {
          id
          title
          description
          color
        }
      }
    }
  }
`;

export interface CustomerEditCategoryModel {
  category: {
    id: string;
    title: string;
    description: string;
    color: string;
  } | null;
}

export const CustomerCategorySelect: FC = function () {
  const formikContext = useFormikContext<CustomerEditCategoryModel>();
  const category = formikContext.values.category;
  const { data: query } =
    useQuery<CustomerCategorySelectCustomerCategoriesQuery>(categoriesQuery);

  const items = useMemo<CustomerSelectItem[]>(() => {
    const edges = query?.customerCategories?.edges;
    const nodes = edges
      ?.filter(isEdgeAndNodeNotNullPredicate)
      .map((edge) => edge?.node ?? null);

    return [...(nodes ?? []), "null"];
  }, [query]);

  return (
    <SelectElement
      noResults={query ? null : <Spinner size={20} />}
      itemsEqual={(a, b) =>
        (a === "null" && b === "null") ||
        (a !== "null" && b !== "null" && a.id === b.id)
      }
      itemPredicate={(query, item) => {
        if (item === "null") {
          return query.trim() === "";
        }
        return normalizeSearchTerm(item.title).includes(
          normalizeSearchTerm(query),
        );
      }}
      items={items}
      itemRenderer={(item, { handleClick, modifiers }) => {
        if (!modifiers.matchesPredicate) {
          return null;
        }

        if (item === "null") {
          return (
            <MenuItem
              active={modifiers.active}
              key={"customer-category-null-value"}
              onClick={handleClick}
              text={
                <span>
                  <span style={{ color: "transparent" }}>
                    <Icon icon="circle" type="solid" />
                  </span>{" "}
                  Keine Kategorie
                </span>
              }
              label={!category ? "Ausgewählt" : ""}
            />
          );
        }

        return (
          <MenuItem
            active={modifiers.active}
            key={item.id}
            onClick={handleClick}
            text={
              <span>
                <span style={{ color: item.color }}>
                  <Icon icon="circle" type="solid" />
                </span>{" "}
                {item.title}
              </span>
            }
            label={category?.id === item.id ? "Ausgewählt" : ""}
          />
        );
      }}
      onItemSelect={(item) => {
        formikContext.setFieldValue("category", item === "null" ? null : item);
      }}
    >
      <Button type="button" rightIcon={<BPIcon icon="double-caret-vertical" />}>
        <span>
          {category && (
            <span
              style={{
                color: category?.color ?? "transparent",
              }}
            >
              <Icon icon="circle" type="solid" />
            </span>
          )}{" "}
          {category?.title ?? "Keine Kategorie"}
        </span>
      </Button>
    </SelectElement>
  );
};
