import { FC, memo, PropsWithChildren, useMemo } from "react";
import * as React from "react";
import { createFragmentContainer } from "react-relay";
import { isEdgeAndNodeNotNullPredicate } from "@mormahr/portal-utils";
import {
  SubscriptionKind,
  SubscriptionOptions,
  useSubscription,
} from "./SubscriptionManager";
import {
  CommonDataContextCustomerSubscription,
  CommonDataContextCustomerSubscription$data,
} from "./__generated__/CommonDataContextCustomerSubscription.graphql";
import { ConnectionHandler, RecordSourceSelectorProxy } from "relay-runtime";
import { CommonDataContext_query$data } from "./__generated__/CommonDataContext_query.graphql";

export type Customers = CommonDataContext_query$data["customers"];
export type Employees = CommonDataContext_query$data["employees"];

export interface CommonData {
  hasLoaded: boolean;
  customers:
    | {
        readonly id: string;
        readonly displayName: string;
        readonly printName: string;
        readonly reference: string | null;
      }[]
    | null;
  employees:
    | {
        readonly id: string;
        readonly displayName: string;
        readonly printName: string;
      }[]
    | null;
}

const isLoadingValue: CommonData = {
  hasLoaded: false,
  customers: [],
  employees: [],
};

function displayNamePredicate(
  a: { readonly displayName: string },
  b: { readonly displayName: string },
): number {
  if (a.displayName < b.displayName) {
    return -1;
  }
  if (a.displayName > b.displayName) {
    return 1;
  }
  return 0;
}

type Connection<N> = {
  readonly edges: ReadonlyArray<{ readonly node: N | null } | null> | null;
} | null;

function process<N extends { readonly displayName: string }>(
  connection: Readonly<Connection<N>>,
): N[] {
  if (!connection || !connection.edges) {
    return [];
  }

  const nodes: N[] = [];

  for (const edge of connection.edges) {
    if (edge && edge.node) {
      nodes.push(edge.node);
    }
  }

  nodes.sort(displayNamePredicate);

  return nodes;
}

export const CommonDataContext = React.createContext<CommonData>({
  hasLoaded: false,
  customers: null,
  employees: null,
});
CommonDataContext.displayName = "CommonDataContext";

const CustomersSubscriptionKind: SubscriptionKind = {
  group: "CommonDataCustomer",
  label: "Kundenliste",
};

const CustomerSubscription = graphql`
  subscription CommonDataContextCustomerSubscription {
    customers(skipFastForward: true) {
      node {
        ... on Customer {
          ...CustomerChooser_customer @relay(mask: false)
        }
      }
      cursor
      type
      nodeId
    }
  }
`;

function CommonDataProviderValueComponent({
  children,
  customers,
  employees,
}: PropsWithChildren<{
  customers: Customers;
  employees: Employees;
}>) {
  return (
    <CommonDataContext.Provider
      value={
        customers && employees
          ? {
              hasLoaded: true,
              customers: process(customers),
              employees: process(employees),
            }
          : isLoadingValue
      }
      children={children}
    />
  );
}

const CommonDataProviderValue = memo(CommonDataProviderValueComponent);

function updater(
  store: RecordSourceSelectorProxy<CommonDataContextCustomerSubscription$data>,
  value: CommonDataContextCustomerSubscription$data,
) {
  // Update is automatically handled, only INSERT and DELETE have to be handled here
  const connection = ConnectionHandler.getConnection(
    store.getRoot(),
    "CommonDataContext_customers",
  );
  if (!connection) {
    return;
  }

  if (!value.customers) {
    return;
  }

  const id = value.customers.nodeId;
  if (!id) {
    return;
  }

  const node = store.get(id);
  if (!node) {
    return;
  }

  if (value.customers.type === "INSERT") {
    const edge = ConnectionHandler.createEdge(
      store,
      connection,
      node,
      "CustomerEdge",
    );

    ConnectionHandler.insertEdgeBefore(connection, edge);
  } else if (value.customers.type === "DELETE") {
    ConnectionHandler.deleteNode(connection, id);
  }
}

export const CommonDataProviderComponent: FC<{
  query: CommonDataContext_query$data | null;
}> = function CommonDataProviderComponent({ query, children }) {
  const customersSubscriptionOptions = useMemo<
    SubscriptionOptions<CommonDataContextCustomerSubscription>
  >(
    () => ({
      kind: CustomersSubscriptionKind,
      enable: true,
      subscription: CustomerSubscription,
      variables: {},
      updater,
    }),
    [],
  );
  useSubscription(customersSubscriptionOptions);

  return (
    <CommonDataProviderValue
      customers={query?.customers ?? null}
      employees={query?.employees ?? null}
      children={children}
    />
  );
};

export const CommonDataProvider = createFragmentContainer(
  CommonDataProviderComponent,
  {
    query: graphql`
      fragment CommonDataContext_query on Query {
        customers(first: 1000) @connection(key: "CommonDataContext_customers") {
          edges {
            node {
              ...CustomerChooser_customer @relay(mask: false)
            }
          }
        }

        employees(first: 1000) {
          edges {
            node {
              ...EmployeeChooser_employee @relay(mask: false)
            }
          }
        }
      }
    `,
  },
);
