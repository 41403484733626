/**
 * @generated SignedSource<<5b6d9aee5bf405a80e0875e496a321ce>>
 * @relayHash b4580311178d035f00cf6c7714698480
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID f8222de3e380d59a09a5b0a63299a93e9960d1a9

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type setTodoPriorityInput = {
  todoId: string;
  priority: string;
  clientMutationId?: string | null;
};
export type TodoMutations_SetTodoPriorityMutation$variables = {
  input: setTodoPriorityInput;
};
export type TodoMutations_SetTodoPriorityMutation$data = {
  readonly setTodoPriority: {
    readonly todo: {
      readonly priority: {
        readonly " $fragmentSpreads": FragmentRefs<"TodoPriorityFatQuery_data">;
      };
    };
  } | null;
};
export type TodoMutations_SetTodoPriorityMutation = {
  variables: TodoMutations_SetTodoPriorityMutation$variables;
  response: TodoMutations_SetTodoPriorityMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TodoMutations_SetTodoPriorityMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "setTodoPriorityPayload",
        "kind": "LinkedField",
        "name": "setTodoPriority",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Todo",
            "kind": "LinkedField",
            "name": "todo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TodoPriority",
                "kind": "LinkedField",
                "name": "priority",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "TodoPriorityFatQuery_data"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TodoMutations_SetTodoPriorityMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "setTodoPriorityPayload",
        "kind": "LinkedField",
        "name": "setTodoPriority",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Todo",
            "kind": "LinkedField",
            "name": "todo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TodoPriority",
                "kind": "LinkedField",
                "name": "priority",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "key",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "f8222de3e380d59a09a5b0a63299a93e9960d1a9",
    "metadata": {},
    "name": "TodoMutations_SetTodoPriorityMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "29c4521f27938fb220b0ffcde6e130a4";

export default node;
