import { useState } from "react";
import { TimeTrackTimeChooserPopover_timeTrack$key } from "./__generated__/TimeTrackTimeChooserPopover_timeTrack.graphql";
import { setTimeTrackTimes } from "../../timetrack/mutations/TimeTrackTimingMutations";
import {
  DateRangeField,
  OnChangeCallbackParameter,
} from "../../../components/daterange/DateRangeField";
import { Classes, Dialog, FormGroup } from "@blueprintjs/core";
import { Button } from "../../../components/button/Button";
import { Intent } from "../../../util/Intent";
import classNames from "classnames";
import { MutationCompletionStatus } from "../../../mutation/doMutation";
import { useFragment, useRelayEnvironment } from "relay-hooks";
import { parseISO } from "date-fns";
import { TimeFromNow } from "../../../components/TimeFromNow";

type Props = {
  timeTrack: TimeTrackTimeChooserPopover_timeTrack$key;
  isOpen: boolean;
  setOpen: (open: boolean) => void;
};

const fragment = graphql`
  fragment TimeTrackTimeChooserPopover_timeTrack on TimeTrack {
    id

    recordedStart
    recordedEnd
  }
`;

export const TimeTrackTimeChooserPopover = (props: Props) => {
  const environment = useRelayEnvironment();
  const timeTrack = useFragment(fragment, props.timeTrack);
  const { isOpen, setOpen } = props;
  const { recordedStart, recordedEnd, id } = timeTrack;

  const [currentDates, setCurrentDates] = useState<OnChangeCallbackParameter>({
    startDate: recordedStart ? parseISO(recordedStart) : null,
    endDate: recordedEnd ? parseISO(recordedEnd) : null,
  });

  return (
    <Dialog
      title="Zeitraum anpassen"
      isOpen={isOpen}
      onClose={() => setOpen(false)}
    >
      <form
        onSubmit={(event) => {
          event.preventDefault();
          setTimeTrackTimes(
            environment,
            id,
            currentDates.startDate?.toISOString() ?? null,
            currentDates.endDate?.toISOString() ?? null,
            (status) => {
              if (status === MutationCompletionStatus.success) {
                setOpen(false);
              }
            },
          );
        }}
      >
        <div className={Classes.DIALOG_BODY}>
          <FormGroup label={"Zeitraum"} labelFor={"tt-widg-dates"}>
            <DateRangeField
              id={"tt-widg-dates"}
              className={Classes.INPUT}
              startDate={currentDates.startDate}
              endDate={currentDates.endDate}
              onChange={setCurrentDates}
              directUpdate
              autoFocus
            />
          </FormGroup>

          <p className={"text-gray-500 dark:text-gray:600"}>
            {currentDates.startDate ? (
              <TimeFromNow date={currentDates.startDate} />
            ) : (
              <br />
            )}
          </p>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div
            className={classNames(Classes.DIALOG_FOOTER_ACTIONS, "space-x-2")}
          >
            <Button type="button" onClick={() => setOpen(false)}>
              Abbrechen
            </Button>
            <Button intent={Intent.PRIMARY} type="submit">
              Speichern
            </Button>
          </div>
        </div>
      </form>
    </Dialog>
  );
};
