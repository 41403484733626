import { FC, useState } from "react";
import { TodosQuery } from "./__generated__/TodosQuery.graphql";
import { parseTodoConnectionFilters } from "../model/TodoConnectionSerialization";
import { Header, Tab } from "../../../components/Header";
import { defaultFilters } from "../components/TodoListDefaultFilters";
import { STORE_THEN_NETWORK, useQuery } from "relay-hooks";
import loadable from "@loadable/component";
import { useHistory } from "react-router";
import { Icon } from "@mormahr/babel-transform-icon";
import { HeaderLoader } from "../../../components/HeaderLoading";

const TodoList = loadable(
  () => import(/* webpackChunkName: "TodoList" */ "../components/TodoList"),
);

export const query = graphql`
  query TodosQuery(
    $order: [String!]
    $searchTerm: String
    $onlyDone: Boolean
    $onlyNotDone: Boolean
    $onlyCreatedBy: [ID!]
    $onlyEmployee: [ID!]
    $includeUnassignedEmployee: Boolean
    $onlyCustomer: [ID!]
    $onlyPriority: [String!]
    $onlyDueBefore: DateTime
    $onlyDueAfter: DateTime
  ) {
    ...TodoConnectionProvider_query
      @arguments(
        order: $order
        searchTerm: $searchTerm
        onlyDone: $onlyDone
        onlyNotDone: $onlyNotDone
        onlyCreatedBy: $onlyCreatedBy
        onlyEmployee: $onlyEmployee
        includeUnassignedEmployee: $includeUnassignedEmployee
        onlyCustomer: $onlyCustomer
        onlyPriority: $onlyPriority
        onlyDueBefore: $onlyDueBefore
        onlyDueAfter: $onlyDueAfter
      )
  }
`;

export const Todos: FC = function Todos() {
  const history = useHistory();
  const filtersSetInUrl = parseTodoConnectionFilters(
    history.location.search,
    defaultFilters,
  );
  const [initialFilters] = useState(filtersSetInUrl);
  const {
    data: props,
    error,
    isLoading,
  } = useQuery<TodosQuery>(query, initialFilters, {
    fetchPolicy: STORE_THEN_NETWORK,
  });

  if (error) {
    throw error;
  }

  return (
    <>
      <Header title="Übersicht" subTitle="Todos" isLoading={isLoading}>
        <Tab
          to="/todo"
          exact
          icon={<Icon icon="clipboard-list" type="regular" />}
        >
          Offen
        </Tab>
        <Tab
          to="/todo/done"
          exact
          icon={<Icon icon="clipboard-check" type="regular" />}
        >
          Erledigt
        </Tab>
      </Header>
      <TodoList
        query={props ?? null}
        urlFilters={initialFilters}
        fallback={<HeaderLoader />}
      />
    </>
  );
};
